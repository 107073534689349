import React, { useEffect, useRef, useCallback, useState } from "react";
import { Box, Stack, Card, Title, Text, Group, Badge, Alert, Loader, Center, Divider, Button } from "@mantine/core";
import { IconAlertCircle, IconUser, IconUserSearch } from "@tabler/icons-react";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { CreatorDetails } from "components/discovery/Datamodels";
import { SearchResult } from "components/discovery/embedding_search/EmbeddingDemoTypes";
import ROCCurveChart from 'components/discovery/embedding_search/ROCCurveChart';
import ReactMarkdown from 'react-markdown';
import RelatedCreatorsModal from 'components/discovery/embedding_search/RelatedCreatorsModal';

interface ResultsColumnProps {
  results: SearchResult;
  title: string;
  creatorsToDisplay: CreatorDetails[];
  hasMore: boolean;
  isLoadingMore: boolean;
  showDifferencesOnly: boolean;
  onLoadMore: () => void;
  loading: boolean;
  indexType: string;
}

const ResultsColumn: React.FC<ResultsColumnProps> = ({
  results,
  title,
  creatorsToDisplay,
  hasMore,
  isLoadingMore,
  showDifferencesOnly,
  onLoadMore,
  loading,
  indexType
}) => {
  // Create a ref for the intersection observer target
  const observerTarget = useRef<HTMLDivElement>(null);

  // State for related creators modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState<{ id: string, name: string } | null>(null);

  // Set up intersection observer for infinite scrolling
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasMore && !isLoadingMore && !showDifferencesOnly) {
        onLoadMore();
      }
    },
    [hasMore, isLoadingMore, onLoadMore, showDifferencesOnly]
  );

  // Create and connect the intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '100px',
      threshold: 0.1
    });
    
    const currentTarget = observerTarget.current;
    
    if (currentTarget && hasMore && !showDifferencesOnly) {
      observer.observe(currentTarget);
    }
    
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerCallback, hasMore, showDifferencesOnly]);

  const handleShowRelatedCreators = (creator: CreatorDetails) => {
    // Get creator name from platform profiles or use creator ID as fallback
    let creatorName = `Creator ${creator.creator_id}`;
    
    if (creator.youtube_channel?.title) {
      creatorName = creator.youtube_channel.title;
    } else if (creator.tiktok_profile?.info.display_name) {
      creatorName = creator.tiktok_profile.info.display_name;
    } else if (creator.instagram_profile?.info.display_name) {
      creatorName = creator.instagram_profile.info.display_name;
    }
    
    // Ensure creator_id is converted to string
    setSelectedCreator({ 
      id: String(creator.creator_id), 
      name: creatorName 
    });
    setModalOpen(true);
  };

  return (
    <Stack gap="md">
      <Card withBorder p="md">
        <Title order={4} mb="md">
          {title}
        </Title>

        {results.error && (
          <Text c="red" mb="md">
            {results.error}
          </Text>
        )}

        {!results.error && results.creators.length > 0 && (
          <>
            <Group justify="space-between" mb="md">
              <Text fw={500}>
                {showDifferencesOnly 
                  ? `Differences (${creatorsToDisplay.length} of ${results.creators.length})` 
                  : `Results (${results.creators.length})`}
              </Text>
              <Group>
                {results.scoringTime !== null && (
                  <Badge color="blue" size="sm">
                    Scoring: {results.scoringTime.toFixed(3)}s
                  </Badge>
                )}
                {results.detailsTime !== null && (
                  <Badge color="green" size="sm">
                    Details: {results.detailsTime.toFixed(3)}s
                  </Badge>
                )}
                {results.scoringTime !== null && results.detailsTime !== null && (
                  <Badge color="violet" size="sm">
                    Total: {(results.scoringTime + results.detailsTime).toFixed(3)}s
                  </Badge>
                )}
              </Group>
            </Group>
            
            {/* Display generated query if available */}
            {results.generatedQuery && (
              <Alert icon={<IconAlertCircle size="1rem" />} color="indigo" mb="md">
                <Text fw={700} mb="xs">Generated Query:</Text>
                <Box className="markdown-content">
                  <ReactMarkdown>{results.generatedQuery}</ReactMarkdown>
                </Box>
              </Alert>
            )}
            
            {/* Display evaluation metrics if available */}
            {results.evaluation && (
              <>
                <Alert icon={<IconAlertCircle size="1rem" />} color="teal" mb="md">
                  <Stack gap="xs">
                    <Text fw={700}>Evaluation Metrics:</Text>
                    {results.evaluation.roc_auc && (
                      <Text size="sm">ROC AUC: {results.evaluation.roc_auc.toFixed(4)}</Text>
                    )}
                    <Text size="sm">Total Accepted: {results.evaluation.total_accepted}</Text>
                    <Text size="sm">Total Rejected: {results.evaluation.total_rejected}</Text>
                    <Text size="sm">Missing Creators: {results.evaluation.missing_creators.length}</Text>
                  </Stack>
                </Alert>
                
                {/* Use the ROC Curve chart when ROC data is available */}
                {results.evaluation.roc_curve && (
                  <ROCCurveChart 
                    data={{
                      roc_curve: results.evaluation.roc_curve,
                      roc_auc: results.evaluation.roc_auc || 0
                    }} 
                  />
                )}
              </>
            )}
            
            {results.totalCreatorsInIndex && (
              <Text size="sm" mb="md" c="dimmed">
                Total creators in index: {results.totalCreatorsInIndex.toLocaleString()}
              </Text>
            )}
          </>
        )}

        {!results.error && results.creators.length === 0 && !loading && (
          <Text c="dimmed">No results to display</Text>
        )}
      </Card>

      {/* Display source creator if available */}
      {results.sourceCreator && (
        <>
          <Card withBorder p="md" mb="md">
            <Group mb="md">
              <IconUser size={20} />
              <Title order={5}>Source Creator</Title>
            </Group>
            <Box style={{ position: "relative" }}>
              <UnifiedCreatorRep
                creator={results.sourceCreator}
                defaultExpandedPlatforms={["tiktok", "youtube", "instagram"]}
                refetchThumbnails
              />
              <Button
                leftSection={<IconUserSearch size={16} />}
                variant="light"
                size="xs"
                style={{ position: "absolute", top: 16, right: 90, zIndex: 1 }}
                onClick={() => handleShowRelatedCreators(results.sourceCreator!)}
              >
                Find Similar
              </Button>
            </Box>
          </Card>
          <Divider label="Similar Creators" labelPosition="center" mb="md" />
        </>
      )}

      {/* Display search results */}
      {creatorsToDisplay.map((creator) => {
        // Calculate background color based on creator status
        let backgroundColor = 'transparent';
        let padding = '8px';
        
        if (creator.is_accepted) {
          backgroundColor = 'rgba(0, 128, 0, 0.2)'; // Slightly darker light green
        } else if (creator.is_rejected) {
          backgroundColor = 'rgba(255, 0, 0, 0.2)'; // Slightly darker light red
        } else {
          padding = '0px';
        }
        
        return (
          <Box 
            key={creator.creator_id} 
            style={{ 
              position: "relative",
              backgroundColor,
              borderRadius: '4px',
              padding,
            }}
          >
            <Box style={{ position: "absolute", top: 16, right: 16, zIndex: 1 }}>
              <Group gap={8}>
                <Button
                  leftSection={<IconUserSearch size={16} />}
                  variant="light"
                  size="xs"
                  onClick={() => handleShowRelatedCreators(creator)}
                >
                  Find Similar
                </Button>
                <Text style={{ fontWeight: 700, color: "blue", fontSize: "sm" }}>
                  Score: {creator.similarity_score.toFixed(4)}
                </Text>
              </Group>
            </Box>
            <UnifiedCreatorRep
              creator={creator}
              defaultExpandedPlatforms={["tiktok", "youtube", "instagram"]}
              refetchThumbnails
            />
          </Box>
        );
      })}

      {/* Infinite scrolling intersection observer target */}
      {!showDifferencesOnly && hasMore && (
        <Box ref={observerTarget} py="md">
          <Center>
            <Loader size="md" />
          </Center>
        </Box>
      )}

      {/* Related Creators Modal */}
      {selectedCreator && (
        <RelatedCreatorsModal
          opened={modalOpen}
          onClose={() => setModalOpen(false)}
          creatorId={selectedCreator.id}
          creatorName={selectedCreator.name}
          indexType={indexType}
        />
      )}
    </Stack>
  );
};

export default ResultsColumn; 