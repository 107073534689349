import React from "react";

import { toLongDateString } from "utils/DateUtils";

import { Badge, Group, Text } from "@mantine/core";

import { SupportedFormat, SupportedPlatform } from "models/Common";

import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import DeliverableCardHeader from "components/contracts/common/DeliverableCardHeader";

function ProgressBadge({
  status,
  isPostOrCarousel,
}: {
  status: ContractDeliverableStatus;
  isPostOrCarousel?: boolean;
}) {
  let badgeColor = null;

  switch (status) {
    case ContractDeliverableStatus.NOT_STARTED:
      badgeColor = "gray";
      break;
    case ContractDeliverableStatus.WAITING_FOR_SCRIPT:
    case ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED:
    case ContractDeliverableStatus.SCRIPT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT:
    case ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED:
    case ContractDeliverableStatus.VIDEO_DRAFT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT:
    case ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED:
    case ContractDeliverableStatus.LIVE_CONTENT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_ASSETS_AND_ANALYTICS:
    case ContractDeliverableStatus.WAITING_FOR_ASSETS:
    case ContractDeliverableStatus.WAITING_FOR_ANALYTICS:
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_REVISIONS_REQUESTED:
      badgeColor = "red";
      break;
    case ContractDeliverableStatus.SCRIPT_SUBMITTED:
    case ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED:
    case ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED:
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_SUBMITTED:
      badgeColor = "yellow";
      break;
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_APPROVED:
    case ContractDeliverableStatus.COMPLETE:
      badgeColor = "teal";
      break;
    default:
      badgeColor = "yellow";
      break;
  }

  let badgeLabel = null;

  switch (status) {
    case ContractDeliverableStatus.NOT_STARTED:
      badgeLabel = "Not Started";
      break;
    case ContractDeliverableStatus.WAITING_FOR_SCRIPT:
      badgeLabel = "Action Required: Submit Concept";
      break;
    case ContractDeliverableStatus.SCRIPT_SUBMITTED:
      badgeLabel = "Awaiting Concept Feedback";
      break;
    case ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED:
      badgeLabel = "Action Required: Revise Concept";
      break;
    case ContractDeliverableStatus.SCRIPT_APPROVED:
      if (isPostOrCarousel) {
        badgeLabel = "Action Required: Submit Post";
      } else {
        badgeLabel = "Action Required: Submit Video";
      }
      break;
    case ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT:
      if (isPostOrCarousel) {
        badgeLabel = "Action Required: Submit Post";
      } else {
        badgeLabel = "Action Required: Submit Video";
      }
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED:
      if (isPostOrCarousel) {
        badgeLabel = "Awaiting Post Feedback";
      } else {
        badgeLabel = "Awaiting Video Feedback";
      }
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED:
      if (isPostOrCarousel) {
        badgeLabel = "Action Required: Revise Post";
      } else {
        badgeLabel = "Action Required: Revise Video";
      }
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_APPROVED:
      badgeLabel = "Action Required: Go Live";
      break;
    case ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT:
      badgeLabel = "Action Required: Go Live";
      break;
    case ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED:
      badgeLabel = "Live Content Under Review";
      break;
    case ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED:
      badgeLabel = "Action Required: Revise Live Content";
      break;
    case ContractDeliverableStatus.LIVE_CONTENT_APPROVED:
      badgeLabel = "Action Required: Submit Assets & Analytics";
      break;
    case ContractDeliverableStatus.WAITING_FOR_ASSETS_AND_ANALYTICS:
      badgeLabel = "Action Required: Submit Assets & Analytics";
      break;
    case ContractDeliverableStatus.WAITING_FOR_ASSETS:
      badgeLabel = "Action Required: Submit Assets";
      break;
    case ContractDeliverableStatus.WAITING_FOR_ANALYTICS:
      badgeLabel = "Action Required: Submit Analytics";
      break;
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_SUBMITTED:
      badgeLabel = "Assets & Analytics Under Review";
      break;
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_REVISIONS_REQUESTED:
      badgeLabel = "Action Required: Revise Assets & Analytics";
      break;
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_APPROVED:
      badgeLabel = "Complete";
      break;
    case ContractDeliverableStatus.COMPLETE:
      badgeLabel = "Complete";
      break;
    default:
      badgeLabel = "In Progress";
      break;
  }

  return (
    <Badge color={badgeColor} variant="light">
      {badgeLabel}
    </Badge>
  );
}

export default function ContentSubmissionCardHeader({
  platform,
  format,
  liveDate,
  deliverableStatus,
  isPostOrCarousel,
}: {
  platform: SupportedPlatform;
  format: SupportedFormat;
  liveDate: Date;
  deliverableStatus: ContractDeliverableStatus;
  isPostOrCarousel?: boolean;
}) {
  return (
    <Group justify="space-between" py="0.2rem">
      <DeliverableCardHeader
        platform={platform}
        format={format}
        subHeading={
          <Text size="sm" c="dimmed">
            by {toLongDateString(liveDate)}
          </Text>
        }
      />
      <ProgressBadge status={deliverableStatus} isPostOrCarousel={isPostOrCarousel} />
    </Group>
  );
}
