import { Badge, Box, Button, Group, Stack, Text, TextInput, Title } from "@mantine/core";
import { CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import { CreatorSetItem } from "components/discovery/Datamodels";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { selectCreatorSets } from "reduxStore/creatorSetsSelectors";
import { BaseCreatorSetReviewView } from "components/creator_sets/BaseCreatorSetReviewView";
import { CreatorSetStateIndex } from "components/creator_sets/CreatorSetUtils";
import { IconSearch, IconUserCheck } from "@tabler/icons-react";
import { useElementSize, useInputState, useThrottledValue } from "@mantine/hooks";

const CreatorSetEmptyState = ({ calculatedHeight }: { calculatedHeight: string }) => {
  return (
    <Box style={{ height: calculatedHeight, alignContent: "center" }}>
      <Stack style={{ gap: 12, width: "100%" }} align="center">
        <IconUserCheck size={100} color="var(--mantine-color-dark-0)" />
        <Title order={3} c="var(--mantine-color-dark-2)">
          No New Recommendations
        </Title>
        <Text
          size="lg"
          c="var(--mantine-color-dark-2)"
          style={{
            width: 350,
            textAlign: "center",
          }}>
          You will be notified when you have new recommended creators.
        </Text>
      </Stack>
    </Box>
  );
};

interface CreatorSetAndNumRecommendations {
  id: number;
  name: string;
  numRecommendations: number;
}

const CreatorSetsRecommendations = () => {
  const navigate = useNavigate();
  const allCreatorSets = useSelector(selectCreatorSets) as CreatorSetItem[];
  const creatorSetIdToNumRecs = new Map(
    allCreatorSets.map(({ id, num_needs_review }) => {
      return [id, num_needs_review];
    }),
  );
  const { ref: headerRef, height: headerHeight } = useElementSize();

  // see if creatorSetId is selected
  const { creatorSetId } = useParams<{ creatorSetId: string }>();

  const [selectedCreatorSetId, setSelectedCreatorSetId] = useState<number | undefined>(
    creatorSetId !== undefined ? parseInt(creatorSetId, 10) : undefined,
  );

  const [creatorSetsAndNumRecommendations, setCreatorSetsAndNumRecommendations] = useState<
    CreatorSetAndNumRecommendations[]
  >([]);

  const [searchParams] = useSearchParams();
  // Get the query parameter 'q'
  const existingSearchQuery = searchParams.get("q");

  const [searchQuery, setSearchQuery] = useInputState(existingSearchQuery || "");
  const throttledSearchQuery = useThrottledValue(searchQuery, 1000);

  useEffect(() => {
    let currentCreatorSetsAndNumRecommendations = allCreatorSets.map((creatorSet) => {
      return {
        id: creatorSet.id,
        name: creatorSet.name,
        numRecommendations: creatorSet.num_needs_review,
      };
    });

    // filter to match search query
    if (throttledSearchQuery) {
      currentCreatorSetsAndNumRecommendations = currentCreatorSetsAndNumRecommendations.filter(
        (item) => item.name.toLowerCase().includes(throttledSearchQuery.toLowerCase()),
      );
    }

    // sort creator sets by number of new recommendations (highest to lowest)
    currentCreatorSetsAndNumRecommendations.sort(
      (a, b) => b.numRecommendations - a.numRecommendations,
    );
    setCreatorSetsAndNumRecommendations(currentCreatorSetsAndNumRecommendations);

    if (creatorSetId === undefined && currentCreatorSetsAndNumRecommendations.length > 0) {
      setSelectedCreatorSetId(currentCreatorSetsAndNumRecommendations[0].id);
    } else {
      setSelectedCreatorSetId(parseInt(creatorSetId, 10));
    }
  }, [allCreatorSets, creatorSetId, throttledSearchQuery]);

  return (
    <Stack style={{ gap: 16 }}>
      <Stack ref={headerRef} style={{ gap: 16 }}>
        <Title order={2}>Recommendations</Title>
        <Group style={{ gap: 8, overflowX: "scroll" }} wrap="nowrap">
          <TextInput
            value={searchQuery}
            radius="xl"
            style={{
              width: "fit-content",
            }}
            styles={{
              input: {
                width: "fit-content",
              },
            }}
            leftSection={<IconSearch size={16} />}
            placeholder="Find a creator set"
            onChange={setSearchQuery}
          />
          {creatorSetsAndNumRecommendations.map(({ id, name, numRecommendations }, index) => {
            return (
              <Button
                key={`creatorSetRecommendations-${id}`}
                onClick={() => {
                  if (searchQuery) {
                    navigate(`${CAMPAIGN_PORTAL}/creator_sets/${id}/recommended?q=${searchQuery}`);
                  } else {
                    navigate(`${CAMPAIGN_PORTAL}/creator_sets/${id}/recommended`);
                  }
                }}
                radius="xl"
                variant={id === selectedCreatorSetId ? "filled" : "white"}
                style={{ overflow: "visible" }}>
                <Group style={{ gap: 8, flexWrap: "nowrap" }}>
                  <Text
                    c={
                      id === selectedCreatorSetId
                        ? "var(--mantine-color-white)"
                        : "var(--mantine-color-black)"
                    }
                    size="sm">
                    {name}
                  </Text>
                  {numRecommendations > 0 ? (
                    <Badge
                      color="red"
                      style={{
                        flexShrink: 0,
                      }}
                      styles={{
                        label: {
                          overflow: "visible",
                        },
                      }}>
                      {numRecommendations}
                    </Badge>
                  ) : null}
                </Group>
              </Button>
            );
          })}
        </Group>
      </Stack>
      {selectedCreatorSetId !== undefined && creatorSetIdToNumRecs.get(selectedCreatorSetId) > 0 ? (
        <BaseCreatorSetReviewView
          creatorSetId={selectedCreatorSetId}
          creatorSetStateIndex={CreatorSetStateIndex.NEEDS_REVIEW}
          setCreatorSetStateIndex={() => {}}
          showBanner={false}
        />
      ) : null}
      {selectedCreatorSetId !== undefined &&
      creatorSetIdToNumRecs.get(selectedCreatorSetId) === 0 ? (
        // 48px total = 32px for 16px padding on top and bottom of appshell main and additional 16px for gap between header and empty state
        <CreatorSetEmptyState calculatedHeight={`calc(100vh - ${headerHeight}px - 48px)`} />
      ) : null}
    </Stack>
  );
};

export default CreatorSetsRecommendations;
