import { Chip, Flex, Text } from "@mantine/core";
import Spacer from "components/Spacer";
import { MembershipTypes } from "models/User";
import { OnboardingV2Context } from "onboarding/v2/OnboardingV2Context";
import React, { useContext, useEffect } from "react";

const BANNED_DOMAINS = [
  "gmail.com", "googlemail.com",
  "yahoo.com", "yahoo.co.uk", "ymail.com",
  "hotmail.com", "hotmail.co.uk", "live.com", "msn.com", "outlook.com", "outlook.co.uk",
  "icloud.com", "me.com", "mac.com",
  "aol.com",
  "gmx.com", "gmx.net",
  "mail.com",
  "protonmail.com", "proton.me",
];

export const validateEmailForMembershipResult = (
  email: string,
  membershipType: MembershipTypes | null,
): string | null => {
  if (membershipType && membershipType !== MembershipTypes.BASIC) {
    // for non-creators, email cannot end in gmail, outlook, hotmail, yahoo, aol and also cannot have + in their email unless 1stcollab email
    const emailNoPlusRegex = /^[a-zA-Z0-9._%-]+@(?<domain>[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const collabEmailRegex = /^[a-zA-Z0-9._%+-]+@1stcollab.com$/;

    const emailMatch = emailNoPlusRegex.exec(email);
    const collabEmailMatch = collabEmailRegex.exec(email);

    // if 1stcollab email let through
    if (collabEmailMatch) {
      return null;
    }

    // first check if email is in email form
    if (emailMatch === null) {
      return "Invalid email (e.g. containing invalid characters)";
    }

    // cannot end in gmail, outlook, hotmail, yahoo, aol
    if (BANNED_DOMAINS.includes(emailMatch.groups.domain)) {
      return "To sign up as a brand, please use a business email address.";
    }
    return null;
  }
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) ? null : "Invalid email";
};

export const ONBOARDING_MEMBERSHIP_OPTIONS = [
  MembershipTypes.BASIC,
  MembershipTypes.BRAND_USER,
  MembershipTypes.CREATOR_MANAGER,
  MembershipTypes.BRAND_MANAGER,
  MembershipTypes.NONE,
];

export const getLabelForMembershipType = (type: MembershipTypes): string | null => {
  switch (type) {
    case MembershipTypes.BASIC:
      return "Creator / Influencer";
    case MembershipTypes.BRAND_USER:
      return "Brand";
    case MembershipTypes.CREATOR_MANAGER:
      return "Creator Management / Talent Agency";
    case MembershipTypes.BRAND_MANAGER:
      return "Brand Agency / Ad Agency";
    case MembershipTypes.NONE:
    default:
      return "Other";
  }
};

const OnboardingMembershipStep = () => {
  const { selectedMembershipType, setSelectedMembershipType } = useContext(OnboardingV2Context);

  useEffect(() => {
    // write membership to localStorage
    localStorage.setItem("userOnboardingMembershipType", selectedMembershipType?.toString() || "");
  }, [selectedMembershipType]);
  return (
    <>
      <Text fw={500} size="lg" style={{ textAlign: "center" }}>
        Which of the following best describes you or your organization?
      </Text>
      <Spacer height={48} />
      <Flex gap={8} wrap="wrap" justify="center" maw="100%">
        {ONBOARDING_MEMBERSHIP_OPTIONS.map((type) => {
          const label = getLabelForMembershipType(type);
          return (
            <Chip
              key={type}
              variant="outline"
              size="md"
              radius="4px"
              checked={type === selectedMembershipType}
              onChange={() => setSelectedMembershipType(type)}>
              {label}
            </Chip>
          );
        })}
      </Flex>
    </>
  );
};

export default OnboardingMembershipStep;
