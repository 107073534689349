import { API_URL } from "configs/Configs";

import { CampaignCreatorListResponse, CampaignCreatorProgressResponse } from "models/Campaign";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { toISODateString } from "utils/DateUtils";

const CREATOR_PROGRESS_REPORT_URL = `${API_URL}/api/campaigns/creator_progress_report`;
const CREATORS_FOR_PROGRESS_URL = `${API_URL}/api/campaigns/get_creators_for_progress`;

export const fetchCreatorProgressOverview = async (
  campaignHashId: number,
  timeframe: string,
  activityRange: [Date | null, Date | null],
  cohortRange: [Date | null, Date | null],
  reportType: string,
  repeatDealsOnly: boolean,
  abortController: AbortController,
): Promise<CampaignCreatorProgressResponse> => {
  const requestUrl = new URL(CREATOR_PROGRESS_REPORT_URL);
  requestUrl.searchParams.append("campaignId", campaignHashId.toString());
  requestUrl.searchParams.append("timeframe", timeframe);
  requestUrl.searchParams.append("report_type", reportType);
  if (activityRange[0] !== null && activityRange[1] !== null) {
    requestUrl.searchParams.append("activity_start", toISODateString(activityRange[0]));
    requestUrl.searchParams.append("activity_end", toISODateString(activityRange[1]));
  }
  if (cohortRange[0] !== null && cohortRange[1] !== null) {
    requestUrl.searchParams.append("cohort_start", toISODateString(cohortRange[0]));
    requestUrl.searchParams.append("cohort_end", toISODateString(cohortRange[1]));
  }
  if (repeatDealsOnly) {
    requestUrl.searchParams.append("repeat_deals_only", "true");
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response: CampaignCreatorProgressResponse | null = await handleResult(
    request,
    abortController,
  );

  if (response) {
    return response;
  }
  return null;
};

export const fetchCreatorsForProgress = async (
  campaignHashId: number,
  cohortRange: [Date | null, Date | null],
  timeframe: string,
  activityRange: [Date | null, Date | null],
  state: string,
  repeatDealsOnly: boolean,
  abortController: AbortController,
): Promise<CampaignCreatorListResponse> => {
  const requestUrl = new URL(CREATORS_FOR_PROGRESS_URL);
  requestUrl.searchParams.append("campaignId", campaignHashId.toString());
  if (timeframe !== undefined) {
    requestUrl.searchParams.append("timeframe", timeframe);
  }
  if (activityRange[0] !== null && activityRange[1] !== null) {
    requestUrl.searchParams.append("activity_start", toISODateString(activityRange[0]));
    requestUrl.searchParams.append("activity_end", toISODateString(activityRange[1]));
  }
  if (state !== undefined) {
    requestUrl.searchParams.append("state", state);
  }
  if (cohortRange[0] !== null && cohortRange[1] !== null) {
    requestUrl.searchParams.append("cohort_start", toISODateString(cohortRange[0]));
    requestUrl.searchParams.append("cohort_end", toISODateString(cohortRange[1]));
  }
  if (repeatDealsOnly) {
    requestUrl.searchParams.append("repeat_deals_only", "true");
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response: CampaignCreatorListResponse | null = await handleResult(request, abortController);

  if (response) {
    return response;
  }
  return null;
};

export default null;
