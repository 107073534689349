import React, { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { 
	Box,
	Button,
	Flex,
	Group,
	Stack,
	Avatar,
	Text,
	Chip,
	Card,
	Center,
	Title,
	Image,
	Tooltip,
	Space,
	Textarea,
} from "@mantine/core";
import { NEGATIVE_TAGS, POSITIVE_TAGS, Rating } from "models/Rating";
import Star from "components/ratings/Star";
import Stars from "components/ratings/Stars";
import RatingsViewModal from "components/ratings/RatingsViewModal";
import RatingsListViewModal from "components/ratings/RatingsListViewModal";
import { IconInfoCircle } from "@tabler/icons-react";
import RatingsModal from "components/ratings/RatingsModal";

type CompactRatingNoRatingsProps = {
  opened: boolean,
  openRatingsModal: () => void,
  closeRatingsModal: () => void,
  creatorName: string,
  creatorAvatar?: string,
  handleSubmit: (rating: Rating) => void,
}

type CompactRatingListProps = {
  size?: number;
  showViewDetails?: boolean;
  creatorName: string;
  creatorAvatar?: string;
  creatorRatings: Rating[];
};

type CompactRatingProps = {
  size?: number;
	showViewDetails?: boolean,
	creatorName: string,
	creatorAvatar?: string,
	creatorRating: Rating
}

type BrandViewRatingProps = {
	rating: Rating,
	creatorName: string,
	creatorAvatar?: string,
}

type BrandSubmitRatingProps = {
	creatorName: string,
	handleSubmit: (rating: Rating) => void,
}

export const BrandSubmitRating = ({
	creatorName,
	handleSubmit,
}: BrandSubmitRatingProps) => {
	const [rating, setRating] = useState<Rating>({
		starValue: 0,
		tags: [],
		description: "",
	});
	const isFiveStars = rating.starValue === 5;
	const tags = isFiveStars ? POSITIVE_TAGS : NEGATIVE_TAGS; 
	const isOther = rating.tags.includes("Other");

	const handleStarClick = (star: number) => {
		setRating({ ...rating, starValue: star, tags: [], description: "" });
	}

	const handleTagClick = (newTags: string[]) => {
		setRating({ ...rating, tags: newTags });
	}
		
	return (
	  <Card withBorder radius="md" py="md" px="xl">
		<Flex direction="column">
			<Text fw={600} size="lg">Your Rating of {creatorName}</Text>
			<Flex
				direction="column"
				justify="space-between"
				gap="sm"
			>
				<Flex>
					<Text>
						How likely are you to work with this creator again?
					</Text>
					<Center pl="xs">
						<Tooltip 
							multiline
							arrowSize={4} 
							w={220}
							label={
								<Text ta="center" size="xs">
									Your ratings will be aggregated and anonymized. Creators cannot see how you rate them.
								</Text>
							}
							color="#868E96" 
							withArrow>
							<IconInfoCircle size={18} color="#868E96"/>
						</Tooltip>
					</Center>
				</Flex>
				<Stars rating={rating} handleStarClick={handleStarClick} />
			</Flex>         
			{rating.starValue > 0 && <Flex direction="column" py="md">
				<Flex>
					<Text>
						{isFiveStars
							? "What did you like about this partnership?"
							: "What issues did you encounter in this partnership?"}
					</Text>
				</Flex>
				<Chip.Group multiple value={rating.tags} onChange={(newTags) => handleTagClick(newTags)}>
					<Group mt="md">
						{tags.map((tag) => 
							<Chip key={tag} variant="outline" value={tag}>{tag}</Chip>
						)}
					</Group>
				</Chip.Group>
			</Flex>}
			{isOther && <Textarea
				w="100%"
				label="Add reasons for 'Other':"
				value={rating.description}
				onChange={(event) => setRating({
					...rating,
					description: event.currentTarget.value
				})}
			/>
			}     
			<Stack py="sm">
			  <Button 
				  onClick={() => handleSubmit(rating)}
				  fullWidth
				  color={rating.starValue 
					  ? "#228BE6"
					  : "#E9ECEF" 
				  }>
				  <Text c={rating.starValue ? "#FFFFFF" : "#ADB5BD"}>Submit Rating</Text>
			  </Button>
			</Stack>           
		</Flex>
	  </Card>
	);
}


export const BrandViewRating = ({ 
	rating,
	creatorName,
	creatorAvatar,
}: BrandViewRatingProps) => {
	const stars = Array.from({ length: 5 }, (_, index) => index + 1);
	
	return (
	  <Card withBorder radius="md" py="md" px="xl">
		<Stack w="100%">
			<Stack gap="xs">
				<Text fw={600} size="lg">Your Rating of {creatorName}</Text>
				<Group justify="flex-start" align="flex-start"> 
					<Avatar src={creatorAvatar} size="4.25rem" />
					<Group
					justify="flex-start"
					align="start"
					>
						<Box>
							<Group gap="xs">
							{stars.map((star) => 
								<Star 
									active={star <= (rating.starValue ?? 0)}
									key={star}
									clickable={false}
									size={24}
								/>
							)}
							</Group>
							{rating?.tags?.length > 0 && (
							<Group gap="xs" py={12}>
								{rating.tags.map((tag) => (
									<Chip
										key={tag}
										variant="outline"
										checked={false}
										disabled
										styles={{
											label: {
												backgroundColor: 'inherit',
												borderColor: '#CED4DA',
												color: 'black',
												cursor: 'default'
											},
											root: {
												opacity: 1
											}
										}}
									>
										{tag}
									</Chip>
								))}
							</Group>
							)}
							<Box mih={18}>
								{rating.description && <Text>&quot;{rating.description}&quot;</Text>}
							</Box>
						</Box>
					</Group>
				</Group>
			</Stack>
		</Stack>      
	</Card>
	);
}

// A short view showing only stars
export const CompactRating = ({ 
	size = 16, 
	showViewDetails = true,
	creatorName,
	creatorAvatar,
	creatorRating
} : CompactRatingProps) => {
	// Opens creator ratings modal on "View details"
	const [opened, { open: openRatingsViewModal, close: closeRatingsViewModal }] = 
		useDisclosure(false);

	const stars = Array.from({ length: 5 }, (_, index) => index + 1);
	
	return (
		<Flex 
		  align="center"
		  w="100%"
		>
			<Group gap={1} align="center" justify="center" wrap="nowrap">
				{stars.map((star) => 
					<Star 
						key={star}
						active={star <= (creatorRating?.starValue ?? 0)}
						clickable={false}
						size={size}
					/>
				)}
			</Group>
			{showViewDetails && (
				<Button 
					variant="subtle"
					size="xs"
					onClick={openRatingsViewModal}
					styles={{
						label: {
							textDecoration: "underline",
							color: "#228BE6"
						}
					}}
				>
					View details
				</Button>
			)}
			<RatingsViewModal 
				opened={opened} 
				close={closeRatingsViewModal}
				creatorName={creatorName}
				creatorAvatar={creatorAvatar}
				creatorRating={creatorRating}
			/>
		</Flex>
	);
};

export const CompactRatingList = ({
  size = 16,
  showViewDetails = true,
  creatorName,
  creatorAvatar,
  creatorRatings,
}: CompactRatingListProps) => {
  const [opened, { open: openRatingsViewModal, close: closeRatingsViewModal }] = 
  useDisclosure(false);
  return (
    <Flex>
      <Button 
					variant="outline"
					onClick={openRatingsViewModal}
					styles={{
						label: {
							color: "#228BE6"
						}
					}}
				>
					Show all ratings
				</Button>
        <RatingsListViewModal
          opened={opened}
          close={closeRatingsViewModal}
          creatorName={creatorName}
          creatorAvatar={creatorAvatar}
          creatorRatings={creatorRatings}
        />
    </Flex>
  );
};

export const CompactRatingNoRatings = ({
  opened,
  openRatingsModal,
  closeRatingsModal,
  creatorName,
  creatorAvatar,
  handleSubmit,
}: CompactRatingNoRatingsProps) => {
  return (
    <Group>
      <Button variant="outline" color="blue" onClick={openRatingsModal}>
        Rate Creator
      </Button>
      <RatingsModal
        opened={opened}
        close={closeRatingsModal}
        handleSubmit={handleSubmit}
      />
    </Group>
  );
};