import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "reduxStore/hooks";

import {
  ActionIcon,
  Button,
  Card,
  Center,
  Drawer,
  Flex,
  Group,
  HoverCard,
  Loader,
  Modal,
  NumberInput,
  Radio,
  Stack,
  Space,
  Table,
  Text,
  Title,
  Tooltip,
  Progress,
  ThemeIcon,
  Anchor,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import {
  IconInfoCircle,
  IconPencil,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
} from "@tabler/icons-react";

import { BudgetType, CampaignStatus } from "models/Campaign";
import { updateCampaignStatus, updateCurrentBudget } from "reduxStore/campaignsSlice";

import {
  fetchBudgetAndSpendForCampaign,
  pauseCampaign,
  resumeCampaign,
  saveBudgetsForCampaign,
  BudgetAndSpend,
  BudgetAndSpendResponse,
} from "campaigns/api/fetchBudgetAndSpend";

import { showFailureNotification } from "components/common/Notifications";

const DRAWER_WIDTH = 750;
const PADDING_WIDTH = 32;

function formatDollarAmount(amount: number): string {
  return (amount / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

function formatPercentage(value: number, decimals = 2): string {
  return `${value.toFixed(decimals)}%`;
}

function toMonthYearString(month: string): string {
  if (month === "Lifetime") {
    return "Lifetime";
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthIndex = parseInt(month.split("-")[1], 10) - 1;
  const monthName = monthNames[monthIndex];

  return `${monthName} ${month.split("-")[0]}`;
}

function Header({
  canSave,
  handleClose,
  handleSave,
  saving,
}: {
  canSave: boolean;
  handleClose: () => void;
  handleSave: () => void;
  saving: boolean;
}) {
  return (
    <Flex justify="space-between" w={DRAWER_WIDTH - PADDING_WIDTH}>
      <Title order={3}>Edit Budget</Title>
      <Group gap={4}>
        <Button size="xs" onClick={handleClose} variant="subtle" color="gray">
          Cancel
        </Button>
        <Button loading={saving} size="xs" onClick={handleSave} disabled={!canSave}>
          Save
        </Button>
      </Group>
    </Flex>
  );
}

function SpendBar({
  budget,
  completed,
  contracted,
  pending,
}: {
  budget: number;
  completed: number;
  contracted: number;
  pending: number;
}) {
  const completedPercent = (completed / budget) * 100;
  const contractedPercent = (contracted / budget) * 100;
  const pendingPercent = (pending / budget) * 100;

  return (
    <Stack gap={4}>
      <Group justify="space-between">
        <Text size="xs">
          {formatDollarAmount(completed + contracted + pending)} / {formatDollarAmount(budget)}
        </Text>
        {budget > 0 && (
          <Text size="xs">
            {formatPercentage(completedPercent + contractedPercent + pendingPercent, 0)}
          </Text>
        )}
      </Group>
      <Progress.Root>
        <Tooltip
          label={`Completed - ${formatDollarAmount(completed)} (${formatPercentage(
            completedPercent,
          )})`}>
          <Progress.Section value={completedPercent} color="cyan" />
        </Tooltip>
        <Tooltip
          label={`Contracted - ${formatDollarAmount(contracted)} (${formatPercentage(
            contractedPercent,
          )})`}>
          <Progress.Section value={contractedPercent} color="pink" />
        </Tooltip>
        <Tooltip
          label={`Pending - ${formatDollarAmount(pending)} (${formatPercentage(pendingPercent)})`}>
          <Progress.Section value={pendingPercent} color="orange" />
        </Tooltip>
      </Progress.Root>
    </Stack>
  );
}

function InfoHoverCard({ disabled }: { disabled: boolean }) {
  if (disabled) {
    return <Space w={18} />;
  }

  return (
    <HoverCard width={240} shadow="md" position="top">
      <HoverCard.Target>
        <ThemeIcon variant="transparent" color="gray" size="xs">
          <IconInfoCircle />
        </ThemeIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text size="xs">
          Creators are contracted up to a month in advance. To ensure we can meet these commitments,
          only budget increases are allowed for the current and upcoming month.
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

function EditRecurringBudgetModal({
  campaignTitle,
  monthName,
  budget,
  opened,
  close,
  handleForwardFill,
}: {
  campaignTitle: string;
  monthName: string;
  budget: number;
  opened: boolean;
  close: () => void;
  handleForwardFill: () => void;
}) {
  const [value, setValue] = useState("currentMonth");

  const handleConfirm = () => {
    if (value === "futureMonths") {
      handleForwardFill();
    }
    close();
  };

  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={close}
      title={<Title order={4}>Edit recurring budget for {campaignTitle}</Title>}>
      <Stack>
        <Radio.Group value={value} onChange={setValue} name="fillRecurringBudget">
          <Radio
            value="currentMonth"
            label={`Update budget to ${formatDollarAmount(budget)} for just ${monthName}`}
          />
          <Radio
            mt="xs"
            value="futureMonths"
            label={`Update budget to ${formatDollarAmount(
              budget,
            )} for ${monthName} and all following months`}
          />
        </Radio.Group>
        <Group justify="right" gap={4}>
          <Button size="xs" onClick={close} variant="subtle" color="gray">
            Cancel
          </Button>
          <Button size="xs" onClick={handleConfirm}>
            OK
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

function BudgetRow({
  campaignTitle,
  budgetRowData,
  budget,
  setBudget,
  minBudget,
  validBudget,
  markValid,
  validateBudget,
  showRecurringModal,
  handleForwardFill,
  disabled,
  isAdmin,
}: {
  campaignTitle: string;
  budgetRowData: BudgetAndSpend;
  budget: number;
  setBudget: (value: number) => void;
  minBudget: number;
  validBudget: boolean;
  markValid: () => void;
  validateBudget: () => boolean;
  showRecurringModal: boolean;
  handleForwardFill: (month: string, budget: number) => void;
  disabled: boolean;
  isAdmin: boolean;
}) {
  const now = new Date();

  const isLifetimeBudget = budgetRowData.month === "Lifetime";
  const isCurrentMonth =
    !isLifetimeBudget && new Date(budgetRowData.month).getUTCMonth() === now.getUTCMonth();
  const isNextMonth =
    !isLifetimeBudget && new Date(budgetRowData.month).getUTCMonth() === now.getUTCMonth() + 1;

  const monthName = toMonthYearString(budgetRowData.month);

  const getErrorMessage = () => {
    if (!validBudget) {
      if (isAdmin) {
        return `Admin override: You can decrease below the minimum budget (${formatDollarAmount(
          minBudget,
        )})`;
      }
      if (isCurrentMonth) {
        return `Cannot decrease budget below ${formatDollarAmount(
          minBudget,
        )} for the current month`;
      } else if (isNextMonth) {
        return `Cannot decrease budget below ${formatDollarAmount(minBudget)} for the next month`;
      }
      return `Cannot decrease below ${formatDollarAmount(minBudget)} of already committed spend`;
    }
    return null;
  };

  const handleNumberInputChange = (value: number) => {
    setBudget(value * 100);
    markValid();
  };

  // Modal for editing recurring budget
  const [opened, { open, close }] = useDisclosure();

  return (
    <Table.Tr>
      <EditRecurringBudgetModal
        campaignTitle={campaignTitle}
        monthName={monthName}
        budget={budget}
        opened={opened}
        close={close}
        handleForwardFill={() => handleForwardFill(budgetRowData.month, budget)}
      />
      <Table.Td miw={130}>{monthName}</Table.Td>
      <Table.Td>
        <Flex gap="xs" align="center">
          <NumberInput
            allowNegative={false}
            allowLeadingZeros={false}
            onBlur={() => validateBudget() && showRecurringModal && open()}
            hideControls
            size="xs"
            value={budget / 100}
            onChange={handleNumberInputChange}
            prefix="$"
            decimalSeparator="."
            thousandSeparator=","
            decimalScale={2}
            fixedDecimalScale
            error={!validBudget && getErrorMessage()}
            disabled={disabled}
          />
          <InfoHoverCard disabled={!isCurrentMonth && !isNextMonth} />
        </Flex>
      </Table.Td>
      <Table.Td w="60%">
        <SpendBar
          budget={budgetRowData.budget}
          completed={budgetRowData.completed}
          contracted={budgetRowData.contracted}
          pending={budgetRowData.pending}
        />
      </Table.Td>
    </Table.Tr>
  );
}

function PausedCampaignBanner() {
  return (
    <Card bg="var(--mantine-color-red-0)" radius="md" p="xs">
      <Flex align="center" justify="center" gap={4}>
        <ThemeIcon variant="transparent" color="red" size="sm">
          <IconInfoCircle />
        </ThemeIcon>
        <Text c="red" fw="600" size="sm">
          This campaign is paused. Click &ldquo;Resume Campaign&rdquo; to continue.
        </Text>
      </Flex>
    </Card>
  );
}

function Contents({
  campaignHashId,
  campaignTitle,
  campaignStatus,
  budgetRowsData,
  budgetType,
  newBudgets,
  setNewBudgets,
  minBudgets,
  validBudgets,
  setValidBudgets,
  validateBudget,
  handlePause,
  handleResume,
  pauseOrResumeLoading,
  isAdmin,
}: {
  campaignHashId: number;
  campaignTitle: string;
  campaignStatus: CampaignStatus;
  budgetRowsData: BudgetAndSpend[];
  budgetType: BudgetType;
  newBudgets: Record<string, number>;
  setNewBudgets: (newBudgets: Record<string, number>) => void;
  minBudgets: Record<string, number>;
  validBudgets: Record<string, boolean>;
  setValidBudgets: (validBudgets: Record<string, boolean>) => void;
  validateBudget: (month: string) => boolean;
  handlePause: () => void;
  handleResume: () => void;
  pauseOrResumeLoading: boolean;
  isAdmin: boolean;
}) {
  const rows = budgetRowsData.map((budgetRowData, index) => (
    <BudgetRow
      key={`${budgetRowData.month}-${campaignHashId}`}
      campaignTitle={campaignTitle}
      budgetRowData={budgetRowData}
      budget={newBudgets[budgetRowData.month]}
      setBudget={(value: number) => {
        setNewBudgets({
          ...newBudgets,
          [budgetRowData.month]: value,
        });
      }}
      minBudget={minBudgets[budgetRowData.month]}
      validBudget={validBudgets[budgetRowData.month]}
      markValid={() => {
        setValidBudgets({
          ...validBudgets,
          [budgetRowData.month]: true,
        });
      }}
      validateBudget={() => validateBudget(budgetRowData.month)}
      showRecurringModal={
        budgetType === BudgetType.Monthly &&
        index !== budgetRowsData.length - 1 &&
        newBudgets[budgetRowData.month] !== budgetRowData.budget
      }
      handleForwardFill={(month: string, budget: number) => {
        const monthIndex = budgetRowsData.findIndex((row) => row.month === month);
        const newBudgetsCopy = { ...newBudgets };
        for (let i = monthIndex + 1; i < budgetRowsData.length; i += 1) {
          newBudgetsCopy[budgetRowsData[i].month] = budget;
        }
        setNewBudgets(newBudgetsCopy);
      }}
      disabled={
        pauseOrResumeLoading ||
        campaignStatus === CampaignStatus.Paused ||
        campaignStatus === CampaignStatus.Completed
      }
      isAdmin={isAdmin}
    />
  ));

  return (
    <Stack mt="sm">
      {campaignStatus === CampaignStatus.Paused && <PausedCampaignBanner />}
      <Group justify="space-between">
        <Title order={4}>{campaignTitle}</Title>
        {campaignStatus === CampaignStatus.Active && (
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconPlayerPauseFilled size="1rem" />}
            onClick={handlePause}
            loading={pauseOrResumeLoading}>
            Pause Campaign
          </Button>
        )}
        {campaignStatus === CampaignStatus.Paused && (
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconPlayerPlayFilled size="1rem" />}
            onClick={handleResume}
            loading={pauseOrResumeLoading}>
            Resume Campaign
          </Button>
        )}
      </Group>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>
              <Text fw="600" size="sm">
                {budgetType === BudgetType.Once ? "Period" : "Month"}
              </Text>
            </Table.Th>
            <Table.Th>
              <Text fw="600" size="sm">
                Budget
              </Text>
            </Table.Th>
            <Table.Th>
              <Text fw="600" size="sm">
                Spend
              </Text>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Stack>
  );
}

function SaveWarningModal({
  opened,
  close,
  closeDrawer,
  handleSave,
}: {
  opened: boolean;
  close: () => void;
  closeDrawer: () => void;
  handleSave: () => void;
}) {
  return (
    <Modal
      opened={opened}
      onClose={close}
      title={<Title order={4}>Your changes have not been saved</Title>}
      size="sm"
      withCloseButton={false}>
      <Stack>
        <Text size="sm">Do you want to save before you exit the budget settings?</Text>
        <Group grow>
          <Button
            size="sm"
            variant="subtle"
            color="gray"
            onClick={() => {
              close();
              closeDrawer();
            }}>
            Discard Changes
          </Button>
          <Button
            size="sm"
            onClick={() => {
              close();
              handleSave();
            }}>
            Save Changes
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

function ResumeCampaignModal({
  campaignHashId,
  campaignTitle,
  budgetType,
  opened,
  close,
  setPauseOrResumeLoading,
  handleBudgetRowsDataResponse,
}: {
  campaignHashId: number;
  campaignTitle: string;
  budgetType: BudgetType;
  opened: boolean;
  close: () => void;
  setPauseOrResumeLoading: (loading: boolean) => void;
  handleBudgetRowsDataResponse: (data: BudgetAndSpend[]) => void;
}) {
  const dispatch = useAppDispatch();

  const [newBudget, setNewBudget] = useState(0);

  const handleResume = () => {
    setPauseOrResumeLoading(true);
    close();
    resumeCampaign(campaignHashId, newBudget)
      .then((response) => {
        if (response.success) {
          handleBudgetRowsDataResponse(response.data);
          dispatch(
            updateCampaignStatus({
              campaignHashId,
              newStatus: CampaignStatus.Active,
            }),
          );
        } else {
          showFailureNotification({
            message: "Failed to resume campaign",
          });
        }
      })
      .finally(() => setPauseOrResumeLoading(false));
  };

  const productGuideLink = (
    <Anchor href="https://1stcollab.notion.site/1stCollab-Brand-Product-Guide-c260996ea008436c9837f6078b83bdab">
      Product Guide
    </Anchor>
  );

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={<Title order={4}>Resume Campaign</Title>}
      size="md"
      withCloseButton={false}>
      <Stack>
        {budgetType === BudgetType.Monthly && (
          <Text size="sm">
            To resume {campaignTitle}, please set a monthly budget. Note that budget resets each
            month regardless of the amount spent. (Unspent budget does not roll over.) See our{" "}
            {productGuideLink} to learn more.
          </Text>
        )}
        {budgetType === BudgetType.Once && (
          <Text size="sm">
            To resume {campaignTitle}, please set a budget. This budget will be spent until it is
            depleted. See our {productGuideLink} to learn more.
          </Text>
        )}
        <NumberInput
          allowNegative={false}
          allowLeadingZeros={false}
          label={
            budgetType === BudgetType.Monthly
              ? "Monthly Budget (in USD)"
              : "Lifetime Budget (in USD)"
          }
          hideControls
          size="sm"
          value={newBudget / 100}
          onChange={(value: number) => setNewBudget(value * 100)}
          prefix="$"
          decimalSeparator="."
          thousandSeparator=","
          decimalScale={2}
          fixedDecimalScale
        />
        <Group justify="right" gap={4}>
          <Button size="sm" variant="subtle" color="gray" onClick={close}>
            Cancel
          </Button>
          <Button size="sm" onClick={handleResume} disabled={newBudget <= 0}>
            Save & Resume Campaign
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

export default function EditBudget({
  campaignHashId,
  campaignStatus,
}: {
  campaignHashId: number;
  campaignStatus: CampaignStatus;
}) {
  if (campaignStatus !== CampaignStatus.Active && campaignStatus !== CampaignStatus.Paused) {
    return null;
  }

  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector((state) => state.me.user.is_staff);

  const [loading, setLoading] = useState(false);
  const [campaignTitle, setCampaignTitle] = useState("");
  const [budgetRowsData, setBudgetRowsData] = useState<BudgetAndSpend[]>([]);
  const [budgetType, setBudgetType] = useState<BudgetType>(null);
  const [newBudgets, setNewBudgets] = useState<Record<string, number>>({});
  const [validBudgets, setValidBudgets] = useState<Record<string, boolean>>({});

  const [settingsOpened, { open: openSettings, close: closeSettings }] = useDisclosure();
  const [saveWarningOpened, { open: openSaveWarning, close: closeSaveWarning }] = useDisclosure();
  const [resumeModalOpened, { open: openResumeModal, close: closeResumeModal }] = useDisclosure();

  const [saving, setSaving] = useState(false);
  const [pauseOrResumeLoading, setPauseOrResumeLoading] = useState(false);

  // Select only current or future months
  const handleBudgetRowsDataResponse = (data: BudgetAndSpend[]) => {
    // Select only current or future months
    const filteredBudgetRowsData = data.filter(
      (budgetRowData) =>
        budgetRowData.month === "Lifetime" ||
        new Date(budgetRowData.month).getUTCFullYear() > new Date().getUTCFullYear() ||
        (new Date(budgetRowData.month).getUTCMonth() >= new Date().getUTCMonth() &&
          new Date(budgetRowData.month).getUTCFullYear() === new Date().getUTCFullYear()),
    );

    setBudgetRowsData(filteredBudgetRowsData);
    dispatch(updateCurrentBudget({ campaignHashId, newBudget: filteredBudgetRowsData[0].budget }));

    // Keep a record of changes to the budget
    setNewBudgets(
      filteredBudgetRowsData.reduce((acc, budgetRowData) => {
        acc[budgetRowData.month] = budgetRowData.budget;
        return acc;
      }, {} as Record<string, number>),
    );
    setValidBudgets(
      filteredBudgetRowsData.reduce((acc, budgetRowData) => {
        acc[budgetRowData.month] = true;
        return acc;
      }, {} as Record<string, boolean>),
    );
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (settingsOpened) {
      setLoading(true);
      fetchBudgetAndSpendForCampaign(campaignHashId, abortController)
        .then((response: BudgetAndSpendResponse) => {
          console.log(response);
          if (response.success) {
            setCampaignTitle(response.campaign_name);
            setBudgetType(response.budget_type);
            handleBudgetRowsDataResponse(response.data);
          }
        })
        .finally(() => setLoading(false));
    }

    return () => {
      abortController.abort();
    };
  }, [campaignHashId, settingsOpened]);

  const isDirty = budgetRowsData.some((budgetRowData) => {
    return newBudgets[budgetRowData.month] !== budgetRowData.budget;
  });

  const minBudgets = budgetRowsData.reduce((acc, budgetRowData) => {
    const now = new Date();

    const isLifetimeBudget = budgetRowData.month === "Lifetime";
    const isCurrentMonth =
      !isLifetimeBudget && new Date(budgetRowData.month).getUTCMonth() === now.getUTCMonth();
    const isNextMonth =
      !isLifetimeBudget && new Date(budgetRowData.month).getUTCMonth() === now.getUTCMonth() + 1;

    const minBudget =
      isCurrentMonth || isNextMonth
        ? budgetRowData.budget
        : budgetRowData.completed + budgetRowData.contracted + budgetRowData.pending;

    acc[budgetRowData.month] = minBudget;
    return acc;
  }, {} as Record<string, number>);

  const validateBudget = (month: string) => {
    // Skip validation for admin users - they can decrease the budget to any value
    if (isAdmin) {
      return true;
    }

    if (newBudgets[month] < minBudgets[month]) {
      setValidBudgets({
        ...validBudgets,
        [month]: false,
      });
      return false;
    }
    return true;
  };

  const validateAllBeforeSave = () => {
    // Admin users can always save
    if (isAdmin) {
      return true;
    }

    return budgetRowsData.every((budgetRowData) => validateBudget(budgetRowData.month));
  };

  const allBudgetsValid = Object.values(validBudgets).every((valid) => valid);

  const handleSave = () => {
    if (validateAllBeforeSave()) {
      setSaving(true);
      saveBudgetsForCampaign(campaignHashId, newBudgets)
        .then((response: BudgetAndSpendResponse) => {
          if (response.success) {
            handleBudgetRowsDataResponse(response.data);
          } else {
            showFailureNotification({
              message: "Failed to save budget changes",
            });
          }
        })
        .catch(() => {
          showFailureNotification({
            message: "Failed to save budget changes",
          });
        })
        .finally(() => setSaving(false));
    }
  };

  const handlePause = () => {
    setPauseOrResumeLoading(true);
    pauseCampaign(campaignHashId)
      .then((response) => {
        if (response.success) {
          handleBudgetRowsDataResponse(response.data);
          dispatch(
            updateCampaignStatus({
              campaignHashId,
              newStatus: CampaignStatus.Paused,
            }),
          );
        } else {
          showFailureNotification({
            message: "Failed to pause campaign",
          });
        }
      })
      .catch(() => {
        showFailureNotification({
          message: "Failed to pause campaign",
        });
      })
      .finally(() => setPauseOrResumeLoading(false));
  };

  const showSaveWarningAndClose = () => {
    if (isDirty) {
      openSaveWarning();
    } else {
      closeSettings();
    }
  };

  return (
    <>
      <Drawer
        opened={settingsOpened}
        onClose={showSaveWarningAndClose}
        withCloseButton={false}
        closeOnClickOutside={!isDirty}
        closeOnEscape={!isDirty}
        title={
          <Header
            canSave={isDirty && allBudgetsValid}
            handleSave={handleSave}
            handleClose={showSaveWarningAndClose}
            saving={saving}
          />
        }
        size={DRAWER_WIDTH}
        position="right">
        <SaveWarningModal
          opened={saveWarningOpened}
          close={closeSaveWarning}
          closeDrawer={closeSettings}
          handleSave={handleSave}
        />
        <ResumeCampaignModal
          campaignHashId={campaignHashId}
          campaignTitle={campaignTitle}
          budgetType={budgetType}
          opened={resumeModalOpened}
          close={closeResumeModal}
          setPauseOrResumeLoading={setPauseOrResumeLoading}
          handleBudgetRowsDataResponse={handleBudgetRowsDataResponse}
        />
        {loading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <Contents
            campaignHashId={campaignHashId}
            campaignTitle={campaignTitle}
            campaignStatus={campaignStatus}
            budgetRowsData={budgetRowsData}
            budgetType={budgetType}
            newBudgets={newBudgets}
            setNewBudgets={setNewBudgets}
            minBudgets={minBudgets}
            validBudgets={validBudgets}
            setValidBudgets={setValidBudgets}
            validateBudget={validateBudget}
            handlePause={handlePause}
            handleResume={openResumeModal}
            pauseOrResumeLoading={pauseOrResumeLoading}
            isAdmin={isAdmin}
          />
        )}
      </Drawer>
      <ActionIcon variant="subtle" size="sm" color="gray" onClick={openSettings}>
        <IconPencil />
      </ActionIcon>
    </>
  );
}
