export interface Rating {
    "starValue": number,
    "tags": string[],
    "description": string,
    "createdAt"?: string
};

export const POSITIVE_TAGS = [
    "High-quality content",
    "Great value",
    "Responsive and timely",
    "Flexible and collaborative",
    "Other"
];

export const NEGATIVE_TAGS = [
    "Low content quality",
    "Rude or unprofessional",
    "Poor value",
    "Slow or delayed timelines",
    "Lack of effort",
    "Didn't follow brief",
    "Other"
];
