import React, { useEffect, useState } from "react";

import { Button, Divider, Flex, Group, Loader, Modal, Stack, Text, Tooltip } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconCheck, IconDeviceFloppy, IconNotes, IconSend } from "@tabler/icons-react";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import LoadingError from "components/contracts/common/LoadingError";
import RichTextEditor, {
  getEditorWithCharacterLimit,
} from "components/contracts/common/RichTextEditor";

import {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping,
} from "components/contracts/models/Deliverable";
import Script from "components/contracts/models/Script";

import { ContentStatus, ContentType } from "components/contracts/common/Common";

import { SupportedPlatform } from "models/Common";

import {
  fetchScriptsForDeliverable,
  saveScript,
  submitScriptForReview,
} from "components/contracts/common/Api";

import CreatorMessages from "components/contracts/deliverables/CreatorMessages";
import ScriptCard from "components/contracts/deliverables/ScriptCard";
import ViewPreviousRevisions from "components/contracts/deliverables/ViewPreviousRevisions";

function ViewScript({
  brandName,
  platform,
  script,
  label,
}: {
  brandName: string;
  platform: SupportedPlatform;
  script: Script;
  label: string;
}) {
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Modal padding={0} opened={opened} onClose={close} size="xl" withCloseButton={false}>
        <ScriptCard brandName={brandName} platform={platform} script={script} isCreator />
      </Modal>
      <Button
        leftSection={<IconNotes size="1rem" />}
        variant="light"
        onClick={() => {
          open();
        }}>
        {label}
      </Button>
    </>
  );
}

function ShowPreviousScripts({
  brandName,
  platform,
  deliverableStatus,
  versionedScripts,
  isPostOrCarousel,
}: {
  brandName: string;
  platform: SupportedPlatform;
  deliverableStatus: ContractDeliverableStatus;
  versionedScripts: Script[];
  isPostOrCarousel?: boolean;
}) {
  const [filteredScripts, setFilteredScripts] = useState<Script[]>([]);
  const [previousScriptRevisions, setPreviousScriptRevisions] = useState<Script[]>([]);

  useEffect(() => {
    const filtered = versionedScripts.filter(
      (script) =>
        script.status !== ContentStatus.IN_PROGRESS &&
        (isPostOrCarousel ? script.isPostCarousel : !script.isPostCarousel),
    );

    setFilteredScripts(filtered);

    if (filtered.length > 0) {
      setPreviousScriptRevisions(filtered.slice(1));
    } else {
      setPreviousScriptRevisions([]);
    }
  }, [versionedScripts, isPostOrCarousel]);

  if (filteredScripts.length === 0) {
    return null;
  }

  const latestScript = filteredScripts[0];

  if (
    deliverableStatus === ContractDeliverableStatus.SCRIPT_SUBMITTED ||
    (isPostOrCarousel && deliverableStatus === ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED)
  ) {
    return (
      <Group>
        <ViewScript
          brandName={brandName}
          platform={platform}
          script={latestScript}
          label={isPostOrCarousel ? "View Draft Post" : "View Concept Draft"}
        />
        <ViewPreviousRevisions
          contentType={ContentType.SCRIPT}
          previousScriptRevisions={previousScriptRevisions}
        />
      </Group>
    );
  } else if (
    deliverableStatus === ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED ||
    (isPostOrCarousel &&
      deliverableStatus === ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED)
  ) {
    return (
      <Group>
        <ViewScript
          brandName={brandName}
          platform={platform}
          script={latestScript}
          label={isPostOrCarousel ? "View Submitted Post" : "View Submitted Concept"}
        />
        <ViewPreviousRevisions
          contentType={ContentType.SCRIPT}
          previousScriptRevisions={previousScriptRevisions}
        />
      </Group>
    );
  } else if (
    (ContractDeliverableStatusMapping[deliverableStatus] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.SCRIPT_APPROVED] &&
      !isPostOrCarousel) ||
    (isPostOrCarousel &&
      ContractDeliverableStatusMapping[deliverableStatus] >=
        ContractDeliverableStatusMapping[ContractDeliverableStatus.VIDEO_DRAFT_APPROVED])
  ) {
    return (
      <Group>
        <ViewScript
          brandName={brandName}
          platform={platform}
          script={latestScript}
          label={isPostOrCarousel ? "View Approved Post" : "View Approved Concept"}
        />
        <ViewPreviousRevisions
          contentType={ContentType.SCRIPT}
          previousScriptRevisions={previousScriptRevisions}
        />
      </Group>
    );
  }

  return null;
}

function ScriptEditor({
  deliverableId,
  deliverableStatus,
  setDeliverableStatus,
  fetchedScripts,
  setFetchedScripts,
  scriptsLoaded,
  showError,
  isPostOrCarousel,
}: {
  deliverableId: string;
  deliverableStatus: ContractDeliverableStatus;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  fetchedScripts: Script[];
  setFetchedScripts: (scripts: Script[]) => void;
  scriptsLoaded: boolean;
  showError: boolean;
  isPostOrCarousel?: boolean;
}) {
  if (
    (!isPostOrCarousel &&
      deliverableStatus !== ContractDeliverableStatus.WAITING_FOR_SCRIPT &&
      deliverableStatus !== ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED) ||
    (isPostOrCarousel &&
      deliverableStatus !== ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT &&
      deliverableStatus !== ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED)
  ) {
    return null;
  }

  let divider = null;
  if (
    deliverableStatus === ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED ||
    (isPostOrCarousel &&
      deliverableStatus === ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED)
  ) {
    divider = <Divider mt="sm" label="OR" />;
  }

  const [editorInitialized, setEditorInitialized] = useState(false);

  const [isEditorDirty, setIsEditorDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [scriptId, setScriptId] = useState(null);

  const editor = getEditorWithCharacterLimit(
    isPostOrCarousel
      ? "Start writing details for your post here..."
      : "Start writing your concept here...",
  );

  useEffect(() => {
    if (editor) {
      editor.on("update", () => {
        setIsEditorDirty(true);
      });

      if (fetchedScripts.length > 0 && editor.isEmpty) {
        editor.commands.setContent(fetchedScripts[0].text);
        setScriptId(fetchedScripts[0].scriptId);
      }
      setEditorInitialized(true);
    }
  }, [fetchedScripts, editor]);

  const handleSaveDraft = () => {
    const content = editor.getHTML();

    setIsSaving(true);
    saveScript(deliverableId, content)
      .then((response) => {
        const { success, script } = response;

        if (success) {
          const deserializedScript = Script.deserialize(script);
          setScriptId(deserializedScript.scriptId);

          // Check if Script ID exists in fetchedScripts. It if does, update the index.
          // If it doesn't, push the new Script to fetchedScripts.
          const fetchedScriptsCopy = [...fetchedScripts];
          const scriptIndex = fetchedScripts.findIndex(
            (fetchedScript) => fetchedScript.scriptId === deserializedScript.scriptId,
          );

          if (scriptIndex !== -1) {
            fetchedScriptsCopy[scriptIndex] = deserializedScript;
          } else {
            fetchedScriptsCopy.push(deserializedScript);
          }

          setFetchedScripts(fetchedScriptsCopy);

          showSuccessNotification({
            message: isPostOrCarousel
              ? "Successfully saved post draft."
              : "Successfully saved concept draft.",
          });
          setIsEditorDirty(false);
        } else {
          showFailureNotification({
            message: isPostOrCarousel
              ? "Failed to save post draft."
              : "Failed to save concept draft.",
          });
        }
        setIsSaving(false);
      })
      .catch(() => {
        showFailureNotification({
          message: isPostOrCarousel
            ? "Failed to save post draft."
            : "Failed to save concept draft.",
        });
        setIsSaving(false);
      });
  };

  const handleSubmitScriptForReview = () => {
    setIsSubmitting(true);
    submitScriptForReview(deliverableId, scriptId)
      .then((response) => {
        const { success, script } = response;

        if (success) {
          const deserializedScript = Script.deserialize(script);

          setScriptId(deserializedScript.scriptId);
          setFetchedScripts([deserializedScript, ...fetchedScripts.slice(1)]);
          setDeliverableStatus(response.deliverableStatus);
          setSubmitted(true);

          editor.setEditable(false);

          showSuccessNotification({
            message: isPostOrCarousel
              ? "Successfully submitted post for review."
              : "Successfully submitted concept for review.",
          });
        } else {
          showFailureNotification({
            message: isPostOrCarousel
              ? "Failed to submit post for review."
              : "Failed to submit concept for review.",
          });
        }
        setIsSubmitting(false);
      })
      .catch(() => {
        showFailureNotification({
          message: isPostOrCarousel
            ? "Failed to submit post for review."
            : "Failed to submit concept for review.",
        });
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {showError && (
        <LoadingError
          message={
            isPostOrCarousel ? "Failed to load post draft." : "Failed to load concept draft."
          }
        />
      )}
      {(!scriptsLoaded || !editorInitialized) && !showError && (
        <Flex justify="center">
          <Loader size="sm" />
        </Flex>
      )}
      {scriptsLoaded && editorInitialized && !showError && (
        <>
          {divider}
          <Text fw="500">{isPostOrCarousel ? "Post Draft" : "Concept Draft"}</Text>
          <RichTextEditor editor={editor} />
          <Group justify="right" mt="-lg">
            <Button
              disabled={!isEditorDirty || submitted}
              loading={isSaving}
              variant="outline"
              color={isEditorDirty ? "gray" : "teal"}
              leftSection={
                isEditorDirty ? <IconDeviceFloppy size="1rem" /> : <IconCheck size="1rem" />
              }
              onClick={handleSaveDraft}>
              {isEditorDirty ? "Save Draft" : "Saved"}
            </Button>
            <Tooltip label="Please Save Before Submitting for Review" disabled={!isEditorDirty}>
              <Button
                variant="outline"
                disabled={(editorInitialized && editor.isEmpty) || isEditorDirty || submitted}
                loading={isSubmitting}
                color={submitted ? "teal" : "blue"}
                leftSection={submitted ? <IconCheck size="1rem" /> : <IconSend size="1rem" />}
                onClick={handleSubmitScriptForReview}>
                {submitted ? "Submitted for Review" : "Submit for Review"}
              </Button>
            </Tooltip>
          </Group>
        </>
      )}
    </>
  );
}

export default function ScriptDraftTool({
  brandName,
  platform,
  deliverableId,
  deliverableStatus,
  setDeliverableStatus,
  showAdminOptions,
  isPostOrCarousel,
}: {
  brandName: string;
  platform: SupportedPlatform;
  deliverableId: string;
  deliverableStatus: ContractDeliverableStatus;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  showAdminOptions: boolean;
  isPostOrCarousel?: boolean;
}) {
  if (
    ContractDeliverableStatusMapping[deliverableStatus] <
    ContractDeliverableStatusMapping[ContractDeliverableStatus.WAITING_FOR_SCRIPT]
  ) {
    return null;
  }

  const [fetchedScripts, setFetchedScripts] = useState<Script[]>([]);
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [showError, setShowError] = useState(false);

  const lastSubmittedScript = fetchedScripts.find(
    (script) => script.status !== ContentStatus.IN_PROGRESS,
  );

  useEffect(() => {
    fetchScriptsForDeliverable(deliverableId)
      .then((response) => {
        const { scripts, success } = response;
        if (success) {
          const deserializedScripts = scripts
            ? scripts
                .map((script: any) => Script.deserialize(script))
                .filter((script: Script) => script.isPostCarousel === !!isPostOrCarousel)
            : [];
          setFetchedScripts(deserializedScripts);
          setScriptsLoaded(true);
        } else {
          setScriptsLoaded(false);
          setShowError(true);
        }
      })
      .catch(() => {
        setScriptsLoaded(false);
        setShowError(true);
      });
  }, []);

  return (
    <Stack mt="md" mb="-xs" gap="sm">
      <ShowPreviousScripts
        brandName={brandName}
        platform={platform}
        deliverableStatus={deliverableStatus}
        versionedScripts={fetchedScripts}
        isPostOrCarousel={isPostOrCarousel}
      />
      <CreatorMessages
        brandName={brandName}
        deliverableStatus={deliverableStatus}
        setDeliverableStatus={setDeliverableStatus}
        contentType={ContentType.SCRIPT}
        script={lastSubmittedScript}
        showAdminOptions={showAdminOptions}
        isPostOrCarousel={isPostOrCarousel}
      />
      <ScriptEditor
        deliverableId={deliverableId}
        deliverableStatus={deliverableStatus}
        setDeliverableStatus={setDeliverableStatus}
        fetchedScripts={fetchedScripts}
        setFetchedScripts={setFetchedScripts}
        scriptsLoaded={scriptsLoaded}
        showError={showError}
        isPostOrCarousel={isPostOrCarousel}
      />
    </Stack>
  );
}
