import React, { useState } from "react";

import {
  Button,
  Card,
  Checkbox,
  Flex,
  Grid,
  Group,
  NumberInput,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";

import InfoCircleWithTooltipNode from "campaigns/create/common/InfoCircleWithTooltipNode";
import { ContractBrandReviewDeliverable } from "components/contracts/brand_review/ContractBrandReviewDeliverable";
import { getStatusBadge } from "components/contracts/brand_review/ContractBrandReviewCard";
import {
  ContractOfferBrandReview,
  ContractReviewStatus,
} from "components/contracts/brand_review/types";
import { archiveContractOfferReview } from "components/contracts/common/Api";
import {
  getUsageRightsDays,
  SUPPORTED_FORMATS_TO_PLATFORMS,
} from "components/contracts/common/Common";
import InfoCircleWithTooltip from "campaigns/create/common/InfoCircleWithTooltip";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { CreatorDetails } from "components/discovery/Datamodels";
import Spacer from "components/Spacer";
import { SupportedFormat, SupportedPlatform, UsageRightsDuration } from "models/Common";
import { DeliverablePackage } from "models/DeliverablePackage";
import { notifications } from "@mantine/notifications";
import { ActionButton } from "components/creator_lists/unified_creator_rep/ActionRows";
import { IconCheck, IconLink } from "@tabler/icons-react";

export const makeDeliverableList = (deliverablePackage: DeliverablePackage) => {
  const res = [] as {
    format: SupportedFormat;
    platform: SupportedPlatform;
    usageRights: UsageRightsDuration;
  }[];
  [
    SupportedFormat.INSTAGRAM_DEDICATED_REEL,
    SupportedFormat.INSTAGRAM_DEDICATED_POST,
    SupportedFormat.INSTAGRAM_CAROUSEL,
    SupportedFormat.TIKTOK_DEDICATED_VIDEO,
    SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO,
    SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO,
    SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO,
    SupportedFormat.YOUTUBE_DEDICATED_VIDEO,
    SupportedFormat.YOUTUBE_SHORT,
  ].forEach((format) => {
    const num = deliverablePackage[format];
    for (let i = 0; i < num; i += 1) {
      res.push({
        format,
        platform: SUPPORTED_FORMATS_TO_PLATFORMS[format],
        usageRights: deliverablePackage.usage_rights,
      });
    }
  });
  return res;
};

export const AdminActionRow = ({
  contractOfferReview,
  setRefreshKey,
}: {
  contractOfferReview: ContractOfferBrandReview;
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const markComplete = (contractOfferReviewId: string) => {
    archiveContractOfferReview(contractOfferReviewId).then(() => {
      notifications.show({
        color: "blue",
        title: "Contract Review marked as Complete",
        message: `Contract Review with id ${contractOfferReviewId} has been marked as complete and will no longer be shown!`,
        autoClose: true,
      });
      setRefreshKey((prevKey) => prevKey + 1);
    });
  };
  const viewContract = () => {
    const url = `/admin/contracts/${contractOfferReview.contract_hash_id}`;
    window.open(url, "_blank");
  };
  const hasContractHashId = !!contractOfferReview.contract_hash_id;

  return (
    <Flex align="center" h="100%" justify="space-between" gap="8px">
      {hasContractHashId &&
        contractOfferReview.brand_review_status === ContractReviewStatus.APPROVED && (
          <ActionButton
            onClick={() => viewContract()}
            color="blue"
            text="View Contract"
            IconName={IconLink}
          />
        )}
      <Tooltip label="Marking this as complete will remove it from this view.">
        <ActionButton
          onClick={() => markComplete(contractOfferReview.uuid)}
          color="green"
          text="Mark Complete"
          IconName={IconCheck}
        />
      </Tooltip>
    </Flex>
  );
};

const formatDebugString = (debugString: string) => {
  const pos = debugString.indexOf("\n");
  if (pos) {
    return `$${debugString.substring(0, pos)}: ${debugString.substring(pos + 2)}`;
  }
  return `$${debugString}`;
};

const PackagePricingDebugContent = ({
  deliverablePackage,
}: {
  deliverablePackage: DeliverablePackage;
}) => {
  return (
    <Stack gap={20}>
      {deliverablePackage.instagram_dedicated_reel_price_debug && (
        <Text>
          Instagram reel:{" "}
          {formatDebugString(deliverablePackage.instagram_dedicated_reel_price_debug)}
        </Text>
      )}
      {deliverablePackage.tiktok_dedicated_video_price_debug && (
        <Text>
          Tiktok: {formatDebugString(deliverablePackage.tiktok_dedicated_video_price_debug)}
        </Text>
      )}
      {deliverablePackage.youtube_short_price_debug && (
        <Text>
          YouTube short: {formatDebugString(deliverablePackage.youtube_short_price_debug)}
        </Text>
      )}
      {deliverablePackage.youtube_dedicated_video_price_debug && (
        <Text>
          YouTube dedicated:{" "}
          {formatDebugString(deliverablePackage.youtube_dedicated_video_price_debug)}
        </Text>
      )}
      {deliverablePackage.youtube_60s_integrated_video_price_debug && (
        <Text>
          YouTube 60s integration:{" "}
          {formatDebugString(deliverablePackage.youtube_60s_integrated_video_price_debug)}
        </Text>
      )}
      {deliverablePackage.youtube_90s_integrated_video_price_debug && (
        <Text>
          YouTube 90s integration:{" "}
          {formatDebugString(deliverablePackage.youtube_90s_integrated_video_price_debug)}
        </Text>
      )}
    </Stack>
  );
};

/**
 * Calculate the creator's asking price for a package.
 * @param deliverablePackage The deliverable package in question
 * @returns The asking price for the package, or 0 if there isn't one.
 */
const calculateAskingPrice = (deliverablePackage: DeliverablePackage): number => {
  return (
    (deliverablePackage?.creator_asking_price || 0) +
    (deliverablePackage?.creator_asking_usage_rights_price || 0)
  );
};

const getPricingPackageDebugContent = (deliverablePackage: DeliverablePackage) => {
  const debugs = [
    deliverablePackage.instagram_dedicated_reel_price_debug,
    deliverablePackage.tiktok_dedicated_video_price_debug,
    deliverablePackage.youtube_short_price_debug,
    deliverablePackage.youtube_dedicated_video_price_debug,
    deliverablePackage.youtube_60s_integrated_video_price_debug,
    deliverablePackage.youtube_90s_integrated_video_price_debug,
  ];
  if (debugs.some((elt) => elt && elt.trim().length > 0)) {
    return <PackagePricingDebugContent deliverablePackage={deliverablePackage} />;
  }
  return null;
};

const PackageCard = ({
  contractReview,
  packageIdx,
  checked,
  setChecked,
  packageFeedback,
  setPackageFeedback,
  setContractReviews,
  readOnly,
}: {
  contractReview: ContractOfferBrandReview;
  packageIdx: number;
  checked: boolean;
  setChecked: (newState: boolean, idx: number) => void;
  packageFeedback: string;
  setPackageFeedback: (fb: string, idx: number) => void;
  setContractReviews?: React.Dispatch<React.SetStateAction<ContractOfferBrandReview[]>>;
  readOnly?: boolean;
}) => {
  const deliverablePackage = contractReview.deliverable_packages.at(packageIdx);
  const simplifiedDeliverables = makeDeliverableList(deliverablePackage);

  const pricingCard = getPricingPackageDebugContent(deliverablePackage);

  return (
    <Card
      px="md"
      shadow="none"
      py="sm"
      radius="md"
      m="xs"
      withBorder
      bd={checked ? "1px solid blue" : undefined}
      bg={checked ? "#F5FBFF" : undefined}>
      <Flex justify="space-between" align="center">
        <Text fw="600">Package #{packageIdx + 1}</Text>
        <Checkbox
          disabled={readOnly}
          checked={checked}
          onChange={(event) => setChecked(event.currentTarget.checked, packageIdx)}
        />
      </Flex>
      <Flex gap="sm" my="xs" direction="column">
        {simplifiedDeliverables.map((deliverable) => (
          <ContractBrandReviewDeliverable
            key={`deliverable-card-${deliverable.format}`}
            format={deliverable.format}
            platform={deliverable.platform}
            usageRights={getUsageRightsDays(deliverable.usageRights)}
            usageRightsInPerpetuity={deliverable.usageRights === UsageRightsDuration.IN_PERPETUITY}
            width="100%"
          />
        ))}
      </Flex>
      <Spacer height={8} />
      <Flex align="center" h="100%" justify="space-between" gap="8px">
        <Textarea
          label="Reason / Feedback"
          // Disable feedback for packages to be rejected.
          readOnly={readOnly || !checked}
          size="sm"
          style={{ flexGrow: 1 }}
          placeholder="Optional feedback"
          value={packageFeedback || ""}
          onChange={(event) => {
            const { value } = event.target;
            // Feedback is not attached to the deliverable package, it's a thing we use for history
            setPackageFeedback(value, packageIdx);
          }}
          rightSection={
            <Text size="xs" ta="end" pr="4px" c={packageFeedback.length > 5000 ? "red" : undefined}>
              {packageFeedback.length}/5000
            </Text>
          }
          error={packageFeedback.length > 5000}
          rightSectionWidth="67px"
          miw="200px"
          styles={{
            section: {
              justifyContent: "flex-end",
            },
          }}
        />
      </Flex>
      <Text />
      <Group mt="md" mb="xs">
        <NumberInput
          id="brandMaxOffer"
          value={deliverablePackage.brand_approved_price}
          thousandSeparator=","
          onChange={(value: number) => {
            if (!value) {
              return;
            }
            setContractReviews((prevReviews: ContractOfferBrandReview[]) => {
              const updatedReviews = prevReviews.map((review) => {
                if (review.uuid === contractReview.uuid) {
                  const updatedPackages = review.deliverable_packages.map((pkg) => {
                    if (pkg.uuid === deliverablePackage.uuid) {
                      // We found the package that we've updated with a price, set it
                      return {
                        ...pkg,
                        brand_approved_price: value,
                      };
                    }
                    return pkg;
                  });
                  // Update the review's offer's packages with the new list.
                  const newReview = {
                    ...review,
                    deliverable_packages: updatedPackages,
                  };
                  return newReview;
                }
                return review;
              });
              return updatedReviews;
            });
          }}
          label="Max Offer"
          min={1}
          max={9999999}
          clampBehavior="strict"
          allowDecimal={false}
          prefix="$"
          description={
            <>
              <>
                {`Creator's Asking Price: $${calculateAskingPrice(
                  deliverablePackage,
                ).toLocaleString("en-US", {})}`}
                <br />
              </>
              {`Recommended Price: $${deliverablePackage.suggested_price.toLocaleString(
                "en-US",
                {},
              )}`}{" "}
              {pricingCard && (
                <InfoCircleWithTooltipNode
                  tooltipNode={
                    <PackagePricingDebugContent deliverablePackage={deliverablePackage} />
                  }
                />
              )}
            </>
          }
          hideControls
          disabled={readOnly || !checked}
        />
      </Group>
    </Card>
  );
};

// Exported for use in the contract offer approval task
export const CreatorOfferApprovalActionRow = ({
  contractReview,
  isAccepted,
  isRejected,
  isInReview,
  showAdminOptions,
  checkedStates,
  setCheckedStates,
  packageFeedbacks,
  setPackageFeedbacks,
  setContractReviews,
  approveOffer,
  declineCreator,
  setRefreshKey,
}: {
  contractReview: ContractOfferBrandReview;
  isAccepted: boolean;
  isRejected: boolean;
  isInReview: boolean;
  showAdminOptions?: boolean;
  checkedStates: boolean[];
  setCheckedStates: (states: boolean[]) => void;
  packageFeedbacks: string[];
  setPackageFeedbacks: (feedbacks: string[]) => void;
  setContractReviews?: React.Dispatch<React.SetStateAction<ContractOfferBrandReview[]>>;
  approveOffer?: () => void;
  declineCreator?: () => void;
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <Stack py="md" gap="sm">
      <Group px="md" gap={2}>
        <Text fw="600">Packages and Rates</Text>
        <InfoCircleWithTooltip
          tooltipText={
            "Please select the package(s) you would like to approve. If you approve multiple, we will " +
            "contract the creator for the single package we can get the best deal on. Once submitted, " +
            "your offer(s) is final."
          }
        />
      </Group>
      <SimpleGrid cols={Math.min(3, contractReview.deliverable_packages.length)}>
        {contractReview.deliverable_packages.map((deliverablePackage, packageIndex) => {
          return (
            <PackageCard
              key={deliverablePackage.uuid}
              contractReview={contractReview}
              packageIdx={packageIndex}
              checked={checkedStates[packageIndex]}
              setChecked={(newState, idx) => {
                const newCheckedStates = [...checkedStates];
                newCheckedStates[idx] = newState;
                setCheckedStates(newCheckedStates);
              }}
              packageFeedback={packageFeedbacks[packageIndex]}
              setPackageFeedback={(fb, idx) => {
                const newFeedbacks = [...packageFeedbacks];
                newFeedbacks[idx] = fb;
                setPackageFeedbacks(newFeedbacks);
              }}
              setContractReviews={setContractReviews}
              readOnly={isAccepted || isRejected}
            />
          );
        })}
      </SimpleGrid>
      {isAccepted && (
        <Grid>
          <Grid.Col span={12}>
            <Button color="green" disabled fullWidth mt="md" radius="md">
              Selected Offers Approved
            </Button>
          </Grid.Col>
        </Grid>
      )}
      {isRejected && (
        <Grid>
          <Grid.Col span={12}>
            <Button color="red" disabled fullWidth mt="md" radius="md">
              Offers Rejected
            </Button>
          </Grid.Col>
        </Grid>
      )}
      {isInReview && (
        <Grid>
          <Grid.Col span={6}>
            <Button color="red" fullWidth mt="md" radius="md" onClick={() => declineCreator()}>
              Decline All
            </Button>
          </Grid.Col>
          <Grid.Col span={6}>
            <Button
              color="green"
              fullWidth
              mt="md"
              radius="md"
              disabled={checkedStates.every((state) => !state)}
              onClick={() => approveOffer()}>
              Approve Offer
              {checkedStates
                .map((state) => (state ? Number(1) : Number(0)))
                .reduce((prevVal, thisVal) => prevVal + thisVal) > 1
                ? "s"
                : ""}
            </Button>
          </Grid.Col>
        </Grid>
      )}
      {showAdminOptions && (
        <Group justify="right" p="md">
          <AdminActionRow contractOfferReview={contractReview} setRefreshKey={setRefreshKey} />
        </Group>
      )}
    </Stack>
  );
};

export const ContractOfferApprovalCard = ({
  contractReview,
  creatorDetail,
  isAccepted,
  isRejected,
  isInReview,
  showAdminOptions,
  index,
  setContractReviews,
  approveOffer,
  declineCreator,
  setRefreshKey,
}: {
  contractReview: ContractOfferBrandReview;
  creatorDetail: CreatorDetails;
  isAccepted: boolean;
  isRejected: boolean;
  isInReview: boolean;
  showAdminOptions?: boolean;
  index: number;
  setContractReviews?: React.Dispatch<React.SetStateAction<ContractOfferBrandReview[]>>;
  approveOffer?: (
    contractReviewId: string,
    index: number,
    packageApprovalFlags: boolean[],
    feedbacks: string[],
  ) => void;
  declineCreator?: (contractReviewId: string, index: number) => void;
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [checkedStates, setCheckedStates] = useState<boolean[]>(
    Array.from({ length: contractReview.deliverable_packages.length }, () => true),
  );
  const [packageFeedbacks, setPackageFeedbacks] = useState<string[]>(
    Array.from({ length: contractReview.deliverable_packages.length }, () => ""),
  );

  const approveOfferWrapper = () => {
    approveOffer(contractReview.uuid, index, checkedStates, packageFeedbacks);
  };

  const declineCreatorWrapper = () => {
    declineCreator(contractReview.uuid, index);
  };

  const actionRow = (
    <CreatorOfferApprovalActionRow
      contractReview={contractReview}
      isAccepted={isAccepted}
      isRejected={isRejected}
      isInReview={isInReview}
      showAdminOptions={showAdminOptions}
      checkedStates={checkedStates}
      setCheckedStates={setCheckedStates}
      packageFeedbacks={packageFeedbacks}
      setPackageFeedbacks={setPackageFeedbacks}
      setContractReviews={setContractReviews}
      approveOffer={approveOfferWrapper}
      declineCreator={declineCreatorWrapper}
      setRefreshKey={setRefreshKey}
    />
  );
  return (
    <React.Fragment key={`entry-${contractReview.uuid}`}>
      <UnifiedCreatorRep
        creator={creatorDetail}
        key={`live-result-${contractReview.uuid}`}
        defaultExpandedPlatforms={[]}
        archetype={getStatusBadge(contractReview.brand_review_status)}
        useScrollOverflow={false}
        actionRow={actionRow}
      />
    </React.Fragment>
  );
};

export default ContractOfferApprovalCard;
