import { ContentStatus } from "components/contracts/common/Common";
import { SupportedFormat, SupportedPlatform } from "models/Common";
import { fromISODateString } from "utils/DateUtils";

export default class Script {
  scriptId: string;

  deliverableId: string;

  platform: SupportedPlatform;

  format: SupportedFormat;

  creatorHandle: string;

  profileLink: string;

  dateCreated: Date;

  lastUpdated: Date;

  version: number;

  status: ContentStatus;

  submissionDate: Date;

  reviewDate: Date;

  text: string;

  isPostCarousel: boolean;

  constructor({
    scriptId,
    deliverableId,
    platform,
    format,
    creatorHandle,
    profileLink,
    dateCreated,
    lastUpdated,
    version,
    status,
    submissionDate,
    reviewDate,
    text,
    isPostCarousel,
  }: {
    scriptId: string;
    deliverableId: string;
    platform: SupportedPlatform;
    format: SupportedFormat;
    creatorHandle: string;
    profileLink: string;
    dateCreated: Date;
    lastUpdated: Date;
    version: number;
    status: ContentStatus;
    submissionDate: Date;
    reviewDate: Date;
    text: string;
    isPostCarousel: boolean;
  }) {
    this.scriptId = scriptId;
    this.deliverableId = deliverableId;
    this.platform = platform;
    this.format = format;
    this.creatorHandle = creatorHandle;
    this.profileLink = profileLink;
    this.dateCreated = dateCreated;
    this.lastUpdated = lastUpdated;
    this.version = version;
    this.status = status;
    this.submissionDate = submissionDate;
    this.reviewDate = reviewDate;
    this.text = text;
    this.isPostCarousel = isPostCarousel;
  }

  public static deserialize(response: any) {
    const {
      scriptId,
      deliverableId,
      platform,
      format,
      creatorHandle,
      profileLink,
      dateCreated,
      lastUpdated,
      version,
      status,
      submissionDate,
      reviewDate,
      text,
      isPostCarousel,
    } = response;

    return new Script({
      scriptId,
      deliverableId,
      platform,
      format,
      creatorHandle,
      profileLink,
      dateCreated: fromISODateString(dateCreated),
      lastUpdated: fromISODateString(lastUpdated),
      version,
      status,
      submissionDate: fromISODateString(submissionDate),
      reviewDate: fromISODateString(reviewDate),
      text,
      isPostCarousel,
    });
  }
}
