import React, { useState, useRef, useEffect, Fragment } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Flex,
  Group,
  NumberInput,
  Radio,
  Space,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { IconCheck, IconCurrencyDollar, IconHelp, IconZoomCheck } from "@tabler/icons-react";

import { DeliverableConfigs } from "components/contracts/models/Deliverable";
import {
  SUPPORTED_FORMATS_TO_PLATFORMS,
  SUPPORTED_FORMATS_TO_LABELS,
  ErrorMessage,
} from "components/contracts/common/Common";
import { Campaign, MaxOfferAction, UsageRightsPolicy } from "models/Campaign";
import {
  SupportedFormat,
  SupportedPlatform,
  SupportedFormatNameToInteger,
  SUPPORTED_PLATFORMS_TO_HANDLES,
  SUPPORTED_PLATFORMS_TO_USAGE_RIGHTS_DAYS,
  UsageRightsDurationToDays,
} from "models/Common";
import { Opportunity } from "models/Opportunity";
import { encodeDeliverablesConfigs } from "components/contracts/deliverables/DeliverablesUrlUtils";
import { ActivePlatformInfo } from "components/creator/CreatorUtils";
import {
  InstagramCreatorInfo,
  TiktokCreatorInfo,
  YoutubeChannelInfo,
} from "components/discovery/Datamodels";
import { CreatorV2Info } from "models/CreatorV2Info";
import { ContractBrandReview } from "components/contracts/brand_review/types";

const OfferCreation = ({
  hidden,
  activePlatforms,
  activeOpportunities,
  contractReview,
  creatorInfo,
  closeLeadName,
  campaignId,
  campaign,
  brandName,
  checkedPlatformList,
  lowerPrice,
  upperPrice,
}: {
  hidden: boolean;
  activePlatforms: ActivePlatformInfo;
  activeOpportunities: Opportunity[];
  contractReview: ContractBrandReview[];
  creatorInfo: CreatorV2Info;
  closeLeadName: string;
  campaignId: number;
  campaign: Campaign;
  brandName: string;
  checkedPlatformList: Array<SupportedFormat>;
  lowerPrice: number;
  upperPrice: number;
}) => {
  const icon = <IconHelp />;
  const usagePriceLower = lowerPrice * 0.1;
  const usagePriceUpper = upperPrice * 0.1;
  const firstSelectedCampaign = activeOpportunities.filter(
    (opp) => opp.campaign_id === campaignId,
  )[0];
  const firstActiveMaxOffer =
    firstSelectedCampaign && firstSelectedCampaign?.max_offer_cap !== undefined
      ? firstSelectedCampaign.max_offer_cap
      : null;
  const firstActiveMaxOfferAction =
    firstSelectedCampaign && firstSelectedCampaign?.max_offer_action !== undefined
      ? firstSelectedCampaign.max_offer_action
      : null;

  const firstBrandMaxPrice =
    contractReview && contractReview.length > 0 ? contractReview[0].brand_max_price : null;

  const creatorId = creatorInfo.id;
  const firstName = creatorInfo.first_name;
  const { email } = creatorInfo;
  const closeContactName = closeLeadName ?? null;

  const dollarRef = useRef<HTMLInputElement>(null);
  const usageRef = useRef<HTMLInputElement>(null);
  const [dollar, setDollar] = useState<string | number>(0);
  const [usagePrice, setUsagePrice] = useState<string | number>(0);

  const [maxOfferCap, setMaxOfferCap] = useState<number>(firstActiveMaxOffer);
  const [maxOfferAction, setMaxOfferAction] = useState<MaxOfferAction>(firstActiveMaxOfferAction);
  const [brandMaxPrice, setBrandMaxPrice] = useState<number>(firstBrandMaxPrice);
  const [maxPrice, setMaxPrice] = useState<number>(0);

  const [checkedUsageRights, setCheckedUsageRights] = useState(false);
  const [tiktokUsageRightsDays, setTiktokUsageRightsDays] = useState<number | string>(0);
  const [youtubeLongUsageRightsDays, setYoutubeLongUsageRightsDays] = useState<number | string>(0);
  const [youtube30UsageRightsDays, setYoutube30UsageRightsDays] = useState<number | string>(0);
  const [youtube60UsageRightsDays, setYoutube60UsageRightsDays] = useState<number | string>(0);
  const [youtube90UsageRightsDays, setYoutube90UsageRightsDays] = useState<number | string>(0);
  const [youtubeShortUsageRightsDays, setYoutubeShortUsageRightsDays] = useState<number | string>(
    0,
  );
  const [instagramUsageRightsDays, setInstagramUsageRightsDays] = useState<number | string>(0);
  const [ugcUsageRightsDays, setUgcUsageRightsDays] = useState<number | string>(0);

  const [tiktokScriptReview, setTiktokScriptReview] = useState(false);
  const [youtubeLongScriptReview, setYoutubeLongScriptReview] = useState(false);
  const [youtube30ScriptReview, setYoutube30ScriptReview] = useState(false);
  const [youtube60ScriptReview, setYoutube60ScriptReview] = useState(false);
  const [youtube90ScriptReview, setYoutube90ScriptReview] = useState(false);
  const [youtubeShortScriptReview, setYoutubeShortScriptReview] = useState(false);
  const [instagramScriptReview, setInstagramScriptReview] = useState(false);
  const [ugcScriptReview, setUgcScriptReview] = useState(false);

  const [checkedBonus, setCheckedBonus] = useState(false);
  const [bonus, setBonus] = useState<string | number>(0);
  const [bonusCondition, setBonusCondition] = useState<string>("");
  const bonusConditionRef = useRef<HTMLTextAreaElement>(null);
  const bonusStates = [checkedBonus, bonus, bonusCondition];

  const [disabled, setDisabled] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Generate Contract");
  const [needsReview, setNeedsReview] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // this is nasty but hopefully this will be temp since
  // the pricing calc will be deprecated for negotiations flow
  const checkedMap = [dollar, checkedUsageRights, checkedBonus, checkedPlatformList.length > 0];
  const formatToStateMap = {
    [SupportedFormat.TIKTOK_DEDICATED_VIDEO]: {
      usage: {
        value: tiktokUsageRightsDays,
        set: setTiktokUsageRightsDays,
      },
      scriptReview: {
        value: tiktokScriptReview,
        set: setTiktokScriptReview,
      },
    },
    [SupportedFormat.YOUTUBE_DEDICATED_VIDEO]: {
      usage: {
        value: youtubeLongUsageRightsDays,
        set: setYoutubeLongUsageRightsDays,
      },
      scriptReview: {
        value: youtubeLongScriptReview,
        set: setYoutubeLongScriptReview,
      },
    },
    [SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO]: {
      usage: {
        value: youtube30UsageRightsDays,
        set: setYoutube30UsageRightsDays,
      },
      scriptReview: {
        value: youtube30ScriptReview,
        set: setYoutube30ScriptReview,
      },
    },
    [SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO]: {
      usage: {
        value: youtube60UsageRightsDays,
        set: setYoutube60UsageRightsDays,
      },
      scriptReview: {
        value: youtube60ScriptReview,
        set: setYoutube60ScriptReview,
      },
    },
    [SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO]: {
      usage: {
        value: youtube90UsageRightsDays,
        set: setYoutube90UsageRightsDays,
      },
      scriptReview: {
        value: youtube90ScriptReview,
        set: setYoutube90ScriptReview,
      },
    },
    [SupportedFormat.YOUTUBE_SHORT]: {
      usage: {
        value: youtubeShortUsageRightsDays,
        set: setYoutubeShortUsageRightsDays,
      },
      scriptReview: {
        value: youtubeShortScriptReview,
        set: setYoutubeShortScriptReview,
      },
    },
    [SupportedFormat.INSTAGRAM_DEDICATED_REEL]: {
      usage: {
        value: instagramUsageRightsDays,
        set: setInstagramUsageRightsDays,
      },
      scriptReview: {
        value: instagramScriptReview,
        set: setInstagramScriptReview,
      },
    },
    [SupportedFormat.INSTAGRAM_DEDICATED_POST]: {
      usage: {
        value: instagramUsageRightsDays,
        set: setInstagramUsageRightsDays,
      },
      scriptReview: {
        value: instagramScriptReview,
        set: setInstagramScriptReview,
      },
    },
    [SupportedFormat.INSTAGRAM_CAROUSEL]: {
      usage: {
        value: instagramUsageRightsDays,
        set: setInstagramUsageRightsDays,
      },
      scriptReview: {
        value: instagramScriptReview,
        set: setInstagramScriptReview,
      },
    },
    [SupportedFormat.UGC]: {
      usage: {
        value: ugcUsageRightsDays,
        set: setUgcUsageRightsDays,
      },
      scriptReview: {
        value: ugcScriptReview,
        set: setUgcScriptReview,
      },
    },
  };

  const deliverablesConfigs = checkedPlatformList.map((platform) => {
    const formatInt = SupportedFormatNameToInteger[platform as SupportedFormat];
    const selectedUsageDays = formatToStateMap[platform as SupportedFormat].usage.value;
    const selectedScriptReview = formatToStateMap[platform as SupportedFormat].scriptReview.value;

    return {
      format: formatInt,
      include_script_review: selectedScriptReview,
      include_usage_rights: checkedUsageRights,
      usage_rights_duration: selectedUsageDays,
    } as DeliverableConfigs;
  });

  useEffect(() => {
    const allUsageSelected = deliverablesConfigs.every((usage) => usage.usage_rights_duration > 0);

    if (checkedMap[0]) {
      if (!checkedMap[3]) {
        setDisabled(true);
      } else if (checkedMap[1] && checkedMap[2]) {
        if (allUsageSelected && bonusStates.every((state) => Number(state) !== 0 || state !== "")) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else if (checkedMap[1] && !checkedMap[2]) {
        if (allUsageSelected) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else if (checkedMap[2] && !checkedMap[1]) {
        if (bonusStates.every((state) => Number(state) !== 0 || state !== "")) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }

    if (!checkedUsageRights) {
      // need to reset usage duration state to zero if unchecked
      Object.entries(formatToStateMap).forEach((state) => {
        const [platform, { usage }] = state;
        usage.set(0);
      });
      setUsagePrice(0);
    }

    if (!checkedBonus) {
      setBonus(0);
      setBonusCondition("");
    }
  }, [
    dollar,
    bonus,
    bonusCondition,
    checkedBonus,
    checkedUsageRights,
    JSON.stringify(deliverablesConfigs.map((item) => item.usage_rights_duration)),
  ]);

  useEffect(() => {
    setDollar(upperPrice);
    if (checkedUsageRights) setUsagePrice(usagePriceUpper);
  }, [upperPrice, usagePriceUpper, checkedUsageRights]);

  useEffect(() => {
    if (!contractReview) return;
    if (activeOpportunities.length > 0) {
      const newCampaign = activeOpportunities.filter((opp) => opp.campaign_id === campaignId)[0];
      setMaxOfferCap(newCampaign?.max_offer_cap);
      setMaxOfferAction(newCampaign?.max_offer_action);
    }
    if (contractReview.length > 0) {
      const newBrandReview = contractReview
        .filter((review) => review.campaign_id === campaignId)
        .at(-1);
      setBrandMaxPrice(newBrandReview?.brand_max_price);
    }
  }, [campaignId, contractReview]);

  useEffect(() => {
    if (!campaign) return;
    Object.values(formatToStateMap).forEach((state) => {
      const { usage } = state;
      if (campaign.usage_rights_policy === UsageRightsPolicy.UPFRONT) {
        setCheckedUsageRights(true);
        usage.set(UsageRightsDurationToDays[campaign.usage_rights_duration]);
      } else {
        setCheckedUsageRights(false);
        usage.set(0);
      }
    });
  }, [campaign, campaignId, checkedUsageRights]);

  useEffect(() => {
    if (Number(dollar) < maxPrice) {
      setNeedsReview(false);
      setShowError(false);
      setErrorMessage("");
      setButtonText("Generate Contract");
    }
  }, [dollar, maxOfferCap, brandMaxPrice]);

  useEffect(() => {
    setMaxPrice(Math.max(brandMaxPrice, maxOfferCap));
  }, [maxOfferCap, brandMaxPrice]);

  if (hidden) return null;

  const platforms = checkedPlatformList.map((platform) => {
    const platformName = SUPPORTED_FORMATS_TO_PLATFORMS[platform as SupportedFormat];
    const usageDays = SUPPORTED_PLATFORMS_TO_USAGE_RIGHTS_DAYS[platformName as SupportedPlatform];
    return {
      [platform]: {
        platform: SUPPORTED_FORMATS_TO_PLATFORMS[platform as SupportedFormat],
        usageDays,
      },
    };
  });

  const scriptReviewOptions = checkedPlatformList.map((platform) => {
    const formatNametoDeliverableString = SUPPORTED_FORMATS_TO_LABELS[platform as SupportedFormat];
    const { scriptReview } = formatToStateMap[platform as SupportedFormat];
    return (
      <Group key={`script-review-${platform}`} wrap="nowrap">
        <Checkbox
          value="script-review"
          checked={scriptReview.value}
          onChange={(e) => {
            scriptReview.set(e.currentTarget.checked);
          }}
        />
        <Text size="sm">{formatNametoDeliverableString}</Text>
      </Group>
    );
  });

  const usageRightsOptions = Object.entries(platforms).map(([platform, info]) => {
    const platformFormat = Object.keys(info)[0] as SupportedFormat;
    const radioMap = Object.values(info).map((stats) => {
      const usageDaysList = stats.usageDays.map((day) => {
        return (
          <Radio
            p={1}
            key={`${platformFormat}-usageRights-${day}`}
            id={`${platformFormat}-${day.toString()}`}
            label={`${day} days`}
            value={day.toString()}
          />
        );
      });
      return usageDaysList;
    });

    const formatNametoDeliverableString =
      SUPPORTED_FORMATS_TO_LABELS[platformFormat as SupportedFormat];
    return (
      <Fragment key={platformFormat}>
        <Stack key={`usageRights-${platformFormat}`}>
          <Radio.Group
            key={`${platformFormat}-selectedUsageDays`}
            name={`${platformFormat}-selectedUsageDays`}
            label={formatNametoDeliverableString}
            value={formatToStateMap[platformFormat].usage.value.toString()}
            onChange={(e) => {
              const { usage } = formatToStateMap[platformFormat];
              usage.set(parseInt(e, 10));
            }}
            withAsterisk>
            {radioMap}
          </Radio.Group>
        </Stack>
        <Space w="sm" key={`space-${platformFormat}`} />
      </Fragment>
    );
  });

  // function to build the url to redirect to contract
  const buildUrl = () => {
    const encodedUrlString = encodeDeliverablesConfigs(deliverablesConfigs);

    const basePlatform: Record<string, string> = {};
    const baseHandles: Record<string, string> = {};

    const seenPlatforms: Record<SupportedPlatform, boolean> = {
      [SupportedPlatform.INSTAGRAM]: null,
      [SupportedPlatform.TIKTOK]: null,
      [SupportedPlatform.YOUTUBE]: null,
      [SupportedPlatform.NONE]: null,
    };

    // build url for selected platforms based on selected deliverable formats
    checkedPlatformList.forEach((format) => {
      const platformName = SUPPORTED_FORMATS_TO_PLATFORMS[format as SupportedFormat];
      if (!seenPlatforms[platformName]) {
        seenPlatforms[platformName] = true;
        basePlatform[platformName] = "true";
      }
    });

    // get handles only for selected platforms from platform info
    Object.entries(activePlatforms)
      .filter(([key, __]) => {
        const platformName = key.split("_")[0] as SupportedPlatform;
        return seenPlatforms[platformName];
      })
      .forEach(([platform, value]) => {
        let platformHandle = "";
        let handle = null;
        const strippedPlatform = platform.split("_")[0];
        if (strippedPlatform.includes("instagram") || strippedPlatform.includes("tiktok")) {
          handle = value as InstagramCreatorInfo | TiktokCreatorInfo;
          platformHandle = handle?.info?.handle;
          platformHandle = "@".concat(platformHandle);
        } else {
          handle = value as YoutubeChannelInfo;
          platformHandle = handle?.handle;
        }
        const platformName = platform.split("_")[0] as SupportedPlatform;
        const platformHandleKey = SUPPORTED_PLATFORMS_TO_HANDLES[platformName];
        baseHandles[platformHandleKey] = platformHandle;
      });

    // build params data
    const data: Record<string, string> = {
      deliverables: encodedUrlString,
      campaignId: campaignId.toString(),
      brandName,
      creatorId: creatorId.toString(),
      email,
      firstName,
      closeContactName,
      contractAmount: (Number(dollar) + Number(usagePrice)).toString(),
      // maxOfferCap: maxOfferCap.toString(),
      recommendPrice: Number(dollar).toString(),
      hasBonus: checkedBonus ? "true" : "false",
      bonusAmount: bonus.toString(),
      bonusCondition: bonusCondition.toLowerCase(),
      ...basePlatform,
      ...baseHandles,
    };
    const params = new URLSearchParams(data);
    const url = `/admin/create_contract?${params}`;
    return url;
  };

  const redirectToContract = () => {
    if (!campaignId) {
      setShowError(true);
      setErrorMessage("Cannot generate contract. Creator is missing an active opportunity.");
      return;
    }
    if (campaignId) {
      if (needsReview) {
        setButtonLoading(true);
        const builtUrl = buildUrl();
        const win = window.open(builtUrl, "_blank");
        win.focus();
        setButtonLoading(false);
      } else if (
        maxPrice !== undefined &&
        maxPrice > 0 &&
        Number(dollar) > maxPrice &&
        maxOfferAction === MaxOfferAction.Review
      ) {
        setNeedsReview(true);
        setShowError(true);
        setErrorMessage(
          `Warning! The contract price is greater than the max offer cap of $${maxPrice}. Click to create a Contract Review.`,
        );
        setButtonText("Generate Contract Review");
      } else if (
        maxPrice !== undefined &&
        maxPrice > 0 &&
        Number(dollar) > maxPrice &&
        maxOfferAction === MaxOfferAction.Reject
      ) {
        setShowError(true);
        setErrorMessage(
          `Cannot generate contract. 
        For this campaign, the max offer cap is $${maxPrice} and is set to Reject for any price above $${maxOfferCap}. 
        Choose an amount less than $${maxPrice}.`,
        );
      } else {
        setShowError(false);
        setErrorMessage("");
        setButtonLoading(true);
        const builtUrl = buildUrl();
        const win = window.open(builtUrl, "_blank");
        win.focus();
        setButtonLoading(false);
      }
    }
  };

  return (
    <Stack p="sm">
      <Text c="gray.9" fw={500}>
        Create Offer
      </Text>
      <Stack>
        <Group align="flex-start" gap="sm" grow={false} wrap="nowrap">
          <Stack>
            <NumberInput
              ref={dollarRef}
              description="Enter price in whole dollars"
              label="Content Price"
              leftSection={<IconCurrencyDollar />}
              value={dollar}
              allowNegative={false}
              allowDecimal={false}
              onChange={setDollar}
              clampBehavior="strict"
              min={1}
            />
            <Alert title="Recommended Price" color="blue" icon={icon}>
              ${upperPrice.toLocaleString()}
              <Space h={1} />
              Sum of all deliverables
            </Alert>
          </Stack>
          <Flex direction="column" ml="md" gap={2} wrap="wrap" maw={500}>
            <Group grow={false} align="center" mt={2}>
              <Text fw={500} size="sm">
                Usage Rights Included:
              </Text>
              <Checkbox
                checked={checkedUsageRights}
                onChange={(e) => {
                  setCheckedUsageRights(e.currentTarget.checked);
                }}
                value="usage-rights"
              />
            </Group>
            {checkedUsageRights && (
              <Stack>
                <NumberInput
                  ref={usageRef}
                  description="Enter price in whole dollars"
                  leftSection={<IconCurrencyDollar />}
                  value={usagePrice}
                  allowNegative={false}
                  allowDecimal={false}
                  onChange={setUsagePrice}
                />

                <Alert title="Recommended Price" color="blue" icon={icon}>
                  ${usagePriceUpper.toLocaleString()}
                  <Space h={1} />
                  Based on 10% of price range
                </Alert>
                <Group gap={2} mt="xs" align="flex-start">
                  {checkedUsageRights && usageRightsOptions.map((option) => option)}
                </Group>
              </Stack>
            )}
          </Flex>
          <Stack>
            <Stack align="flex-start" mt={2} ml="md">
              <Text fw={500} size="sm">
                Script Review Needed:
              </Text>
              {scriptReviewOptions.map((option) => option)}
            </Stack>
          </Stack>
          <Flex direction="column" ml="md" gap={2} wrap="wrap" maw={500}>
            <Group grow={false} align="center" mt={2} wrap="nowrap">
              <Text fw={500} size="sm">
                Bonus Included:
              </Text>
              <Checkbox
                checked={checkedBonus}
                onChange={(e) => {
                  setCheckedBonus(e.currentTarget.checked);
                }}
                value="bonus"
              />
            </Group>
            {checkedBonus && (
              <Stack>
                <NumberInput
                  description="Enter price in whole dollars"
                  leftSection={<IconCurrencyDollar />}
                  allowNegative={false}
                  allowDecimal={false}
                  onChange={setBonus}
                  clampBehavior="strict"
                  min={1}
                  error={bonus === "" || bonus === 0}
                />
                <Textarea
                  required
                  ref={bonusConditionRef}
                  label="Specify Bonus Condition:"
                  autosize
                  minRows={2}
                  maxRows={4}
                  onChange={(event) => setBonusCondition(event.currentTarget.value)}
                />
              </Stack>
            )}
          </Flex>
        </Group>
      </Stack>
      <Space h="xs" />
      {showError && <ErrorMessage errorMessage={errorMessage} />}
      <Group grow>
        {needsReview ? (
          <Button
            disabled={disabled}
            color="yellow"
            rightSection={<IconZoomCheck />}
            loading={buttonLoading}
            onClick={redirectToContract}>
            {buttonText}
          </Button>
        ) : (
          <Button
            disabled={disabled}
            color="green"
            rightSection={<IconCheck />}
            loading={buttonLoading}
            onClick={redirectToContract}>
            {buttonText}
          </Button>
        )}
        <Button color="red" variant="outline" disabled>
          Decline Creator
        </Button>
      </Group>
    </Stack>
  );
};

export default OfferCreation;
