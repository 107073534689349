import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Loader, Text, Stack, Button, Group, Badge, Box, Center } from "@mantine/core";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { embeddingSearch } from "components/discovery/embedding_search/EmbeddingDemoAPI";
import { CreatorDetails } from "components/discovery/Datamodels";

interface RelatedCreatorsModalProps {
  opened: boolean;
  onClose: () => void;
  creatorId: string;
  creatorName: string;
  indexType: string;
}

const RelatedCreatorsModal: React.FC<RelatedCreatorsModalProps> = ({
  opened,
  onClose,
  creatorId,
  creatorName,
  indexType,
}) => {
  const [loading, setLoading] = useState(false);
  const [relatedCreators, setRelatedCreators] = useState<CreatorDetails[]>([]);
  const [error, setError] = useState("");
  const [scoringTime, setScoringTime] = useState<number | null>(null);
  const [detailsTime, setDetailsTime] = useState<number | null>(null);
  
  // Add state for pagination
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  
  // Create ref for the infinite scroll observer target
  const observerTarget = useRef<HTMLDivElement>(null);

  // Reset state when modal opens with new creator
  useEffect(() => {
    if (opened) {
      // Only reset if modal is opening (not when it's already open)
      setRelatedCreators([]);
      setError("");
      setNextCursor(undefined);
      setHasMore(false);
    }
  }, [opened, creatorId]);

  // Define fetchRelatedCreators to get initial results
  const fetchRelatedCreators = async () => {
    setLoading(true);
    setError("");
    
    try {
      const response = await embeddingSearch(
        "",
        indexType,
        20, // Default page size
        false, // isDescription
        undefined, // cursor
        0, // skipResults
        undefined, // evaluationSetId
        false, // useCreatorSetAsQuery
        undefined, // creatorSetId
        false, // enableEvaluation
        creatorId // creatorId
      );

      if (response.success) {
        setRelatedCreators(response.creator_details);
        setScoringTime(response.scoring_time_seconds);
        setDetailsTime(response.details_time_seconds);
        
        // Set pagination state
        setHasMore(response.pagination?.has_more || false);
        setNextCursor(response.pagination?.next_cursor);
      } else {
        setError(response.message || "Failed to fetch related creators");
      }
    } catch (err) {
      setError("An error occurred while fetching related creators");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  // Function to load more results
  const loadMoreResults = async () => {
    if (!nextCursor || isLoadingMore) return;
    
    setIsLoadingMore(true);
    
    try {
      const response = await embeddingSearch(
        "",
        indexType,
        20, // Default page size
        false, // isDescription
        nextCursor, // Using the cursor from previous response
        undefined, // skipResults
        undefined, // evaluationSetId
        false, // useCreatorSetAsQuery
        undefined, // creatorSetId
        false, // enableEvaluation
        creatorId // creatorId
      );

      if (response.success) {
        // Append new results to existing ones
        setRelatedCreators(prev => [...prev, ...response.creator_details]);
        
        // Update pagination state
        setHasMore(response.pagination?.has_more || false);
        setNextCursor(response.pagination?.next_cursor);
      } else {
        console.error("Failed to load more results:", response.message);
      }
    } catch (err) {
      console.error("Error loading more results:", err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Setup intersection observer for infinite scrolling
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasMore && !isLoadingMore && !loading) {
        loadMoreResults();
      }
    },
    [hasMore, isLoadingMore, loading, nextCursor]
  );

  // Create and connect the intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '100px',
      threshold: 0.1
    });
    
    const currentTarget = observerTarget.current;
    
    if (currentTarget && hasMore) {
      observer.observe(currentTarget);
    }
    
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerCallback, hasMore]);

  useEffect(() => {
    if (opened && creatorId) {
      fetchRelatedCreators();
    }
  }, [opened, creatorId, indexType]);

  const renderContent = () => {
    if (loading && relatedCreators.length === 0) {
      return (
        <Stack align="center" py="xl">
          <Loader size="lg" />
          <Text>Finding related creators...</Text>
        </Stack>
      );
    }
    
    if (error && relatedCreators.length === 0) {
      return <Text c="red">{error}</Text>;
    }
    
    return (
      <Stack>
        <Group justify="space-between" mb="md">
          <Text fw={500}>Found {relatedCreators.length} related creators</Text>
          <Group>
            {scoringTime !== null && (
              <Badge color="blue" size="sm">
                Scoring: {scoringTime.toFixed(3)}s
              </Badge>
            )}
            {detailsTime !== null && (
              <Badge color="green" size="sm">
                Details: {detailsTime.toFixed(3)}s
              </Badge>
            )}
          </Group>
        </Group>
        
        <Stack gap="md" style={{ maxHeight: "70vh", overflow: "auto" }}>
          {relatedCreators.length > 0 ? (
            <>
              {relatedCreators.map((creator) => (
                <Box key={creator.creator_id} style={{ position: "relative" }}>
                  <Text fw={700} size="sm" style={{ position: "absolute", top: 16, right: 16, zIndex: 1, color: "blue" }}>
                    Score: {creator.similarity_score?.toFixed(4)}
                  </Text>
                  <UnifiedCreatorRep
                    creator={creator}
                    defaultExpandedPlatforms={["tiktok", "youtube", "instagram"]}
                    refetchThumbnails
                  />
                </Box>
              ))}
              
              {/* Intersection observer target for infinite scrolling */}
              {hasMore && (
                <Box ref={observerTarget} py="md">
                  <Center>
                    <Loader size="md" />
                  </Center>
                </Box>
              )}
            </>
          ) : (
            <Text>No related creators found</Text>
          )}
        </Stack>
        
        <Button onClick={onClose} mt="md" fullWidth>
          Close
        </Button>
      </Stack>
    );
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={`Creators Similar to ${creatorName}`}
      size="xl"
      centered
    >
      {renderContent()}
    </Modal>
  );
};

export default RelatedCreatorsModal; 