import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Loader, Text, Title, Stack, Box, Center } from "@mantine/core";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { getSimilarCreators } from "components/discovery/embedding_search/EmbeddingDemoAPI";
import { CreatorDetails } from "components/discovery/Datamodels";
import SimpleActionRows from "components/creator_lists/unified_creator_rep/SimpleActionRows";
import { getCreatorDisplayName } from "components/discovery/embedding_search/Common";

const DEFAULT_PAGE_SIZE = 20;

interface SimilarCreatorsModalProps {
  parentSessionId: string;
  opened: boolean;
  onClose: () => void;
  creatorId: string;
  creatorName: string;
  creatorSetName: string;
  indexType: string;
  creatorSetId: number;
  onCreatorStateChanged?: (
    creatorId: number,
    entryState: any,
    notes: string | null,
  ) => void;
}

const SimilarCreatorsModal: React.FC<SimilarCreatorsModalProps> = ({
  parentSessionId,
  opened,
  onClose,
  creatorId,
  creatorName,
  creatorSetName,
  indexType,
  creatorSetId,
  onCreatorStateChanged,
}) => {
  const [loading, setLoading] = useState(false);
  const [relatedCreators, setRelatedCreators] = useState<CreatorDetails[]>([]);
  const [error, setError] = useState("");
  
  // Add state for pagination
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  
  // Add state for session tracking
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  
  // Create ref for the infinite scroll observer target
  const observerTarget = useRef<HTMLDivElement>(null);

  // Create ref to track previous creatorId
  const previousCreatorIdRef = useRef<string | undefined>(undefined);

  // Add this inside the SimilarCreatorsModal component
  const [animatingCreators, setAnimatingCreators] = useState<Set<number>>(new Set());

  // Add state to track position information
  const [pagesFetched, setPagesFetched] = useState(0);
  const [creatorPositions, setCreatorPositions] = useState<Map<number, number>>(new Map());

  // Custom close handler to reset session ID
  const handleClose = () => {
    // Reset session ID when modal closes
    setSessionId(undefined);
    // Call the parent onClose function
    onClose();
  };

  // Handle creator state changes
  const handleCreatorStateChanged = (
    creatorIdToUpdate: number,
    entryState: any,
    notes: string | null,
  ) => {
    // When a creator state changes, remove them from the display
    setRelatedCreators((prevCreators) =>
      prevCreators.filter((creator) => creator.creator_id !== creatorIdToUpdate),
    );
    
    // Call the parent handler if provided
    if (onCreatorStateChanged) {
      onCreatorStateChanged(creatorIdToUpdate, entryState, notes);
    }
  };

  // Add this function to handle animation start
  const handleActionStarted = (targetCreatorId: number) => {
    setAnimatingCreators(prevState => {
      const newState = new Set(prevState);
      newState.add(targetCreatorId);
      return newState;
    });
  };

  // Combine effects for modal state management and data fetching
  useEffect(() => {
    if (opened) {
      // Reset states when opening modal
      setRelatedCreators([]);
      setError("");
      setNextCursor(undefined);
      setHasMore(false);
      setPagesFetched(0);
      setCreatorPositions(new Map());
      
      // Reset sessionId and fetch data if creator changed
      if (previousCreatorIdRef.current !== creatorId) {
        setSessionId(undefined);
        previousCreatorIdRef.current = creatorId;
      }
      
      // Fetch data when modal is opened
      if (creatorId) {
        setLoading(true);
        setError("");
        
        getSimilarCreators(
          parentSessionId,
          String(creatorSetId),
          creatorId,
          indexType,
          DEFAULT_PAGE_SIZE,
          undefined, // cursor
          sessionId, // Pass current sessionId (will be undefined for a new creator)
        )
          .then(response => {
            if (response.success) {
              setRelatedCreators(response.creator_details);
              
              // Create position mappings
              const newPositions = new Map();
              response.creator_details.forEach((creator: CreatorDetails, index: number) => {
                newPositions.set(creator.creator_id, index + 1);
              });
              
              setCreatorPositions(newPositions);
              setPagesFetched(1);
              
              // Set pagination state
              setHasMore(response.pagination?.has_more || false);
              setNextCursor(response.pagination?.next_cursor);
              
              // Store session ID from response
              if (response.session_id) {
                setSessionId(response.session_id);
              }
            } else {
              setError(response.message || "Failed to fetch similar creators");
            }
          })
          .catch(err => {
            setError("An error occurred while fetching similar creators");
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      // Reset session ID when modal is closed
      setSessionId(undefined);
    }
  }, [opened, creatorId, indexType, creatorSetId]);

  // Function to load more results
  const loadMoreResults = async () => {
    if (!nextCursor || isLoadingMore) return;
    
    setIsLoadingMore(true);
    
    try {
      const response = await getSimilarCreators(
        parentSessionId,
        String(creatorSetId),
        creatorId,
        indexType,
        DEFAULT_PAGE_SIZE,
        nextCursor, // Using the cursor from previous response
        sessionId, // Pass the session ID for continued pagination
      );

      if (response.success) {
        // Calculate the base position for this page
        const basePosition = (pagesFetched - 1) * DEFAULT_PAGE_SIZE;
        
        // Create a new position map with the current positions
        const newPositions = new Map(creatorPositions);
        
        // Add positions for new creators
        response.creator_details.forEach((creator: CreatorDetails, index: number) => {
          newPositions.set(creator.creator_id, basePosition + index + 1);
        });
        
        // Update position map and increment page counter
        setCreatorPositions(newPositions);
        setPagesFetched(prev => prev + 1);
        
        // Append new results to existing ones
        setRelatedCreators(prev => [...prev, ...response.creator_details]);
        
        // Update pagination state
        setHasMore(response.pagination?.has_more || false);
        setNextCursor(response.pagination?.next_cursor);
        
        // Update session ID if provided (shouldn't change but handle just in case)
        if (response.session_id && response.session_id !== sessionId) {
          setSessionId(response.session_id);
        }
      } else {
        console.error("Failed to load more results:", response.message);
      }
    } catch (err) {
      console.error("Error loading more results:", err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Setup intersection observer for infinite scrolling
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasMore && !isLoadingMore && !loading) {
        loadMoreResults();
      }
    },
    [hasMore, isLoadingMore, loading, nextCursor]
  );

  // Keep the intersection observer effect separate as it has different dependencies
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '100px',
      threshold: 0.1
    });
    
    const currentTarget = observerTarget.current;
    
    if (currentTarget && hasMore) {
      observer.observe(currentTarget);
    }
    
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerCallback, hasMore]);

  const renderContent = () => {
    if (loading && relatedCreators.length === 0) {
      return (
        <Stack align="center" py="xl">
          <Loader size="lg" />
          <Text>Finding similar creators...</Text>
        </Stack>
      );
    }
    
    if (error && relatedCreators.length === 0) {
      return <Text c="red">{error}</Text>;
    }
    
    return (
      <Stack gap={0}>
          {relatedCreators.length > 0 ? (
            <>
              {relatedCreators.map((creator, index) => (
                <Box 
                  key={creator.creator_id} 
                  style={{ 
                    position: "relative",
                    transition: "all 0.3s ease",
                    opacity: animatingCreators.has(creator.creator_id) ? 0 : 1,
                    transform: animatingCreators.has(creator.creator_id) 
                      ? "translateX(20px) scale(0.98)" 
                      : "translateX(0) scale(1)",
                    maxHeight: animatingCreators.has(creator.creator_id) ? "0" : "2000px",
                    marginTop: animatingCreators.has(creator.creator_id) ? "0" : "16px",
                    overflow: "hidden",
                  }}
                >
                  <UnifiedCreatorRep
                    creator={creator}
                    defaultExpandedPlatforms={["tiktok", "youtube", "instagram"]}
                    actionRow={
                      <SimpleActionRows
                        creatorSetId={creatorSetId}
                        creatorId={creator.creator_id}
                        onCreatorStateChanged={handleCreatorStateChanged}
                        sessionId={sessionId}
                        position={creatorPositions.get(creator.creator_id)}
                        surface="related"
                        onActionStarted={() => handleActionStarted(creator.creator_id)}
                        creatorName={getCreatorDisplayName(creator)}
                        creatorSetName={creatorSetName}
                      />
                    }
                  />
                </Box>
              ))}
              
              {/* Intersection observer target for infinite scrolling */}
              {hasMore && (
                <Box ref={observerTarget} py="md">
                  <Center>
                    <Loader size="md" />
                  </Center>
                </Box>
              )}
            </>
          ) : (
            <Text>No similar creators found</Text>
          )}
      </Stack>
    );
  };

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title={<Title order={3}>Creators Similar to {creatorName}</Title>}
      size="xl"
      centered
    >
      {renderContent()}
    </Modal>
  );
};

export default SimilarCreatorsModal; 