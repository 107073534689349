import React from "react";
import { API_URL } from "configs/Configs";
import { Status } from "gestalt";
import "gestalt/dist/gestalt.css";

export enum AuthenticationState {
  LOADING = 1,
  NOT_AUTHENTICATED = 2,
  AUTHENTICATED = 3,
  ERROR = 4, // this doesn't seem to be actually used (not returned from backend)
  IN_DEVELOPMENT = 5, // state for social networks that don't currently support authentication but for which we'll build it in the future.
  CANCELLED = 6, // if the user cancels the authentication process
}

export interface SetAuthState {
  (loginStatus: AuthenticationState): void;
}

export const AuthenticationStatus = ({ authStep }: { authStep: AuthenticationState }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  switch (authStep) {
    case AuthenticationState.LOADING:
      return <Status type="inProgress" title="Getting Status" />;
    case AuthenticationState.NOT_AUTHENTICATED:
      return <Status type="unstarted" title="Unauthenticated" />;
    case AuthenticationState.AUTHENTICATED:
      return <Status type="ok" title="Authenticated!" />;
    case AuthenticationState.ERROR:
      return <Status type="problem" title="Internal Error" subtext="Please refresh." />;
    case AuthenticationState.IN_DEVELOPMENT:
      return <Status type="halted" title="In Development" subtext="Check back soon!" />;
    case AuthenticationState.CANCELLED:
      return <Status type="unstarted" title="Cancelled" subtext="Permissions not granted." />;
    default:
      return <Status type="unstarted" />;
  }
};

// Return error if the email is blocked
export const validateEmailForBlockedDomain = async (email: string): Promise<string | null> => {
  const emailNoPlusRegex = /^[a-zA-Z0-9._%-]+@(?<domain>[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  const emailMatch = emailNoPlusRegex.exec(email);
  if (emailMatch) {
    const url = new URL(`${API_URL}/api/emails/is_domain_blocked/`);
    if (emailMatch.groups.domain) {
      url.searchParams.append("domain", emailMatch.groups.domain);
    }
    const isDomainBlocked = await fetch(url).then((res) => {
      return res.json();
    });
    if (!isDomainBlocked.allowed) {
      throw new Error("Invalid email address");
    }
  }
  throw new Error("Invalid email address format");
};