import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";
import { CreatorSetEntryItem } from "components/discovery/Datamodels";
import {
  CREATOR_SETS_URL,
  CreatorSetEntriesResponse,
} from "components/creator_sets/CreatorSetUtils";

const GET_EXISTING_BRAND_PARTNERS = `${API_URL}/creatorsets/get_existing_partners/`;

export const getExistingBrandPartners = async (abortController: AbortController) => {
  const request = await createRequestWithFirebaseToken({
    url: GET_EXISTING_BRAND_PARTNERS,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  return response;
};

export const getCreatorSetsToDisplay = async (abortController: AbortController) => {
  const request = await createRequestWithFirebaseToken({
    url: `${CREATOR_SETS_URL}get_creator_sets_to_display/`,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  return response;
}

export const fetchAllCreatorSetEntryItems = async (
  abortController: AbortController = new AbortController(),
  onlyActivated = false,
): Promise<CreatorSetEntryItem[]> => {
  const creatorSetInfoEndpoint = new URL(`${CREATOR_SETS_URL}get_all_creator_set_entries/`);
  creatorSetInfoEndpoint.searchParams.append("onlyActivated", onlyActivated.toString());
  const request = await createRequestWithFirebaseToken({
    url: creatorSetInfoEndpoint,
  });

  const response: CreatorSetEntriesResponse = await handleResult(request, abortController);
  if (response !== null) {
    return response.creator_set_entries;
  }
  return [];
};

export const refreshCreatorSetSummary = async (creatorSetId: number) => {
  const requestUrl = new URL(`${API_URL}/api/models/creator_set/${creatorSetId}/refresh_summary/`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
  });
  return handleResult(request);
};

export default null;
