import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export const DEFAULT_PAGE_SIZE = 20;

// API functions for embedding search
export const getEmbeddingIndexTypes = async () => {
  const requestUrl = new URL(`${API_URL}/api/discovery/search/get_embedding_index_types`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "GET",
  });
  return handleResult(request);
};

export const embeddingSearch = async (
  query: string,
  indexType: string,
  pageSize = DEFAULT_PAGE_SIZE,
  isDescription = false,
  cursor?: string,
  skipResults?: number,
  evaluationSetId?: string,
  useCreatorSetAsQuery = false,
  creatorSetId?: string,
  enableEvaluation = true,
  creatorId?: string
) => {
  const requestUrl = new URL(`${API_URL}/api/discovery/search/embedding_search`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      query,
      indexType,
      pageSize,
      isDescription,
      cursor,
      skipResults,
      creatorSetId: useCreatorSetAsQuery ? creatorSetId : evaluationSetId,
      useCreatorSetAsQuery,
      evaluation: enableEvaluation || (!useCreatorSetAsQuery && !!evaluationSetId),
      creatorId
    }),
  });
  return handleResult(request);
};

export const getEvaluationCreatorsets = async () => {
  const requestUrl = new URL(`${API_URL}/api/discovery/search/get_evaluation_creatorsets`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "GET",
  });
  return handleResult(request);
};

export const getAllCreatorsets = async () => {
  const requestUrl = new URL(`${API_URL}/api/discovery/search/get_all_creatorsets`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "GET",
  });
  return handleResult(request);
};

export const getCreatorSetRecommendations = async (
  creatorSetId: string,
  indexType: string,
  pageSize = DEFAULT_PAGE_SIZE,
  cursor?: string,
  skipResults?: number,
  sessionId?: string,
) => {
  const requestUrl = new URL(`${API_URL}/api/discovery/search/get_creator_set_recommendations`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      creatorSetId,
      indexType,
      pageSize,
      cursor,
      skipResults,
      sessionId
    }),
  });
  return handleResult(request);
};

export const getSimilarCreators = async (
  parentSessionId: string,
  creatorSetId: string,
  creatorId: string,
  indexType: string,
  pageSize = DEFAULT_PAGE_SIZE,
  cursor?: string,
  sessionId?: string
) => {
  const requestUrl = new URL(`${API_URL}/api/discovery/search/get_similar_creators`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      parentSessionId,
      creatorSetId,
      creatorId,
      indexType,
      pageSize,
      cursor,
      sessionId
    }),
  });
  return handleResult(request);
};

export const recordCreatorAction = async (
  sessionId: string,
  creatorSetId: string,
  creatorId: string,
  action: "activate" | "reject" | "save" | "existing_partner",
  position = 0,
  surface: "search" | "browse" | "related" = "browse"
) => {
  const requestUrl = new URL(`${API_URL}/api/discovery/search/creator_action`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      session_id: sessionId,
      creator_set_id: creatorSetId,
      creator_id: creatorId,
      action,
      position,
      surface
    }),
  });
  return handleResult(request);
}; 