/* eslint-disable eqeqeq */
import { User } from "firebase/auth";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";

import {
  Accordion,
  Badge,
  Button,
  ComboboxItem,
  Divider,
  Flex,
  Group,
  Modal,
  NativeSelect,
  NumberInput,
  OptionsFilter,
  Select,
  Stack,
  Switch,
  Table,
  TagsInput,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications, Notifications } from "@mantine/notifications";

import { IconPlayerPlay, IconCircleCheck } from "@tabler/icons-react";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import {
  createAdGroupByHashId,
  createCreatorSetByHashId,
  CreatorSetSettingsResponse,
  fetchCreatorSetsByHashId,
  getAdGroupCreatorSets,
} from "campaigns/api/fetchCampaignSettings";
import { startCampaign, markCampaignComplete } from "campaigns/api/manageCampaign";

import {
  createCreativeBriefByBrandId,
  CreativeBriefSettingsResponse,
  fetchCreativeBriefsByBrandId,
} from "campaigns/api/fetchCreativeBriefs";
import { AdGroupTableRow } from "campaigns/main/campaignPage/ad_groups/AdGroupTableRow";
import { CreativeBriefTableRow } from "campaigns/main/campaignPage/creative_briefs/CreativeBriefTableRow";
import { CreatorSet } from "components/discovery/Datamodels";
import Spacer from "components/Spacer";
import { fromISODateString } from "utils/DateUtils";

import { API_URL } from "configs/Configs";
import {
  BudgetType,
  Campaign,
  CampaignAdGroup,
  CampaignObjective,
  CampaignStatus,
  MaxOfferAction,
  ProductAccessInfoNeededFromCreatorType,
  ProductAccessInfoToProvideToCreatorType,
  ReferralLinkLevel,
  ReferralLinkType,
  UsageRightsPolicy,
} from "models/Campaign";
import { SupportedFormatIntegers, UsageRightsDuration } from "models/Common";
import { CreativeBrief } from "models/CreativeBrief";
import { handleResult } from "utils/ApiUtils";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

const updateCampaignByHashId = async (
  firebaseUser: User,
  campaignHashId: number,
  form: any,
  updates: { [key: string]: any },
  setButtonLoading: Dispatch<SetStateAction<boolean>>,
  close: () => void,
) => {
  setButtonLoading(true);
  const firebaseToken = await firebaseUser.getIdToken();
  // convert update to raw fields
  const translatedUpdates: { [key: string]: any } = {
    campaignId: campaignHashId,
  };
  Object.entries(updates).forEach(([key, value]) => {
    if (key === "isTestCampaign") {
      translatedUpdates.is_test_campaign = value;
    } else if (key === "campaignTitle") {
      translatedUpdates.title = value;
    } else if (key === "campaignDescription") {
      translatedUpdates.description = value;
    } else if (key === "budget") {
      translatedUpdates.budget = Number(value);
    } else if (key === "budgetType") {
      translatedUpdates.budget_type = Number(value);
    } else if (key === "takeRate") {
      translatedUpdates.take_rate = value;
    } else if (key === "monthlyTakeCap") {
      translatedUpdates.monthly_take_cap = value * 100;
    } else if (key === "campaignStatus") {
      translatedUpdates.status = Number(value);
    } else if (key === "findEmails") {
      translatedUpdates.find_emails = value;
    } else if (key === "sendEmails") {
      translatedUpdates.send_emails = value;
    } else if (key === "formats") {
      translatedUpdates.formats = value.map(
        (v: keyof typeof SupportedFormatIntegers) => SupportedFormatIntegers[v],
      );
    } else if (key === "usageRights") {
      translatedUpdates.usage_rights_policy = Number(value);
    } else if (key === "usageRightsDuration") {
      translatedUpdates.usage_rights_duration = Number(value);
    } else if (key === "mode") {
      translatedUpdates.mode = value;
    } else if (key === "contentReviewMode") {
      translatedUpdates.content_review_mode = Number(value);
    } else if (key === "concept_review") {
      translatedUpdates.concept_review = value;
    } else if (key === "draft_review") {
      translatedUpdates.draft_review = value;
    } else if (key === "referralLinkType") {
      translatedUpdates.referral_link_type = Number(value);
    } else if (key === "referralLinkLevel") {
      translatedUpdates.referral_link_level = Number(value);
    } else if (key === "has_promo_code") {
      translatedUpdates.has_promo_code = value;
    } else if (key === "fixed_promo_code") {
      translatedUpdates.fixed_promo_code = value;
    } else if (key === "referralLinkTemplate") {
      translatedUpdates.referral_link_template = value;
    } else if (key === "maxOfferAction") {
      translatedUpdates.max_offer_action = Number(value);
    } else if (key === "maxOfferCap") {
      translatedUpdates.max_offer_cap = value != null ? Number(value) : null;
    } else if (key === "hasProductAccess") {
      translatedUpdates.has_product_access = value;
    } else if (key === "productAccessDescription") {
      translatedUpdates.product_access_description = value;
    } else if (key === "productAccessInfoNeededFromCreator") {
      translatedUpdates.product_access_info_needed_from_creator = value;
    } else if (key === "productAccessOtherInfoNeededFromCreator") {
      translatedUpdates.product_access_other_info_needed_from_creator = value;
    } else if (key === "productAccessInfoToProvideToCreator") {
      translatedUpdates.product_access_info_to_provide_to_creator = value;
    } else if (key === "productAccessOtherInfoToProvideToCreator") {
      translatedUpdates.product_access_other_info_to_provide_to_creator = value;
    } else if (key === "productAccessSameAccessCode") {
      translatedUpdates.product_access_same_access_code = value;
    } else if (key === "startDate") {
      translatedUpdates.start_date = value != null ? value.toISOString() : null;
    } else if (key === "endDate") {
      translatedUpdates.end_date = value != null ? value.toISOString() : null;
    } else if (key === "goals") {
      translatedUpdates.goals = value;
    } else if (key === "creative_asset_requests") {
      translatedUpdates.creative_asset_requests = value;
    } else if (key === "parameter_filter") {
      translatedUpdates.parameter_filter = value;
    } else if (key === "objectives") {
      translatedUpdates.objectives = value.map(
        (v: keyof typeof CampaignObjective) => CampaignObjective[v],
      );
    } else if (key === "brand_auto_accept") {
      translatedUpdates.brand_auto_accept = value;
    } else if (key === "internal_auto_accept") {
      translatedUpdates.internal_auto_accept = value;
    }
  });
  const request = new Request(`${API_URL}/api/models/campaigns/${campaignHashId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(translatedUpdates),
  });
  try {
    const response = await handleResult(request);
    if (response != null && response?.message != null) {
      notifications.show({
        color: "red",
        title: "Error with updating the campaign!",
        message: `${response.message}`,
        autoClose: false,
      });
    }
    if (response != null && response.id != null) {
      notifications.show({
        title: "Campaign updated successfully!",
        message: "Campaign updated.",
        autoClose: false,
      });
      form.resetDirty();
    } else {
      notifications.show({
        color: "red",
        title: "Error with updating the campaign!",
        message: "Unknown error.",
        autoClose: false,
      });
    }
  } catch (e) {
    notifications.show({
      color: "red",
      title: "Error with updating the campaign!",
      message: e.message,
      autoClose: false,
    });
  } finally {
    setButtonLoading(false);
    close();
  }
};

function CampaignChangeConfirmation({
  firebaseUser,
  campaignHashId,
  form,
  formValues,
  formatValue,
  initialValues,
  onSubmit,
  close,
}: {
  firebaseUser: User;
  campaignHashId: number;
  form: any;
  formValues: { [key: string]: any };
  formatValue: (key: string, value: any) => string;
  initialValues: { [key: string]: any };
  onSubmit: (
    firebaseUser: User,
    campaignHashId: number,
    form: any,
    updates: { [key: string]: any },
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    close: () => void,
  ) => void;
  close: () => void;
}) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const updates: { [key: string]: any } = {
    campaignId: campaignHashId,
  };

  const valuesEqual = (key: string, initialValue: any, value: any) => {
    if (Array.isArray(initialValue) && Array.isArray(value)) {
      // lol
      return initialValue.sort().toString() === value.sort().toString();
    }
    return initialValue === value;
  };

  const diffs: JSX.Element[] = [];
  Object.entries(formValues).forEach(([key, value]) => {
    if (!valuesEqual(key, initialValues[key], value)) {
      diffs.push(
        <Table.Tr key={`field-changed-${key}`}>
          <Table.Td>{key}</Table.Td>
          <Table.Td>{formatValue(key, initialValues[key])}</Table.Td>
          <Table.Td>{formatValue(key, value)}</Table.Td>
        </Table.Tr>,
      );
      updates[key] = value;
    }
  });

  return (
    <Stack>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Field Name</Table.Th>
            <Table.Th>Previous Value</Table.Th>
            <Table.Th>Updated Value</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{diffs}</Table.Tbody>
      </Table>
      <Flex justify="center">
        <Button
          loading={buttonLoading}
          onClick={() =>
            onSubmit(firebaseUser, campaignHashId, form, updates, setButtonLoading, close)
          }>
          Submit
        </Button>
      </Flex>
    </Stack>
  );
}

function CampaignStatusBadge({ status }: { status: CampaignStatus }) {
  let color: string;
  let label: string;

  switch (status) {
    case CampaignStatus.Draft:
      color = "gray";
      label = "Draft";
      break;
    case CampaignStatus.Onboarding:
      color = "blue";
      label = "Onboarding";
      break;
    case CampaignStatus.Active:
      color = "teal";
      label = "Active";
      break;
    case CampaignStatus.Paused:
      color = "yellow";
      label = "Paused";
      break;
    case CampaignStatus.Completed:
      color = "cyan";
      label = "Completed";
      break;
    default:
      color = "gray";
      label = "Unknown";
  }

  return (
    <Badge variant="light" color={color}>
      {label}
    </Badge>
  );
}

function MarkCampaignCompleteButton({
  campaignId,
  campaignStatus,
  setCampaignStatus,
}: {
  campaignId: number;
  campaignStatus: CampaignStatus;
  setCampaignStatus: (status: CampaignStatus) => void;
}) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const abortController = new AbortController();

  const handleMarkCampaignComplete = async () => {
    setButtonLoading(true);
    markCampaignComplete(campaignId, abortController)
      .then((response) => {
        if (response.success) {
          showSuccessNotification({ message: response.message });
          setCampaignStatus(CampaignStatus.Completed);
        } else {
          showFailureNotification({ message: response.message });
        }
      })
      .catch((error) => {
        showFailureNotification({ message: error.message });
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return (
    <Button
      loading={buttonLoading}
      onClick={handleMarkCampaignComplete}
      size="sm"
      leftSection={<IconCircleCheck size="1rem" />}
      disabled={
        campaignStatus !== CampaignStatus.Active && campaignStatus !== CampaignStatus.Paused
      }>
      Mark Campaign Complete
    </Button>
  );
}

function StartCampaignButton({
  campaignId,
  campaignStatus,
  setCampaignStatus,
}: {
  campaignId: number;
  campaignStatus: CampaignStatus;
  setCampaignStatus: (status: CampaignStatus) => void;
}) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const abortController = new AbortController();

  const handleStartCampaign = async () => {
    setButtonLoading(true);
    startCampaign({ campaignId, abortController })
      .then((response) => {
        if (response.success) {
          showSuccessNotification({ message: response.message });
          setCampaignStatus(CampaignStatus.Active);
        } else {
          showFailureNotification({ message: response.message });
        }
      })
      .catch((error) => {
        showFailureNotification({ message: error.message });
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return (
    <Button
      loading={buttonLoading}
      onClick={handleStartCampaign}
      size="sm"
      leftSection={<IconPlayerPlay size="1rem" />}
      disabled={
        campaignStatus !== CampaignStatus.Draft && campaignStatus !== CampaignStatus.Onboarding
      }>
      Start Campaign
    </Button>
  );
}

function AdminCampaignBasicInfoAccordionItem({
  firebaseUser,
  campaignHashId,
  campaign,
  setCampaignStatus,
  readOnly = false,
}: {
  campaign: Campaign;
  firebaseUser: User;
  campaignHashId: number;
  setCampaignStatus: (status: CampaignStatus) => void;
  readOnly?: boolean;
}) {
  if (!campaign) {
    return null;
  }

  const [opened, { open, close }] = useDisclosure(false);

  const parameterFilters = useAdminAppContext()?.campaignParameters;

  const parameterFilterFormData = parameterFilters?.map((filter) => ({
    label: filter.name,
    value: String(filter.id),
  }));

  const optionsFilter: OptionsFilter = ({ options, search }) => {
    const filtered = (options as ComboboxItem[]).filter((option) =>
      option.label.toLowerCase().trim().includes(search.toLowerCase().trim()),
    );

    filtered.sort((a, b) => a.label.localeCompare(b.label));
    return filtered;
  };

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const initialObj = {
      isTestCampaign: campaign != null ? campaign?.is_test_campaign : false,
      campaignTitle: campaign != null ? campaign?.title : "",
      campaignDescription: campaign != null ? campaign?.description : "",
      campaignStatus:
        campaign != null ? campaign?.status.toString() : CampaignStatus.Unknown.toString(),
      startDate:
        campaign != null && campaign?.start_date != null
          ? fromISODateString(campaign?.start_date)
          : null,
      endDate:
        campaign != null && campaign?.end_date != null
          ? fromISODateString(campaign?.end_date)
          : null,
      concept_review: campaign != null ? campaign?.concept_review : false,
      draft_review: campaign != null ? campaign?.draft_review : false,
      referralLinkType:
        campaign != null
          ? campaign?.referral_link_type.toString()
          : ReferralLinkType.NONE.toString(),
      referralLinkLevel:
        campaign != null
          ? campaign?.referral_link_level.toString()
          : ReferralLinkLevel.NONE.toString(),
      referralLinkTemplate: campaign != null ? campaign?.referral_link_template : "",
      has_promo_code: campaign != null ? campaign?.has_promo_code : false,
      fixed_promo_code: campaign != null ? campaign?.fixed_promo_code : "",
      objectives:
        (campaign?.objectives?.length &&
          campaign?.objectives.map((v: number) => CampaignObjective[v].toString())) ||
        [],
      goals: campaign?.goals || "",
      creative_asset_requests: campaign?.creative_asset_requests || "",
      campaign_params: campaign?.campaign_params.toString() || null,
      brand_auto_accept: campaign?.brand_auto_accept || false,
      internal_auto_accept: campaign?.internal_auto_accept || false,
    };
    setInitialValues(initialObj);
  }, [campaign]);

  const form = useForm({
    initialValues,

    validate: {
      referralLinkTemplate: (value, values) =>
        values.referralLinkType === ReferralLinkType.AUTOMATIC.toString() && value === ""
          ? "Referral Link Template must be set if referral link type is AUTOMATIC"
          : null,
    },
  });

  useEffect(() => {
    form.setInitialValues(initialValues);
    form.setValues(initialValues);
  }, [initialValues]);

  const formatValue = (key: string, value: any) => {
    if (key === "status") {
      return CampaignStatus[value];
    } else if (key === "isTestCampaign") {
      return value ? "true" : "false";
    } else if (key === "referralLinkLevel") {
      return ReferralLinkLevel[value];
    } else if (key === "referralLinkType") {
      return ReferralLinkType[value];
    } else if (key === "startDate" || key === "endDate") {
      return value != null ? value.toDateString() : "";
    } else if (key === "has_promo_code") {
      return value ? "true" : "false";
    } else if (key === "fixed_promo_code") {
      return value;
    } else if (key === "campaign_params") {
      return parameterFilterFormData?.find((filter) => filter.value === value)?.label || value;
    } else if (key === "objectives") {
      return value.join(", ");
    } else if (key === "brand_auto_accept") {
      return value ? "true" : "false";
    } else if (key === "internal_auto_accept") {
      return value ? "true" : "false";
    } else if (key === "concept_review") {
      return value ? "true" : "false";
    } else if (key === "draft_review") {
      return value ? "true" : "false";
    }
    return value;
  };

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Basic Campaign Info</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <CampaignChangeConfirmation
            firebaseUser={firebaseUser}
            campaignHashId={campaignHashId}
            form={form}
            formValues={form.values}
            formatValue={formatValue}
            initialValues={initialValues}
            onSubmit={updateCampaignByHashId}
            close={close}
          />
        </Stack>
      </Modal>
      <Accordion.Item value="basic-info">
        <Accordion.Control>
          <Text fw={700}>Basic Campaign Info</Text>
        </Accordion.Control>
        <Accordion.Panel>
          <form onSubmit={form.onSubmit(open)}>
            <Flex direction="column" gap={12}>
              <Switch
                label="Is Test Campaign"
                checked={form.values.isTestCampaign}
                {...form.getInputProps("isTestCampaign")}
              />
              <TextInput
                styles={{
                  root: {
                    maxWidth: "40%",
                  },
                }}
                label="Campaign Name"
                required
                placeholder="Campaign name"
                /* eslint-disable react/jsx-props-no-spreading */
                {...form.getInputProps("campaignTitle")}
                disabled={readOnly}
              />
              <Textarea
                styles={{
                  root: {
                    maxWidth: "80%",
                  },
                }}
                label="Campaign Description"
                required
                placeholder="Campaign description"
                {...form.getInputProps("campaignDescription")}
                disabled={readOnly}
              />
              <TagsInput
                styles={{
                  root: {
                    maxWidth: "40%",
                  },
                }}
                label="Campaign Objectives"
                data={Object.keys(CampaignObjective)
                  .filter((k) => typeof CampaignObjective[k as any] === "number")
                  .map((key) => ({ label: key, value: key }))}
                {...form.getInputProps("objectives")}
                disabled={readOnly}
              />
              <Textarea
                label="Goals"
                autosize
                maw="80%"
                {...form.getInputProps("goals")}
                description="Campaign goals for influencer marketing."
                disabled={readOnly}
              />
              <Switch
                label="Is Brand Auto Accept"
                checked={form.values.brand_auto_accept}
                {...form.getInputProps("brand_auto_accept")}
              />
              <Switch
                label="Is Internal Auto Accept"
                checked={form.values.internal_auto_accept}
                {...form.getInputProps("internal_auto_accept")}
              />
              <Group>
                <DatePickerInput
                  allowDeselect
                  {...form.getInputProps("startDate")}
                  label="Start Date"
                  placeholder="Campaign Start Date"
                  disabled={readOnly}
                />
                <DatePickerInput
                  allowDeselect
                  {...form.getInputProps("endDate")}
                  label="End Date"
                  placeholder="Campaign End Date"
                  disabled={readOnly}
                />
              </Group>
              <Divider />
              <Flex align="center" gap="xs">
                <Text fw={500}>Campaign Status</Text>
                <CampaignStatusBadge status={campaign.status} />
              </Flex>
              <Group>
                <StartCampaignButton
                  campaignId={campaign.id}
                  campaignStatus={campaign.status}
                  setCampaignStatus={setCampaignStatus}
                />
                <MarkCampaignCompleteButton
                  campaignId={campaign.id}
                  campaignStatus={campaign.status}
                  setCampaignStatus={setCampaignStatus}
                />
              </Group>
              <Divider />
              <Text fw={500}>Execution</Text>
              <Switch
                label="Require Script Review"
                checked={form.values.concept_review}
                {...form.getInputProps("concept_review")}
              />
              <Switch
                label="Require Draft Review"
                checked={form.values.draft_review}
                {...form.getInputProps("draft_review")}
              />
              <Textarea
                label="Creative Asset Requests"
                autosize
                maw="80%"
                {...form.getInputProps("creative_asset_requests")}
                description="Creative asset requests for this campaign."
                disabled={readOnly}
              />
              <NativeSelect
                styles={{
                  root: {
                    maxWidth: "20%",
                  },
                }}
                label="Referral Link Type"
                required
                data={Object.keys(ReferralLinkType)
                  .filter((k) => typeof ReferralLinkType[k as any] === "number")
                  .map((key) => ({ label: key, value: ReferralLinkType[key as any] }))}
                {...form.getInputProps("referralLinkType")}
                disabled={readOnly}
              />
              <TextInput
                styles={{
                  root: {
                    maxWidth: "40%",
                  },
                }}
                required={form.values.referralLinkType === ReferralLinkType.AUTOMATIC.toString()}
                label="Referral Link Template"
                /* eslint-disable react/jsx-props-no-spreading */
                {...form.getInputProps("referralLinkTemplate")}
                disabled={readOnly}
              />
              <NativeSelect
                styles={{
                  root: {
                    maxWidth: "20%",
                  },
                }}
                label="Referral Link Level"
                disabled={
                  readOnly || form.values.referralLinkType === ReferralLinkType.NONE.toString()
                }
                required={form.values.referralLinkType !== ReferralLinkType.NONE.toString()}
                data={Object.keys(ReferralLinkLevel)
                  // TODO(albert): Contract-level referral links are not yet supported.
                  .filter((k) => k !== "CONTRACT")
                  .filter((k) => typeof ReferralLinkLevel[k as any] === "number")
                  .map((key) => ({ label: key, value: ReferralLinkLevel[key as any] }))}
                {...form.getInputProps("referralLinkLevel")}
              />
              <Switch
                label="Has Promo Code"
                checked={form.values.has_promo_code}
                {...form.getInputProps("has_promo_code")}
                disabled={readOnly}
              />
              <TextInput
                styles={{
                  root: {
                    maxWidth: "40%",
                  },
                }}
                label="Fixed Promo Code"
                /* eslint-disable react/jsx-props-no-spreading */
                {...form.getInputProps("fixed_promo_code")}
                disabled={readOnly}
              />
              <Select
                w={200}
                label="Campaign Parameter Filter"
                data={parameterFilterFormData}
                clearable
                searchable
                checkIconPosition="right"
                filter={optionsFilter}
                {...form.getInputProps("campaign_params")}
                disabled={readOnly}
              />
            </Flex>
            <Flex justify="center">
              <Button type="submit" disabled={!form.isDirty()}>
                Save
              </Button>
            </Flex>
          </form>
        </Accordion.Panel>
      </Accordion.Item>
    </>
  );
}

function AdminCampaignBudgetAccordionItem({
  firebaseUser,
  campaignHashId,
  campaign,
  readOnly = false,
}: {
  campaign: Campaign;
  firebaseUser: User;
  campaignHashId: number;
  readOnly?: boolean;
}) {
  if (!campaign) {
    return null;
  }

  const [opened, { open, close }] = useDisclosure(false);

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const initialObj = {
      budget: campaign != null ? campaign?.budget : null,
      budgetType: campaign != null ? campaign?.budget_type.toString() : BudgetType.Once.toString(),
      takeRate: campaign != null ? campaign?.take_rate : null,
      monthlyTakeCap: campaign != null ? campaign.monthly_take_cap / 100 : 0,
    };
    setInitialValues(initialObj);
  }, [campaign]);

  const form = useForm({
    initialValues,
  });

  useEffect(() => {
    form.setInitialValues(initialValues);
    form.setValues(initialValues);
  }, [initialValues]);

  const formatValue = (key: string, value: any) => {
    if (key === "budgetType") {
      return BudgetType[value];
    }
    return value;
  };

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Campaign Budget</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <CampaignChangeConfirmation
            firebaseUser={firebaseUser}
            campaignHashId={campaignHashId}
            form={form}
            formValues={form.values}
            formatValue={formatValue}
            initialValues={initialValues}
            onSubmit={updateCampaignByHashId}
            close={close}
          />
        </Stack>
      </Modal>
      <Accordion.Item value="budget">
        <Accordion.Control>
          <Text fw={700}>Campaign Budget</Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Flex direction="column" gap={12}>
            <NumberInput
              styles={{
                root: {
                  maxWidth: "20%",
                },
              }}
              label="Initial Budget (in USD)"
              required
              placeholder="Budget"
              {...form.getInputProps("budget")}
              disabled={
                readOnly ||
                (campaign.status !== CampaignStatus.Draft &&
                  campaign.status !== CampaignStatus.Onboarding)
              }
              min={0}
              decimalScale={2}
              fixedDecimalScale
              decimalSeparator="."
              thousandSeparator=","
              hideControls
              prefix="$"
            />
            <NativeSelect
              styles={{
                root: {
                  maxWidth: "20%",
                },
              }}
              label="Budget Type"
              required
              // data={[{label: "one-time", value:"0"}, {label: "monthly", value:"1"}, {label: "quarterly", value:"2"}, {label: "yearly", value:"3"}]}
              data={Object.keys(BudgetType)
                .filter((k) => typeof BudgetType[k as any] === "number")
                .map((key) => ({ label: key, value: BudgetType[key as any] }))}
              {...form.getInputProps("budgetType")}
              disabled={
                readOnly ||
                (campaign.status !== CampaignStatus.Draft &&
                  campaign.status !== CampaignStatus.Onboarding)
              }
            />
            <TextInput
              styles={{
                root: {
                  maxWidth: "20%",
                },
              }}
              label="Take Rate"
              required
              placeholder="0.0 - 1.0"
              {...form.getInputProps("takeRate")}
              disabled={readOnly}
            />
            <NumberInput
              w={200}
              label="Monthly Take Cap"
              description="The maximum take we will invoice for the campaign in a month."
              {...form.getInputProps("monthlyTakeCap")}
              min={0}
              decimalScale={2}
              fixedDecimalScale
              decimalSeparator="."
              thousandSeparator=","
              hideControls
              prefix="$"
            />
          </Flex>
          <Flex justify="center">
            <Button
              onClick={() => {
                open();
              }}
              disabled={!form.isDirty()}>
              Save
            </Button>
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </>
  );
}

function AdminProductAccessAccordionItem({
  firebaseUser,
  campaignHashId,
  campaign,
  readOnly = false,
}: {
  campaign: Campaign;
  firebaseUser: User;
  campaignHashId: number;
  readOnly?: boolean;
}) {
  if (!campaign) {
    return null;
  }

  const [opened, { open, close }] = useDisclosure(false);

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const initialObj = {
      hasProductAccess: campaign != null ? campaign?.has_product_access : false,
      productAccessDescription: campaign != null ? campaign?.product_access_description : "",
      productAccessInfoNeededFromCreator:
        campaign != null
          ? campaign?.product_access_info_needed_from_creator
          : ProductAccessInfoNeededFromCreatorType.NONE,
      productAccessOtherInfoNeededFromCreator:
        campaign != null ? campaign?.product_access_other_info_needed_from_creator : "",
      productAccessInfoToProvideToCreator:
        campaign != null
          ? campaign?.product_access_info_to_provide_to_creator
          : ProductAccessInfoToProvideToCreatorType.NONE,
      productAccessOtherInfoToProvideToCreator:
        campaign != null ? campaign?.product_access_other_info_to_provide_to_creator : "",
      productAccessSameAccessCode:
        campaign != null ? campaign?.product_access_same_access_code : "",
    };
    setInitialValues(initialObj);
  }, [campaign]);

  const [isSameAccessCode, setIsSameAccessCode] = useState(
    campaign != null ? !!campaign?.product_access_same_access_code : false,
  );
  const form = useForm({
    initialValues,
  });

  useEffect(() => {
    form.setInitialValues(initialValues);
    form.setValues(initialValues);
  }, [initialValues]);

  // TODO(Bryant): this is a bit hacky with the enum conversion since we wanted this to be more readable.
  const needFromCreatorOptions = [
    { label: "None", value: 0 },
    { label: "Shipping Address", value: 1 },
    { label: "Account Email Address", value: 2 },
    { label: "Accont Username", value: 3 },
    { label: "Other", value: 4 },
  ];

  const provideToCreatorOptions = [
    { label: "None", value: 0 },
    { label: "Access Code", value: 1 },
    { label: "Tracking Code", value: 2 },
    { label: "Other", value: 3 },
  ];
  const formatValue = (key: string, value: any) => {
    if (key === "formats") {
      return value.join(", ");
    } else if (key === "hasProductAccess") {
      return value ? "true" : "false";
    } else if (key === "productAccessInfoNeededFromCreator") {
      return ProductAccessInfoNeededFromCreatorType[value];
    } else if (key === "productAccessInfoToProvideToCreator") {
      return ProductAccessInfoToProvideToCreatorType[value];
    }
    return value;
  };

  return (
    <>
      <Modal size="lg" opened={opened} onClose={close} title={<Text fw="600">Product Access</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <CampaignChangeConfirmation
            firebaseUser={firebaseUser}
            campaignHashId={campaignHashId}
            form={form}
            formValues={form.values}
            formatValue={formatValue}
            initialValues={initialValues}
            onSubmit={updateCampaignByHashId}
            close={close}
          />
        </Stack>
      </Modal>
      <Accordion.Item value="product-access">
        <Accordion.Control>
          <Text fw={700}>Product Access</Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Flex direction="column" gap={12}>
            <Text>
              Do you need to provide access to your product for this campaign (either because your
              product is not free to try or because you need to ship a product)?
            </Text>
            <Switch
              checked={form.values.hasProductAccess}
              {...form.getInputProps("hasProductAccess")}
            />
            {form.getInputProps("hasProductAccess").value && (
              <>
                <Textarea
                  label="Provide a brief description of what you want to provide to creators. This will be displayed to creators."
                  autosize
                  maw="60%"
                  {...form.getInputProps("productAccessDescription")}
                  disabled={readOnly}
                  required
                />
                <NativeSelect
                  styles={{
                    root: {
                      maxWidth: "60%",
                    },
                  }}
                  label="What Information do you need from the creator to provide access?"
                  required
                  data={needFromCreatorOptions.map((option) => ({
                    label: option.label,
                    value: option.value.toString(),
                  }))}
                  {...form.getInputProps("productAccessInfoNeededFromCreator")}
                  disabled={readOnly}
                />
                {form.getInputProps("productAccessInfoNeededFromCreator").value == "4" && (
                  <Textarea
                    label="What information do you need from the creator?"
                    autosize
                    maw="60%"
                    {...form.getInputProps("productAccessOtherInfoNeededFromCreator")}
                    disabled={readOnly}
                    required
                  />
                )}
                <NativeSelect
                  styles={{
                    root: {
                      maxWidth: "60%",
                    },
                  }}
                  label="What Information do you need to provide to creators to access your product?"
                  required
                  data={provideToCreatorOptions.map((option) => ({
                    label: option.label,
                    value: option.value.toString(),
                  }))}
                  {...form.getInputProps("productAccessInfoToProvideToCreator")}
                  disabled={readOnly}
                />
                {form.getInputProps("productAccessInfoToProvideToCreator").value == 1 && (
                  <>
                    <Text>Is the access code you need to provide the same for every creator?</Text>
                    <Switch
                      checked={isSameAccessCode}
                      onChange={() => setIsSameAccessCode(!isSameAccessCode)}
                    />

                    {isSameAccessCode && (
                      <Textarea
                        label="What is the access code you'll provide to creators?"
                        autosize
                        maw="60%"
                        {...form.getInputProps("productAccessSameAccessCode")}
                        disabled={readOnly}
                        required
                      />
                    )}
                  </>
                )}
                {form.getInputProps("productAccessInfoToProvideToCreator").value == "3" && (
                  <Textarea
                    label="What information do you need to give creators?"
                    autosize
                    maw="60%"
                    {...form.getInputProps("productAccessOtherInfoToProvideToCreator")}
                    disabled={readOnly}
                    required
                  />
                )}
              </>
            )}
          </Flex>
          <Flex justify="center">
            <Button
              onClick={() => {
                open();
              }}
              disabled={!form.isDirty()}>
              Save
            </Button>
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </>
  );
}

function AdminCampaignDeliverablesAccordionItem({
  firebaseUser,
  campaignHashId,
  campaign,
  readOnly = false,
}: {
  campaign: Campaign;
  firebaseUser: User;
  campaignHashId: number;
  readOnly?: boolean;
}) {
  if (!campaign) {
    return null;
  }

  const [opened, { open, close }] = useDisclosure(false);

  const convertFormatsToStr = (formats: number[]) => formats.map((f) => SupportedFormatIntegers[f]);

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const initialObj = {
      formats:
        campaign != null && campaign?.formats != null ? convertFormatsToStr(campaign?.formats) : [],
      usageRights:
        campaign != null && campaign?.usage_rights_policy != null
          ? campaign?.usage_rights_policy.toString()
          : UsageRightsPolicy.DEFAULT.toString(),
      usageRightsDuration:
        campaign != null && campaign?.usage_rights_duration != null
          ? campaign?.usage_rights_duration.toString()
          : UsageRightsDuration.SEVEN_DAYS.toString(),
      maxOfferCap: campaign != null ? campaign?.max_offer_cap : 0,
      maxOfferAction:
        campaign != null ? campaign?.max_offer_action.toString() : MaxOfferAction.Review.toString(),
    };
    setInitialValues(initialObj);
  }, [campaign]);

  const form = useForm({
    initialValues,
  });

  useEffect(() => {
    form.setInitialValues(initialValues);
    form.setValues(initialValues);
  }, [initialValues]);

  const formatValue = (key: string, value: any) => {
    if (key === "formats") {
      return value.join(", ");
    } else if (key === "usageRights") {
      return UsageRightsPolicy[value];
    } else if (key === "usageRightsDuration") {
      return UsageRightsDuration[value];
    } else if (key === "maxOfferAction") {
      return MaxOfferAction[value];
    }
    return value;
  };

  return (
    <>
      <Modal size="lg" opened={opened} onClose={close} title={<Text fw="600">Deliverables</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <CampaignChangeConfirmation
            firebaseUser={firebaseUser}
            campaignHashId={campaignHashId}
            form={form}
            formValues={form.values}
            formatValue={formatValue}
            initialValues={initialValues}
            onSubmit={updateCampaignByHashId}
            close={close}
          />
        </Stack>
      </Modal>
      <Accordion.Item value="deliverables">
        <Accordion.Control>
          <Text fw={700}>Deliverables</Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Flex direction="column" gap={12}>
            <TagsInput
              styles={{
                root: {
                  maxWidth: "60%",
                },
              }}
              label="Formats"
              required
              data={Object.keys(SupportedFormatIntegers)
                .filter((k) => typeof SupportedFormatIntegers[k as any] === "number")
                .map((key) => ({ label: key, value: key }))}
              {...form.getInputProps("formats")}
              disabled={readOnly}
            />
            <NativeSelect
              styles={{
                root: {
                  maxWidth: "60%",
                },
              }}
              label="Usage Rights Policy"
              required
              data={Object.keys(UsageRightsPolicy)
                .filter((k) => typeof UsageRightsPolicy[k as any] === "number")
                .map((key) => ({ label: key, value: UsageRightsPolicy[key as any] }))}
              {...form.getInputProps("usageRights")}
              disabled={readOnly}
            />
            <NativeSelect
              styles={{
                root: {
                  maxWidth: "60%",
                },
              }}
              label="Usage Rights Duration"
              required
              data={Object.keys(UsageRightsDuration)
                .filter((k) => typeof UsageRightsDuration[k as any] === "number")
                .map((key) => ({ label: key, value: UsageRightsDuration[key as any] }))}
              {...form.getInputProps("usageRightsDuration")}
              disabled={readOnly}
            />
            <NumberInput
              styles={{
                root: {
                  maxWidth: "20%",
                },
              }}
              label="Max Offer Cap (in USD)"
              description="The maximum offer for a contract that does not need Brand review. If set to 0, there is no cap."
              prefix="$"
              placeholder="0"
              {...form.getInputProps("maxOfferCap")}
              disabled={readOnly}
            />
            <NativeSelect
              styles={{
                root: {
                  maxWidth: "60%",
                },
              }}
              label="Max Offer Action"
              description="The action to take when the max offer cap is reached."
              required
              data={Object.keys(MaxOfferAction)
                .filter((k) => typeof MaxOfferAction[k as any] === "number")
                .map((key) => ({ label: key, value: MaxOfferAction[key as any] }))}
              {...form.getInputProps("maxOfferAction")}
              disabled={readOnly}
            />
          </Flex>
          <Flex justify="center">
            <Button
              onClick={() => {
                open();
              }}
              disabled={!form.isDirty()}>
              Save
            </Button>
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </>
  );
}

function AdminCampaignOperationsAccordionItem({
  firebaseUser,
  campaignHashId,
  campaign,
}: {
  campaign: Campaign;
  firebaseUser: User;
  campaignHashId: number;
}) {
  if (!campaign) {
    return null;
  }

  const [opened, { open, close }] = useDisclosure(false);

  const initialObj = {
    clienCreatorApprovalGoal: campaign != null ? campaign?.client_approved_creators_goal : null,
    findEmails: campaign != null ? campaign?.find_emails : false,
    sendEmails: campaign != null ? campaign?.send_emails : false,
  };

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialObj);

  const form = useForm({
    initialValues: initialObj,
  });

  const formatValue = (key: string, value: any) => value;

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Operations Settings</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <CampaignChangeConfirmation
            firebaseUser={firebaseUser}
            campaignHashId={campaignHashId}
            form={form}
            formValues={form.values}
            formatValue={formatValue}
            initialValues={initialValues}
            onSubmit={updateCampaignByHashId}
            close={close}
          />
        </Stack>
      </Modal>
      <Accordion.Item value="operations">
        <Accordion.Control>
          <Text fw={700}>Operations Settings</Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Flex direction="column" gap={12}>
            <TextInput
              styles={{
                root: {
                  maxWidth: "20%",
                },
              }}
              label="Client Creator Approval Goal"
              required
              placeholder="Client Creator Approval Goal"
              {...form.getInputProps("clientCreatorApprovalGoal")}
            />
            <Switch
              label="Find Emails"
              description="(deprecated -- will remove after syncing states) Enable ops-team enrichment."
              required
              {...form.getInputProps("findEmails", { type: "checkbox" })}
            />
            <Switch
              label="Send Emails"
              description="(deprecated -- will remove after syncing states) Send emails for the campaign."
              required
              {...form.getInputProps("sendEmails", { type: "checkbox" })}
            />
          </Flex>
          <Flex justify="center">
            <Button
              onClick={() => {
                open();
              }}
              disabled={!form.isDirty()}>
              Save
            </Button>
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </>
  );
}

const EDITABLE_ROW_ITEM_STYLE = {
  root: {
    maxWidth: "40%",
  },
};

const AddCreativeBrief = ({
  firebaseUser,
  campaign,
  creativeBriefs,
  setCreativeBriefs,
}: {
  firebaseUser: User;
  campaign: Campaign;
  creativeBriefs: CreativeBrief[];
  setCreativeBriefs: (creativeBriefs: CreativeBrief[]) => void;
}) => {
  const [creativeBriefName, setCreativeBriefName] = useState<string>("");
  return (
    <>
      <Flex direction="column" gap={12}>
        <TextInput
          styles={EDITABLE_ROW_ITEM_STYLE}
          label="Creative Brief Name"
          required
          placeholder="creative brief name"
          onChange={(event) => {
            setCreativeBriefName(event.currentTarget.value);
          }}
          /* eslint-disable react/jsx-props-no-spreading */
        />
      </Flex>
      <Flex justify="center">
        <Button
          onClick={() => {
            createCreativeBriefByBrandId(
              firebaseUser,
              campaign.brand.id,
              creativeBriefName,
              new AbortController(),
            ).then((creativeBrief: CreativeBrief) => {
              setCreativeBriefs([...creativeBriefs, creativeBrief]);
            });
          }}>
          Add
        </Button>
      </Flex>
    </>
  );
};

function AdminCreativeBriefAccordionItem({
  firebaseUser,
  campaign,
  creativeBriefs,
  setCreativeBriefs,
}: {
  firebaseUser: User;
  campaign: Campaign;
  creativeBriefs: CreativeBrief[];
  setCreativeBriefs: (creativeBriefs: CreativeBrief[]) => void;
}) {
  // List out all the creativeBriefs - there's no modification here available yet.

  if (!campaign || !creativeBriefs) {
    return null;
  }
  return (
    <Accordion.Item value="creative-briefs">
      <Accordion.Control>
        <Text fw={700}>Creative Briefs</Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Table withTableBorder withColumnBorders>
          <Table.Tr>
            <Table.Th>Id</Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th>External URL</Table.Th>
            <Table.Th>Internal URL</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th maw={600}>Linked Ad Groups</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
          <Table.Tbody>
            {creativeBriefs.map((brief: CreativeBrief) => (
              <CreativeBriefTableRow
                key={`creative-brief-${brief.id}`}
                brandId={campaign.brand.id}
                creativeBrief={brief}
              />
            ))}
          </Table.Tbody>
        </Table>
        <AddCreativeBrief
          firebaseUser={firebaseUser}
          campaign={campaign}
          creativeBriefs={creativeBriefs}
          setCreativeBriefs={setCreativeBriefs}
        />
      </Accordion.Panel>
    </Accordion.Item>
  );
}

const AddCreatorSet = ({
  firebaseUser,
  campaign,
  creatorSets,
  setCreatorSets,
}: {
  firebaseUser: User;
  campaign: Campaign;
  creatorSets: CreatorSet[];
  setCreatorSets: (creatorSets: CreatorSet[]) => void;
}) => {
  const [creatorSetName, setCreatorSetName] = useState<string>("");

  return (
    <>
      <Flex direction="column" gap={12}>
        <TextInput
          styles={EDITABLE_ROW_ITEM_STYLE}
          label="Creator Set Name"
          required
          placeholder="creator set name"
          onChange={(event) => {
            setCreatorSetName(event.currentTarget.value);
          }}
          /* eslint-disable react/jsx-props-no-spreading */
        />
      </Flex>
      <Flex justify="center">
        <Button
          onClick={() => {
            createCreatorSetByHashId(
              firebaseUser,
              campaign.hash_id,
              creatorSetName,
              new AbortController(),
            ).then((creatorSet: CreatorSet) => {
              setCreatorSets([...creatorSets, creatorSet]);
            });
          }}>
          Add
        </Button>
      </Flex>
    </>
  );
};

function AdminCampaignAdGroupsAccordionItem({
  firebaseUser,
  campaign,
  adGroups,
  creativeBriefs,
  creatorSets,
  setAdGroups,
  readOnly = false,
}: {
  firebaseUser: User;
  campaign: Campaign;
  adGroups: CampaignAdGroup[];
  creativeBriefs: CreativeBrief[];
  creatorSets: CreatorSet[];
  setAdGroups: (adGroups: CampaignAdGroup[]) => void;
  readOnly?: boolean;
}) {
  // Display all the adgroups - with the brief names

  if (!campaign) {
    return null;
  }

  const [opened, { open, close }] = useDisclosure(false);
  const [adGroupName, setAdGroupName] = useState<string>("");
  const [adGroupDescription, setAdGroupDescription] = useState<string>("");
  const [adGroupBriefId, setAdGroupBriefId] = useState<number>(0);
  const [adGroupCreatorSets, setAdGroupCreatorSets] = useState<Record<number, CreatorSet[]>>(null);

  useEffect(() => {
    const adGroupCreatorSetsAbortController = new AbortController();

    if (adGroups?.length > 0) {
      getAdGroupCreatorSets(
        adGroups.map((adGroup) => adGroup.id),
        campaign.hash_id,
        adGroupCreatorSetsAbortController,
      ).then((creatorSetsResponse) => {
        if (creatorSetsResponse) {
          setAdGroupCreatorSets(creatorSetsResponse || {});
        }
      });
    }
  }, [adGroups, campaign]);

  return (
    <Accordion.Item value="adgroups">
      <Accordion.Control>
        <Text fw={700}>Ad Groups</Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Table withTableBorder withColumnBorders>
          <Table.Tr>
            <Table.Th>Ad Group Id</Table.Th>
            <Table.Th>Ad Group Name</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Eligible for Outreach</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
          <Table.Tbody>
            {adGroups.map((adGroup: CampaignAdGroup) => (
              <AdGroupTableRow
                key={`ad-group-${adGroup.id}`}
                user={firebaseUser}
                campaignHashId={campaign.hash_id}
                campaign={campaign}
                adGroup={adGroup}
                adGroupCreatorSets={adGroupCreatorSets?.[adGroup.id]}
                creatorSets={creatorSets}
                creativeBriefs={creativeBriefs}
                readOnly={readOnly}
              />
            ))}
          </Table.Tbody>
        </Table>
        <Spacer height={20} />
        {!readOnly && (
          <>
            <Flex direction="column" gap={12}>
              <TextInput
                styles={EDITABLE_ROW_ITEM_STYLE}
                label="Ad Group Name"
                required
                placeholder="Ad group name"
                onChange={(event) => {
                  setAdGroupName(event.currentTarget.value);
                }}
                /* eslint-disable react/jsx-props-no-spreading */
              />
              <Textarea
                styles={EDITABLE_ROW_ITEM_STYLE}
                label="Ad group Description"
                required
                placeholder="Ad group description"
                onChange={(event) => {
                  setAdGroupDescription(event.currentTarget.value);
                }}
              />
              <Select
                styles={EDITABLE_ROW_ITEM_STYLE}
                label="Creative Brief"
                required
                data={
                  creativeBriefs &&
                  creativeBriefs?.map((brief: CreativeBrief) => ({
                    label: brief.name,
                    value: String(brief.id),
                  }))
                }
                onChange={(value) => {
                  setAdGroupBriefId(parseInt(value, 10));
                }}
              />
            </Flex>
            <Flex justify="center">
              <Button
                onClick={() => {
                  createAdGroupByHashId(
                    campaign.hash_id,
                    adGroupName,
                    adGroupDescription,
                    adGroupBriefId,
                    new AbortController(),
                  ).then((adGroup: CampaignAdGroup) => {
                    setAdGroups([...adGroups, adGroup]);
                  });
                }}>
                Add
              </Button>
            </Flex>
          </>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
}

export const AdminCampaignSettings = ({
  firebaseUser,
  campaign,
  setCampaignStatus,
  readOnly = false,
}: {
  firebaseUser: User;
  campaign: Campaign | null;
  setCampaignStatus: (status: CampaignStatus) => void;
  readOnly?: boolean;
}) => {
  if (!campaign) {
    return null;
  }

  // NOTE WE USE THE HASH ID HERE
  const [campaignId, setCampaignId] = useState<number | null>(campaign?.hash_id || null);

  const [creatorSets, setCreatorSets] = useState<CreatorSet[]>([]);
  const [creativeBriefs, setCreativeBriefs] = useState<CreativeBrief[]>([]);
  const [adGroups, setAdGroups] = useState<CampaignAdGroup[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    setAdGroups(campaign.ad_groups);
    setCampaignId(campaign.hash_id);

    fetchCreatorSetsByHashId(firebaseUser, campaign.hash_id, abortController).then(
      (creatorSetResponse: CreatorSetSettingsResponse) => {
        if (creatorSetResponse) {
          setCreatorSets(creatorSetResponse.results);
        }
      },
    );

    return () => {
      abortController.abort();
    };
  }, [campaign]);

  useEffect(() => {
    const abortController = new AbortController();
    if (campaign) {
      fetchCreativeBriefsByBrandId(campaign?.brand.id, abortController).then(
        (creativeBriefResponse: CreativeBriefSettingsResponse) => {
          if (creativeBriefResponse) {
            setCreativeBriefs(creativeBriefResponse?.results || []);
          }
        },
      );
    }
    return () => {
      abortController.abort();
    };
  }, [campaign]);

  return (
    <>
      <Notifications />
      <Stack gap={24}>
        <Accordion
          variant="contained"
          multiple
          defaultValue={[
            "basic-info",
            "budget",
            "product-access",
            "deliverables",
            "operations",
            "creator-sets",
            "targeting",
            "adgroups",
          ]}>
          <AdminCampaignBasicInfoAccordionItem
            firebaseUser={firebaseUser}
            campaignHashId={campaignId}
            campaign={campaign}
            setCampaignStatus={setCampaignStatus}
            readOnly={readOnly}
          />
          <AdminProductAccessAccordionItem
            firebaseUser={firebaseUser}
            campaignHashId={campaignId}
            campaign={campaign}
            readOnly={readOnly}
          />
          <AdminCampaignBudgetAccordionItem
            firebaseUser={firebaseUser}
            campaignHashId={campaignId}
            campaign={campaign}
            readOnly={readOnly}
          />
          <AdminCampaignDeliverablesAccordionItem
            firebaseUser={firebaseUser}
            campaignHashId={campaignId}
            campaign={campaign}
            readOnly={readOnly}
          />
          <AdminCampaignAdGroupsAccordionItem
            firebaseUser={firebaseUser}
            campaign={campaign}
            adGroups={adGroups}
            creativeBriefs={creativeBriefs}
            creatorSets={creatorSets}
            setAdGroups={setAdGroups}
            readOnly={readOnly}
          />
        </Accordion>
      </Stack>
    </>
  );
};

export default AdminCampaignSettings;
