import {
  ContentStatus,
  ProductAccessInfoNeededFromCreatorType,
  ProductAccessInfoToProvideToCreatorType,
} from "components/contracts/common/Common";
import { CreatorDetails } from "components/discovery/Datamodels";
import { SupportedFormat, SupportedPlatform } from "models/Common";

export enum TaskStatus {
  NONE = 0,
  PENDING = 1,
  COMPLETE = 2,
}

export enum TaskStage {
  ALL = "all",
  CONTRACT_REVIEW = "contract_review",
  CONTRACT_OFFER_REVIEW = "contract_offer_review",
  UNIFIED_CONTRACT_REVIEW = "unified_contract_review",
  PRODUCT_ACCESS = "product_access",
  CODES_LINKS = "codes_links",
  CONTENT_PRODUCTION = "content_production",
  MESSAGES = "messages",
  LIVE_VERIFICATION = "live_verification",
  CREATOR_RATINGS = "creator_ratings",
}

export enum TaskCountType {
  ALL = 1,
  PENDING = 2,
  COMPLETED = 3,
}

export const SupportedFormatToLabel: { [key in SupportedFormat]: string } = {
  [SupportedFormat.INSTAGRAM_DEDICATED_REEL]: "Instagram: Dedicated Reel",
  [SupportedFormat.INSTAGRAM_DEDICATED_POST]: "Instagram: Single Media Post",
  [SupportedFormat.INSTAGRAM_CAROUSEL]: "Instagram: Carousel",
  [SupportedFormat.TIKTOK_DEDICATED_VIDEO]: "TikTok: Dedicated Video",
  [SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO]: "YouTube: 30s Integration",
  [SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO]: "YouTube: 60s Integration",
  [SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO]: "YouTube: 90s Integration",
  [SupportedFormat.YOUTUBE_DEDICATED_VIDEO]: "YouTube: Dedicated Video",
  [SupportedFormat.YOUTUBE_SHORT]: "YouTube: Short",
  [SupportedFormat.UGC]: "UGC",
};

export interface LiveContent {
  dateCreated: Date;
  requiresUsageRights: boolean;
  usageRightsExpirationDate: Date | null;
  url: string;
  instagramAdCode: string;
  tiktokAdCode: string;
  assetUrl: string | null;
}

export function deserializeLiveContent(data: any): LiveContent {
  return {
    dateCreated: new Date(data.date_created),
    requiresUsageRights: data.requires_usage_rights,
    usageRightsExpirationDate: data.usage_rights_expiration_date
      ? new Date(data.usage_rights_expiration_date)
      : null,
    url: data.url,
    instagramAdCode: data.instagram_ad_code,
    tiktokAdCode: data.tiktok_ad_code,
    assetUrl: data.asset_url,
  };
}

export interface DeliverableVideo {
  dateCreated: Date;
  videoId: string;
  deliverableId: string;
  videoLocation: string;
  isTranscoded: boolean;
  title: string;
  caption: string;
  integrationTimestamp: number | null;
  version: number;
  status: ContentStatus;
  reviewDate: Date | null;
  numUnreadMessages?: number;
}

export function deserializeDeliverableVideo(data: any): DeliverableVideo {
  return {
    dateCreated: new Date(data.date_created),
    videoId: data.video_id,
    deliverableId: data.deliverable_id,
    videoLocation: data.video_location,
    isTranscoded: data.is_transcoded,
    title: data.title,
    caption: data.caption,
    integrationTimestamp: data.integration_timestamp,
    version: data.version,
    status: data.status,
    reviewDate: data.review_date ? new Date(data.review_date) : null,
    numUnreadMessages: data.num_unread_messages,
  };
}

export interface Script {
  dateCreated: Date;
  scriptId: string;
  deliverableId: string;
  text: string;
  version: number;
  status: ContentStatus;
  reviewDate: Date | null;
  numUnreadMessages?: number;
  isPostCarousel: boolean;
}

export function deserializeScript(data: any): Script {
  return {
    dateCreated: new Date(data.date_created),
    scriptId: data.script_id,
    deliverableId: data.deliverable_id,
    text: data.text,
    version: data.version,
    status: data.status,
    reviewDate: data.review_date ? new Date(data.review_date) : null,
    numUnreadMessages: data.num_unread_messages,
    isPostCarousel: data.is_post_carousel,
  };
}

export interface DeliverableContent {
  videoDrafts: DeliverableVideo[];
  scripts: Script[];
  format: SupportedFormat;
  platform: SupportedPlatform;
  creatorHandle: string;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
}

export function deserializeDeliverableContent(data: any): DeliverableContent {
  return {
    videoDrafts: data.video_drafts.map(deserializeDeliverableVideo),
    scripts: data.scripts.map(deserializeScript),
    format: data.format,
    platform: data.platform,
    creatorHandle: data.creator_handle,
    missingReferralLink: data.missing_referral_link,
    missingPromoCode: data.missing_promo_code,
  };
}

export enum ProductAccessStatus {
  NOT_STARTED = 0,
  CREATOR_ACTION_REQUIRED = 1,
  CREATOR_RESUBMIT_REQUIRED = 2,
  BRAND_ACTION_REQUIRED = 3,
  COMPLETED = 4,
}

export interface ProductAccess {
  status: ProductAccessStatus;
  description: string;
  infoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
  otherInfoNeededFromCreator: string;
  infoToProvideToCreator: ProductAccessInfoToProvideToCreatorType;
  otherInfoToProvideToCreator: string;
  creatorEmail: string;
  creatorUsername: string;
  creatorShippingAddressJson: Record<string, any>;
  creatorCustomInfo: string;
  accessCode: string;
  trackingInfo: Record<string, any>;
  brandCustomInfo: string;
  brandDisputeReason: string;
}

export function deserializeProductAccess(data: any): ProductAccess {
  return {
    status: data.status,
    description: data.description,
    infoNeededFromCreator: data.info_needed_from_creator,
    otherInfoNeededFromCreator: data.other_info_needed_from_creator,
    infoToProvideToCreator: data.info_to_provide_to_creator,
    otherInfoToProvideToCreator: data.other_info_to_provide_to_creator,
    creatorEmail: data.creator_email,
    creatorUsername: data.creator_username,
    creatorShippingAddressJson: data.creator_shipping_address_json,
    creatorCustomInfo: data.creator_custom_info,
    accessCode: data.access_code,
    trackingInfo: data.tracking_info,
    brandCustomInfo: data.brand_custom_info,
    brandDisputeReason: data.brand_dispute_reason,
  };
}

export enum ContractReviewStatus {
  IN_REVIEW = 0,
  APPROVED = 1,
  REJECTED = 2,
}

export interface ContractReviewDeliverable {
  format: SupportedFormat;
  platform: SupportedPlatform;
  usageRightsDays: number;
  usageRightsInPerpetuity: boolean;
}

export interface ContractReview {
  date_created: string;
  recommended_price: number;
  creator_asking_price: number;
  deliverables: ContractReviewDeliverable[];
  status: ContractReviewStatus;
  creator_details: CreatorDetails;
  // Likely to be empty if not admin or certain brands
  price_debug: string;
}

export function deserializeContractReview(data: any): ContractReview {
  return {
    date_created: data.date_created,
    recommended_price: data.recommended_price,
    creator_asking_price: data.creator_asking_price,
    deliverables: data.deliverables.map((d: any) => ({
      format: d.format,
      platform: d.platform,
      usageRightsDays: d.usage_rights_days,
      usageRightsInPerpetuity: d.usage_rights_in_perpetuity,
    })),
    status: data.status,
    creator_details: data.creator_details,
    price_debug: data.price_debug,
  };
}

export interface CreatorRatings {
  creator_id: number;
  display_name: string;
  avatar_url?: string;
  contract: {
    display_name: string;
    admin_contract_url: string;
    brand_contract_url: string;
    avatar_url: string;
    creator_id: number;
    hash_id: string;
  };
  deadline: Date;
}

export function deserializeCreatorRatings(data: any): CreatorRatings {
  return {
    creator_id: data.creator_id,
    display_name: data.display_name,
    avatar_url: data.avatar_url,
    contract: data.contract,
    deadline: new Date(data.deadline),
  };
}
