import {
  Box,
  Group,
  Stack,
  Text,
  Title,
  Loader,
  Center,
  Alert,
  Button,
  TextInput,
  Badge,
} from "@mantine/core";
import { CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import {
  CreatorDetails,
  CreatorSetEntryState,
  CreatorSetItem,
} from "components/discovery/Datamodels";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  IconUserCheck,
  IconSparkles,
  IconUserSearch,
  IconEye,
  IconSearch,
  IconInfoCircle,
} from "@tabler/icons-react";
import { useElementSize, useInputState, useThrottledValue } from "@mantine/hooks";
import { getCreatorSetRecommendations } from "components/discovery/embedding_search/EmbeddingDemoAPI";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { useUser } from "utils/UserContext";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { selectCreatorSets } from "reduxStore/creatorSetsSelectors";
import { useAppDispatch } from "reduxStore/hooks";
import { getCreatorSetItems } from "reduxStore/creatorSetsSlice";
import SimilarCreatorsModal from "components/discovery/embedding_search/SimilarCreatorsModal";
import SimpleActionRows from "components/creator_lists/unified_creator_rep/SimpleActionRows";
import { getCreatorDisplayName } from "components/discovery/embedding_search/Common";
import { timeAgo } from "utils/DateUtils";

const DEFAULT_PAGE_SIZE = 20;

// Interface for the generated summary structure
interface GeneratedSummary {
  text: string | null;
  exists: boolean;
  last_updated: string | null;
}

// Component to display the AI-generated description or summary
export const AIGeneratedDescription = ({
  description,
  summary,
  creatorSetId,
  onRefresh,
}: {
  description?: string;
  summary?: GeneratedSummary;
  creatorSetId?: number;
  onRefresh?: (id: number) => Promise<void>;
}) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  // We want to always show the component if either:
  // 1. We have a description or summary to display
  // 2. We have a creatorSetId and onRefresh function (so users can generate a summary)
  const hasContent = !!description || (summary && summary.exists && !!summary.text);
  const canRefresh = !!creatorSetId && !!onRefresh;

  if (!hasContent && !canRefresh) return null;

  const displayText = summary?.text || description;
  const lastUpdated = summary?.last_updated ? timeAgo(new Date(summary.last_updated)) : null;

  const handleRefresh = async () => {
    if (!creatorSetId || !onRefresh || isRefreshing) return;

    setIsRefreshing(true);
    try {
      await onRefresh(creatorSetId);
    } catch (error) {
      console.error("Failed to refresh summary:", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <Alert
      icon={<IconSparkles size="2rem" />}
      color="var(--mantine-color-blue-3)"
      radius="md"
      styles={{
        root: {
          border: "1px solid var(--mantine-color-dark-1)",
          position: "relative",
        },
      }}>
      <Box
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          zIndex: 1,
        }}>
        <Group gap={4} align="center">
          <IconEye size={14} />
          <Text size="xs" fw={600} c="dimmed">
            Admin Only
          </Text>
        </Group>
      </Box>
      <Stack gap={4} style={{ flexGrow: 1 }}>
        <Text fw={700} size="md">
          AI-Generated Search Profile
        </Text>

        {!displayText ? (
          <Text size="sm">
            No AI-generated profile exists for this creator set yet. Click the button below to
            generate one.
          </Text>
        ) : (
          <>
            <Text size="xs" c="dimmed">
              Our AI has analyzed your creator set to generate this search profile. It considers
              your description, example topics, keywords, hashtags, example creators, and your
              previously accepted/rejected creators. All recommendations below are based on this
              AI-generated profile.
            </Text>
            <Box style={{ fontSize: "14px" }} className="markdown-content">
              <ReactMarkdown>{displayText}</ReactMarkdown>
            </Box>
          </>
        )}

        <Group gap="xs" justify="space-between" style={{ marginTop: 8 }}>
          {lastUpdated && (
            <Group gap={4} align="center">
              <IconInfoCircle size={14} />
              <Text size="xs" c="dimmed">
                Last updated {lastUpdated}
              </Text>
            </Group>
          )}
          {canRefresh && (
            <Button
              size="xs"
              variant="light"
              onClick={handleRefresh}
              loading={isRefreshing}
              style={{ alignSelf: "flex-end" }}>
              {!displayText ? "Generate Summary" : "Refresh Summary"}
            </Button>
          )}
        </Group>
      </Stack>
    </Alert>
  );
};

const EmptyState = ({ calculatedHeight }: { calculatedHeight: string }) => {
  return (
    <Box style={{ height: calculatedHeight, alignContent: "center" }}>
      <Stack style={{ gap: 12, width: "100%" }} align="center">
        <IconUserCheck size={100} color="var(--mantine-color-dark-0)" />
        <Title order={3} c="var(--mantine-color-dark-2)">
          Activate More Creators
        </Title>
        <Text
          size="lg"
          c="var(--mantine-color-dark-2)"
          style={{
            width: 350,
            textAlign: "center",
          }}>
          Activate at least five creators to unlock recommendations for this creator set.
        </Text>
      </Stack>
    </Box>
  );
};

interface CreatorSetOption {
  value: string;
  label: string;
}

/**
 * Component that shows AI-powered recommendations based on a creator set
 */
const InstantRecommendationsView = ({
  creatorSetId,
  creatorSetName,
}: {
  creatorSetId: number;
  creatorSetName: string;
}) => {
  const [currentUser] = useUser();
  const isStaff = currentUser?.is_staff ?? false;

  // State for search results
  const [loading, setLoading] = useState(false);
  const [creators, setCreators] = useState<CreatorDetails[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedIndexType] = useState("voyage_3_large_filtered_creators_v2");

  // Add session tracking state
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);

  // State for generated query and summary
  const [generatedQuery, setGeneratedQuery] = useState<string>("");
  const [generatedSummary, setGeneratedSummary] = useState<GeneratedSummary | undefined>(undefined);

  // Observer for infinite scroll
  const observerTarget = useRef<HTMLDivElement>(null);

  // State for similar creators modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState<{ id: string; name: string } | null>(null);

  // State for animation
  const [animatingCreators, setAnimatingCreators] = useState<Set<number>>(new Set());

  // Add this state to track the number of pages fetched
  const [pagesFetched, setPagesFetched] = useState(0);
  const [creatorPositions, setCreatorPositions] = useState<Map<number, number>>(new Map());

  // Handle creator state changes
  const handleCreatorStateChanged = (
    creatorId: number,
    entryState: CreatorSetEntryState,
    notes: string | null,
  ) => {
    // When a creator state changes, remove them from the display
    setCreators((prevCreators) =>
      prevCreators.filter((creator) => creator.creator_id !== creatorId),
    );
  };

  // Function to handle showing similar creators
  const handleShowSimilarCreators = (creator: CreatorDetails) => {
    // Get creator name from platform profiles or use creator ID as fallback
    const creatorName = getCreatorDisplayName(creator);

    // Set selected creator and open modal
    setSelectedCreator({
      id: String(creator.creator_id),
      name: creatorName,
    });
    setModalOpen(true);
  };

  // Function to get recommendations for a creator set
  const getRecommendations = async (cursor?: string, currentSessionId?: string) => {
    if (!creatorSetId) return;

    const isLoadingMore = cursor !== undefined;

    if (!isLoadingMore) {
      setLoading(true);
      setCreators([]);
      setPagesFetched(0); // Reset page counter for a new search
      setCreatorPositions(new Map()); // Reset position mapping
    } else {
      setLoadingMore(true);
    }

    try {
      const response = await getCreatorSetRecommendations(
        String(creatorSetId),
        selectedIndexType,
        DEFAULT_PAGE_SIZE,
        cursor, // Cursor for pagination
        undefined, // Skip results
        currentSessionId, // Pass the session ID
      );

      if (response.success) {
        // Calculate the base position based on pages fetched
        const basePosition = pagesFetched * DEFAULT_PAGE_SIZE;

        // Create a new map with the updated positions
        const newPositions = new Map(creatorPositions);

        // Add position mappings for each creator in this page
        response.creator_details.forEach((creator: CreatorDetails, index: number) => {
          newPositions.set(creator.creator_id, basePosition + index + 1);
        });

        // Update the position map
        setCreatorPositions(newPositions);

        // Increment page counter for the next fetch
        setPagesFetched((prev) => prev + 1);

        if (isLoadingMore) {
          setCreators((prev) => [...prev, ...response.creator_details]);
        } else {
          setCreators(response.creator_details);
        }

        setHasMore(response.pagination?.has_more || false);
        setNextCursor(response.pagination?.next_cursor);

        // Store the session ID if it's a new request
        if (response.session_id) {
          setSessionId(response.session_id);
        }

        // Store the generated query if it exists and we're not loading more results
        if (response.generated_query && !isLoadingMore) {
          setGeneratedQuery(response.generated_query);
        }

        // Store the generated summary if it exists and we're not loading more results
        if (response.summary && !isLoadingMore) {
          setGeneratedSummary(response.summary);
        }
      } else {
        console.error("Error in creator set recommendations:", response.message);
      }
    } catch (error) {
      console.error("Failed to fetch recommendations:", error);
    } finally {
      if (isLoadingMore) {
        setLoadingMore(false);
      } else {
        setLoading(false);
      }
    }
  };

  // Reset session when creator set ID changes
  useEffect(() => {
    // Reset the session ID when the creator set changes
    getRecommendations();
  }, [creatorSetId, selectedIndexType]);

  // Set up intersection observer for infinite scrolling
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasMore && !loadingMore) {
        getRecommendations(nextCursor, sessionId);
      }
    },
    [hasMore, loadingMore, nextCursor],
  );

  // Create and connect the intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "100px",
      threshold: 0.1,
    });

    const currentTarget = observerTarget.current;

    if (currentTarget && hasMore) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerCallback, hasMore]);

  // Add this function to handle animation start
  const handleActionStarted = (creatorId: number) => {
    setAnimatingCreators((prevState) => {
      const newState = new Set(prevState);
      newState.add(creatorId);
      return newState;
    });
  };

  if (loading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center", padding: "40px 0" }}>
        <Loader size="lg" />
      </Box>
    );
  }

  if (!loading && creators.length === 0) {
    return <EmptyState calculatedHeight="calc(100vh - 200px)" />;
  }

  return (
    <Stack style={{ gap: 0 }}>
      {/* Only display the AI-generated description when not loading */}
      {!loading && isStaff && (
        <AIGeneratedDescription description={generatedQuery} summary={generatedSummary} />
      )}

      {creators.map((creator) => (
        <Box
          key={`creator-${creator.creator_id}`}
          style={{
            position: "relative",
            transition: "all 0.3s ease",
            opacity: animatingCreators.has(creator.creator_id) ? 0 : 1,
            transform: animatingCreators.has(creator.creator_id)
              ? "translateX(20px) scale(0.98)"
              : "translateX(0) scale(1)",
            maxHeight: animatingCreators.has(creator.creator_id) ? "0" : "2000px",
            marginTop: animatingCreators.has(creator.creator_id) ? "0" : "16px",
            overflow: "hidden",
          }}>
          <Button
            leftSection={<IconUserSearch size={16} />}
            variant="light"
            size="sm"
            styles={{
              root: {
                position: "absolute",
                zIndex: 1,
                top: 16,
                right: "var(--mantine-spacing-lg)",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                transition: "transform 0.2s ease, box-shadow 0.2s ease",
                fontWeight: 600,
                "&:hover": {
                  transform: "translateY(-1px)",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.05)",
                },
              },
            }}
            onClick={() => handleShowSimilarCreators(creator)}>
            Similar Creators
          </Button>
          <UnifiedCreatorRep
            creator={creator}
            defaultExpandedPlatforms={["tiktok", "youtube", "instagram"]}
            actionRow={
              <SimpleActionRows
                creatorSetId={creatorSetId}
                creatorId={creator.creator_id}
                onCreatorStateChanged={handleCreatorStateChanged}
                sessionId={sessionId}
                position={creatorPositions.get(creator.creator_id)}
                surface="browse"
                onActionStarted={() => handleActionStarted(creator.creator_id)}
                creatorName={getCreatorDisplayName(creator)}
                creatorSetName={creatorSetName}
              />
            }
          />
        </Box>
      ))}

      {/* Infinite scrolling intersection observer target */}
      {hasMore && (
        <Box ref={observerTarget} py="md">
          <Center>
            <Loader size="md" />
          </Center>
        </Box>
      )}

      {/* Similar Creators Modal */}
      {selectedCreator && (
        <SimilarCreatorsModal
          parentSessionId={sessionId}
          opened={modalOpen}
          onClose={() => setModalOpen(false)}
          creatorId={selectedCreator.id}
          creatorName={selectedCreator.name}
          creatorSetName={creatorSetName}
          indexType={selectedIndexType}
          creatorSetId={creatorSetId}
          onCreatorStateChanged={handleCreatorStateChanged}
        />
      )}
    </Stack>
  );
};

const InstantRecommendations = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ref: headerRef, height: headerHeight } = useElementSize();
  const allCreatorSets = useSelector(selectCreatorSets) as CreatorSetItem[];

  // see if creatorSetId is selected
  const { creatorSetId } = useParams<{ creatorSetId: string }>();
  const [selectedCreatorSetId, setSelectedCreatorSetId] = useState<number | undefined>(
    creatorSetId !== undefined ? parseInt(creatorSetId, 10) : undefined,
  );

  // State for creator sets and recommendations
  const [creatorSetsAndRecommendations, setCreatorSetsAndRecommendations] = useState<
    { id: number; name: string }[]
  >([]);

  const [searchParams] = useSearchParams();
  // Get the query parameter 'q'
  const existingSearchQuery = searchParams.get("q");

  const [searchQuery, setSearchQuery] = useInputState(existingSearchQuery || "");
  const throttledSearchQuery = useThrottledValue(searchQuery, 1000);

  // Fetch creator sets when component mounts
  useEffect(() => {
    const abortController = new AbortController();
    dispatch(getCreatorSetItems(abortController));
    return () => {
      abortController.abort();
    };
  }, [dispatch]);

  // Update creator set options from Redux
  useEffect(() => {
    let currentCreatorSets = allCreatorSets.map((creatorSet) => {
      return {
        id: creatorSet.id,
        name: creatorSet.name,
      };
    });

    // filter to match search query
    if (throttledSearchQuery) {
      currentCreatorSets = currentCreatorSets.filter((item) =>
        item.name.toLowerCase().includes(throttledSearchQuery.toLowerCase()),
      );
    }

    // Sort creator sets alphabetically by name
    currentCreatorSets.sort((a, b) => a.name.localeCompare(b.name));
    setCreatorSetsAndRecommendations(currentCreatorSets);

    if (creatorSetId === undefined && currentCreatorSets.length > 0) {
      const firstCreatorSet = currentCreatorSets[0];
      setSelectedCreatorSetId(firstCreatorSet.id);
      navigate(
        `${CAMPAIGN_PORTAL}/creator_sets/${firstCreatorSet.id}/instant_recommendations${
          searchQuery ? `?q=${searchQuery}` : ""
        }`,
      );
    } else if (creatorSetId !== undefined) {
      setSelectedCreatorSetId(parseInt(creatorSetId, 10));
    }
  }, [allCreatorSets, creatorSetId, throttledSearchQuery, navigate, searchQuery]);

  // Get the current creator set name for the selected ID
  const selectedCreatorSetName =
    creatorSetsAndRecommendations.find((cs) => cs.id === selectedCreatorSetId)?.name ||
    "Creator Set";

  // For debugging, log the state of creator sets
  const hasCreatorSets = creatorSetsAndRecommendations.length > 0;

  return (
    <Stack style={{ gap: 16 }}>
      <Stack ref={headerRef} style={{ gap: 16 }}>
        <Title order={2}>AI-Powered Creator Recommendations (Beta)</Title>
        <Text c="dimmed" size="sm">
          Our AI analyzes your creator set and recommends new creators that match your preferences.
          Accept or reject creators to further refine your recommendations.
        </Text>

        {/* Creator set selector buttons */}
        <Group style={{ gap: 8, overflowX: "scroll" }} wrap="nowrap">
          <TextInput
            value={searchQuery}
            radius="xl"
            style={{
              width: "fit-content",
            }}
            styles={{
              input: {
                width: "fit-content",
              },
            }}
            leftSection={<IconSearch size={16} />}
            placeholder="Find a creator set"
            onChange={setSearchQuery}
          />
          {hasCreatorSets ? (
            creatorSetsAndRecommendations.map(({ id, name }) => (
              <Button
                key={`instantRecommendations-${id}`}
                onClick={() => {
                  if (searchQuery) {
                    navigate(
                      `${CAMPAIGN_PORTAL}/creator_sets/${id}/instant_recommendations?q=${searchQuery}`,
                    );
                  } else {
                    navigate(`${CAMPAIGN_PORTAL}/creator_sets/${id}/instant_recommendations`);
                  }
                }}
                radius="xl"
                variant={id === selectedCreatorSetId ? "filled" : "white"}
                style={{ overflow: "visible" }}>
                <Text
                  c={
                    id === selectedCreatorSetId
                      ? "var(--mantine-color-white)"
                      : "var(--mantine-color-black)"
                  }
                  size="sm">
                  {name}
                </Text>
              </Button>
            ))
          ) : (
            <Text size="sm" c="dimmed">
              Loading creator sets...
            </Text>
          )}
        </Group>
      </Stack>

      {selectedCreatorSetId && (
        <InstantRecommendationsView
          creatorSetId={selectedCreatorSetId}
          creatorSetName={selectedCreatorSetName}
        />
      )}
    </Stack>
  );
};

export default InstantRecommendations;
