import React, { useState } from "react";
import Star from "components/ratings/Star";
import { Flex } from "@mantine/core";
import { Rating } from "models/Rating";

type StarsProps = {
  rating: Rating;
  isClickable?: boolean;
  handleStarClick: (value: number) => void;
}

function Stars({
  rating,
	isClickable = true,
  handleStarClick,
}: StarsProps) {

	const [stars, setStars] = useState(Array.from({ length: 5 }, (_, index) => {
		return {
			value: index + 1,
			isHovered: false,
		}
	}));

	const handleStarHover = (value: number, isHovered: boolean) => {
		const updatedStars = stars.map((star, index) => ({
			...star,
			isHovered: isHovered ? index < value : false,
		}));
		setStars(updatedStars);
	};
	const isAnyStarHovered = stars.some((star) => star.isHovered);

  return (
		<Flex gap="sm">
			{stars.map(({ value, isHovered }) => 
				<Star 
					key={value}
					active={isAnyStarHovered ? isHovered : value <= (rating.starValue ?? 0)}
					clickable={isClickable}
					isHovered={isHovered}
					onClick={() => handleStarClick(value)}
					onMouseEnter={() => handleStarHover(value, true)}
					onMouseLeave={() => handleStarHover(value, false)}
				/>
			)}
		</Flex>
  );
}

export default Stars;
