import React, { useState, useEffect } from "react";
import {
  TextInput,
  Button,
  Group,
  NumberInput,
  SegmentedControl,
  Textarea,
  Paper,
  Stack,
  Select,
  Switch,
  Alert,
  Loader,
  Text,
  Badge,
  Anchor
} from "@mantine/core";
import { IconAlertCircle, IconExternalLink } from "@tabler/icons-react";
import { EmbeddingIndexType, SearchMode, SearchParams, EvaluationCreatorSet } from "components/discovery/embedding_search/EmbeddingDemoTypes";
import { 
  getEvaluationCreatorsets, 
  getAllCreatorsets 
} from "components/discovery/embedding_search/EmbeddingDemoAPI";

interface SearchFormProps {
  searchParams: SearchParams;
  leftEndpoint: string;
  rightEndpoint: string;
  onSearchParamsChange: (updates: Partial<SearchParams>) => void;
  onEndpointChange: (endpoint: "left" | "right", value: string) => void;
  onSubmit: () => void;
  loading: boolean;
  loadingIndexTypes: boolean;
  indexTypes: EmbeddingIndexType[];
  showDifferencesOnly: boolean;
  setShowDifferencesOnly: (show: boolean) => void;
  hasDifferences: boolean;
  differencesCount: number;
}

const SearchForm: React.FC<SearchFormProps> = ({
  searchParams,
  leftEndpoint,
  rightEndpoint,
  onSearchParamsChange,
  onEndpointChange,
  onSubmit,
  loading,
  loadingIndexTypes,
  indexTypes,
  showDifferencesOnly,
  setShowDifferencesOnly,
  hasDifferences,
  differencesCount
}) => {
  const [evaluationSets, setEvaluationSets] = useState<EvaluationCreatorSet[]>([]);
  const [allCreatorSets, setAllCreatorSets] = useState<EvaluationCreatorSet[]>([]);
  const [loadingEvaluationSets, setLoadingEvaluationSets] = useState(false);
  const [loadingAllCreatorSets, setLoadingAllCreatorSets] = useState(false);

  // Fetch evaluation creator sets
  useEffect(() => {
    const fetchEvaluationSets = async () => {
      setLoadingEvaluationSets(true);
      try {
        const response = await getEvaluationCreatorsets();
        if (response.success) {
          setEvaluationSets(response.results);
        } else {
          console.error("Failed to fetch evaluation sets:", response.message);
        }
      } catch (error) {
        console.error("Error fetching evaluation sets:", error);
      } finally {
        setLoadingEvaluationSets(false);
      }
    };

    fetchEvaluationSets();
  }, []);

  // Fetch all creator sets for the creator set mode
  useEffect(() => {
    const fetchAllCreatorSets = async () => {
      setLoadingAllCreatorSets(true);
      try {
        const response = await getAllCreatorsets();
        if (response.success) {
          setAllCreatorSets(response.results);
        } else {
          console.error("Failed to fetch all creator sets:", response.message);
        }
      } catch (error) {
        console.error("Error fetching all creator sets:", error);
      } finally {
        setLoadingAllCreatorSets(false);
      }
    };

    fetchAllCreatorSets();
  }, []);

  // Format evaluation set options for the Select component
  const evaluationSetOptions = evaluationSets.map(set => ({
    value: String(set.id),
    label: `${set.name} (${set.brand_name}) - ${set.accepted_count} positive, ${set.rejected_count} negative`
  }));

  // Format all creator set options for the Select component
  const allCreatorSetOptions = allCreatorSets.map(set => ({
    value: String(set.id),
    label: `${set.name} (${set.brand_name}) - ${set.accepted_count} positive, ${set.rejected_count} negative`
  }));

  const renderInputField = () => {
    switch (searchParams.searchMode) {
      case "query":
        return (
          <TextInput
            placeholder="Enter your search query"
            label="Search query"
            style={{ flex: 1 }}
            value={searchParams.query}
            onChange={(e) => onSearchParamsChange({ query: e.currentTarget.value })}
            onKeyDown={(e) => e.key === "Enter" && onSubmit()}
          />
        );
      case "description":
        return (
          <Textarea
            placeholder="Enter a detailed description of the creator set you're looking for"
            label="Creator set description"
            style={{ flex: 1 }}
            value={searchParams.description}
            onChange={(e) => onSearchParamsChange({ description: e.currentTarget.value })}
            minRows={3}
            autosize
            maxRows={8}
          />
        );
      case "creatorset":
        return (
          <Group align="flex-end" style={{ flex: 1 }}>
            <div style={{ flex: 1, position: 'relative' }}>
              <Select
                label="Creator Set for Recommendations"
                placeholder="Select a creator set"
                data={allCreatorSetOptions}
                value={searchParams.creatorSetId ? String(searchParams.creatorSetId) : null}
                onChange={(value) => onSearchParamsChange({ creatorSetId: value ? Number(value) : undefined })}
                clearable
                searchable
                nothingFoundMessage="No matching creator sets found"
                disabled={loadingAllCreatorSets}
                required
                style={{ width: '100%' }}
              />
              {searchParams.creatorSetId && (
                <Anchor 
                  href={`/admin/creator_sets?creatorSetId=${searchParams.creatorSetId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="xs"
                  style={{ position: 'absolute', right: 0, top: 0 }}
                >
                  <Group gap={4}>
                    <span>View Creator Set</span>
                    <IconExternalLink size={14} />
                  </Group>
                </Anchor>
              )}
            </div>
            <NumberInput
              label="Number of results"
              value={searchParams.numResults}
              onChange={(value) => onSearchParamsChange({ numResults: Number(value) })}
              min={1}
              max={500}
              step={10}
              style={{ width: "150px" }}
            />
            <NumberInput
              label="Skip first N results"
              value={searchParams.skipResults}
              onChange={(value) => onSearchParamsChange({ skipResults: Number(value) })}
              min={0}
              step={10}
              style={{ width: "150px" }}
            />
            <Button
              onClick={onSubmit}
              disabled={loading || loadingIndexTypes || !searchParams.creatorSetId}>
              {loading ? <Loader size="sm" /> : "Search"}
            </Button>
          </Group>
        );
      case "creator":
        return (
          <Group align="flex-end" style={{ flex: 1 }}>
            <div style={{ flex: 1, position: 'relative' }}>
              <TextInput
                placeholder="Enter creator ID (numeric value only)"
                label="Creator ID"
                style={{ width: '100%' }}
                value={searchParams.creatorId || ""}
                onChange={(e) => {
                  // Only allow numeric input
                  const value = e.currentTarget.value.replace(/[^0-9]/g, '');
                  onSearchParamsChange({ creatorId: value });
                }}
                onKeyDown={(e) => e.key === "Enter" && onSubmit()}
                required
              />
              {searchParams.creatorId && (
                <Anchor 
                  href={`/admin/creator?creatorId=${searchParams.creatorId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="xs"
                  style={{ position: 'absolute', right: 0, top: 0 }}
                >
                  <Group gap={4}>
                    <span>View Creator Profile</span>
                    <IconExternalLink size={14} />
                  </Group>
                </Anchor>
              )}
            </div>
            <NumberInput
              label="Number of results"
              value={searchParams.numResults}
              onChange={(value) => onSearchParamsChange({ numResults: Number(value) })}
              min={1}
              max={500}
              step={10}
              style={{ width: "150px" }}
            />
            <NumberInput
              label="Skip first N results"
              value={searchParams.skipResults}
              onChange={(value) => onSearchParamsChange({ skipResults: Number(value) })}
              min={0}
              step={10}
              style={{ width: "150px" }}
            />
            <Button
              onClick={onSubmit}
              disabled={loading || loadingIndexTypes || !searchParams.creatorId}>
              {loading ? <Loader size="sm" /> : "Search"}
            </Button>
          </Group>
        );
      default:
        throw new Error("Invalid search mode");
    }
  };

  return (
    <Paper p="md" withBorder mb="xl">
      <Stack gap="md">
        <Group mb="md" align="center">
          <SegmentedControl
            value={searchParams.searchMode}
            onChange={(value: SearchMode) => {
              onSearchParamsChange({ searchMode: value });
            }}
            data={[
              { label: "Search Query", value: "query" },
              { label: "Creator Set Description", value: "description" },
              { label: "Related Creators", value: "creator" },
              { label: "Creator Set Recommendations", value: "creatorset" },
            ]}
          />
        </Group>

        {/* Creator Set mode */}
        {searchParams.searchMode === "creatorset" && renderInputField()}

        {/* Creator ID mode */}
        {searchParams.searchMode === "creator" && renderInputField()}

        {/* Show inputs and search button for query and description modes */}
        {(searchParams.searchMode === "query" || searchParams.searchMode === "description") && (
          <Group align="flex-end">
            {renderInputField()}
            <NumberInput
              label="Number of results"
              value={searchParams.numResults}
              onChange={(value) => onSearchParamsChange({ numResults: Number(value) })}
              min={1}
              max={500}
              step={10}
              style={{ width: "150px" }}
            />
            <NumberInput
              label="Skip first N results"
              value={searchParams.skipResults}
              onChange={(value) => onSearchParamsChange({ skipResults: Number(value) })}
              min={0}
              step={10}
              style={{ width: "150px" }}
            />
            <Button
              onClick={onSubmit}
              disabled={
                loading ||
                loadingIndexTypes ||
                !(
                  (searchParams.searchMode === "query" && searchParams.query.trim()) ||
                  (searchParams.searchMode === "description" && searchParams.description.trim())
                )
              }>
              {loading ? <Loader size="sm" /> : "Search"}
            </Button>
          </Group>
        )}

        {/* Only show Evaluation Set Selector if not in creatorset or creator mode */}
        {(searchParams.searchMode !== "creatorset" && searchParams.searchMode !== "creator") && (
          <div style={{ position: 'relative' }}>
            <Select
              label="Evaluation Creator Set (Optional)"
              placeholder="Select a creator set for evaluation"
              data={evaluationSetOptions}
              value={searchParams.evaluationSetId ? String(searchParams.evaluationSetId) : null}
              onChange={(value) => onSearchParamsChange({ evaluationSetId: value ? Number(value) : undefined })}
              clearable
              searchable
              nothingFoundMessage="No matching evaluation sets found"
              disabled={loadingEvaluationSets}
              description="Select a creator set to evaluate search quality"
            />
            {searchParams.evaluationSetId && (
              <Anchor 
                href={`/admin/creator_sets?creatorSetId=${searchParams.evaluationSetId}`}
                target="_blank"
                rel="noopener noreferrer"
                size="xs"
                style={{ position: 'absolute', right: 0, top: 0 }}
              >
                <Group gap={4}>
                  <span>View Creator Set</span>
                  <IconExternalLink size={14} />
                </Group>
              </Anchor>
            )}
          </div>
        )}

        <Group grow>
          <Select
            label="Left Column Endpoint"
            value={leftEndpoint}
            onChange={(value) => value && onEndpointChange("left", value)}
            data={indexTypes.map(type => ({ 
              value: type.value, 
              label: `${type.label} - ${type.version_info}` 
            }))}
            description={
              (indexTypes.find(type => type.value === leftEndpoint)?.description || '') +
              (indexTypes.find(type => type.value === leftEndpoint)?.index_size != null
                ? ` (${indexTypes.find(type => type.value === leftEndpoint)?.index_size?.toLocaleString()} creators)` 
                : '')
            }
            disabled={loadingIndexTypes}
          />
          <Select
            label="Right Column Endpoint"
            value={rightEndpoint}
            onChange={(value) => value && onEndpointChange("right", value)}
            data={indexTypes.map(type => ({ 
              value: type.value, 
              label: `${type.label} - ${type.version_info}` 
            }))}
            description={
              (indexTypes.find(type => type.value === rightEndpoint)?.description || '') +
              (indexTypes.find(type => type.value === rightEndpoint)?.index_size != null
                ? ` (${indexTypes.find(type => type.value === rightEndpoint)?.index_size?.toLocaleString()} creators)` 
                : '')
            }
            disabled={loadingIndexTypes}
          />
        </Group>
        
        {/* Differences toggle */}
        {hasDifferences && (
          <Group justify="space-between" mt="md">
            <Switch
              checked={showDifferencesOnly}
              onChange={(event) => setShowDifferencesOnly(event.currentTarget.checked)}
              label="Show only differences between results"
            />
            
            {showDifferencesOnly && (
              <Badge color="blue">
                {differencesCount} differences found
              </Badge>
            )}
          </Group>
        )}
        
        {showDifferencesOnly && hasDifferences && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="blue">
            Showing creators that appear in one result set but not the other.
            These are creators exclusive to each embedding model&apos;s results.
          </Alert>
        )}
        
        {loadingIndexTypes && (
          <Group justify="center" mt="md">
            <Loader size="sm" />
            <Text>Loading available embedding models...</Text>
          </Group>
        )}
      </Stack>
    </Paper>
  );
};

export default SearchForm; 