import { showSuccessNotification } from "components/common/Notifications";

import { showFailureNotification } from "components/common/Notifications";
import { submitCreatorRatings } from "components/contracts/tasks/api/Api";
import { Rating } from "models/Rating";

export const handleSubmitRating = async (rating: Rating, contractId: string, closeRatingsModal: () => void) => {
  if (!contractId) {
    showFailureNotification({
      title: "Error",
      message: "No contract ID found for rating submission.",
    });
    return;
  }

  try {
    const response = await submitCreatorRatings({
      contractId,
      starValue: rating.starValue,
      tags: rating.tags,
      other_notes: rating.description,
    })

    if (response.success) {
      showSuccessNotification({
        title: "Success",
        message: "Rating submitted successfully.",
      });
      closeRatingsModal();
    } else {
      showFailureNotification({
        title: "Error",
        message: response.error || "Failed to submit rating.",
      });
    }
  } catch (error) {
    showFailureNotification({
      title: "Error",
      message: "An error occurred while submitting the rating.",
    });
  }
};

export default null;