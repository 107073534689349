import {
  Button,
  Center,
  Checkbox,
  Divider,
  Group,
  NativeSelect,
  NumberInput,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import CardBase from "campaigns/create/common/CardBase";
import Spacer from "components/Spacer";
import { IconCheckbox, IconInfoCircle } from "@tabler/icons-react";
import NativeSelectWithTooltip from "campaigns/create/execution/NativeSelectWithTooltip";

import classes from "campaigns/create/ExecutionCampaignCard.module.css";
import { useForm } from "@mantine/form";
import {
  CONCEPT_REVIEW_OPTIONS,
  REFERRAL_LINK_TYPE_PARAMETERIZED,
  REFERRAL_LINK_TYPE_CUSTOM,
  REFERRAL_MANAGEMENT_OPTIONS,
  REFERRAL_LINK_MANAGEMENT_TYPE_INFO,
  REFERRAL_LINK_TYPES,
  REFERRAL_LINK_GRANULARITY_TYPES,
  PROMO_CODE_MANAGEMENT_TYPE_INFO,
  REFERRAL_LINK_TYPE_FIXED,
  PROMO_CODE_TYPES,
  PROMO_CODE_TYPE_FIXED,
  PRODUCT_ACCESS_INFO_FROM_CREATOR,
  PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR,
  PRODUCT_ACCESS_INFO_FROM_CREATOR_NONE,
  PRODUCT_ACCESS_INFO_FROM_CREATOR_OTHER,
  PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_NONE,
  PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_OTHER,
  LIVE_VERIFICATION,
  REFERRAL_LINK_TYPE_VALUE_TO_API_VALUE,
  getReferralLinkTypeFromApiValue,
  PROMO_CODE_TYPE_PER_CONTRACT,
  CONCEPT_REVIEW,
  DRAFT_REVIEW,
  REFFERAL_LINK_GRANULARITY_TYPE_PER_DELIVERABLE,
} from "campaigns/create/execution/ExecutionCardConsts";
import {
  Campaign,
  CampaignStatus,
  MaxOfferAction,
  ReferralLinkLevel,
  ReferralLinkType,
} from "models/Campaign";
import { updateCampaign } from "campaigns/create/api/UpdateCampaignApi";

export interface ExecutionFormType {
  reapprovalBudget: number | null;
  contentReviewOptions: string[];
  useReferralOrPromo: boolean;
  referralManagementTypes: string[];
  referralLinkType: string;
  referralLinkGranularity: string;
  referralLinkTemplate: string | null;
  promoCodeType: string;
  fixedPromoCode: string | null;
  needProductAccess: boolean;
  productAccessDescription: string | null;
  productAccessInfoFromCreator: string;
  productAccessInfoFromCreatorOther: string | null;
  productAccessProvidedForCreator: string;
  productAccessProvidedForCreatorOther: string | null;
}

const ExecutionCampaignCard = ({
  campaign,
  isActive,
  onSaveSuccess,
}: {
  campaign: Campaign | null;
  isActive: boolean;
  onSaveSuccess: (formInfo: ExecutionFormType) => void;
}) => {
  const [isCampaignAlreadyLive, setIsCampaignAlreadyLive] = useState<boolean>(false);
  const [hasFormBeenEdited, setHasFormBeenEdited] = useState<boolean>(false);

  const [showReferralOrPromoSettings, setShowReferralOrPromoSettings] = useState<boolean>(false);
  const [currentReferralManagementTypes, setCurrentReferralManagementTypes] = useState<string[]>(
    [],
  );
  const [currentReferralLinkType, setCurrentReferralLinkType] = useState<string>(
    REFERRAL_LINK_TYPE_PARAMETERIZED.value,
  );
  const [currentPromoCodeType, setCurrentPromoCodeType] = useState<string>(
    PROMO_CODE_TYPE_PER_CONTRACT.value,
  );
  const [showProductAccess, setShowProductAccess] = useState<boolean>(false);
  const [currentProductAccessInfoFromCreator, setCurrentProductAccessInfoFromCreator] =
    useState<string>(PRODUCT_ACCESS_INFO_FROM_CREATOR_NONE.value);
  const [currentProductAccessProvidedForCreator, setCurrentProductAccessProvidedForCreator] =
    useState<string>(PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_NONE.value);

  const form = useForm<ExecutionFormType>({
    mode: "uncontrolled",
    initialValues: {
      reapprovalBudget: null,
      contentReviewOptions: CONCEPT_REVIEW_OPTIONS.map(({ value }) => value),
      useReferralOrPromo: false,
      referralManagementTypes: [],
      referralLinkType: REFERRAL_LINK_TYPE_PARAMETERIZED.value,
      referralLinkGranularity: REFFERAL_LINK_GRANULARITY_TYPE_PER_DELIVERABLE.value,
      referralLinkTemplate: null,
      promoCodeType: PROMO_CODE_TYPE_PER_CONTRACT.value,
      fixedPromoCode: null,
      needProductAccess: false,
      productAccessDescription: null,
      productAccessInfoFromCreator: PRODUCT_ACCESS_INFO_FROM_CREATOR_NONE.value,
      productAccessInfoFromCreatorOther: null,
      productAccessProvidedForCreator: PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_NONE.value,
      productAccessProvidedForCreatorOther: null,
    },

    validate: {
      reapprovalBudget: (value, values) => {
        const MIN_BUDGET_THRESHOLD = 1000;
        if (value && value < MIN_BUDGET_THRESHOLD) {
          return `Please enter an amount greater than $${MIN_BUDGET_THRESHOLD}. If an empty value is provided, there is no cap.`
        }
        return null;
      },
      referralManagementTypes: (value, values) => {
        if (values.useReferralOrPromo && value.length === 0) {
          return "Please select at least one option";
        }
        return null;
      },
      referralLinkTemplate: (value, values) => {
        if (
          values.useReferralOrPromo &&
          values.referralManagementTypes.includes(REFERRAL_LINK_MANAGEMENT_TYPE_INFO.value) &&
          [REFERRAL_LINK_TYPE_PARAMETERIZED.value, REFERRAL_LINK_TYPE_FIXED.value].includes(
            values.referralLinkType,
          )
        ) {
          if (!value) {
            return "Please enter a link here";
          }
          const allMatchingParams = /\{([^{}]+)\}/g;
          const parametersInUrl = value.match(allMatchingParams);

          const validParams = /\{(creator_handle|platform|format|contract_id|deliverable_id)\}/g;
          const validParamsInUrl = value.match(validParams);
          if (REFERRAL_LINK_TYPE_PARAMETERIZED.value === values.referralLinkType) {
            if (
              !validParamsInUrl ||
              validParamsInUrl.length === 0 ||
              !parametersInUrl ||
              parametersInUrl.length === 0
            ) {
              return "Please include at least one valid parameter in your url";
            }
            if (validParamsInUrl.length !== parametersInUrl.length) {
              return "Please make sure all your parameters are valid";
            }
          }
          if (REFERRAL_LINK_TYPE_FIXED.value === values.referralLinkType) {
            if (validParamsInUrl && validParamsInUrl.length !== 0) {
              return "Did you want a parameterized link instead?";
            }
          }
        }
        return null;
      },
      fixedPromoCode: (value, values) => {
        if (
          values.useReferralOrPromo &&
          values.referralManagementTypes.includes(PROMO_CODE_MANAGEMENT_TYPE_INFO.value) &&
          values.promoCodeType === PROMO_CODE_TYPE_FIXED.value &&
          !value
        ) {
          return "Please enter a promo code here";
        }
        return null;
      },
      productAccessDescription: (value, values) => {
        if (values.needProductAccess && !value) {
          return "Please enter a description here";
        }
        return null;
      },
      productAccessInfoFromCreatorOther: (value, values) => {
        if (
          values.needProductAccess &&
          values.productAccessInfoFromCreator === PRODUCT_ACCESS_INFO_FROM_CREATOR_OTHER.value &&
          !value
        ) {
          return "Please enter additional information here";
        }
        return null;
      },
      productAccessProvidedForCreatorOther: (value, values) => {
        if (
          values.needProductAccess &&
          values.productAccessProvidedForCreator ===
            PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_OTHER.value &&
          !value
        ) {
          return "Please enter additional information here";
        }
        return null;
      },
    },
    onValuesChange: () => {
      setHasFormBeenEdited(form.isDirty());
    },
  });

  form.watch("useReferralOrPromo", ({ value }) => {
    setShowReferralOrPromoSettings(value);
  });

  form.watch("referralManagementTypes", ({ value }) => {
    setCurrentReferralManagementTypes(value);
    // if there is no referral link management type selected, reset the referral link type
    if (!value.includes(REFERRAL_LINK_MANAGEMENT_TYPE_INFO.value)) {
      if (form.values.referralLinkType !== currentReferralLinkType) {
        setCurrentReferralLinkType(form.values.referralLinkType);
        form.setFieldValue("referralLinkType", form.values.referralLinkType);
      }
    }
  });

  form.watch("referralLinkType", ({ value }) => {
    setCurrentReferralLinkType(value);
  });

  form.watch("promoCodeType", ({ value }) => {
    setCurrentPromoCodeType(value);
  });

  form.watch("needProductAccess", ({ value }) => {
    setShowProductAccess(value);
  });

  form.watch("productAccessInfoFromCreator", ({ value }) => {
    setCurrentProductAccessInfoFromCreator(value);
  });

  form.watch("productAccessProvidedForCreator", ({ value }) => {
    setCurrentProductAccessProvidedForCreator(value);
  });

  useEffect(() => {
    setIsCampaignAlreadyLive(campaign?.status === CampaignStatus.Active);

    if (campaign) {
      const contentReviewOptions = [LIVE_VERIFICATION.value];
      if (campaign.concept_review) {
        contentReviewOptions.push(CONCEPT_REVIEW.value);
      }
      if (campaign.draft_review) {
        contentReviewOptions.push(DRAFT_REVIEW.value);
      }

      const referralManagementTypes = [];
      let referralLinkType: string | null = REFERRAL_LINK_TYPE_PARAMETERIZED.value;
      let referralLinkGranularity: string | null =
        REFFERAL_LINK_GRANULARITY_TYPE_PER_DELIVERABLE.value;
      let referralLinkTemplate: string | null = null;
      if (campaign.referral_link_type && campaign.referral_link_type !== ReferralLinkType.NONE) {
        referralManagementTypes.push(REFERRAL_LINK_MANAGEMENT_TYPE_INFO.value);
        referralLinkType = getReferralLinkTypeFromApiValue(
          campaign.referral_link_type,
          campaign.referral_link_template,
        );
        referralLinkGranularity = campaign.referral_link_level?.toString();
        referralLinkTemplate = campaign.referral_link_template;
      }

      let promoCodeType = PROMO_CODE_TYPE_PER_CONTRACT.value;
      let fixedPromoCode = "";
      if (campaign.has_promo_code || campaign.fixed_promo_code) {
        referralManagementTypes.push(PROMO_CODE_MANAGEMENT_TYPE_INFO.value);
        if (campaign.fixed_promo_code) {
          promoCodeType = PROMO_CODE_TYPE_FIXED.value;
          fixedPromoCode = campaign.fixed_promo_code;
        }
      }

      form.setValues({
        reapprovalBudget: campaign.max_offer_cap,
        contentReviewOptions,
        useReferralOrPromo: referralManagementTypes.length > 0,
        referralManagementTypes,
        referralLinkType,
        referralLinkGranularity,
        referralLinkTemplate,
        promoCodeType,
        fixedPromoCode,
        needProductAccess: campaign.has_product_access ?? false,
        productAccessDescription: campaign.product_access_description,
        productAccessInfoFromCreator:
          campaign.product_access_info_needed_from_creator.toString() ??
          PRODUCT_ACCESS_INFO_FROM_CREATOR_NONE.value,
        productAccessInfoFromCreatorOther: campaign.product_access_other_info_needed_from_creator,
        productAccessProvidedForCreator:
          campaign.product_access_info_to_provide_to_creator.toString() ??
          PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_NONE.value,
        productAccessProvidedForCreatorOther:
          campaign.product_access_other_info_to_provide_to_creator,
      });
      form.resetDirty();
      setHasFormBeenEdited(form.isDirty());
    }
  }, [campaign]);

  return (
    <CardBase
      isActive={isActive}
      title="Execution"
      subtitle="Choose how involved you want to be in the campaign execution process."
      nodeShownWhenActive={
        <form
          onSubmit={form.onSubmit((values) => {
            // TODO (victoria 4.2024): MAKE API CALL
            let referralLinkType = ReferralLinkType.NONE;
            let referralLinkLevel = ReferralLinkLevel.NONE;
            let referralLinkTemplate = "";

            let fixedPromoCode = "";
            let hasPromoCode = false;
            if (values.useReferralOrPromo) {
              if (
                values.referralManagementTypes.includes(REFERRAL_LINK_MANAGEMENT_TYPE_INFO.value)
              ) {
                referralLinkType = REFERRAL_LINK_TYPE_VALUE_TO_API_VALUE[values.referralLinkType];
                // referralLinkGranularity is None (0) if referral link type is not set
                // but there is a bug right now where selecting the referral link type does not set the referral link granularity
                // so im setting it here (lol)
                // we only support deliverable level for now
                const referralLinkGranularity =
                  values.referralLinkGranularity || ReferralLinkLevel.DELIVERABLE;
                if (
                  [
                    REFERRAL_LINK_TYPE_PARAMETERIZED.value,
                    REFERRAL_LINK_TYPE_CUSTOM.value,
                  ].includes(values.referralLinkType)
                ) {
                  referralLinkLevel = Number(referralLinkGranularity);
                } else if ([REFERRAL_LINK_TYPE_FIXED.value].includes(values.referralLinkType)) {
                  referralLinkLevel = ReferralLinkLevel.DELIVERABLE;
                } else {
                  // clear the fields
                  referralLinkLevel = ReferralLinkLevel.NONE;
                  referralLinkTemplate = "";
                  referralLinkType = ReferralLinkType.NONE;
                }
                if (
                  [REFERRAL_LINK_TYPE_PARAMETERIZED.value, REFERRAL_LINK_TYPE_FIXED.value].includes(
                    values.referralLinkType,
                  )
                ) {
                  referralLinkTemplate = values.referralLinkTemplate;
                }
              } else {
                // Use promo, but don't use referral link
                // Clear referral link fields
                referralLinkType = ReferralLinkType.NONE;
                referralLinkLevel = ReferralLinkLevel.NONE;
                referralLinkTemplate = "";
              }

              if (values.referralManagementTypes.includes(PROMO_CODE_MANAGEMENT_TYPE_INFO.value)) {
                if (values.promoCodeType === PROMO_CODE_TYPE_PER_CONTRACT.value) {
                  hasPromoCode = true;
                }
                if (values.promoCodeType === PROMO_CODE_TYPE_FIXED.value && values.fixedPromoCode) {
                  fixedPromoCode = values.fixedPromoCode;
                }
              }
            } else {
              // Clear both referral links and promos
              referralLinkType = ReferralLinkType.NONE;
              referralLinkLevel = ReferralLinkLevel.NONE;
              referralLinkTemplate = "";
              fixedPromoCode = "";
              hasPromoCode = false;
            }

            updateCampaign(campaign.hash_id, {
              concept_review: values.contentReviewOptions.includes(CONCEPT_REVIEW.value),
              draft_view: values.contentReviewOptions.includes(DRAFT_REVIEW.value),
              max_offer_action: MaxOfferAction.Review, // technically doesn't matter since MaxOfferAction.Reject should be sunsetted
              max_offer_cap: values.reapprovalBudget,
              referral_link_type: referralLinkType,
              referral_link_level: referralLinkLevel,
              referral_link_template: referralLinkTemplate,
              has_promo_code: hasPromoCode,
              fixed_promo_code: fixedPromoCode,
              has_product_access: values.needProductAccess,
              product_access_description: values.productAccessDescription ?? "",
              product_access_info_needed_from_creator: Number(values.productAccessInfoFromCreator),
              product_access_other_info_needed_from_creator:
                values.productAccessInfoFromCreatorOther ?? "",
              product_access_info_to_provide_to_creator: Number(
                values.productAccessProvidedForCreator,
              ),
              product_access_other_info_to_provide_to_creator:
                values.productAccessProvidedForCreatorOther ?? "",
            }).then((result) => {
              onSaveSuccess(values);
              form.resetDirty();
              setHasFormBeenEdited(form.isDirty());
            });
          })}>
          <Stack style={{ "--stack-gap": "24px" }}>
            <Stack gap={0}>
              <NumberInput
                label="Review expensive contracts"
                description="Larger creators usually charge higher rates. If our recommended contract price for a creator exceeds the threshold below, the contract will be sent for you for approval before we submit the offer to the creator."
                placeholder={`$${
                  Math.max(campaign?.max_offer_cap ?? Math.round((campaign?.budget || 0) * 0.3), 1000)
                }`}
                prefix="$"
                decimalScale={2}
                thousandSeparator=","
                key={form.key("reapprovalBudget")}
                {...form.getInputProps("reapprovalBudget")}
                disabled={isCampaignAlreadyLive}
                min={1000}
              />
              <Spacer height={12} />
              <Text size="xs" c="var(--mantine-color-gray-6)">
                The lower the threshold, the more contracts to review and the longer the campaign
                will take to run. The minimum threshold is fixed at $1000.
              </Text>
            </Stack>
            <Divider />
            <Checkbox.Group
              label="Content Review Options"
              description="We strongly recommend that video draft review remain enabled for all campaigns, and that concept review remain enabled for most campaigns. Post-live review is always required."
              withAsterisk
              key={form.key("contentReviewOptions")}
              {...form.getInputProps("contentReviewOptions")}
              readOnly={isCampaignAlreadyLive}>
              <Group mt="xs" wrap="nowrap" align="start">
                {CONCEPT_REVIEW_OPTIONS.map((option) => (
                  <Checkbox
                    key={option.value}
                    value={option.value}
                    label={
                      <>
                        {option.label}
                        <Spacer width={4} />
                        <Tooltip label={option.tooltip}>
                          <IconInfoCircle size="14" color="var(--mantine-color-gray-6)" />
                        </Tooltip>
                      </>
                    }
                    disabled={option.value === LIVE_VERIFICATION.value || isCampaignAlreadyLive}
                    description={option.description}
                    w="0"
                    style={{
                      flexGrow: 1,
                    }}
                    styles={{
                      label: {
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  />
                ))}
              </Group>
            </Checkbox.Group>
            <Divider />
            <Switch
              size="xs"
              label="Do you plan to track attribution through referral codes / promo codes?"
              labelPosition="left"
              key={form.key("useReferralOrPromo")}
              {...form.getInputProps("useReferralOrPromo", { type: "checkbox" })}
              disabled={isCampaignAlreadyLive}
            />
            {showReferralOrPromoSettings ? (
              <>
                <Checkbox.Group
                  classNames={{ root: classes.referralGroupRoot }}
                  label="Referral Management"
                  description="We support custom referral links per creator, promo codes per creator, or a combination of both to track specific creator performance on a campaign."
                  withAsterisk
                  key={form.key("referralManagementTypes")}
                  {...form.getInputProps("referralManagementTypes")}
                  readOnly={isCampaignAlreadyLive}>
                  <Group mt="xs" wrap="nowrap">
                    {REFERRAL_MANAGEMENT_OPTIONS.map(({ label, value, IconName }) => (
                      <Checkbox
                        classNames={{ input: classes.referralOrPromoCheckbox }}
                        key={label}
                        value={value}
                        disabled={isCampaignAlreadyLive}
                        size="lg"
                        label={
                          <Group>
                            <Center
                              h="40px"
                              w="40px"
                              c="var(--mantine-color-gray-5)"
                              style={{
                                border: "1px solid var(--mantine-color-gray-4)",
                                borderRadius: "var(--mantine-radius-sm)",
                              }}>
                              <IconName size={16} />
                            </Center>
                            <Text component="span" size="sm">
                              {label}
                            </Text>
                          </Group>
                        }
                        icon={IconCheckbox}
                        labelPosition="left"
                        variant="outline"
                        styles={{
                          root: {
                            border: "1px solid var(--mantine-color-gray-2)",
                            padding: " var(--mantine-spacing-xs) var(--mantine-spacing-sm)",
                            borderRadius: "var(--mantine-radius-md)",
                          },
                          labelWrapper: {
                            flexGrow: 1,
                            alignContent: "center",
                          },
                          body: {
                            alignItems: "center",
                          },
                          icon: {
                            height: "36px",
                            width: "36px",
                          },
                        }}
                        style={{
                          flexGrow: 1,
                          width: 0,
                        }}
                      />
                    ))}
                  </Group>
                </Checkbox.Group>
                {currentReferralManagementTypes.includes(
                  REFERRAL_LINK_MANAGEMENT_TYPE_INFO.value,
                ) ? (
                  <>
                    <NativeSelectWithTooltip
                      data={REFERRAL_LINK_TYPES}
                      title="Referral Link Type"
                      keyProp={form.key("referralLinkType")}
                      inputProps={form.getInputProps("referralLinkType")}
                      disabled={isCampaignAlreadyLive}
                    />
                    {/* only show referral link template input for paramaterized or fixed */}
                    {[
                      REFERRAL_LINK_TYPE_PARAMETERIZED.value,
                      REFERRAL_LINK_TYPE_FIXED.value,
                    ].includes(currentReferralLinkType) ? (
                      <TextInput
                        label={
                          currentReferralLinkType === REFERRAL_LINK_TYPE_PARAMETERIZED.value
                            ? "Referral Link Template"
                            : "Fixed Link"
                        }
                        description={
                          currentReferralLinkType === REFERRAL_LINK_TYPE_PARAMETERIZED.value ? (
                            <Text size="xs" component="span">
                              We will autogenerate referral links based on the structure you define
                              below. Available parameters:
                              <br />
                              &#123;creator_handle&#125;: becomes the creator’s handle. So Mr Beast
                              would become MrBeast
                              <br />
                              &#123;platform&#125;: The social platform. Can be YouTube, Instagram,
                              or TikTok
                              <br />
                              &#123;format&#125;: The content type. Can be Short, Long, Reel, or
                              video
                              <br />
                              &#123;contract_id&#125;: An ID that we assign to each contract.
                              <br />
                              &#123;deliverable_id&#125;: An ID that we assign to each deliverable
                              within the contract.
                            </Text>
                          ) : null
                        }
                        placeholder={
                          currentReferralLinkType === REFERRAL_LINK_TYPE_PARAMETERIZED.value
                            ? "https://www.1stcollab.com.com/landing-page/?utm_source={platform}&utm_campaign={creator_handle}"
                            : "https://www.1stcollab.com.com/landing-page"
                        }
                        withAsterisk
                        key={form.key("referralLinkTemplate")}
                        {...form.getInputProps("referralLinkTemplate")}
                        disabled={isCampaignAlreadyLive}
                      />
                    ) : null}
                  </>
                ) : null}
                {currentReferralManagementTypes.includes(PROMO_CODE_MANAGEMENT_TYPE_INFO.value) ? (
                  <>
                    <NativeSelectWithTooltip
                      data={PROMO_CODE_TYPES}
                      title="Promo Code Type"
                      keyProp={form.key("promoCodeType")}
                      inputProps={form.getInputProps("promoCodeType")}
                      disabled={isCampaignAlreadyLive}
                    />
                    {currentPromoCodeType === PROMO_CODE_TYPE_FIXED.value ? (
                      <TextInput
                        label="Fixed Promo Code"
                        description="Enter the promo code that you would like us to send to every creator joining your campaign."
                        placeholder="INFLUENCER10"
                        key={form.key("fixedPromoCode")}
                        {...form.getInputProps("fixedPromoCode")}
                        disabled={isCampaignAlreadyLive}
                      />
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
            <Divider />
            <Switch
              label="Do you need to provide creators with access to your product (e.g., ship free samples; free software upgrade; etc.)? Creators are provided product access only after signing their contracts."
              size="xs"
              labelPosition="left"
              key={form.key("needProductAccess")}
              {...form.getInputProps("needProductAccess", { type: "checkbox" })}
              disabled={isCampaignAlreadyLive}
            />
            {showProductAccess ? (
              <>
                <TextInput
                  label="Provide a brief description of what you want to provide to creators."
                  description="This is displayed to creators so they know what to expect."
                  placeholder="Example: 1 bottle of our award-winning olive oil"
                  withAsterisk
                  key={form.key("productAccessDescription")}
                  {...form.getInputProps("productAccessDescription")}
                  disabled={isCampaignAlreadyLive}
                />
                <NativeSelect
                  label="Is there any information you need from each creator to provide them with product access?"
                  withAsterisk
                  data={PRODUCT_ACCESS_INFO_FROM_CREATOR}
                  key={form.key("productAccessInfoFromCreator")}
                  {...form.getInputProps("productAccessInfoFromCreator")}
                  disabled={isCampaignAlreadyLive}
                />
                {currentProductAccessInfoFromCreator ===
                PRODUCT_ACCESS_INFO_FROM_CREATOR_OTHER.value ? (
                  <TextInput
                    label="What additional information do you need from the creator?"
                    withAsterisk
                    key={form.key("productAccessInfoFromCreatorOther")}
                    {...form.getInputProps("productAccessInfoFromCreatorOther")}
                    disabled={isCampaignAlreadyLive}
                  />
                ) : null}
                <NativeSelect
                  label="After providing product access, is there any information you need to send back to each creator?"
                  withAsterisk
                  data={PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR}
                  key={form.key("productAccessProvidedForCreator")}
                  {...form.getInputProps("productAccessProvidedForCreator")}
                  disabled={isCampaignAlreadyLive}
                />
                {currentProductAccessProvidedForCreator ===
                PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_OTHER.value ? (
                  <TextInput
                    label="What additional information do you need to provide creators?"
                    withAsterisk
                    key={form.key("productAccessProvidedForCreatorOther")}
                    {...form.getInputProps("productAccessProvidedForCreatorOther")}
                    disabled={isCampaignAlreadyLive}
                  />
                ) : null}
              </>
            ) : null}
            <Button
              type="submit"
              variant="filled"
              disabled={
                !campaign || campaign?.status === CampaignStatus.Active || !hasFormBeenEdited
              }>
              Save
            </Button>
          </Stack>
        </form>
      }
    />
  );
};

export default ExecutionCampaignCard;
