import {
  Accordion,
  ActionIcon,
  Badge,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  MantineColor,
  Menu,
  Modal,
  rem,
  Space,
  Stack,
  Switch,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconArrowBack,
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutubeFilled,
  IconCheck,
  IconMenu2,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { SUPPORTED_FORMATS_TO_LABELS } from "components/contracts/common/Common";
import {
  approveContractOfferInternal,
  ContractErrorResponse,
  revertBrandContractReview,
  revertContractIssued,
} from "components/contracts/negotiations/api/ContractOfferApi";
import ApprovedOfferModule from "components/creator/ApprovedOfferModule";
import { ActivePlatformInfo } from "components/creator/CreatorUtils";
import NeedsApprovalModule from "components/creator/NeedsApprovalModule";
import SuggestedPrice, { FormulaAccordionItem } from "components/creator/SuggestedPrice";
import { PriceMap } from "creators/api/creatorApiUtils";
import { Campaign, MaxOfferAction, UsageRightsPolicytoFormatString } from "models/Campaign";
import {
  ContractOfferStatus,
  ContractStatusColors,
  SupportedFormat,
  UsageRightsColors,
  UsageRightsDuration,
  UsageRightsDurationToDays,
} from "models/Common";
import { DeliverablePackage } from "models/DeliverablePackage";
import { Opportunity, OpportunityOutreachReason } from "models/Opportunity";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";
import { getUTCDate } from "utils/DateUtils";
import { timeAgo } from "utils/TimeUtils";

const NEEDS_APPROVAL_STATES = [
  ContractOfferStatus.NEEDS_INTERNAL_REVIEW,
  ContractOfferStatus.OVERRIDE_REQUESTED,
];

const APPROVED_STATES = [
  ContractOfferStatus.BRAND_APPROVED_OFFER,
  ContractOfferStatus.INTERNAL_APPROVED_BRAND_OFFER,
  ContractOfferStatus.INTERNAL_APPROVED_OFFER,
  ContractOfferStatus.BRAND_REJECTED_OFFER,
];

const ContractStatusBadge = ({ deliverable }: { deliverable: DeliverablePackage }) => {
  return (
    <Badge
      variant="light"
      color={ContractStatusColors[deliverable?.status]}
      component={deliverable?.status === ContractOfferStatus.CONTRACT_ISSUED ? "button" : "div"}
      onClick={() => {
        if (deliverable?.status === ContractOfferStatus.CONTRACT_ISSUED) {
          window.open(`/contracts/${deliverable?.contract_hash_id}`, "_blank");
        }
      }}>
      {ContractOfferStatus[deliverable?.status]}
    </Badge>
  );
};

const handleRevert = (selectedDeliverable: DeliverablePackage, close: () => void) => {
  if (selectedDeliverable.status === ContractOfferStatus.CONTRACT_ISSUED) {
    revertContractIssued(selectedDeliverable.id).then((resp) => {
      if (resp?.id !== undefined) {
        notifications.show({
          color: "green",
          title: "Successfully reverted the state!",
          message: "The state has been reverted to the previous state.",
          autoClose: false,
        });
        // TODO(andrew): set deliverable package based on returned data
        close();
      } else {
        notifications.show({
          color: "red",
          title: "Error with reverting the state!",
          message: resp?.message || "Unknown error.",
          autoClose: false,
        });
      }
    });
  } else if (selectedDeliverable.status === ContractOfferStatus.NEEDS_BRAND_REVIEW) {
    revertBrandContractReview(selectedDeliverable.id).then((resp) => {
      if (resp?.id !== undefined) {
        notifications.show({
          color: "green",
          title: "Successfully reverted the state!",
          message: "The state has been reverted to the previous state.",
          autoClose: false,
        });
        // TODO(andrew): set deliverable package based on returned data
        close();
      } else {
        notifications.show({
          color: "red",
          title: "Error with reverting the state!",
          message: resp?.message || "Unknown error.",
          autoClose: false,
        });
      }
    });
  } else {
    notifications.show({
      color: "red",
      title: "Error with reverting the state!",
      message: "Cannot revert from current state.",
      autoClose: false,
    });
  }
};

const RevertDeliverableStateModal = ({
  selectedDeliverable,
  previousStatus,
  opened,
  close,
}: {
  selectedDeliverable: DeliverablePackage;
  previousStatus: ContractOfferStatus;
  opened: boolean;
  close: () => void;
}) => {
  const [confirmationText, setConfirmationText] = useState<string>("");

  useEffect(() => {
    if (selectedDeliverable.status === ContractOfferStatus.CONTRACT_ISSUED) {
      if (previousStatus === ContractOfferStatus.BRAND_APPROVED_OFFER) {
        setConfirmationText(
          "Do you want to revert the state to BRAND_APPROVED_OFFER? This will fail if there is already a created contract. You will need to delete the contract first.",
        );
      } else if (previousStatus === ContractOfferStatus.INTERNAL_APPROVED_OFFER) {
        setConfirmationText(
          "Do you want to revert the state to INTERNAL_APPROVED_OFFER? This will fail if there is already a created contract. You will need to delete the contract first.",
        );
      }
    } else if (selectedDeliverable.status === ContractOfferStatus.NEEDS_BRAND_REVIEW) {
      if (previousStatus === ContractOfferStatus.NEEDS_INTERNAL_REVIEW) {
        setConfirmationText(
          "Do you want to revert the state to NEEDS_INTERNAL_REVIEW? This will AUTOMATICALLY delete the recently created Brand Contract Review.",
        );
      }
    }
  }, [previousStatus]);

  return (
    <Modal
      size="auto"
      centered
      opened={opened}
      onClose={close}
      title={
        <Text fw="600">
          Revert Package State to {ContractOfferStatus[selectedDeliverable.status]}?
        </Text>
      }>
      <Stack gap={24}>
        <Text>{confirmationText}</Text>
        <Flex justify="center" gap="md">
          <Button onClick={close} color="gray" variant="light">
            Cancel
          </Button>
          <Button onClick={() => handleRevert(selectedDeliverable, close)} color="red">
            Revert
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
};

const DeliverableCardOptions = ({
  selectedDeliverable,
  deletePackageHandler,
}: {
  selectedDeliverable: DeliverablePackage;
  deletePackageHandler: () => void;
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  const [previousStatus, setPreviousStatus] = useState<ContractOfferStatus>(null);

  useEffect(() => {
    if (selectedDeliverable?.status) {
      let revertStatus: ContractOfferStatus = null;
      if (selectedDeliverable.status === ContractOfferStatus.CONTRACT_ISSUED) {
        // If a contract has been issued, the candidate previous states are:
        // 1) BRAND_APPROVED_OFFER
        // 2) INTERNAL_APPROVED_OFFER
        if (
          selectedDeliverable?.brand_approved_price > 0 &&
          selectedDeliverable?.brand_approved_price < selectedDeliverable.price
        ) {
          revertStatus = ContractOfferStatus.BRAND_APPROVED_OFFER;
        } else {
          revertStatus = ContractOfferStatus.INTERNAL_APPROVED_OFFER;
        }
      } else if (selectedDeliverable.status === ContractOfferStatus.NEEDS_BRAND_REVIEW) {
        revertStatus = ContractOfferStatus.NEEDS_INTERNAL_REVIEW;
      }
      setPreviousStatus(revertStatus);
    }
  }, [selectedDeliverable]);

  return (
    <Stack>
      <RevertDeliverableStateModal
        selectedDeliverable={selectedDeliverable}
        previousStatus={previousStatus}
        opened={opened}
        close={close}
      />
      <Menu width={160} withinPortal>
        <Menu.Target>
          <ActionIcon variant="subtle" color="gray" component="button" size="sm">
            <IconMenu2 style={{ width: rem(20), height: rem(20) }} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={
              <IconArrowBack
                style={{ width: rem(16), height: rem(16) }}
                color="orange"
                stroke={1.5}
              />
            }
            disabled={previousStatus === null}
            onClick={open}>
            Revert State
          </Menu.Item>
          <Menu.Item
            leftSection={
              <IconTrash style={{ width: rem(16), height: rem(16) }} color="red" stroke={1.5} />
            }
            onClick={deletePackageHandler}>
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Stack>
  );
};

const DeliverableOfferCards = ({
  selectedDeliverableId,
  selectedDeliverable,
  selectedOpportunity,
  isOps,
  campaign,
  contractOfferId,
  activePlatforms,
  contractStatus,
  setContractStatus,
  platformPricing,
  totalViewsRange,
  generateContractData,
  hasDiscount,
  discountFactor,
  fetchedPackages,
  setFetchedPackages,
  deletePackageHandler, // TODO(andrew): refactor delete handler
}: {
  selectedDeliverableId: number;
  selectedDeliverable: DeliverablePackage;
  isOps?: boolean;
  campaign: Campaign;
  contractOfferId?: number;
  activePlatforms?: ActivePlatformInfo;
  contractStatus?: ContractOfferStatus;
  setContractStatus?: (newStatus: ContractOfferStatus) => void;
  selectedOpportunity?: Opportunity;
  platformPricing?: PriceMap;
  totalViewsRange?: Record<string, number[]>;
  generateContractData?: Record<string, string>;
  hasDiscount?: boolean;
  discountFactor?: number;
  fetchedPackages?: [number, ContractOfferStatus, DeliverablePackage[]][];
  setFetchedPackages?: React.Dispatch<
    React.SetStateAction<[number, ContractOfferStatus, DeliverablePackage[]][]>
  >;
  deletePackageHandler?: () => void;
}) => {
  const [totalViews, setTotalViews] = useState<Record<string, number[]>>(totalViewsRange);
  const [cpm, setCpm] = useState<number>(100);
  const [suggestedPricing, setSuggestedPricing] = useState<number>(
    selectedDeliverable.suggested_price,
  );
  const [internalApprovedPrice, setInternalApprovedPrice] = useState<number>(
    selectedDeliverable.price,
  );
  const [creatorPrice, setCreatorPrice] = useState<number>(
    selectedDeliverable.creator_asking_price,
  );
  const [creatorAskingUsageRightsPrice, setCreatorAskingUsageRightsPrice] = useState<number>(
    selectedDeliverable.creator_asking_usage_rights_price || 0,
  );
  const [upfrontUsageRights, setUpfrontUsageRights] = useState<boolean>(
    campaign?.usage_rights_policy === 1 || false,
  );
  const [usageRightsDuration, setUsageRightsDuration] = useState<UsageRightsDuration>(
    selectedDeliverable.usage_rights,
  );
  const [usageRightsPrice, setUsageRightsPrice] = useState<number>(
    selectedDeliverable.usage_rights_price,
  );

  // campaign loading state
  const [campaignLoading, setCampaignLoading] = useState<boolean>(true);

  // price states
  const [approvedPrice, setApprovedPrice] = useState<number>(
    (internalApprovedPrice
      ? Math.min(internalApprovedPrice, creatorPrice)
      : internalApprovedPrice) || Math.min(creatorPrice, suggestedPricing),
  );
  const [creatorAskingPrice, setCreatorAskingPrice] = useState<number>(creatorPrice);
  const [creatorUsagePrice, setCreatorUsagePrice] = useState<number>(
    creatorAskingUsageRightsPrice || 0,
  );
  const [maxOfferCap, setMaxOfferCap] = useState<number>(0);
  const [outreachReason, setOutreachReason] = useState<OpportunityOutreachReason>(
    OpportunityOutreachReason.DEFAULT,
  );
  const [brandMaxPrice, setBrandMaxPrice] = useState<number>(0);
  const [brandOverridePrice, setBrandOverridePrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [maxOfferAction, setMaxOfferAction] = useState<MaxOfferAction>(null);

  // usage rights state
  const [usagePrice, setUsagePrice] = useState<number>(usageRightsPrice || 0);

  // contract status state
  // using this to optimistically render the contract status after action
  const [internalStatus, setInternalStatus] = useState<ContractOfferStatus>(contractStatus);
  const [offerredPrice, setOfferedPrice] = useState<number>(internalApprovedPrice);
  const [offeredUsagePrice, setOfferedUsagePrice] = useState<number>(usageRightsPrice);
  const [hasRecommended, setHasRecommended] = useState<boolean>(false);
  const [currSuggestedPrice, setCurrSuggestedPrice] = useState<number>(0);
  const [currSuggestedPriceAccordion, setCurrSuggestedPriceAccordion] = useState<JSX.Element[]>([]);

  // button states
  const [buttonText, setButtonText] = useState<string>("Approve Offer");
  const [buttonColor, setButtonColor] = useState<MantineColor>("blue");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [detailsChecked, setDetailsChecked] = useState<boolean>(false);
  const [needsReview, setNeedsReview] = useState<boolean>(false);

  // error message states
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);

  // filter selected package to get selected formats
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);

  useEffect(() => {
    setInternalStatus(contractStatus);
  }, [contractStatus]);

  useEffect(() => {
    let suggestedPrice = 0;
    const suggestedPriceAccordion = Object.entries(platformPricing)
      .filter(
        ([platform, val]) => selectedPlatforms.includes(platform) && val?.upper_price !== null,
      )
      .sort(([a, _], [b, __]) => a.localeCompare(b))
      .map(([platform, info]) => {
        const currCPM = selectedOpportunity?.ad_group.target_cpm;
        suggestedPrice += info ? info.upper_price : 0;

        const baseStr = `(${getAbbreviatedNumber(info?.upper_views)} views * ${
          info?.views_adjustment
        } views adjustment)
* (${info?.format_weight} format weight * $${currCPM} CPM)`;
        const formulaStr = hasDiscount
          ? `${baseStr} * ${discountFactor} country discount`
          : baseStr;

        let platformIcon = null;
        let variantColor = "";
        switch (platform) {
          case SupportedFormat.TIKTOK_DEDICATED_VIDEO:
            variantColor = "var(--mantine-color-cyan-4";
            platformIcon = <IconBrandTiktok color={variantColor} size={15} />;
            break;
          case platform.match(/^youtube/)?.input:
            variantColor = "var(--mantine-color-red-6";
            platformIcon = <IconBrandYoutubeFilled color={variantColor} size={15} />;
            break;
          case SupportedFormat.YOUTUBE_SHORT:
            variantColor = "var(--mantine-color-red-6";
            platformIcon = <IconBrandYoutubeFilled color={variantColor} size={15} />;
            break;
          case SupportedFormat.INSTAGRAM_DEDICATED_REEL:
          case SupportedFormat.INSTAGRAM_DEDICATED_POST:
          case SupportedFormat.INSTAGRAM_CAROUSEL:
            variantColor = "var(--mantine-color-grape-6";
            platformIcon = <IconBrandInstagram color={variantColor} size={15} />;
            break;
          default:
            platformIcon = null;
            variantColor = "light";
        }
        return (
          <FormulaAccordionItem
            key={platform}
            platform={platform}
            platformIcon={platformIcon}
            formatPrice={info ? Math.round(info.upper_price / 100) : 0}
            formulaStr={formulaStr}
          />
        );
      });
    setCurrSuggestedPrice(suggestedPrice);
    setCurrSuggestedPriceAccordion(suggestedPriceAccordion);
  }, [platformPricing, selectedPlatforms]);

  // check if we have history of activity
  // split by timestamp
  const re = /\n?\d{4}-\d{2}-\d{2}\s{1}.{8}\s/;
  const matchRe = /\d{4}-\d{2}-\d{2}\s{1}.{8}\s/g;
  const history = selectedDeliverable.history ? selectedDeliverable.history.split(re) : null;
  const dates = selectedDeliverable.history ? selectedDeliverable.history.match(matchRe) : null;
  const activityFeed =
    history &&
    history
      .filter((item) => item !== "")
      .map((item, index) => {
        const [person, ...rest] = item.split(" ");
        const firstName = person.substring(person.indexOf("(") + 1, person.lastIndexOf("@"));
        const fullDateString = new Date(dates[index]);
        const utcStr = moment(fullDateString).format("YYYY-MM-DD HH:mm:ss");
        const restString = rest.join(" ").split("- ")[1];
        return (
          <Stack gap={1} key={`${dates[index]}-${restString.substring(0, 10)}`}>
            <Group gap={3} wrap="wrap">
              <Text size="sm">{restString}</Text>
              <Text size="sm" fw={500}>{`by ${
                firstName.charAt(0).toUpperCase() + firstName.slice(1)
              }`}</Text>
            </Group>
            <Tooltip position="left" label={`${getUTCDate(utcStr)}`}>
              <Text size="xs" c="gray">
                {timeAgo(utcStr)}
              </Text>
            </Tooltip>
          </Stack>
        );
      });

  useEffect(() => {
    if (campaign !== undefined) {
      setCampaignLoading(false);
      setUpfrontUsageRights(campaign.usage_rights_policy === 1);
    }
  }, [campaign]);

  // find max price from contract review for current opportunity
  useEffect(() => {
    if (!selectedOpportunity) return;
    setCpm(selectedOpportunity?.ad_group.target_cpm);
    setMaxOfferCap(selectedOpportunity?.max_offer_cap);
    setOutreachReason(selectedOpportunity?.outreach_reason);

    // take latest max offer price
    setMaxOfferAction(selectedOpportunity?.max_offer_action);
  }, [selectedOpportunity]);

  useEffect(() => {
    const updatedSelectedPlatforms = Object.keys(selectedDeliverable).filter(
      (format) =>
        Object.keys(SUPPORTED_FORMATS_TO_LABELS).includes(format) &&
        selectedDeliverable[format as keyof DeliverablePackage] === 1,
    );
    setSelectedPlatforms(updatedSelectedPlatforms);

    setSuggestedPricing(selectedDeliverable.suggested_price);
    setCreatorPrice(selectedDeliverable.creator_asking_price);
    setInternalApprovedPrice(selectedDeliverable.price);
    setCreatorAskingUsageRightsPrice(selectedDeliverable.creator_asking_usage_rights_price || 0);
    setUsageRightsDuration(selectedDeliverable.usage_rights);
    setUsageRightsPrice(selectedDeliverable.usage_rights_price);
    setBrandMaxPrice(selectedDeliverable.brand_approved_price || 0);
    setBrandOverridePrice(selectedDeliverable.brand_override_price || 0);
  }, [selectedDeliverable]);

  useEffect(() => {
    if (
      outreachReason !== OpportunityOutreachReason.REPEAT_OPPORTUNITY &&
      (brandMaxPrice || maxOfferCap || brandOverridePrice)
    ) {
      setMaxPrice(
        Math.min(
          Math.max(brandMaxPrice, maxOfferCap, brandOverridePrice),
          creatorAskingPrice + creatorUsagePrice,
        ),
      );
    } else {
      // There is no cap or explicit brand price, so we are limited only by what the creator has
      // asked for.
      // When the outreach reason is repeat opportunity, we don't have a cap, so we use the
      // creator's "asking price", which is the previous opportunity's price.
      setMaxPrice(creatorAskingPrice + creatorUsagePrice);
    }
  }, [
    maxOfferCap,
    brandMaxPrice,
    brandOverridePrice,
    creatorAskingPrice,
    creatorUsagePrice,
    outreachReason,
  ]);

  useEffect(() => {
    if (approvedPrice < maxPrice) {
      setNeedsReview(false);
      setShowError(false);
      setErrorMessage("");
      setButtonColor("blue");
      setButtonText("Approve Offer");
    }
  }, [approvedPrice, maxOfferCap, brandMaxPrice, maxPrice, outreachReason]);

  useEffect(() => {
    // check to see if any fetched packages are recced
    if (!fetchedPackages) return;
    const reccedPackages = fetchedPackages?.filter(([_, __, packages]) =>
      packages.some((dp) => dp.is_recommended),
    );
    if (reccedPackages.length > 0) {
      setHasRecommended(true);
    } else {
      setHasRecommended(false);
    }
  }, [fetchedPackages]);

  // approved price handler
  const approvedPriceHandler = (value: string | number) => {
    setApprovedPrice(value as number);
  };

  // button handler for admin approval
  const adminHandler = () => {
    const packageId = selectedDeliverable.id;
    if (needsReview) {
      setButtonLoading(true);
      approveContractOfferInternal(contractOfferId, approvedPrice, usagePrice, packageId)
        .then((res) => {
          if (res.success !== "true") throw new Error("Failed to create a contract review");
          setButtonText("Contract Review Created!");
          setButtonLoading(false);
          notifications.show({
            title: "Contract Review Created!",
            message: "A contract review has been sent to the brand for approval.",
            color: "green",
            icon: <IconCheck />,
            autoClose: 2000,
          });
          setInternalStatus(ContractOfferStatus.NEEDS_BRAND_REVIEW);
          if (setContractStatus) {
            setContractStatus(ContractOfferStatus.NEEDS_BRAND_REVIEW);
          }
          setOfferedPrice(approvedPrice);
          setOfferedUsagePrice(usagePrice);
        })
        .catch((err) => {
          setButtonLoading(false);
          setShowError(true);
          setErrorMessage("Failed to create a contract review");
          notifications.show({
            title: "Something went wrong!",
            message: "Failed to create a contract review",
            color: "red",
            icon: <IconX />,
          });
        });
    } else if (
      maxPrice &&
      maxPrice > 0 &&
      approvedPrice + usagePrice > maxPrice &&
      maxOfferAction === MaxOfferAction.Review
    ) {
      setNeedsReview(true);
      setButtonColor("yellow");
      setShowError(true);
      setErrorMessage(
        `Cannot auto-approve contract price of $${
          approvedPrice + usagePrice
        } since it is above the max offer cap of $${maxPrice}. Click to create a Contract Review.`,
      );
      setButtonText("Generate Contract Review");
    } else if (
      maxPrice &&
      maxPrice > 0 &&
      approvedPrice + usagePrice > maxPrice &&
      maxOfferAction === MaxOfferAction.Reject
    ) {
      setShowError(true);
      setErrorMessage(
        `Cannot approve price.
        For this campaign, the max offer cap is $${maxPrice} and is set to Reject for any price above $${maxOfferCap}.
        Choose an amount less than $${maxPrice}.`,
      );
    } else {
      setNeedsReview(false);
      setShowError(false);
      setErrorMessage("");
      approveContractOfferInternal(contractOfferId, approvedPrice, usagePrice, packageId)
        .then((res) => {
          if (res.success !== "true") {
            const errRes = res as unknown as ContractErrorResponse;
            throw new Error(errRes.result.message);
          }
          setButtonText("Approved!");
          notifications.show({
            title: "Approved!",
            message: "Deliverable package approved successfully.",
            color: "green",
            icon: <IconCheck />,
            autoClose: 2000,
          });
          setInternalStatus(ContractOfferStatus.INTERNAL_APPROVED_OFFER);
          if (setContractStatus) {
            setContractStatus(ContractOfferStatus.INTERNAL_APPROVED_OFFER);
          }
          setOfferedPrice(approvedPrice);
          setOfferedUsagePrice(usagePrice);
          setButtonText("Approve Offer");
        })
        .catch((err) => {
          setShowError(true);
          setButtonText("Approve Offer");
          setErrorMessage(`${err}. Failed to approve deliverable package`);
          notifications.show({
            title: "Something went wrong!",
            message: "Failed to create an approved package.",
            color: "red",
            icon: <IconX />,
            autoClose: false,
          });
        });
    }
  };

  return (
    <Card
      key={selectedDeliverableId}
      withBorder
      styles={{
        root: {
          minWidth: "300px",
          maxWidth: "450px",
        },
      }}>
      <Stack gap={1} p="sm">
        <Group justify="space-between" align="flex-start" wrap="nowrap">
          <Stack gap={1}>
            <Text size="sm" fw={500} c="gray.8">
              {internalStatus !== ContractOfferStatus.DRAFT && selectedOpportunity
                ? selectedOpportunity.ad_group.campaign_name
                : selectedDeliverable.opportunity.ad_group.campaign_name}
            </Text>
            <Text size="xs" c="dimmed">
              Created:{" "}
              {selectedDeliverable.date_created
                ? getUTCDate(selectedDeliverable.date_created)
                : getUTCDate(new Date(Date.now()).toISOString())}
            </Text>
            {detailsChecked && (
              <Text size="xs" c="dimmed">
                Opportunity ID:{" "}
                {contractStatus ? selectedOpportunity.id : selectedDeliverable.opportunity.id}
              </Text>
            )}
            {selectedDeliverable.is_recommended ? (
              <Group gap="xs">
                <IconCheck size={15} color="var(--mantine-color-green-6)" />
                <Text size="xs" c="var(--mantine-color-green-6)">
                  Recommended
                </Text>
              </Group>
            ) : (
              <Space h="sm" />
            )}
            <Space h={2} />
            <Switch
              checked={detailsChecked}
              defaultChecked={false}
              label="Show Details"
              size="xs"
              labelPosition="left"
              onChange={(event) => setDetailsChecked(event.currentTarget.checked)}
            />
          </Stack>
          <Stack gap="xs" align="flex-end">
            <DeliverableCardOptions
              selectedDeliverable={selectedDeliverable}
              deletePackageHandler={deletePackageHandler}
            />
            <ContractStatusBadge deliverable={selectedDeliverable} />
          </Stack>
        </Group>
        <Space h="lg" />
        {detailsChecked && (
          <>
            <Group gap="lg" justify="space-between">
              <Text size="sm" fw={600}>
                Contract Offer ID
              </Text>
              <Text size="sm">{internalStatus ? contractOfferId : "Not Yet Set"}</Text>
            </Group>
            <Divider my="sm" />
          </>
        )}
        {detailsChecked && (
          <>
            <Group gap="lg" justify="space-between">
              <Text size="sm" fw={600}>
                Deliverable Package ID
              </Text>
              <Text size="sm">{selectedDeliverable.id}</Text>
            </Group>
            <Divider my="sm" />
          </>
        )}
        <Group gap="lg" justify="space-between">
          <Text size="sm" fw={600}>
            Creator Price
          </Text>
          <Text size="sm">${creatorAskingPrice || selectedDeliverable?.creator_asking_price}</Text>
        </Group>
        <Divider my="sm" />
        {creatorUsagePrice > 0 && (
          <>
            <Group gap="lg" justify="space-between">
              <Text size="sm" fw={600}>
                Creator Asking Usage Price
              </Text>
              <Text size="sm">${creatorUsagePrice}</Text>
            </Group>
            <Divider my="sm" />
          </>
        )}
        {selectedDeliverable.brand_override_price &&
          selectedDeliverable.status === ContractOfferStatus.OVERRIDE_REQUESTED && (
            <>
              <Group gap="lg" justify="space-between">
                <Text size="sm" fw={600} c="red">
                  Brand Override Price
                </Text>
                <Tooltip
                  position="left"
                  label={
                    usageRightsPrice > 0 ? "Includes usage rights" : "No usage rights included"
                  }>
                  <Text size="sm">${selectedDeliverable?.brand_override_price}</Text>
                </Tooltip>
              </Group>
              <Divider mt="sm" mb="xs" />
            </>
          )}
        {selectedDeliverable.brand_approved_price && (
          <>
            <Group gap="lg" justify="space-between">
              <Text size="sm" fw={600}>
                Brand Approved Price
              </Text>
              <Tooltip
                position="left"
                label={usageRightsPrice > 0 ? "Includes usage rights" : "No usage rights included"}>
                <Text size="sm">${selectedDeliverable?.brand_approved_price}</Text>
              </Tooltip>
            </Group>
            <Divider mt="sm" mb="xs" />
          </>
        )}
        {(offerredPrice || selectedDeliverable.price) && (
          <>
            <Group gap="lg" justify="space-between">
              <Text size="sm" fw={600}>
                Offer Price
              </Text>
              <Text size="sm">${offerredPrice || selectedDeliverable?.price}</Text>
            </Group>
            <Divider my="sm" />
          </>
        )}
        {!campaignLoading && offeredUsagePrice > 0 && (
          <>
            <Group gap="lg" justify="space-between">
              <Text size="sm" fw={600}>
                Usage Rights Price
              </Text>
              <Text span size="sm">
                {offeredUsagePrice ? `$${offeredUsagePrice}` : "N/A"}
              </Text>
            </Group>
            <Divider my="sm" />
            <Group gap="lg" justify="space-between">
              <Text size="sm" fw={600}>
                Usage Rights Policy
              </Text>
              <Text span size="sm">
                <Group gap="xs">
                  <Badge color={UsageRightsColors[campaign?.usage_rights_policy]} variant="light">
                    {UsageRightsPolicytoFormatString[campaign?.usage_rights_policy] || "Unknown"}
                  </Badge>
                  <Badge color="gray" variant="light">
                    {UsageRightsDurationToDays[usageRightsDuration] ||
                      UsageRightsDurationToDays[campaign?.usage_rights_duration] ||
                      "Unknown"}{" "}
                    days
                  </Badge>
                </Group>
              </Text>
            </Group>
            <Divider mt="sm" mb="xs" />
          </>
        )}
        {currSuggestedPriceAccordion && (
          <>
            <Accordion
              styles={{
                control: {
                  borderRadius: 0,
                  margin: 0,
                  padding: 0,
                },
              }}>
              <Accordion.Item value="realtime">
                <Accordion.Control>
                  <Group gap="lg" justify="space-between">
                    <Text size="sm" fw={600}>
                      Realtime Suggested Price
                    </Text>
                    <Text size="sm">${currSuggestedPrice / 100}</Text>
                  </Group>
                </Accordion.Control>
                <Accordion.Panel>
                  <Accordion
                    variant="filled"
                    styles={{
                      control: {
                        borderRadius: 0,
                      },
                    }}>
                    {currSuggestedPriceAccordion.map((item) => item)}
                  </Accordion>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            <Space h="sm" />
          </>
        )}
        {suggestedPricing ? (
          <SuggestedPrice
            cpm={cpm}
            upperPrice={suggestedPricing}
            platformList={selectedPlatforms}
            totalViewsRange={totalViews}
            platformPricing={platformPricing}
            hasDiscount={hasDiscount}
            discountFactor={discountFactor}
            showUsageRights={detailsChecked}
            hidden={false}
            isRepeatOpportunity={outreachReason === OpportunityOutreachReason.REPEAT_OPPORTUNITY}
          />
        ) : (
          <SuggestedPrice
            cpm={selectedOpportunity?.ad_group.target_cpm}
            upperPrice={selectedDeliverable.suggested_price}
            selectedDeliverable={selectedDeliverable}
            platformList={selectedPlatforms}
            showUsageRights={detailsChecked}
            hidden={false}
            isRepeatOpportunity={outreachReason === OpportunityOutreachReason.REPEAT_OPPORTUNITY}
          />
        )}
        <Space h="lg" />
        {NEEDS_APPROVAL_STATES.includes(internalStatus) && (
          <NeedsApprovalModule
            selectedDeliverable={selectedDeliverable}
            hasRecommended={hasRecommended}
            approvedPrice={approvedPrice}
            creatorAskingPrice={creatorAskingPrice}
            usagePrice={usagePrice}
            showError={showError}
            errorMessage={errorMessage}
            buttonText={buttonText}
            buttonColor={buttonColor}
            buttonLoading={buttonLoading}
            onChangeHandler={approvedPriceHandler}
            setUsagePrice={setUsagePrice}
            isOps={isOps}
            adminHandler={adminHandler}
          />
        )}
        {APPROVED_STATES.includes(internalStatus) && (
          <ApprovedOfferModule
            contractOfferId={contractOfferId}
            offerPrice={offerredPrice}
            usagePrice={usagePrice}
            generateContractData={generateContractData}
            selectedDeliverable={selectedDeliverable}
            activePlatforms={activePlatforms}
            setStatus={(status: ContractOfferStatus) => {
              if (setContractStatus) {
                setContractStatus(status);
              }
              setInternalStatus(status);
            }}
            setCreatorAskingPrice={setCreatorAskingPrice}
            setCreatorUsagePrice={setCreatorUsagePrice}
            setUsagePrice={setUsagePrice}
            setOfferedPrice={setOfferedPrice}
            setFetchedPackages={setFetchedPackages}
            fetchedPackages={fetchedPackages}
            maxPrice={maxPrice}
          />
        )}
        {internalStatus === ContractOfferStatus.NEEDS_BRAND_REVIEW && (
          <>
            <Stack gap={1}>
              <Text size="sm" c="red">
                Waiting for brand to review contract offer.
              </Text>
              {/* somehow we don't need the campaign hash id to link to the contract review page */}
              <Link
                to={`/campaigns/${
                  selectedOpportunity.hash_id ? selectedOpportunity.hash_id : "foo"
                }/${selectedOpportunity.ad_group.id}/contract_approval`}
                target="_blank">
                <Text size="sm">View pending contract reviews for campaign.</Text>
              </Link>
            </Stack>
            <Divider my="sm" />
          </>
        )}
        <Accordion variant="filled">
          <Accordion.Item value="Activity">
            <Accordion.Control>
              <Text size="sm" fw={600}>
                Activity Log
              </Text>
            </Accordion.Control>
            <Accordion.Panel>{selectedDeliverable.history && activityFeed}</Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Stack>
    </Card>
  );
};

export default DeliverableOfferCards;
