import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { User as FirebaseUser } from "firebase/auth";

// Assume that notifications has already been rendered in the app.
import { notifications } from "@mantine/notifications";

import { Button, Flex, Stack, Table } from "@mantine/core";

import { notifyUpdate } from "campaigns/main/campaignPage/ChangeConfirmation";

export function wrapUpdateFunc(
  endpoint: string,
  objType: string,
  form: any,
  updateFunc: (
    firebaseUser: FirebaseUser,
    campaignHashId: number,
    endpoint: string,
    objId: number,
    updates: { [key: string]: any },
    updateFields: string[],
  ) => Promise<any>,
  setButtonLoading: Dispatch<SetStateAction<boolean>>,
  close: () => void,
): (
  firebaseUser: FirebaseUser,
  campaignHashId: number,
  objId: number,
  updates: { [key: string]: any },
  updateFields: string[],
) => Promise<any> {
  const wrappedfn = async (
    firebaseUser: FirebaseUser,
    campaignHashId: number,
    objId: number,
    updates: { [key: string]: any },
    updateFields: string[],
  ): Promise<any> => {
    try {
      const response = await updateFunc(
        firebaseUser,
        campaignHashId,
        endpoint,
        objId,
        updates,
        updateFields,
      );
      // TODO(andrew): we should generalize the updateFunc to return a response with debug info.
      notifyUpdate(response, objType);
      form.resetDirty();
      return response;
    } catch (e) {
      // assume that <Notifications /> has been rendered in the app.
      notifications.show({
        color: "red",
        title: `Error with updating the ${objType}!`,
        message: e.message,
        autoClose: false,
      });
    } finally {
      setButtonLoading(false);
      close();
    }
    return null;
  };
  return wrappedfn;
}

export const CampaignObjChangeConfirmation = ({
  firebaseUser,
  campaignHashId,
  endpoint,
  objType,
  objId,
  form,
  formValues,
  customFormatValue,
  initialValues,
  onSubmit,
  close,
}: {
  firebaseUser: FirebaseUser;
  campaignHashId: number;
  endpoint: string;
  objType: string;
  objId: number;
  form: any;
  formValues: { [key: string]: any };
  customFormatValue: (key: string, value: any) => any;
  initialValues: { [key: string]: any };
  onSubmit: (
    firebaseUser: FirebaseUser,
    campaignHashId: number,
    endpoint: string,
    objId: number,
    updates: { [key: string]: any },
    updateFields: string[],
  ) => Promise<any>;
  close: () => void;
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const valuesEqual = (key: string, initialValue: any, value: any) => {
    if (Array.isArray(initialValue) && Array.isArray(value)) {
      // lol
      return initialValue.sort().toString() === value.sort().toString();
    }
    return initialValue === value;
  };

  const [diffs, setDiffs] = useState<JSX.Element[]>([]);
  const [updates, setUpdates] = useState<{ [key: string]: any }>({ id: objId });

  useEffect(() => {
    const newDiffs: JSX.Element[] = [];
    const newUpdates: { [key: string]: any } = { id: objId };
    Object.entries(formValues).forEach(([key, value]) => {
      if (!valuesEqual(key, initialValues[key], value)) {
        newDiffs.push(
          <Table.Tr key={`field-changed-${key}`}>
            <Table.Td>{key}</Table.Td>
            <Table.Td>{customFormatValue(key, initialValues[key])}</Table.Td>
            <Table.Td>{customFormatValue(key, value)}</Table.Td>
          </Table.Tr>,
        );
        newUpdates[key] = value;
      }
    });
    setDiffs(newDiffs);
    setUpdates(newUpdates);
  }, [formValues, initialValues]);

  const wrappedFunc = wrapUpdateFunc(endpoint, objType, form, onSubmit, setButtonLoading, close);

  return (
    <Stack>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Field Name</Table.Th>
            <Table.Th>Previous Value</Table.Th>
            <Table.Th>Updated Value</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{diffs}</Table.Tbody>
      </Table>
      <Flex justify="center">
        <Button
          loading={buttonLoading}
          onClick={() =>
            wrappedFunc(firebaseUser, campaignHashId, objId, updates, Object.keys(updates))
          }>
          Submit
        </Button>
      </Flex>
    </Stack>
  );
};

export default CampaignObjChangeConfirmation;
