import React from "react";

import { Anchor, Card, Group, Stack, Text } from "@mantine/core";

import { TaskStage } from "components/contracts/tasks/models/Common";
import CreatorRatingsContent from "components/contracts/tasks/content/CreatorRatingsContent";
import DueDateBadge from "components/contracts/tasks/common/DueDateBadge";
import ActionBadge from "components/contracts/tasks/common/ActionBadge";
import { User } from "models/User";
import { Task } from "components/contracts/tasks/models/Task";
import TaskTypeBadge from "components/contracts/tasks/common/TaskTypeBadge";
import CreatorAvatar from "components/contracts/tasks/common/CreatorAvatar";
import CreatorDrawer from "components/contracts/review/CreatorDrawer";
import { useDisclosure } from "@mantine/hooks";

export default function CreatorRatingsCard({
    user,
    primaryTask,
    taskType,
    handleCompleteTask,
    decrementUnreadMessageCount,
    handleCompleteRelatedTask,
    completedTaskIds,
    isCompletedTask,
  }: {
    user: User;
    primaryTask: Task;
    taskType: TaskStage;
    handleCompleteTask: (waitingForCreator?: boolean) => void;
    decrementUnreadMessageCount: () => void;
    handleCompleteRelatedTask?: (relatedTaskId: string, taskType: TaskStage) => void;
    completedTaskIds?: Set<string>;
    isCompletedTask?: boolean;
  }) {
    const [opened, { open, close }] = useDisclosure(false);
    return (
      <>
        <CreatorDrawer creatorId={primaryTask.creatorId} opened={opened} close={close} />
        <Card p="sm" radius="md" withBorder bg="#F8F9FA">
          <Group justify="space-between">
            <CreatorAvatar
              displayName={primaryTask.displayName}
              avatarUrl={primaryTask.avatarUrl}
              description={
                <Anchor
                  href={user.is_staff ? primaryTask.adminContractUrl : primaryTask.brandContractUrl}
                  target="_blank">
                  <Text size="xs" fw="500">
                    View Contract
                  </Text>
                </Anchor>
              }
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                open();
              }}
            />
            <TaskTypeBadge taskType={taskType} />
          </Group>
          <CreatorRatingsContent
            taskId={primaryTask.hashId}
            taskType={taskType}
            dateCreated={primaryTask.dateCreated}
            avatarUrl={primaryTask.avatarUrl}
            displayName={primaryTask.displayName}
            creatorId={primaryTask.creatorId}
            contractId={primaryTask.contractId}
            handleCompleteTask={handleCompleteTask}
          />
        </Card>
      </>
    );
  }