import React, { useState } from "react";

import { Button, Group, Text, Textarea, Tooltip } from "@mantine/core";
import { IconCheck, IconEdit, IconSend } from "@tabler/icons-react";

import { approveContent, requestContentRevision, sendMessageToCreator } from "components/contracts/tasks/api/Api";

import Message from "components/contracts/models/Message";
import { DeliverableVideo, Script } from "components/contracts/tasks/models/Common";
import { ContentStatus } from "components/contracts/common/Common";
import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

const MESSAGE_LENGTH_LIMIT = 3000;

function getApproveButtonText({
  isScript,
  isVideo,
  isPostOrCarousel,
  canSendMessages,
  messageText,
  forUgc = false,
}: {
  isScript: boolean;
  isVideo: boolean;
  isPostOrCarousel: boolean;
  canSendMessages: boolean;
  messageText: string;
  forUgc?: boolean;
}) {
  const messageEmpty = messageText === "";

  if (isScript && !isPostOrCarousel) {
    if (messageEmpty) {
      return `Approve Concept`;
    }
    return `Approve Concept ${canSendMessages ? "with Message" : "with Feedback"}`;
  } else if ((isVideo || isPostOrCarousel) && !forUgc) {
    if (messageEmpty) {
      return `Approve to Go Live`;
    }
    return `Approve to Go Live ${canSendMessages ? "with Message" : "with Feedback"}`;
  }

  if (messageEmpty) {
    return `Approve`;
  }
  return `Approve ${canSendMessages ? "with Message" : "with Feedback"}`;
}

export default function ContentReviewActions({
  taskId,
  displayName,
  script,
  video,
  contentStatus,
  isMissingReferralLink,
  isMissingPromoCode,
  handleCompleteTask,
  handleSendMessageSuccess,
}: {
  taskId: string;
  displayName: string;
  script?: Script;
  video?: DeliverableVideo;
  contentStatus: ContentStatus;
  isMissingReferralLink: boolean;
  isMissingPromoCode: boolean;
  handleCompleteTask: (requireCreatorAction?: boolean) => void;
  handleSendMessageSuccess: (message: Message) => void;
}) {
  if (
    contentStatus !== ContentStatus.PENDING_REVIEW &&
    contentStatus !== ContentStatus.REVISIONS_REQUESTED
  ) {
    return null;
  }

  const [messageText, setMessageText] = useState("");
  const [approveButtonLoading, setApproveButtonLoading] = useState(false);
  const [revisionButtonLoading, setRevisionButtonLoading] = useState(false);
  const [sendMessageButtonLoading, setSendMessageButtonLoading] = useState(false);

  // TODO(albert-tasks): Handle messaging. We should probably responsively render the message.
  // This should only complete a task if there was an unread message.
  const handleSendMessage = () => {
    setSendMessageButtonLoading(true);
    sendMessageToCreator({ taskId, messageText })
      .then((response) => {
        if (response.success) {
          const { message } = response;
          showSuccessNotification({
            title: "Message sent",
            message: `Message sent to ${displayName}.`,
          });
          handleSendMessageSuccess(Message.deserialize(message));
          setMessageText("");
        } else {
          showFailureNotification({ message: "Failed to send message." });
        }
      })
      .finally(() => {
        setSendMessageButtonLoading(false);
      });
  };

  const handleRequestRevision = () => {
    setRevisionButtonLoading(true);
    requestContentRevision({ taskId, messageText })
      .then((response) => {
        if (response.success) {
          const { message } = response;
          showSuccessNotification({
            title: video ? "Video revision requested" : "Concept revision requested",
            message: video
              ? `A revision has been requested for the video submitted by ${displayName}.`
              : `A revision has been requested for the concept for ${displayName}'s video.`,
          });
          handleCompleteTask(true);
          if (message) {
            handleSendMessageSuccess(Message.deserialize(message));
          }
        } else {
          showFailureNotification({ message: "Failed to request revision." });
        }
      })
      .finally(() => {
        setRevisionButtonLoading(false);
      });
  };

  const handleApproveContent = () => {
    setApproveButtonLoading(true);
    approveContent({ taskId, messageText })
      .then((response) => {
        if (response.success) {
          const { message } = response;
          showSuccessNotification({
            title: video ? "Video approved to go live" : "Concept approved",
            message: video
              ? `The video submitted by ${displayName} has been approved to go live.`
              : `The concept for ${displayName}'s video has been approved.`,
          });
          handleCompleteTask();
          if (message) {
            handleSendMessageSuccess(Message.deserialize(message));
          }
        } else {
          showFailureNotification({ message: "Failed to approve content." });
        }
      })
      .finally(() => {
        setApproveButtonLoading(false);
      });
  };

  const actionRequired = contentStatus === ContentStatus.PENDING_REVIEW;
  const missingLinkOrPromoCodeMessage = `Provide a ${isMissingReferralLink ? "Referral Link" : ""}${
    isMissingReferralLink && isMissingPromoCode ? " and " : ""
  }${isMissingPromoCode ? "Promo Code" : ""} Before approving the content`;
  const shouldBlockReview = isMissingReferralLink || isMissingPromoCode;

  return (
    <>
      <Textarea
        placeholder="Optional"
        label={
          <Text ml={1} mb={4} fw="500">
            Send {actionRequired ? "Feedback" : "Message"} to {displayName}
          </Text>
        }
        autosize
        minRows={3}
        value={messageText}
        onChange={(event) => setMessageText(event.currentTarget.value)}
        error={
          messageText.length > MESSAGE_LENGTH_LIMIT &&
          `${
            actionRequired ? "Feedback" : "Message"
          } must not exceed ${MESSAGE_LENGTH_LIMIT} characters.`
        }
      />
      <Group justify="right">
        {!actionRequired && (
          <Button
            disabled={messageText.length === 0 || messageText.length > MESSAGE_LENGTH_LIMIT}
            loading={sendMessageButtonLoading}
            onClick={handleSendMessage}
            leftSection={<IconSend size="1rem" />}
            variant="outline"
            color="gray"
            size="sm">
            Send Message
          </Button>
        )}
        {actionRequired && (
          <Button
            disabled={messageText.length === 0 || messageText.length > MESSAGE_LENGTH_LIMIT}
            loading={revisionButtonLoading}
            onClick={handleRequestRevision}
            leftSection={<IconEdit size="1rem" />}
            variant="light"
            color="red"
            size="sm">
            Request Revision
          </Button>
        )}
        <Tooltip label={missingLinkOrPromoCodeMessage} disabled={!shouldBlockReview}>
          <Button
            disabled={messageText.length > MESSAGE_LENGTH_LIMIT || shouldBlockReview}
            loading={approveButtonLoading}
            onClick={handleApproveContent}
            leftSection={<IconCheck size="1rem" />}
            variant="light"
            color="teal"
            size="sm">
            {getApproveButtonText({
              isScript: !!script,
              isVideo: !!video,
              isPostOrCarousel: !!script && script.isPostCarousel,
              canSendMessages: !actionRequired,
              messageText,
            })}
          </Button>
        </Tooltip>
      </Group>
    </>
  );
}
