import { CreatorDetails } from "components/discovery/Datamodels";

export const getCreatorDisplayName = (creator: CreatorDetails): string => {
  if (creator.youtube_channel?.title) {
    return creator.youtube_channel.title;
  } else if (creator.tiktok_profile?.info.display_name) {
    return creator.tiktok_profile.info.display_name;
  } else if (creator.instagram_profile?.info.display_name) {
    return creator.instagram_profile.info.display_name;
  }
  return `Creator ${creator.creator_id}`;
};

export default null;