import React, { useEffect, useState } from "react";

import {
  Button,
  ComboboxItem,
  Divider,
  Flex,
  Group,
  Modal,
  NativeSelect,
  NumberInput,
  OptionsFilter,
  Paper,
  Select,
  Switch,
  Stack,
  TagsInput,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { Notifications, showNotification } from "@mantine/notifications";
import { IconEye, IconRefresh } from "@tabler/icons-react";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import { updateCreatorSetById } from "campaigns/api/updateObjsById";
import { CreatorSetChangeConfirmation } from "components/creator_sets/CreatorSetChangeConfirmation";
import { AIGeneratedDescription } from "components/creator_sets/InstantRecommendations";
import { refreshCreatorSetSummary } from "components/creator_sets/api/CreatorSetsApi";
import { CreatorSet, CreatorSetStatus } from "components/discovery/Datamodels";
import { BaseRichTextEditor, getRawTextFromJsonStr } from "components/general/RichTextEditor";
import { Country, Language } from "models/Common";
import { MembershipTypes } from "models/User";
import { useAppSelector } from "reduxStore/hooks";
import CustomTagsInput from "admin/CustomTagsInput";

export const CreatorSetBasicSettings = ({ creatorSet }: { creatorSet: CreatorSet }) => {
  return (
    <Stack gap="xs">
      <Title order={4}>{creatorSet.name}</Title>
      <Group justify="space-between">
        <Text c="dimmed">ID: {creatorSet.id}</Text>
      </Group>
    </Stack>
  );
};

export const CreatorSetAudienceSettings = ({ creatorSet }: { creatorSet: CreatorSet }) => {
  const [opened, { open, close }] = useDisclosure(false);

  const initialObj = {
    status: creatorSet?.status.toString() || CreatorSetStatus.DRAFT.toString(),
    notes: creatorSet?.notes || "",
    example_tiktok_creators: creatorSet?.example_tiktok_creators || [],
    example_instagram_creators: creatorSet?.example_instagram_creators || [],
    example_youtube_creators: creatorSet?.example_youtube_creators || [],
    example_keywords: creatorSet?.example_keywords || [],
    example_hashtags: creatorSet?.example_hashtags || [],
    discovery_tiktok_creators: creatorSet?.discovery_tiktok_creators || [],
    discovery_instagram_creators: creatorSet?.discovery_instagram_creators || [],
    discovery_youtube_creators: creatorSet?.discovery_youtube_creators || [],
    discovery_hashtags: creatorSet?.discovery_hashtags || [],
    related_brands: creatorSet?.related_brands || [],
    description: creatorSet?.description || "",
    example_topics: creatorSet?.example_topics || "",
    requirements: creatorSet?.requirements || "",
    brand_requirements: creatorSet?.brand_requirements || "",
  };

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialObj);

  const form = useForm({
    initialValues: initialObj,
  });

  useEffect(() => {
    const newInitialObj = {
      status: creatorSet?.status.toString() || CreatorSetStatus.DRAFT.toString(),
      notes: creatorSet?.notes || "",
      example_tiktok_creators: creatorSet?.example_tiktok_creators || [],
      example_instagram_creators: creatorSet?.example_instagram_creators || [],
      example_youtube_creators: creatorSet?.example_youtube_creators || [],
      example_keywords: creatorSet?.example_keywords || [],
      example_hashtags: creatorSet?.example_hashtags || [],
      discovery_tiktok_creators: creatorSet?.discovery_tiktok_creators || [],
      discovery_instagram_creators: creatorSet?.discovery_instagram_creators || [],
      discovery_youtube_creators: creatorSet?.discovery_youtube_creators || [],
      discovery_hashtags: creatorSet?.discovery_hashtags || [],
      related_brands: creatorSet?.related_brands || [],
      description: creatorSet?.description || "",
      example_topics: creatorSet?.example_topics || "",
      requirements: creatorSet?.requirements || "",
      brand_requirements: creatorSet?.brand_requirements || "",
    };
    setInitialValues(newInitialObj);
    form.setValues(newInitialObj);
    form.resetDirty();
  }, [creatorSet]);

  const formatValue = (key: string, value: any) => {
    if (key === "example_tiktok_creators") {
      return value.join(", ");
    } else if (key === "example_instagram_creators") {
      return value.join(", ");
    } else if (key === "example_youtube_creators") {
      return value.join(", ");
    } else if (key === "example_keywords") {
      return value.join(", ");
    } else if (key === "example_hashtags") {
      return value.join(", ");
    } else if (key === "related_brands") {
      return value.join(", ");
    }
    return value;
  };

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Admin Settings Change Confirmation</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <CreatorSetChangeConfirmation
            objId={creatorSet.id}
            form={form}
            formValues={form.values}
            initialValues={initialValues}
            customFormatValue={formatValue}
            onSubmit={updateCreatorSetById}
            close={close}
          />
        </Stack>
      </Modal>
      <Stack gap="md">
        <Title order={4}>Audience Settings</Title>
        <Stack gap="sm">
          <Textarea
            label="Description"
            {...form.getInputProps("description")}
            size="sm"
            autosize
            minRows={2}
          />
          <Textarea
            label="Example Topics"
            {...form.getInputProps("example_topics")}
            size="sm"
            autosize
            minRows={2}
          />
          <Textarea
            label="Requirements"
            {...form.getInputProps("brand_requirements")}
            size="sm"
            autosize
            minRows={2}
          />
          <CustomTagsInput
            label="Example TikTok Creators"
            {...form.getInputProps("example_tiktok_creators")}
            splitChars={[",", " "]}
            onRightClick={(tiktokHandle: string) => {
              window.open(`https://www.tiktok.com/@${tiktokHandle}`, "_blank");
            }}
            tagHoverText={(tiktokHandle: string) => `Go to @${tiktokHandle}`}
          />
          <CustomTagsInput
            label="Example Instagram Creators"
            {...form.getInputProps("example_instagram_creators")}
            splitChars={[",", " "]}
            onRightClick={(instagramHandle: string) => {
              window.open(`https://www.instagram.com/${instagramHandle}`, "_blank");
            }}
            tagHoverText={(instagramHandle: string) => `Go to @${instagramHandle}`}
          />
          <CustomTagsInput
            label="Example YouTube Creators"
            {...form.getInputProps("example_youtube_creators")}
            splitChars={[",", " "]}
            onRightClick={(youtubeHandle: string) => {
              window.open(`https://www.youtube.com/@${youtubeHandle}`, "_blank");
            }}
            tagHoverText={(youtubeHandle: string) => `Go to @${youtubeHandle}`}
          />
          <CustomTagsInput
            label="Example Keywords (min 5 for best results)"
            {...form.getInputProps("example_keywords")}
            splitChars={["|"]}
          />
          <CustomTagsInput
            label="Example Hashtags (min 5 for best results)"
            {...form.getInputProps("example_hashtags")}
            onChange={(values) => {
              // TODO (victoria 6.2024): THIS DOESNT WORK WELL WITH DUPES
              // for each value, if it doesn't start with #, add it
              const updatedValues = values.map((value) => {
                if (value?.length > 0 && value[0] !== "#") {
                  return `#${value}`;
                }
                return value;
              });
              const dedupedValues = [...new Set(updatedValues)];
              form.setFieldValue("example_hashtags", dedupedValues);
            }}
            splitChars={[",", " "]}
          />
          <CustomTagsInput
            label="Related Brands"
            {...form.getInputProps("related_brands")}
            splitChars={["|"]}
          />
        </Stack>
        <Title order={4}>Derived Settings</Title>
        <Stack gap="sm">
          <CustomTagsInput
            label="Derived TikTok Creators"
            {...form.getInputProps("discovery_tiktok_creators")}
            splitChars={[",", " "]}
            onRightClick={(tiktokHandle: string) => {
              window.open(`https://www.tiktok.com/@${tiktokHandle}`, "_blank");
            }}
            tagHoverText={(tiktokHandle: string) => `Go to @${tiktokHandle}`}
          />
          <CustomTagsInput
            label="Derived Instagram Creators"
            {...form.getInputProps("discovery_instagram_creators")}
            splitChars={[",", " "]}
            onRightClick={(instagramHandle: string) => {
              window.open(`https://www.instagram.com/${instagramHandle}`, "_blank");
            }}
            tagHoverText={(instagramHandle: string) => `Go to @${instagramHandle}`}
          />
          <CustomTagsInput
            label="Derived YouTube Creators"
            {...form.getInputProps("discovery_youtube_creators")}
            splitChars={[",", " "]}
            onRightClick={(youtubeHandle: string) => {
              window.open(`https://www.youtube.com/@${youtubeHandle}`, "_blank");
            }}
            tagHoverText={(youtubeHandle: string) => `Go to @${youtubeHandle}`}
          />
          <CustomTagsInput
            label="Derived Hashtags"
            {...form.getInputProps("discovery_hashtags")}
            onChange={(values) => {
              // TODO (victoria 6.2024): THIS DOESNT WORK WELL WITH DUPES
              // for each value, if it doesn't start with #, add it
              const updatedValues = values.map((value) => {
                if (value?.length > 0 && value[0] !== "#") {
                  return `#${value}`;
                }
                return value;
              });
              const dedupedValues = [...new Set(updatedValues)];
              form.setFieldValue("discovery_hashtags", dedupedValues);
            }}
            splitChars={[",", " "]}
          />
        </Stack>
        <Flex justify="center">
          <Button
            onClick={() => {
              open();
            }}
            disabled={!form.isDirty()}>
            Save
          </Button>
        </Flex>
      </Stack>
    </>
  );
};

export const CreatorSetAdminSettings = ({ creatorSet }: { creatorSet: CreatorSet }) => {
  const userProfile = useAppSelector((state) => state.me.user);
  const parameterFilters = useAdminAppContext()?.campaignParameters;

  const [filterParamOptions, setFilterParamOptions] = useState<ComboboxItem[]>([]);

  const isOpsUser = userProfile?.membership_type === MembershipTypes.COLLAB_USER;

  const [notesEditorKey, setNotesEditorKey] = useState<string>(`notes-${creatorSet.id}`);
  const [requirementsEditorKey, setRequirementsEditorKey] = useState<string>(
    `requirements-${creatorSet.id}`,
  );
  // Note: we have a separate backend check as well to ensure that the user is an admin
  // if the user is not an admin, these fields will be empty
  if (
    userProfile === null ||
    // either admin or ops
    !(userProfile.is_staff || isOpsUser)
  ) {
    return null;
  }

  // Grab ops users from context
  const opsUsers = useAdminAppContext()?.opsUsers || [];

  const opsUserFormData = opsUsers?.map((opsUser) => ({
    label: opsUser.name,
    value: opsUser.key,
  }));

  const optionsFilter: OptionsFilter = ({ options, search }) => {
    const filtered = (options as ComboboxItem[]).filter((option) =>
      option.label.toLowerCase().trim().includes(search.toLowerCase().trim()),
    );

    filtered.sort((a, b) => a.label.localeCompare(b.label));
    return filtered;
  };

  const [opened, { open, close }] = useDisclosure(false);

  const initialObj = {
    status: creatorSet?.status.toString() || CreatorSetStatus.DRAFT.toString(),
    notes: creatorSet?.notes || "",
    requirements: creatorSet?.requirements || "",
    // sourcing_goal: creatorSet?.sourcing_goal || 0,
    creator_language: creatorSet?.creator_language || [Language.ENGLISH],
    creator_country: creatorSet?.creator_country || [Country.UNITED_STATES],
    ops_users: opsUsers
      ? creatorSet?.ops_users?.map((key: string) => opsUsers.find((u) => u.key === key)?.name) || []
      : [],
    sourcing_difficulty: creatorSet?.sourcing_difficulty || 1.0,
    sourcing_priority: creatorSet?.sourcing_priority || 0,
    default_filter_id: creatorSet?.default_filter_id?.toString() || null,
    brand_auto_accept: creatorSet?.brand_auto_accept || false,
    internal_auto_accept: creatorSet?.internal_auto_accept || false,
  };

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialObj);

  const form = useForm({
    initialValues: initialObj,
  });

  useEffect(() => {
    const parameterFilterFormData = parameterFilters
      ?.filter((filter) => filter.brand.id === creatorSet.brand.id)
      ?.map((filter) => ({
        label: filter.name,
        value: String(filter.id),
      }));
    setFilterParamOptions(parameterFilterFormData);
  }, [parameterFilters, creatorSet]);

  useEffect(() => {
    const newInitialObj = {
      status: creatorSet?.status.toString() || CreatorSetStatus.DRAFT.toString(),
      notes: creatorSet?.notes || "",
      requirements: creatorSet?.requirements || "",
      // sourcing_goal: creatorSet?.sourcing_goal || 0,
      creator_language: creatorSet?.creator_language || [Language.ENGLISH],
      creator_country: creatorSet?.creator_country || [Country.UNITED_STATES],
      ops_users: opsUsers
        ? creatorSet?.ops_users?.map((key: string) => opsUsers.find((u) => u.key === key)?.name) ||
          []
        : [],
      sourcing_difficulty: creatorSet?.sourcing_difficulty || 1.0,
      sourcing_priority: creatorSet?.sourcing_priority || 0,
      default_filter_id: creatorSet?.default_filter_id?.toString() || null,
      brand_auto_accept: creatorSet?.brand_auto_accept || false,
      internal_auto_accept: creatorSet?.internal_auto_accept || false,
    };
    setInitialValues(newInitialObj);
    form.setValues(newInitialObj);
    form.resetDirty();

    // update the editor keys
    setNotesEditorKey(`notes-${creatorSet.id}`);
    setRequirementsEditorKey(`requirements-${creatorSet.id}`);
  }, [creatorSet, opsUsers]);

  const formatValue = (key: string, value: any) => {
    if (key === "status") {
      return CreatorSetStatus[value];
    } else if (key === "notes") {
      return getRawTextFromJsonStr(value);
    } else if (key === "requirements") {
      return getRawTextFromJsonStr(value);
    } else if (key === "creator_language") {
      return value.join(", ");
    } else if (key === "creator_country") {
      return value.join(", ");
    } else if (key === "ops_users") {
      return value.join(", ");
    } else if (key === "default_filter_id") {
      return filterParamOptions?.find((filter) => filter.value === value)?.label || value;
    } else if (key === "brand_auto_accept") {
      return value ? "Yes" : "No";
    } else if (key === "internal_auto_accept") {
      return value ? "Yes" : "No";
    }
    return value;
  };

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Admin Settings Change Confirmation</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <CreatorSetChangeConfirmation
            objId={creatorSet.id}
            form={form}
            formValues={form.values}
            initialValues={initialValues}
            customFormatValue={formatValue}
            opsUsers={opsUsers}
            onSubmit={updateCreatorSetById}
            close={close}
          />
        </Stack>
      </Modal>
      <Stack gap="md">
        <Group gap="xs" justify="space-between">
          <Group>
            <Title order={4}>Admin Settings</Title>
          </Group>
          <Group gap="xs">
            <IconEye size={24} color="var(--mantine-color-gray-5)" />
            <Text c="gray.5" size="sm">
              Internal Only
            </Text>
          </Group>
        </Group>
        <Stack gap="md">
          <NativeSelect
            styles={{
              root: {
                maxWidth: "20%",
              },
            }}
            label="Status"
            required
            data={Object.keys(CreatorSetStatus)
              .filter((k) => typeof CreatorSetStatus[k as any] === "number")
              .map((key) => ({ label: key, value: CreatorSetStatus[key as any] }))}
            {...form.getInputProps("status")}
            disabled={isOpsUser}
          />
          <Select
            w={200}
            label="Default Filter Params"
            data={filterParamOptions}
            clearable
            searchable
            checkIconPosition="right"
            filter={optionsFilter}
            {...form.getInputProps("default_filter_id")}
          />
          <Switch
            label="Brand Auto Accept"
            checked={form.values.brand_auto_accept}
            {...form.getInputProps("brand_auto_accept")}
            disabled={isOpsUser}
          />
          <Switch
            label="Internal Auto Accept"
            checked={form.values.internal_auto_accept}
            {...form.getInputProps("internal_auto_accept")}
            disabled={isOpsUser}
          />
          <Divider my="md" label="Operations" labelPosition="left" />
          <TagsInput
            label="Trained Ops Users"
            w={400}
            data={opsUserFormData}
            {...form.getInputProps("ops_users")}
            splitChars={[",", " "]}
            filter={optionsFilter}
            disabled={isOpsUser}
          />
          <NumberInput
            w={200}
            label="Sourcing Difficulty"
            {...form.getInputProps("sourcing_difficulty")}
            disabled={isOpsUser}
          />
          <NumberInput
            w={600}
            label="Sourcing Priority"
            description="Lower is higher priority. New creator sets default to 0 and get bumped to 1 after they hit 100 activated creators."
            {...form.getInputProps("sourcing_priority")}
            disabled={isOpsUser}
          />
          <Title order={6}>Creator Requirements</Title>
          <Group gap="md">
            <TagsInput
              label="Creator Language"
              w={400}
              data={Object.values(Language)}
              {...form.getInputProps("creator_language")}
              splitChars={[",", " "]}
              disabled
            />
            <TagsInput
              label="Creator Country"
              w={400}
              data={Object.values(Country)}
              {...form.getInputProps("creator_country")}
              splitChars={[",", " "]}
              disabled
            />
          </Group>
          {/* <Divider p="sm" />
          <Title order={5}>Other</Title> */}
          <Divider my="xs" label="Other" labelPosition="left" />
          <BaseRichTextEditor
            editorKey={notesEditorKey}
            value={form.values.notes}
            {...form.getInputProps("notes")}
            label="Notes"
            disabled={isOpsUser}
          />
          <BaseRichTextEditor
            editorKey={requirementsEditorKey}
            value={form.values.requirements}
            {...form.getInputProps("requirements")}
            label="Additional Guidance for Ops"
          />
        </Stack>
        {!isOpsUser && (
          <Flex justify="center">
            <Button
              onClick={() => {
                open();
              }}
              disabled={!form.isDirty()}>
              Save
            </Button>
          </Flex>
        )}
      </Stack>
    </>
  );
};

export const CreatorSetSettings = ({ creatorSet }: { creatorSet: CreatorSet & { summary?: { text: string | null, exists: boolean, last_updated: string | null } } }) => {
  if (creatorSet == null) {
    return null;
  }
  const userProfile = useAppSelector((state) => state.me.user);
  const isStaff = userProfile?.is_staff ?? false;
  const [summary, setSummary] = useState(creatorSet.summary);
  
  // Update summary when creatorSet.summary changes
  useEffect(() => {
    setSummary(creatorSet.summary);
  }, [creatorSet.summary]);
  
  const handleRefreshSummary = async (creatorSetId: number) => {
    try {
      const response = await refreshCreatorSetSummary(creatorSetId);
      if (response.success) {
        setSummary(response.summary);
        showNotification({
          title: "Success",
          message: "Summary refreshed successfully",
          color: "green",
        });
      } else {
        showNotification({
          title: "Error",
          message: response.message || "Failed to refresh summary",
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error refreshing summary:", error);
      showNotification({
        title: "Error",
        message: "Failed to refresh summary. Please try again later.",
        color: "red",
      });
    }
  };
  
  return (
    <>
      <Notifications />
      <Stack gap="md">
        {/* Only display for staff users */}
        {isStaff && (
          <AIGeneratedDescription 
            summary={summary}
            creatorSetId={creatorSet.id}
            onRefresh={handleRefreshSummary}
          />
        )}
        <Paper p="md">
          <CreatorSetBasicSettings creatorSet={creatorSet} />
        </Paper>
        {(userProfile.is_staff || userProfile.membership_type === MembershipTypes.COLLAB_USER) && (
          <Paper p="md">
            <CreatorSetAdminSettings creatorSet={creatorSet} />
          </Paper>
        )}
        <Paper p="md">
          <CreatorSetAudienceSettings creatorSet={creatorSet} />
        </Paper>
      </Stack>
    </>
  );
};

export default CreatorSetSettings;
