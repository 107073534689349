import React from "react";
import {
  Avatar,
  Box,
  Center,
  Chip,
  Group,
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import Star from "components/ratings/Star";
import { Rating } from "models/Rating";

type RatingsViewModalProps = {
  opened: boolean,
  close: () => void,
  creatorName: string,
  creatorAvatar?: string,
  creatorRating: Rating,
}

const RatingsViewModal = ({ 
  opened, 
  close, 
  creatorName,
  creatorAvatar,
  creatorRating
}: RatingsViewModalProps) => {
  const { starValue = 0, tags = [], description } = creatorRating || {};
  const stars = Array.from({ length: 5 }, (_, index) => index + 1);
  
  return (
    <Modal 
      title="Creator Rating"
      size="30rem"
      opened={opened}
      onClose={close}
      styles={{
        title: {
          fontWeight: 700,
          fontSize: "1.25rem"
        }
      }}
      removeScrollProps={{
        allowPinchZoom: true,
        removeScrollBar: false
      }}
    >
      <Stack gap="sm"
        align="center"
        justify="center"
        bg="#F1F3F5"
        p="md"
      >
        <Avatar 
          src={creatorAvatar} 
          size="xl" 
          radius="100%"
        />
        <Text fw={600} size="xl">{creatorName}</Text>
        <Group gap="xs">
          {stars.map((star) => 
            <Star 
              active={star <= starValue}
              key={star}
              clickable={false}
              size={24}
            />
          )}
        </Group>
        {tags?.length > 0 && (
          <Group gap="xs" justify="center">
            {tags.map((tag: any) => (
              <Chip
                key={tag}
                variant="outline"
                checked={false}
                disabled
                styles={{
                  label: {
                    backgroundColor: 'inherit',
                    borderColor: '#CED4DA',
                    color: 'black',
                    cursor: 'default'
                  },
                  root: {
                    opacity: 1
                  }
                }}
              >
                {tag}
              </Chip>
            ))}
          </Group>
        )}
        {description && <Text>{description}</Text>}
      </Stack>
    </Modal>
  );
}

export default RatingsViewModal;