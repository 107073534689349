import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Divider,
  Flex,
  NumberInput,
  Radio,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Timeline,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";

import InfoCircleWithTooltip from "campaigns/create/common/InfoCircleWithTooltip";
import { showFailureNotification } from "components/common/Notifications";
import { ContractBrandReviewDeliverable } from "components/contracts/brand_review/ContractBrandReviewDeliverable";
import {
  fetchNegotiationDetailsForOpportunity,
  overrideNegotiationPrice,
} from "components/contracts/negotiations/api/ContractOfferApi";
import { CreatorHeader } from "components/contracts/tasks/content/ContractReviewContent";
import { ContractReviewDeliverable } from "components/contracts/tasks/models/Common";
import PlatformTable from "components/creator_lists/unified_creator_rep/PlatformTable";
import { CreatorDetails } from "components/discovery/Datamodels";
import { formatCurrencyAmount } from "utils/AnalyticsUtils";
import { toMediumDateString } from "utils/DateUtils";

export enum NeedsExplainType {
  FIRSTCOLLAB = "1stcollab",
  BRAND = "brand",
}

interface NegotiationHistoryEntry {
  // This is effectively the title
  what: string;
  value?: number;
  when: Date;
  needsExplain?: boolean;
  needsExplainType?: NeedsExplainType;
  isTerminal: boolean;
  // Only set when isTerminal
  success?: boolean;
}

interface DeliverablePackage {
  uuid: string;
  deliverables: ContractReviewDeliverable[];
  negotiationHistory: NegotiationHistoryEntry[];
  // May be omitted if we did not request that it be included
  recommendedPrice?: number;
  offeredPrice?: number;
  offeredUsageRightsPrice?: number;
  creatorAskingPrice?: number;
}

export interface NegotiationDetails {
  packages: DeliverablePackage[];
  creatorDetails: CreatorDetails;
}

function makeHistoryTimelineItem(entry: NegotiationHistoryEntry) {
  let bullet;
  if (entry.isTerminal && !entry.success) {
    bullet = (
      <ThemeIcon color="red" radius="xl" variant="filled" size="sm">
        <IconX size="0.8rem" />
      </ThemeIcon>
    );
  } else if (!entry.isTerminal && entry.success) {
    bullet = undefined;
  } else {
    bullet = (
      <ThemeIcon color="blue.6" radius="xl" variant="filled" size="sm">
        <IconCheck size="0.8rem" />
      </ThemeIcon>
    );
  }
  return (
    <Timeline.Item
      title={
        <Text>
          <Text span size="sm" fw={400} c="dark.9">
            {entry.what}
          </Text>
          {entry.value && (
            <Text span size="sm" fw={600} c="dark.9">
              {`: ${formatCurrencyAmount(entry.value * 100)}`}
            </Text>
          )}
          {entry.needsExplain && (
            <>
              {" "}
              <InfoCircleWithTooltip
                tooltipText={
                  entry.needsExplainType === NeedsExplainType.FIRSTCOLLAB
                    ? "This price was based on your target CPM and our predicted views for a sponsored " +
                      "post with this creator. The predicted views may have changed since that time."
                    : "This is the price you submitted in the contract review for this creator."
                }
              />
            </>
          )}
        </Text>
      }
      bullet={bullet}>
      {entry.when && (
        <Text size="xs" fw={400} c="gray.6">
          {toMediumDateString(entry.when)}
        </Text>
      )}
    </Timeline.Item>
  );
}

const NegotiationHistoryContent = ({ history }: { history: NegotiationHistoryEntry[] }) => {
  if (!history || history.length === 0) {
    return null;
  }
  // The last item is "in progress" unless it's marked terminal
  const activeNode = !history[history.length - 1].isTerminal ? history.length - 1 : history.length;
  return (
    <Stack>
      <Text size="md" fw={600}>
        Negotiation History
      </Text>
      <Timeline active={activeNode}>
        {history.map((item) => makeHistoryTimelineItem(item))}
      </Timeline>
    </Stack>
  );
};

function DeliverablePackageCard({
  packageIdx,
  deliverablePackage,
  selected,
  setSelected,
}: {
  packageIdx: number;
  deliverablePackage: DeliverablePackage;
  selected: boolean;
  // Called when the radio button is selected, which will report that it happened plus the packageIdx.
  // If set to null/undefined, the radio button will not appear.
  setSelected: null | ((value: boolean, idx: number) => void);
}) {
  return (
    <Card
      radius="md"
      withBorder
      styles={{
        root: {
          borderColor: selected ? "var(--mantine-color-blue-6)" : "var(--mantine-color-gray-4)",
          backgroundColor: "white",
        },
      }}>
      <Stack gap="xs" w="100%">
        <Flex justify="space-between" align="center">
          <Text fz="16px" fw={700}>
            Package #{packageIdx + 1}
          </Text>
          {setSelected && (
            <Radio
              checked={selected}
              onChange={(event) => setSelected(event.currentTarget.checked, packageIdx)}
            />
          )}
        </Flex>
        <Stack gap="md">
          {deliverablePackage.deliverables.map((deliverable) => (
            <ContractBrandReviewDeliverable
              key={`deliverable-card-${deliverable.format}`}
              format={deliverable.format.toLowerCase()}
              platform={deliverable.platform}
              usageRights={deliverable.usageRightsDays}
              usageRightsInPerpetuity={deliverable.usageRightsInPerpetuity}
              width="100%"
            />
          ))}
          {deliverablePackage?.negotiationHistory &&
            deliverablePackage.negotiationHistory.length > 0 && <Divider />}
          <NegotiationHistoryContent history={deliverablePackage.negotiationHistory} />
        </Stack>
      </Stack>
    </Card>
  );
}

function calculatePackageRecommendedOverride(selectedPackage: DeliverablePackage): string | number {
  /**
   * Calculate how much a package's default override value should be.
   *
   * TL;DR: It's half way between the offered price and the asking price, substituting recommended
   * price if we don't have an offered price.
   */
  const askingPrice = selectedPackage.creatorAskingPrice;
  const offerPrice = selectedPackage.offeredPrice
    ? selectedPackage.offeredPrice + (selectedPackage.offeredUsageRightsPrice || 0)
    : selectedPackage.recommendedPrice || 0;
  if (askingPrice <= offerPrice) {
    return "";
  }
  const halfDiff = (askingPrice - offerPrice) / 2;
  return offerPrice + parseFloat(halfDiff.toFixed(2));
}

export const OverridePrice = ({
  campaignId,
  opportunityId,
  closeModal,
  overrideSuccessCallback,
  forInformationOnly = false,
}: {
  campaignId: number;
  opportunityId: string;
  closeModal: () => void;
  overrideSuccessCallback: () => void;
  // Set to true when we just want to show the negotiation, not override the price
  forInformationOnly?: boolean;
}) => {
  const [negotiationDetails, setNegotiationDetails] = useState<NegotiationDetails | null>(null);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState<number>(null);
  const [overridePrice, setOverridePrice] = useState<number | string>("");

  const [defaultExpandedPlatforms, setDefaultExpandedPlatforms] = useState<string[]>([]);

  useEffect(() => {
    const abortController = new AbortController();

    // on loading the view, fetch the negotiation details from the server
    setNegotiationDetails(null);
    // Information only does not need recommended price
    fetchNegotiationDetailsForOpportunity(
      campaignId,
      opportunityId,
      !forInformationOnly,
      abortController,
    )
      .then((response) => {
        if (response.success) {
          setNegotiationDetails(response.negotiationDetails);
          if (response.negotiationDetails?.packages?.length > 0) {
            setSelectedPackageIndex(0);
          }
        } else {
          setNegotiationDetails(null);
        }
      })
      .catch(() => setNegotiationDetails(null));

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (!negotiationDetails?.packages || selectedPackageIndex === undefined) {
      setOverridePrice("");
      return;
    }
    const selectedPackage = negotiationDetails.packages.at(selectedPackageIndex);
    setOverridePrice(calculatePackageRecommendedOverride(selectedPackage));
  }, [selectedPackageIndex]);

  if (!negotiationDetails) {
    return null;
  }

  const displayName =
    negotiationDetails?.creatorDetails.youtube_channel?.title ||
    negotiationDetails?.creatorDetails.tiktok_profile?.info?.display_name ||
    negotiationDetails?.creatorDetails.instagram_profile?.info?.display_name;

  return (
    <Stack>
      <CreatorHeader
        displayName={displayName}
        creatorDetails={negotiationDetails?.creatorDetails}
        includeBadge={false}
      />
      <PlatformTable
        creator={negotiationDetails?.creatorDetails}
        defaultExpandedPlatforms={defaultExpandedPlatforms}
        refetchThumbnails
      />
      <SimpleGrid cols={Math.min(negotiationDetails?.packages?.length || 1, 3)}>
        {negotiationDetails?.packages.map((deliverablePackage, idx) => (
          <DeliverablePackageCard
            key={deliverablePackage.uuid}
            deliverablePackage={deliverablePackage}
            packageIdx={idx}
            selected={!forInformationOnly && idx === selectedPackageIndex}
            setSelected={
              forInformationOnly
                ? null // use a null function for unselectable packages
                : (isSelected, newIdx) => {
                    if (isSelected) {
                      setSelectedPackageIndex(newIdx);
                    } else {
                      setSelectedPackageIndex(-1);
                    }
                  }
            }
          />
        ))}
      </SimpleGrid>
      {forInformationOnly ? null : (
        <Text size="md" fw={600} c="dark.6">
          Override our recommended price for Package #{selectedPackageIndex + 1}?
        </Text>
      )}
      {forInformationOnly ? null : (
        <Text lh="14px">
          <Text span size="sm" fw={700} c="gray.6">
            We believe the deliverables above are worth{" "}
            {formatCurrencyAmount(
              (negotiationDetails?.packages?.at(selectedPackageIndex)?.recommendedPrice || 0) * 100,
            )}
            .
          </Text>
          <InfoCircleWithTooltip
            tooltipText={
              "The estimated price is based on your target CPM and our predicted views for a sponsored " +
              "post with this creator. The estimated price may have changed since the time of our counter offer."
            }
          />{" "}
          <Text span size="sm" fw={400} c="gray.6">
            If you are willing to offer this creator more than{" "}
            {formatCurrencyAmount(
              (negotiationDetails?.packages?.at(selectedPackageIndex)?.offeredPrice ||
                negotiationDetails?.packages?.at(selectedPackageIndex)?.recommendedPrice ||
                0 +
                  (negotiationDetails?.packages?.at(selectedPackageIndex)
                    ?.offeredUsageRightsPrice || 0)) * 100,
            )}
            , you can override our recommended price with a higher maximum offer and we will
            re-engage the creator for this campaign. Once submitted, your maximum offer is final.
          </Text>
        </Text>
      )}
      {!forInformationOnly && (
        <NumberInput
          placeholder="Override price"
          prefix="$"
          decimalScale={0}
          fixedDecimalScale
          thousandSeparator=","
          clampBehavior="strict"
          min={0}
          max={999999}
          value={overridePrice}
          onChange={setOverridePrice}
        />
      )}
      {forInformationOnly ? (
        <SimpleGrid cols={1}>
          <Button variant="light" color="dark.6" onClick={closeModal}>
            Close
          </Button>
        </SimpleGrid>
      ) : (
        <SimpleGrid cols={2}>
          <Button variant="light" color="dark.6" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="light"
            color="blue.6"
            disabled={overridePrice === "" || Number(overridePrice) <= 0}
            onClick={() => {
              if (overridePrice === "" || Number(overridePrice) <= 0) {
                showFailureNotification({ message: `Invalid override price ${overridePrice}` });
                return;
              }
              const price = Number(overridePrice);
              overrideNegotiationPrice(
                campaignId,
                opportunityId,
                negotiationDetails.packages[selectedPackageIndex].uuid,
                price,
              )
                .then((response) => {
                  if (response.success) {
                    overrideSuccessCallback();
                    closeModal();
                  } else {
                    const message = response.message || response.result?.message;
                    showFailureNotification({
                      message: message || response.text || "Server error.",
                      stayOpen: true,
                    });
                  }
                })
                .catch(() => {
                  showFailureNotification({
                    message: "Server error.",
                    stayOpen: true,
                  });
                });
            }}>
            Set Max Offer
          </Button>
        </SimpleGrid>
      )}
    </Stack>
  );
};
