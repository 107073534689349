import React from "react";

import { Divider, Stack } from "@mantine/core";

import { Task } from "components/contracts/tasks/models/Task";
import { SupportedFormat } from "models/Common";

import LiveContentStage from "components/contracts/tasks/stages/LiveContentStage";
import ScriptDraftStage from "components/contracts/tasks/stages/ScriptDraftStage";
import VideoDraftStage from "components/contracts/tasks/stages/VideoDraftStage";

export default function ReviewContent({
  task,
  handleCompleteTask,
  isCompletedTask,
  showAdminOptions,
  clearUnreadMessages,
  messagesLastRead,
}: {
  task: Task;
  handleCompleteTask: (requireCreatorAction?: boolean) => void;
  clearUnreadMessages: () => void;
  messagesLastRead: Date;
  isCompletedTask?: boolean;
  showAdminOptions?: boolean;
}) {
  return (
    <Stack gap="xs">
      <Divider />
      <ScriptDraftStage
        taskId={task.hashId}
        displayName={task.deliverable.creatorHandle}
        scripts={task.deliverable.scripts}
        missingReferralLink={task.deliverable.missingReferralLink}
        missingPromoCode={task.deliverable.missingPromoCode}
        waitingForCreator={isCompletedTask}
        handleCompleteTask={(requireCreatorAction?: boolean) => {
          handleCompleteTask(requireCreatorAction);
          clearUnreadMessages();
        }}
        messagesLastRead={messagesLastRead}
        clearUnreadMessages={clearUnreadMessages}
        showAdminOptions={showAdminOptions}
      />
      <VideoDraftStage
        taskId={task.hashId}
        displayName={task.deliverable.creatorHandle}
        videoDrafts={task.deliverable.videoDrafts}
        missingReferralLink={task.deliverable.missingReferralLink}
        missingPromoCode={task.deliverable.missingPromoCode}
        waitingForCreator={isCompletedTask}
        handleCompleteTask={(requireCreatorAction?: boolean) => {
          handleCompleteTask(requireCreatorAction);
          clearUnreadMessages();
        }}
        messagesLastRead={messagesLastRead}
        clearUnreadMessages={clearUnreadMessages}
        showAdminOptions={showAdminOptions}
      />
      <LiveContentStage
        taskId={task.hashId}
        platform={task.deliverable.platform}
        displayName={task.displayName}
        liveContent={task.liveContent}
        handleCompleteTask={handleCompleteTask}
        isPostOrCarousel={
          task.deliverable.format === SupportedFormat.INSTAGRAM_DEDICATED_POST ||
          task.deliverable.format === SupportedFormat.INSTAGRAM_CAROUSEL
        }
      />
    </Stack>
  );
}
