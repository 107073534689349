import React from "react";

import { Routes, Route } from "react-router-dom";
import BrandLayout from "auth/BrandLayout";
import CampaignAppShell from "campaigns/CampaignAppShell";
import CampaignPortalHome from "campaigns/main/CampaignPortalHome";
import CampaignResults from "campaigns/main/CampaignResults";
import BrandCreativeBriefHome from "campaigns/main/campaignPage/creative_briefs/BrandCreativeBriefHome";
import BrandContract from "campaigns/main/campaignPage/contracts_overview/BrandContract";
import ContractsOverview from "campaigns/main/campaignPage/contracts_overview/ContractsOverview";
import CampaignCreatorCandidatesLiveView from "components/creator_lists/CampaignCreatorLiveResultsView";
import { CAMPAIGN_AD_GROUP_PATH, CAMPAIGN_PORTAL, CREATOR_SET_PATH } from "campaigns/PathConstants";
import AllContractsView from "components/contracts/review/AllContractsView";
import SetManagementView from "components/creator_sets/SetManagementView";
import { CreatorSetBrandSettings } from "components/creator_sets/BrandCreatorSetSettings";
import TasksView from "components/contracts/tasks/TasksView";
import ContentLibrary from "components/contracts/content/ContentLibrary";
import ContractApprovalFeedView from "components/contracts/brand_review/ContractBrandReviewFeedView";
import CreateCampaign from "campaigns/create/CreateCampaign";
import CreatorSetSearch from "components/discovery/search/CreatorSetSearch";
import CampaignCreatorProgress from "campaigns/main/CampaignCreatorProgress";
import Settings from "brand/settings/Settings";
import AllCreatorsView from "components/creator_sets/AllCreatorsView";
import CreatorSetsRecommendations from "components/creator_sets/CreatorSetsRecommendations";
import InstantRecommendations from "components/creator_sets/InstantRecommendations";

const CampaignApp = () => (
  <Routes>
    <Route element={<BrandLayout />}>
      <Route path={CAMPAIGN_PORTAL} element={<CampaignAppShell />}>
        <Route index element={<CampaignPortalHome />} />
        <Route path="overview" element={<CampaignPortalHome />} />
        <Route path="tasks" element={<TasksView />} />
        <Route path="content_library" element={<ContentLibrary showDefaultContent />} />
        <Route path="contracts_overview" element={<ContractsOverview />} />
        <Route path="contracts_overview/:activeTab" element={<ContractsOverview />} />
        <Route path="contracts_overview/details/:contractId" element={<BrandContract />} />
        <Route path="create" element={<CreateCampaign />} />
        <Route path="creative_briefs" element={<BrandCreativeBriefHome />} />
        <Route path="creator_sets/all" element={<AllCreatorsView />} />
        <Route path="creator_sets/existing_partners" element={<AllCreatorsView />} />
        <Route path="creator_sets/manage" element={<SetManagementView />} />
        <Route path="creator_sets/review" element={<CreatorSetsRecommendations />} />
        <Route path="creator_sets/instant_recommendations" element={<InstantRecommendations />} />
        <Route path={`creator_sets/${CREATOR_SET_PATH}/view`} element={<AllCreatorsView />} />
        <Route
          path={`creator_sets/${CREATOR_SET_PATH}/recommended`}
          element={<CreatorSetsRecommendations />}
        />
        <Route
          path={`creator_sets/${CREATOR_SET_PATH}/instant_recommendations`}
          element={<InstantRecommendations />}
        />
        <Route
          path={`creator_sets/${CREATOR_SET_PATH}/settings`}
          element={<CreatorSetBrandSettings />}
        />
        <Route path="discover" element={<CreatorSetSearch />} />
        <Route path="settings" element={<Settings />} />
        {/* TODO(kevin): Add routes here around the creators + creatorSets */}
        {/* <Route path="campaign"> */}
        <Route path="live" element={<CampaignCreatorCandidatesLiveView />} />
        <Route path="metrics" element={<CampaignResults />} />
        <Route path=":campaignId" element={<CampaignResults />} />
        <Route path=":campaignId/creator_progress" element={<CampaignCreatorProgress />} />
        <Route path="creator_progress/all" element={<CampaignCreatorProgress />} />
        <Route path=":campaignId/edit" element={<CampaignPortalHome />} />
        {/* Match /campaign/:campaignId routes before adgroup routes  */}
        <Route path=":campaignId/live" element={<CampaignCreatorCandidatesLiveView />} />
        {/* Ad Group is currently required, but should we make it optional? Copy these below. */}
        <Route path={CAMPAIGN_AD_GROUP_PATH} element={<CampaignResults />} />
        <Route
          path={`${CAMPAIGN_AD_GROUP_PATH}/live`}
          element={<CampaignCreatorCandidatesLiveView />}
        />
        <Route path={`${CAMPAIGN_AD_GROUP_PATH}/contracts`} element={<AllContractsView />} />
        <Route
          path={`${CAMPAIGN_AD_GROUP_PATH}/contract_approval`}
          element={<ContractApprovalFeedView />}
        />
      </Route>
    </Route>
  </Routes>
);

export default CampaignApp;
