import React, { useState, useMemo, useEffect } from "react";
import {
  Title,
  Box,
  Grid,
  Alert,
} from "@mantine/core"; 
import { IconAlertCircle } from "@tabler/icons-react";

import { getEmbeddingIndexTypes, embeddingSearch } from "components/discovery/embedding_search/EmbeddingDemoAPI";
import SearchForm from "components/discovery/embedding_search/SearchForm";
import ResultsColumn from "components/discovery/embedding_search/ResultsColumn";
import { EmbeddingIndexType, SearchParams, SearchResult } from "components/discovery/embedding_search/EmbeddingDemoTypes";

import { DEFAULT_PAGE_SIZE } from "components/discovery/embedding_search/EmbeddingDemoAPI";

export default function EmbeddingDemo() {
  // Search parameters state
  const [searchParams, setSearchParams] = useState<SearchParams>({
    query: "",
    description: "",
    searchMode: "query",
    numResults: DEFAULT_PAGE_SIZE,
    skipResults: 0,
    enableEvaluation: false,
    creatorId: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingIndexTypes, setLoadingIndexTypes] = useState(false);
  const [indexTypes, setIndexTypes] = useState<EmbeddingIndexType[]>([]);

  // State for endpoint selection
  const [leftEndpoint, setLeftEndpoint] = useState("");
  const [rightEndpoint, setRightEndpoint] = useState("");

  // State for search results from different endpoints
  const [leftResults, setLeftResults] = useState<SearchResult>({
    creators: [],
    queryCreator: null,
    sourceCreator: null,
    scoringTime: null,
    detailsTime: null,
    error: "",
  });
  const [rightResults, setRightResults] = useState<SearchResult>({
    creators: [],
    queryCreator: null,
    sourceCreator: null,
    scoringTime: null,
    detailsTime: null,
    error: "",
  });

  // Add state for differences toggle
  const [showDifferencesOnly, setShowDifferencesOnly] = useState(false);

  // Add pagination state
  const [leftNextCursor, setLeftNextCursor] = useState<string | undefined>(undefined);
  const [rightNextCursor, setRightNextCursor] = useState<string | undefined>(undefined);
  const [leftLoadingMore, setLeftLoadingMore] = useState(false);
  const [rightLoadingMore, setRightLoadingMore] = useState(false);

  // Fetch available embedding index types when component mounts
  useEffect(() => {
    const fetchIndexTypes = async () => {
      setLoadingIndexTypes(true);
      try {
        const response = await getEmbeddingIndexTypes();
        if (response.success) {
          setIndexTypes(response.index_types);
          // Set default values for endpoints if not already set
          if (!leftEndpoint && response.index_types.length > 0) {
            setLeftEndpoint(response.index_types[0].value);
          }
          if (!rightEndpoint && response.index_types.length > 1) {
            setRightEndpoint(response.index_types[1].value);
          }
        } else {
          console.error("Failed to fetch embedding index types:", response.message);
        }
      } catch (error) {
        console.error("Error fetching embedding index types:", error);
      } finally {
        setLoadingIndexTypes(false);
      }
    };

    fetchIndexTypes();
  }, [leftEndpoint, rightEndpoint]);

  const handleSearchParamsChange = (updates: Partial<SearchParams>) => {
    setSearchParams(prev => ({ ...prev, ...updates }));
  };

  const handleEndpointChange = (endpoint: "left" | "right", value: string) => {
    if (endpoint === "left") {
      setLeftEndpoint(value);
    } else {
      setRightEndpoint(value);
    }
  };

  const handleSearch = async () => {
    let searchText;
    let isDesc = false;
    let useCreatorSetAsQuery = false;
    let creatorSetId;
    let creatorId;

    switch (searchParams.searchMode) {
      case "query":
        searchText = searchParams.query;
        break;
      case "description":
        searchText = searchParams.description;
        isDesc = true;
        break;
      case "creatorset":
        searchText = ""; // Not used when using creator set as query
        useCreatorSetAsQuery = true;
        creatorSetId = searchParams.creatorSetId ? String(searchParams.creatorSetId) : undefined;
        if (!creatorSetId) return;
        break;
      case "creator":
        searchText = ""; // Not used when using creator ID
        creatorId = searchParams.creatorId;
        if (!creatorId) return;
        break;
      default:
        throw new Error("Invalid search mode");
    }

    if ((searchParams.searchMode === "query" || searchParams.searchMode === "description") && !searchText?.trim()) return;

    setLoading(true);

    // Reset results and pagination state
    setLeftResults({
      creators: [],
      queryCreator: null,
      sourceCreator: null,
      scoringTime: null,
      detailsTime: null,
      error: "",
    });
    setRightResults({
      creators: [],
      queryCreator: null,
      sourceCreator: null,
      scoringTime: null,
      detailsTime: null,
      error: "",
    });
    setLeftNextCursor(undefined);
    setRightNextCursor(undefined);

    try {
      // Execute both searches in parallel
      const [leftResponse, rightResponse] = await Promise.all([
        embeddingSearch(
          searchText, 
          leftEndpoint, 
          searchParams.numResults, 
          isDesc, 
          undefined, 
          searchParams.skipResults,
          searchParams.evaluationSetId ? String(searchParams.evaluationSetId) : undefined,
          useCreatorSetAsQuery,
          creatorSetId,
          searchParams.enableEvaluation,
          creatorId
        ),
        embeddingSearch(
          searchText, 
          rightEndpoint, 
          searchParams.numResults, 
          isDesc, 
          undefined, 
          searchParams.skipResults,
          searchParams.evaluationSetId ? String(searchParams.evaluationSetId) : undefined,
          useCreatorSetAsQuery,
          creatorSetId,
          searchParams.enableEvaluation,
          creatorId
        ),
      ]);

      // Process left results
      if (leftResponse.success) {
        setLeftResults({
          creators: leftResponse.creator_details,
          queryCreator: leftResponse.query_creator_details || null,
          sourceCreator: leftResponse.source_creator || null,
          scoringTime: leftResponse.scoring_time_seconds,
          detailsTime: leftResponse.details_time_seconds,
          error: "",
          totalCreatorsInIndex: leftResponse.total_creators_in_index,
          hasMore: leftResponse.pagination?.has_more,
          nextCursor: leftResponse.pagination?.next_cursor,
          evaluation: leftResponse.evaluation,
          generatedQuery: leftResponse.generated_query,
        });
        setLeftNextCursor(leftResponse.pagination?.next_cursor);
      } else {
        setLeftResults({
          creators: [],
          queryCreator: null,
          sourceCreator: null,
          scoringTime: null,
          detailsTime: null,
          error: leftResponse.message || "An error occurred during the search",
        });
      }

      // Process right results
      if (rightResponse.success) {
        setRightResults({
          creators: rightResponse.creator_details,
          queryCreator: rightResponse.query_creator_details || null,
          sourceCreator: rightResponse.source_creator || null,
          scoringTime: rightResponse.scoring_time_seconds,
          detailsTime: rightResponse.details_time_seconds,
          error: "",
          totalCreatorsInIndex: rightResponse.total_creators_in_index,
          hasMore: rightResponse.pagination?.has_more,
          nextCursor: rightResponse.pagination?.next_cursor,
          evaluation: rightResponse.evaluation,
          generatedQuery: rightResponse.generated_query,
        });
        setRightNextCursor(rightResponse.pagination?.next_cursor);
      } else {
        setRightResults({
          creators: [],
          queryCreator: null,
          sourceCreator: null,
          scoringTime: null,
          detailsTime: null,
          error: rightResponse.message || "An error occurred during the search",
        });
      }
    } catch (err) {
      const errorMessage = "Failed to perform embedding search";

      setLeftResults((prev) => ({ ...prev, error: errorMessage }));
      setRightResults((prev) => ({ ...prev, error: errorMessage }));
    } finally {
      setLoading(false);
    }
  };

  // Function to load more results for the left column
  const handleLoadMoreLeft = async () => {
    if (!leftNextCursor) return;

    let searchText;
    let isDesc = false;
    let useCreatorSetAsQuery = false;
    let creatorSetId;
    let creatorId;

    switch (searchParams.searchMode) {
      case "query":
        searchText = searchParams.query;
        break;
      case "description":
        searchText = searchParams.description;
        isDesc = true;
        break;
      case "creatorset":
        searchText = ""; // Not used when using creator set as query
        useCreatorSetAsQuery = true;
        creatorSetId = searchParams.creatorSetId ? String(searchParams.creatorSetId) : undefined;
        break;
      case "creator":
        searchText = ""; // Not used when using creator ID
        creatorId = searchParams.creatorId;
        break;
      default:
        throw new Error("Invalid search mode");
    }

    setLeftLoadingMore(true);

    try {
      const response = await embeddingSearch(
        searchText,
        leftEndpoint,
        searchParams.numResults,
        isDesc,
        leftNextCursor,
        undefined,
        searchParams.evaluationSetId ? String(searchParams.evaluationSetId) : undefined,
        useCreatorSetAsQuery,
        creatorSetId,
        searchParams.enableEvaluation,
        creatorId
      );

      if (response.success) {
        setLeftResults(prev => ({
          ...prev,
          creators: [...prev.creators, ...response.creator_details],
          hasMore: response.pagination?.has_more,
          nextCursor: response.pagination?.next_cursor,
        }));
        setLeftNextCursor(response.pagination?.next_cursor);
      } else {
        console.error("Failed to load more left results:", response.message);
      }
    } catch (err) {
      console.error("Error loading more left results:", err);
    } finally {
      setLeftLoadingMore(false);
    }
  };

  // Function to load more results for the right column
  const handleLoadMoreRight = async () => {
    if (!rightNextCursor) return;

    let searchText;
    let isDesc = false;
    let useCreatorSetAsQuery = false;
    let creatorSetId;
    let creatorId;

    switch (searchParams.searchMode) {
      case "query":
        searchText = searchParams.query;
        break;
      case "description":
        searchText = searchParams.description;
        isDesc = true;
        break;
      case "creatorset":
        searchText = ""; // Not used when using creator set as query
        useCreatorSetAsQuery = true;
        creatorSetId = searchParams.creatorSetId ? String(searchParams.creatorSetId) : undefined;
        break;
      case "creator":
        searchText = ""; // Not used when using creator ID
        creatorId = searchParams.creatorId;
        break;
      default:
        throw new Error("Invalid search mode");
    }

    setRightLoadingMore(true);

    try {
      const response = await embeddingSearch(
        searchText,
        rightEndpoint,
        searchParams.numResults,
        isDesc,
        rightNextCursor,
        undefined,
        searchParams.evaluationSetId ? String(searchParams.evaluationSetId) : undefined,
        useCreatorSetAsQuery,
        creatorSetId,
        searchParams.enableEvaluation,
        creatorId
      );

      if (response.success) {
        setRightResults(prev => ({
          ...prev,
          creators: [...prev.creators, ...response.creator_details],
          hasMore: response.pagination?.has_more,
          nextCursor: response.pagination?.next_cursor,
        }));
        setRightNextCursor(response.pagination?.next_cursor);
      } else {
        console.error("Failed to load more right results:", response.message);
      }
    } catch (err) {
      console.error("Error loading more right results:", err);
    } finally {
      setRightLoadingMore(false);
    }
  };

  // Calculate differences between results
  const leftDifferences = useMemo(() => {
    if (!showDifferencesOnly || leftResults.creators.length === 0 || rightResults.creators.length === 0) {
      return leftResults.creators;
    }

    // Get creator IDs from right results
    const rightCreatorIds = new Set(rightResults.creators.map(creator => creator.creator_id));
    
    // Filter left creators that don't appear in right results
    return leftResults.creators.filter(creator => !rightCreatorIds.has(creator.creator_id));
  }, [leftResults.creators, rightResults.creators, showDifferencesOnly]);

  const rightDifferences = useMemo(() => {
    if (!showDifferencesOnly || leftResults.creators.length === 0 || rightResults.creators.length === 0) {
      return rightResults.creators;
    }

    // Get creator IDs from left results
    const leftCreatorIds = new Set(leftResults.creators.map(creator => creator.creator_id));
    
    // Filter right creators that don't appear in left results
    return rightResults.creators.filter(creator => !leftCreatorIds.has(creator.creator_id));
  }, [leftResults.creators, rightResults.creators, showDifferencesOnly]);

  // Calculate total differences count for the badge
  const differencesCount = leftDifferences.length + rightDifferences.length;
  const hasDifferences = differencesCount > 0;

  return (
    <Box style={{ maxWidth: "1600px", margin: "0 auto", padding: 20 }}>
      <Title order={2} mb="md">
        Semantic Creator Search Demo
      </Title>

      <SearchForm
        searchParams={searchParams}
        leftEndpoint={leftEndpoint}
        rightEndpoint={rightEndpoint}
        onSearchParamsChange={handleSearchParamsChange}
        onEndpointChange={handleEndpointChange}
        onSubmit={handleSearch}
        loading={loading}
        loadingIndexTypes={loadingIndexTypes}
        indexTypes={indexTypes}
        showDifferencesOnly={showDifferencesOnly}
        setShowDifferencesOnly={setShowDifferencesOnly}
        hasDifferences={hasDifferences}
        differencesCount={differencesCount}
      />

      {/* Show indication of skipped results if any */}
      {searchParams.skipResults > 0 && !loading && (leftResults.creators.length > 0 || rightResults.creators.length > 0) && (
        <Alert icon={<IconAlertCircle size="1rem" />} color="blue" mb="md">
          Showing results starting after position {searchParams.skipResults}
        </Alert>
      )}

      <Grid>
        <Grid.Col span={6}>
          <ResultsColumn
            results={leftResults}
            title={`${indexTypes.find(type => type.value === leftEndpoint)?.label || "Left"} Results`}
            creatorsToDisplay={showDifferencesOnly ? leftDifferences : leftResults.creators}
            hasMore={!showDifferencesOnly && leftNextCursor !== undefined}
            isLoadingMore={leftLoadingMore}
            showDifferencesOnly={showDifferencesOnly}
            onLoadMore={handleLoadMoreLeft}
            loading={loading}
            indexType={leftEndpoint}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <ResultsColumn
            results={rightResults}
            title={`${indexTypes.find(type => type.value === rightEndpoint)?.label || "Right"} Results`}
            creatorsToDisplay={showDifferencesOnly ? rightDifferences : rightResults.creators}
            hasMore={!showDifferencesOnly && rightNextCursor !== undefined}
            isLoadingMore={rightLoadingMore}
            showDifferencesOnly={showDifferencesOnly}
            onLoadMore={handleLoadMoreRight}
            loading={loading}
            indexType={rightEndpoint}
          />
        </Grid.Col>
      </Grid>
    </Box>
  );
}