import React from "react";

import { Badge } from "@mantine/core";
import { IconMessageChatbot, IconVideoPlus, IconVideo, IconLink, IconLockOpen, IconFileText } from "@tabler/icons-react";

import { TaskStage } from "components/contracts/tasks/models/Common";

export default function TaskTypeBadge({ taskType }: { taskType: TaskStage }) {
  if (taskType === TaskStage.CREATOR_RATINGS) {
    return (
      <Badge leftSection={<IconMessageChatbot size={16} />} variant="light">
        Creator Ratings
      </Badge>
    );
  } else if (taskType === TaskStage.LIVE_VERIFICATION) {
    return (
      <Badge leftSection={<IconVideoPlus size={16} />} variant="light">
        Live Verification
      </Badge>
    );
  } else if (taskType === TaskStage.CONTENT_PRODUCTION || taskType === TaskStage.MESSAGES) {
    return (
      <Badge leftSection={<IconVideo size={16} />} variant="light">
        Content Production
      </Badge>
    );
  } else if (taskType === TaskStage.CODES_LINKS) {
    return (
      <Badge leftSection={<IconLink size={16} />} variant="light">
        Codes & Links
      </Badge>
    );
  } else if (taskType === TaskStage.PRODUCT_ACCESS) {
    return (
      <Badge leftSection={<IconLockOpen size={16} />} variant="light">
        Product Access
      </Badge>
    );
  } else if (taskType === TaskStage.UNIFIED_CONTRACT_REVIEW) {
    return (
      <Badge leftSection={<IconFileText size={16} />} variant="light">
        Contract Review
      </Badge>
    );
  }
  return null;
}
