/** eslint-disable @typescript-eslint/no-unused-vars */
/** eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Button, Flex, NativeSelect, Table, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { User } from "firebase/auth";

import { AdGroupEditDrawer } from "campaigns/main/campaignPage/ad_groups/AdGroupEditDrawer";
import { AdGroupStatusIcon } from "campaigns/main/campaignPage/ad_groups/AdGroupStatusIcon";
import { CreatorSet } from "components/discovery/Datamodels";
import { Campaign, CampaignAdGroup, CampaignAdGroupStatus } from "models/Campaign";
import { CreativeBrief } from "models/CreativeBrief";

export const AdGroupTableRow = ({
  user,
  campaignHashId,
  campaign,
  adGroup,
  creatorSets,
  creativeBriefs,
  readOnly = false,
  adGroupCreatorSets,
}: {
  user: User;
  campaignHashId: number;
  campaign: Campaign;
  adGroup: CampaignAdGroup;
  creatorSets: CreatorSet[];
  creativeBriefs: CreativeBrief[];
  readOnly?: boolean;
  adGroupCreatorSets: CreatorSet[];
}) => {
  if (!adGroup) {
    return null;
  }

  const [opened, { open, close }] = useDisclosure(false);

  const initialObj = {
    name: adGroup.name,
    status: adGroup?.status.toString() || CampaignAdGroupStatus.Draft.toString(),
    landing_page: adGroup.landing_page ? adGroup.landing_page : "",
    blurb: adGroup.blurb,
    brand_blurb: adGroup.brand_blurb,
    creator_sets: adGroupCreatorSets?.map((creatorSet) => creatorSet.id.toString()),
    brief_id: adGroup.brief_id?.toString() || "",
    target_cpm: adGroup.target_cpm,
  };

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialObj);

  const form = useForm({
    initialValues: initialObj,
  });

  // update form when adGroupCreatorSets changes
  useEffect(() => {
    if (adGroupCreatorSets) {
      form.setValues({
        ...form.values,
        creator_sets: adGroupCreatorSets.map((creatorSet) => creatorSet.id.toString()),
      });
    }
  }, [adGroupCreatorSets]);

  const isInherited = (blurb: string) =>
    form.values.blurb == null ||
    form.values.blurb === "" ||
    form.values.blurb === campaign.brand?.company_blurb;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blurbIsInherited, setBlurbIsInherited] = useState(isInherited(adGroup.blurb));

  useEffect(() => {
    setBlurbIsInherited(isInherited(form.values.blurb));
  }, [form.values]);

  const creatorSetIdToName = creatorSets.reduce((acc: { [key: number]: string }, creatorSet) => {
    acc[creatorSet.id] = creatorSet.name;
    return acc;
  }, {});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formatValue = (key: string, value: any) => {
    if (key === "status") {
      return CampaignAdGroupStatus[value];
    } else if (key === "creator_sets") {
      return value.map((id: number) => creatorSetIdToName[id]).join(", ");
    }
    return value;
  };

  return (
    <>
      <AdGroupEditDrawer
        opened={opened}
        close={close}
        title={<Title order={3}>Ad Group Settings ({adGroup.id})</Title>}
        user={user}
        campaignHashId={campaignHashId}
        campaign={campaign}
        adGroup={adGroup}
        creatorSets={creatorSets}
        creativeBriefs={creativeBriefs}
        adGroupCreatorSets={adGroupCreatorSets}
      />
      <Table.Tr key={`adgroup-table-${adGroup.id}`}>
        <Table.Td>{adGroup.id}</Table.Td>
        <Table.Td>
          <TextInput
            placeholder="Test adgroup name"
            /* eslint-disable react/jsx-props-no-spreading */
            {...form.getInputProps("name")}
            disabled
          />
        </Table.Td>
        <Table.Td>
          <NativeSelect
            data={Object.keys(CampaignAdGroupStatus)
              .filter((k) => typeof CampaignAdGroupStatus[k as any] === "number")
              .map((key) => ({ label: key, value: CampaignAdGroupStatus[key as any] }))}
            {...form.getInputProps("status")}
            disabled
          />
        </Table.Td>
        <Table.Td>
          <AdGroupStatusIcon campaign={campaign} adGroup={adGroup} />
        </Table.Td>
        <Table.Td>
          <Flex justify="center">
            {/* <Button type="submit" onClick={() => open()} disabled={!form.isDirty()}>
              Save
            </Button> */}
            <Button onClick={() => open()}>Edit</Button>
          </Flex>
        </Table.Td>
      </Table.Tr>
    </>
  );
};

export default AdGroupTableRow;
