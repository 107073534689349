import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { Rating } from "models/Rating";

export async function getCampaignsWithTasks({
  testMode,
  abortController,
}: {
  testMode: boolean;
  abortController?: AbortController;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_campaigns_with_tasks`);
  if (testMode) {
    requestUrl.searchParams.append("testMode", "true");
  }
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getNumTasks({
  campaignHashId,
  testMode,
  abortController,
}: {
  campaignHashId?: string;
  testMode: boolean;
  abortController?: AbortController;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_num_tasks`);
  if (campaignHashId) {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }
  if (testMode) {
    requestUrl.searchParams.append("testMode", "true");
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getTaskDetails({
  taskId,
  abortController,
}: {
  taskId: string;
  abortController?: AbortController;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_task_details`);
  if (taskId) {
    requestUrl.searchParams.append("taskId", taskId);
  }
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getCreatorRatingsTaskDetails({
  campaignHashId, 
  abortController,
  hideStale,
}: {
  campaignHashId?: string;
  abortController?: AbortController;
  hideStale?: boolean;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_creator_ratings_task_details`);
  
  if (campaignHashId) {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }
  if (hideStale) {
    requestUrl.searchParams.append("hideStale", "true");
  }
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getLiveVerificationTaskDetails({
  campaignHashId,
  abortController,
}: {
  campaignHashId?: string;
  abortController?: AbortController;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_live_verification_task_details`);
  if (campaignHashId) {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getContentReviewTaskDetails({
  campaignHashId,
  abortController,
}: {
  campaignHashId?: string;
  abortController?: AbortController;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_content_review_task_details`);
  if (campaignHashId) {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getUnreadMessageTaskDetails({
  campaignHashId,
  abortController,
}: {
  campaignHashId?: string;
  abortController?: AbortController;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_unread_message_task_details`);
  if (campaignHashId) {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getContractReviewTaskDetails({
  campaignHashId,
  abortController,
}: {
  campaignHashId?: string;
  abortController?: AbortController;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_contract_review_task_details`);
  if (campaignHashId) {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getContractOfferReviewTaskDetails({
  campaignHashId,
  abortController,
}: {
  campaignHashId?: string;
  abortController?: AbortController;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_contract_offer_review_task_details`);
  if (campaignHashId) {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getCodeAndLinkTaskDetails({
  campaignHashId,
  abortController,
}: {
  campaignHashId?: string;
  abortController?: AbortController;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_code_and_link_task_details`);
  if (campaignHashId) {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getProductAccessTaskDetails({
  campaignHashId,
  abortController,
}: {
  campaignHashId?: string;
  abortController?: AbortController;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_product_access_task_details`);
  if (campaignHashId) {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function approveLiveUrl({ taskId }: { taskId: string }) {
  const requestUrl = new URL(`${API_URL}/api/tasks/approve_live_content`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function requestLiveUrlRevision({
  taskId,
  disputeReason,
}: {
  taskId: string;
  disputeReason: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/request_live_content_revision`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      disputeReason,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function approveContent({
  taskId,
  messageText,
}: {
  taskId: string;
  messageText: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/approve_content`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      messageText,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function requestContentRevision({
  taskId,
  messageText,
}: {
  taskId: string;
  messageText: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/request_content_revision`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      messageText,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function sendMessageToCreator({
  taskId,
  messageText,
}: {
  taskId: string;
  messageText: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/send_message_to_creator`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      messageText,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function markMessagesRead({ taskId }: { taskId: string }) {
  const requestUrl = new URL(`${API_URL}/api/tasks/mark_messages_read`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({ taskId }),
  });

  const response = await handleResult(request);
  return response;
}

export async function addReferralLinkToDeliverable({
  taskId,
  url,
}: {
  taskId: string;
  url: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/add_referral_link_to_deliverable`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      url,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function addPromoCodeToDeliverable({
  taskId,
  promoCode,
}: {
  taskId: string;
  promoCode: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/add_promo_code_to_deliverable`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      promoCode,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function saveBrandProductAccessInfo({
  taskId,
  productAccessBrandOutput,
}: {
  taskId: string;
  productAccessBrandOutput: string | object;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/submit_product_access_info`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      productAccessBrandOutput,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function reportIssueWithProductAccessInfo({
  taskId,
  message,
}: {
  taskId: string;
  message: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/report_issue_with_product_access_info`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      message,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function approveContractReview({
  taskId,
  feedback,
  maxOfferCap,
}: {
  taskId: string;
  feedback: string;
  maxOfferCap: number;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/approve_contract_review`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      feedback,
      maxOfferCap,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function approveContractOfferReview({
  taskId,
  packageApproval,
}: {
  taskId: string;
  packageApproval: {
    [uuid: string]: { brand_approved: boolean; max_offer_cap: number; feedback: string };
  };
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/approve_contract_offer_review`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      packageApproval,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function rejectContractReview({
  taskId,
  feedback,
}: {
  taskId: string;
  feedback: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/reject_contract_review`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      feedback,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function rejectContractOfferReview({ taskId }: { taskId: string }) {
  const requestUrl = new URL(`${API_URL}/api/tasks/reject_contract_offer_review`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function submitCreatorRatings({
  contractId,
  starValue,
  tags,
  other_notes,
}: {
  contractId: string;
  starValue: number;
  tags: string[];
  other_notes: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/submit_creator_ratings/`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
      starValue,
      tags,
      otherNotes: other_notes,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export default null;
