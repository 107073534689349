import React, { FC, useMemo } from 'react';
import {
  Card,
  Text,
  useMantineTheme,
  Box,
} from '@mantine/core';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

interface ROCPoint {
  fpr: number;
  tpr: number;
  threshold: number;
}

interface ROCData {
  roc_curve: ROCPoint[];
  roc_auc: number;
}

interface ROCCurveChartProps {
  data: ROCData;
}

const ROCCurveChart: FC<ROCCurveChartProps> = ({ data }) => {
  const theme = useMantineTheme();

  // Format the data for the chart
  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          label: 'ROC Curve',
          // Use x,y coordinates format for consistency
          data: data.roc_curve.map((point) => ({
            x: point.fpr,
            y: point.tpr
          })),
          borderColor: theme.colors.blue[6],
          backgroundColor: theme.colors.blue[4],
          fill: false,
          pointRadius: 0,
          borderWidth: 2.5,
        },
        // Add diagonal reference line (random classifier)
        {
          label: 'Random Classifier',
          data: [
            { x: 0, y: 0 },
            { x: 1, y: 1 }
          ],
          borderColor: 'rgba(200, 200, 200, 0.7)',
          borderDash: [5, 5],
          fill: false,
          pointRadius: 0,
        },
      ],
    };
  }, [data.roc_curve, theme]);

  // Chart configuration
  const chartOptions = useMemo(() => {
    return {
      scales: {
        x: {
          type: 'linear' as const,
          title: { display: true, text: 'False Positive Rate' },
          min: 0,
          max: 1,
        },
        y: {
          type: 'linear' as const,
          title: { display: true, text: 'True Positive Rate' },
          min: 0,
          max: 1,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label(context: any) {
              const idx = context.dataIndex;
              // Skip if it's the reference line
              if (context.dataset.label === 'Random Classifier') {
                return 'Random Classifier';
              }
              
              const point = data.roc_curve[idx];
              const tpr = point.tpr.toFixed(3);
              const fpr = point.fpr.toFixed(3);
              const threshold = point.threshold.toFixed(3);
              return `TPR: ${tpr}, FPR: ${fpr}, Thresh: ${threshold}`;
            },
          },
        },
        legend: {
          position: 'bottom' as const,
        },
        title: {
          display: false,
        },
      },
    };
  }, [data.roc_curve]);

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Box mb={theme.spacing.md}>
        <Text fw={500} size="lg">
          ROC Curve
        </Text>
        <Text c="dimmed" size="sm">
          AUC: {data.roc_auc.toFixed(3)}
        </Text>
      </Box>

      <Line data={chartData} options={chartOptions} />
    </Card>
  );
};

export default ROCCurveChart; 