import { ShortLinkStatus } from "components/contracts/common/Common";

import {
  SupportedFormat,
  SupportedFormatIntegers,
  SupportedPlatform,
  UsageRightsDuration,
} from "models/Common";

import { fromISODateString, toISODateString } from "utils/DateUtils";

import DeliverableTimeline from "components/contracts/contract/DeliverableTimeline";

// Keep in sync with ContractDeliverableStatus in campaigns/models.py
export enum ContractDeliverableStatus {
  NOT_STARTED = "not_started",

  WAITING_FOR_SCRIPT = "waiting_for_script",
  SCRIPT_SUBMITTED = "script_submitted",
  SCRIPT_REVISIONS_REQUESTED = "script_revisions_requested",
  SCRIPT_APPROVED = "script_approved",

  WAITING_FOR_VIDEO_DRAFT = "waiting_for_video_draft",
  VIDEO_DRAFT_SUBMITTED = "video_draft_submitted",
  VIDEO_DRAFT_REVISIONS_REQUESTED = "video_draft_revisions_requested",
  VIDEO_DRAFT_APPROVED = "video_draft_approved",

  WAITING_FOR_LIVE_CONTENT = "waiting_for_live_content",
  LIVE_CONTENT_SUBMITTED = "live_content_submitted",
  LIVE_CONTENT_REVISIONS_REQUESTED = "live_content_revisions_requested",
  LIVE_CONTENT_APPROVED = "live_content_approved",

  WAITING_FOR_ASSETS_AND_ANALYTICS = "waiting_for_assets_and_analytics",
  WAITING_FOR_ASSETS = "waiting_for_assets",
  WAITING_FOR_ANALYTICS = "waiting_for_analytics",
  ASSETS_AND_ANALYTICS_SUBMITTED = "assets_and_analytics_submitted",
  ASSETS_AND_ANALYTICS_REVISIONS_REQUESTED = "assets_and_analytics_revisions_requested",
  ASSETS_AND_ANALYTICS_APPROVED = "assets_and_analytics_approved",

  COMPLETE = "complete",
}

export const ContractDeliverableStatusMapping: { [key in ContractDeliverableStatus]: number } = {
  [ContractDeliverableStatus.NOT_STARTED]: 0,

  [ContractDeliverableStatus.WAITING_FOR_SCRIPT]: 10,
  [ContractDeliverableStatus.SCRIPT_SUBMITTED]: 11,
  [ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED]: 12,
  [ContractDeliverableStatus.SCRIPT_APPROVED]: 13,

  [ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT]: 20,
  [ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED]: 21,
  [ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED]: 22,
  [ContractDeliverableStatus.VIDEO_DRAFT_APPROVED]: 23,

  [ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT]: 30,
  [ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED]: 31,
  [ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED]: 32,
  [ContractDeliverableStatus.LIVE_CONTENT_APPROVED]: 33,

  [ContractDeliverableStatus.WAITING_FOR_ASSETS_AND_ANALYTICS]: 40,
  [ContractDeliverableStatus.WAITING_FOR_ASSETS]: 41,
  [ContractDeliverableStatus.WAITING_FOR_ANALYTICS]: 42,
  [ContractDeliverableStatus.ASSETS_AND_ANALYTICS_SUBMITTED]: 43,
  [ContractDeliverableStatus.ASSETS_AND_ANALYTICS_REVISIONS_REQUESTED]: 44,
  [ContractDeliverableStatus.ASSETS_AND_ANALYTICS_APPROVED]: 45,

  [ContractDeliverableStatus.COMPLETE]: 50,
};

export const ReverseContractDeliverableStatusMapping: { [key: number]: ContractDeliverableStatus } =
  Object.entries(ContractDeliverableStatusMapping).reduce((acc, [key, value]) => {
    acc[value] = key as ContractDeliverableStatus;
    return acc;
  }, {} as { [key: number]: ContractDeliverableStatus });

// Lightweight deliverable configs for passing deliverable data
// from pricing calculator to contract creation
export interface DeliverableConfigs {
  format: SupportedFormatIntegers;
  include_script_review: boolean;
  include_video_review: boolean;
  include_usage_rights: boolean;
  usage_rights_duration: UsageRightsDuration | null;
  usage_rights_in_perpetuity: boolean;
}

export default class Deliverable {
  id: string;

  contractId: string;

  contractAmount?: number;

  contractDeliverableCount?: number;

  lastUpdated: Date;

  platform: SupportedPlatform;

  creatorHandle: string;

  profileLink: string;

  format: SupportedFormat;

  dateCreated: Date;

  timeline: DeliverableTimeline;

  actionRequired: boolean;

  nextDueDate: Date;

  status: ContractDeliverableStatus;

  usageRightsDays: number;

  usageRightsInPerpetuity: boolean;

  usageRightsExpirationDate: Date;

  brandMessagesLastRead: Date;

  liveContentUrl: string;

  adCode: string;

  assetUrl: string;

  assetName: string;

  reuseFinalDraft: boolean;

  unsubmittedLiveContent: boolean;

  liveContentSubmissionDatetime: Date;

  liveContentReviewDatetime: Date;

  liveContentReviewDeadline: Date;

  liveContentDisputeReason: string;

  requiresReferralLink: boolean;

  missingReferralLink: boolean;

  referralLinkStatus: ShortLinkStatus;

  referralLinkUrl: string;

  referralLinkRedirectUrl: string;

  requiresPromoCode: boolean;

  missingPromoCode: boolean;

  promoCode: string;

  constructor({
    id,
    contractId,
    contractAmount,
    contractDeliverableCount,
    lastUpdated,
    platform,
    creatorHandle,
    profileLink,
    format,
    timeline,
    actionRequired,
    nextDueDate,
    status,
    usageRightsDays,
    usageRightsInPerpetuity,
    usageRightsExpirationDate,
    brandMessagesLastRead,
    liveContentUrl,
    adCode,
    assetUrl,
    assetName,
    reuseFinalDraft,
    unsubmittedLiveContent,
    liveContentSubmissionDatetime,
    liveContentReviewDatetime,
    liveContentReviewDeadline,
    liveContentDisputeReason,
    requiresReferralLink,
    missingReferralLink,
    referralLinkStatus,
    referralLinkUrl,
    referralLinkRedirectUrl,
    requiresPromoCode,
    missingPromoCode,
    promoCode,
  }: {
    id: string;
    contractId: string;
    contractAmount?: number;
    contractDeliverableCount?: number;
    lastUpdated: Date;
    platform: SupportedPlatform;
    creatorHandle: string;
    profileLink: string;
    format: SupportedFormat;
    timeline: DeliverableTimeline;
    actionRequired: boolean;
    nextDueDate: Date;
    status: ContractDeliverableStatus;
    usageRightsDays: number;
    usageRightsInPerpetuity: boolean;
    usageRightsExpirationDate: Date;
    brandMessagesLastRead: Date;
    liveContentUrl: string;
    adCode: string;
    assetUrl: string;
    assetName: string;
    reuseFinalDraft: boolean;
    unsubmittedLiveContent: boolean;
    liveContentSubmissionDatetime: Date;
    liveContentReviewDatetime: Date;
    liveContentReviewDeadline: Date;
    liveContentDisputeReason: string;
    requiresReferralLink: boolean;
    missingReferralLink: boolean;
    referralLinkStatus: ShortLinkStatus;
    referralLinkUrl: string;
    referralLinkRedirectUrl: string;
    requiresPromoCode: boolean;
    missingPromoCode: boolean;
    promoCode: string;
  }) {
    this.id = id;
    this.contractId = contractId;
    this.contractAmount = contractAmount;
    this.contractDeliverableCount = contractDeliverableCount;
    this.lastUpdated = lastUpdated;
    this.platform = platform;
    this.creatorHandle = creatorHandle;
    this.profileLink = profileLink;
    this.format = format;
    this.timeline = timeline;
    this.actionRequired = actionRequired;
    this.nextDueDate = nextDueDate;
    this.status = status;
    this.usageRightsDays = usageRightsDays;
    this.usageRightsInPerpetuity = usageRightsInPerpetuity;
    this.usageRightsExpirationDate = usageRightsExpirationDate;
    this.brandMessagesLastRead = brandMessagesLastRead;
    this.liveContentUrl = liveContentUrl;
    this.adCode = adCode;
    this.assetUrl = assetUrl;
    this.assetName = assetName;
    this.reuseFinalDraft = reuseFinalDraft;
    this.unsubmittedLiveContent = unsubmittedLiveContent;
    this.liveContentSubmissionDatetime = liveContentSubmissionDatetime;
    this.liveContentReviewDatetime = liveContentReviewDatetime;
    this.liveContentReviewDeadline = liveContentReviewDeadline;
    this.liveContentDisputeReason = liveContentDisputeReason;
    this.requiresReferralLink = requiresReferralLink;
    this.missingReferralLink = missingReferralLink;
    this.referralLinkStatus = referralLinkStatus;
    this.referralLinkUrl = referralLinkUrl;
    this.referralLinkRedirectUrl = referralLinkRedirectUrl;
    this.requiresPromoCode = requiresPromoCode;
    this.missingPromoCode = missingPromoCode;
    this.promoCode = promoCode;
  }

  public static build() {
    return new Deliverable({
      id: "",
      contractId: "",
      contractAmount: 0,
      contractDeliverableCount: 1,
      lastUpdated: null,
      platform: null,
      profileLink: "",
      creatorHandle: "",
      format: null,
      timeline: DeliverableTimeline.build(),
      actionRequired: false,
      nextDueDate: null,
      status: ContractDeliverableStatus.NOT_STARTED,
      usageRightsDays: 0,
      usageRightsInPerpetuity: false,
      usageRightsExpirationDate: null,
      brandMessagesLastRead: null,
      liveContentUrl: "",
      adCode: "",
      assetUrl: "",
      assetName: "",
      reuseFinalDraft: false,
      unsubmittedLiveContent: false,
      liveContentSubmissionDatetime: null,
      liveContentReviewDatetime: null,
      liveContentReviewDeadline: null,
      liveContentDisputeReason: "",
      requiresReferralLink: false,
      missingReferralLink: false,
      referralLinkStatus: null,
      referralLinkUrl: "",
      referralLinkRedirectUrl: "",
      requiresPromoCode: false,
      missingPromoCode: false,
      promoCode: "",
    });
  }

  public validate() {
    return (
      this.platform != null &&
      (this.platform === SupportedPlatform.NONE || this.creatorHandle.length > 0) &&
      (this.platform === SupportedPlatform.NONE || this.profileLink.length > 0) &&
      this.format != null &&
      this.timeline.validate() &&
      this.status != null &&
      this.usageRightsDays >= 0
    );
  }

  public static deserialize(response: any) {
    const lastUpdated = fromISODateString(response.lastUpdated);
    const platform = response.platform as SupportedPlatform;
    const format = response.format as SupportedFormat;
    const nextDueDate = fromISODateString(response.nextDueDateString);
    const {
      id,
      contractId,
      contractAmount,
      contractDeliverableCount,
      creatorHandle,
      profileLink,
      usageRightsDays,
      usageRightsInPerpetuity,
      usageRightsExpirationDate,
      liveContentUrl,
      adCode,
      assetUrl,
      assetName,
      reuseFinalDraft,
      unsubmittedLiveContent,
      liveContentReviewDatetime,
      liveContentReviewDeadline,
      actionRequired,
      liveContentDisputeReason,
      requiresReferralLink,
      missingReferralLink,
      referralLinkStatus,
      referralLinkUrl,
      referralLinkRedirectUrl,
      requiresPromoCode,
      missingPromoCode,
      promoCode,
    } = response;

    const timeline = new DeliverableTimeline({
      format,
      minLiveDate: fromISODateString(response.minLiveDateString),
      maxLiveDate: fromISODateString(response.maxLiveDateString),
      liveDate: fromISODateString(response.liveDateString),
      scriptDate: fromISODateString(response.scriptDateString),
      scriptRevisionDate: fromISODateString(response.scriptRevisionDateString),
      videoDraftDate: fromISODateString(response.videoDraftDateString),
      approvalDate: fromISODateString(response.approvalDateString),
      analyticsDate: fromISODateString(response.analyticsDateString),
      requiresScriptReview: response.requiresScriptReview,
      requiresVideoReview: response.requiresVideoReview,
      editableLiveDate: response.editableLiveDate,
    });

    const status = response.status as ContractDeliverableStatus;
    const brandMessagesLastRead = fromISODateString(response.brandMessagesLastRead);
    const liveContentSubmissionDatetime = fromISODateString(response.liveContentSubmissionDatetime);

    return new Deliverable({
      id,
      contractId,
      contractAmount,
      contractDeliverableCount,
      lastUpdated,
      platform,
      creatorHandle,
      profileLink,
      format,
      timeline,
      actionRequired,
      nextDueDate,
      status,
      usageRightsDays,
      usageRightsInPerpetuity,
      usageRightsExpirationDate,
      brandMessagesLastRead,
      liveContentUrl,
      adCode,
      assetUrl,
      assetName,
      reuseFinalDraft,
      unsubmittedLiveContent,
      liveContentSubmissionDatetime,
      liveContentReviewDatetime,
      liveContentReviewDeadline,
      liveContentDisputeReason,
      requiresReferralLink,
      missingReferralLink,
      referralLinkStatus,
      referralLinkUrl,
      referralLinkRedirectUrl,
      requiresPromoCode,
      missingPromoCode,
      promoCode,
    });
  }

  public isPostOrCarousel() {
    return (
      this.platform === SupportedPlatform.INSTAGRAM &&
      (this.format === SupportedFormat.INSTAGRAM_DEDICATED_POST ||
        this.format === SupportedFormat.INSTAGRAM_CAROUSEL)
    );
  }

  public serialize() {
    const minLiveDateString = this.timeline.minLiveDate
      ? toISODateString(this.timeline.minLiveDate)
      : "";
    const maxLiveDateString = this.timeline.maxLiveDate
      ? toISODateString(this.timeline.maxLiveDate)
      : "";
    const liveDateString = toISODateString(this.timeline.liveDate);
    const scriptDateString = this.timeline.requiresScriptReview
      ? toISODateString(this.timeline.scriptDate)
      : "";
    const scriptRevisionDateString = this.timeline.requiresScriptReview
      ? toISODateString(this.timeline.scriptRevisionDate)
      : "";
    const videoDraftDateString = this.timeline.requiresVideoReview
      ? toISODateString(this.timeline.videoDraftDate)
      : "";
    const approvalDateString = this.timeline.requiresVideoReview
      ? toISODateString(this.timeline.approvalDate)
      : "";
    const analyticsDateString = toISODateString(this.timeline.analyticsDate);

    return {
      platform: this.platform,
      format: this.format,
      creatorHandle: this.creatorHandle,
      profileLink: this.profileLink,
      minLiveDateString,
      maxLiveDateString,
      liveDateString,
      scriptDateString,
      scriptRevisionDateString,
      videoDraftDateString,
      approvalDateString,
      analyticsDateString,
      status: this.status,
      usageRightsDays: this.usageRightsDays,
      usageRightsInPerpetuity: this.usageRightsInPerpetuity,
      requiresScriptReview: this.timeline.requiresScriptReview,
      requiresVideoReview: this.timeline.requiresVideoReview,
      editableLiveDate: this.timeline.editableLiveDate,
    };
  }
}
