import React, { useState } from "react";
import {
  Avatar,
  Chip,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  Tooltip
} from "@mantine/core";
import Star from "components/ratings/Star";
import { Rating } from "models/Rating";

type RatingsListViewModalProps = {
  opened: boolean,
  close: () => void,
  creatorName: string,
  creatorAvatar?: string,
  creatorRatings: Rating[],
}

const RatingsListViewModal = ({ 
  opened, 
  close, 
  creatorName,
  creatorAvatar,
  creatorRatings  
}: RatingsListViewModalProps) => {
  const stars = Array.from({ length: 5 }, (_, index) => index + 1);
  const [scrolled, setScrolled] = useState(false);
  const getFormattedDate = (date: string) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };
  const rows = creatorRatings.map((rating, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Table.Tr key={index} style={{ height: "40px" }}>
      <Tooltip label={getFormattedDate(rating.createdAt)} withArrow offset={-20}>
        <Table.Td style={{ height: "80px", padding: "8px", verticalAlign: "middle", textAlign: "center" }}>
          {stars.map((star) => 
            <Star 
              active={star <= rating.starValue}
              key={star}
              clickable={false}
              size={24}
            />
          )}
        </Table.Td>
      </Tooltip>
      <Table.Td style={{
        padding: "8px",
        verticalAlign: "middle",
        textAlign: "center",
        overflowY: "auto",
        maxHeight: "80px",
      }}>{
        rating.tags?.length > 0 ? rating.tags?.map((tag) => (
          <Chip
            key={tag}
            variant="outline"
            checked={false}
            disabled
            styles={{
              label: {
                backgroundColor: 'inherit',
                borderColor: '#CED4DA',
                color: 'black',
                cursor: 'default'
              },
              root: {
                opacity: 1
              }
          }}>{tag}</Chip>
        )) : <Text c="dimmed">No tags.</Text>
      }</Table.Td>
      <Table.Td style={{ height: "80px", padding: "8px", verticalAlign: "middle", textAlign: "center" }}>{
        rating.description ? rating.description : <Text c="dimmed">No description.</Text>
      }</Table.Td>
    </Table.Tr>
  ));

  return (
    <Modal 
      title="Creator Rating"
      size="auto"
      opened={opened}
      onClose={close}
      styles={{
        title: {
          fontWeight: 700,
          fontSize: "1.25rem"
        }
      }}
      removeScrollProps={{
        allowPinchZoom: true,
        removeScrollBar: false
      }}
    >
      <Stack gap="sm"
        align="center"
        justify="center"
        bg="#F1F3F5"
        p="md"
      >
        <Avatar 
          src={creatorAvatar} 
          size="xl" 
          radius="100%"
        />
        <Text fw={600} size="xl">{creatorName}</Text>
      </Stack>
      <Stack
        align="center"
        justify="center"
        bg="white"
        py="md"
      >
        <ScrollArea h={300} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
          <Table withTableBorder withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th px="5rem">Star</Table.Th>
                <Table.Th px="5rem">Tags</Table.Th>
                <Table.Th px="5rem">Description</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </ScrollArea> 
      </Stack>
    </Modal>
  );
}

export default RatingsListViewModal;