import React, { useState } from "react";
import { Button, Flex, Group, MantineColor } from "@mantine/core";
import {
  IconCheck,
  IconPlus,
  IconBookmark,
  IconX,
  IconCircleCheck,
  IconExclamationCircle,
} from "@tabler/icons-react";
import { CreatorSetEntryState } from "components/discovery/Datamodels";
import { notifications } from "@mantine/notifications";
import { recordCreatorAction } from "components/discovery/embedding_search/EmbeddingDemoAPI";

interface SimpleActionButtonProps {
  color: MantineColor;
  icon: React.ReactNode;
  onClick: () => void;
  text: string;
  disabled?: boolean;
  loading?: boolean;
}

const SimpleActionButton: React.FC<SimpleActionButtonProps> = ({
  color,
  icon,
  onClick,
  text,
  disabled = false,
  loading = false,
}) => (
  <Button
    disabled={disabled || loading}
    variant="light"
    size="sm"
    radius="sm"
    color={color}
    onClick={onClick}
    leftSection={icon}
    loading={loading}
    styles={{
      root: {
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
        transition: "transform 0.2s ease, box-shadow 0.2s ease",
        fontWeight: 600,
        "&:hover": {
          transform: "translateY(-1px)",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.05)",
        },
      },
    }}>
    {text}
  </Button>
);

interface SimpleActionRowsProps {
  creatorSetId: number;
  creatorId: number;
  onCreatorStateChanged: (
    creatorId: number,
    entryState: CreatorSetEntryState,
    notes: string | null,
  ) => void;
  sessionId: string;
  position: number;
  surface: "search" | "browse" | "related";
  onActionStarted?: () => void;
  creatorName?: string;
  creatorSetName?: string;
}

export const SimpleActionRows: React.FC<SimpleActionRowsProps> = ({
  creatorSetId,
  creatorId,
  onCreatorStateChanged,
  sessionId,
  position,
  surface,
  onActionStarted,
  creatorName = "Creator",
  creatorSetName = "Creator Set",
}) => {
  // Add loading state to track which button is currently loading
  const [loadingState, setLoadingState] = useState<CreatorSetEntryState | null>(null);

  // Map from CreatorSetEntryState to the new action strings and friendly names
  const actionMap: Record<number, { action: string; friendlyName: string }> = {
    [CreatorSetEntryState.ACCEPTED]: { action: "activate", friendlyName: "Activated" },
    [CreatorSetEntryState.REJECTED]: { action: "reject", friendlyName: "Rejected" },
    [CreatorSetEntryState.SAVED]: { action: "save", friendlyName: "Saved" },
    [CreatorSetEntryState.WORKED_WITH]: {
      action: "existing_partner",
      friendlyName: "Marked as Existing Partner",
    },
  };

  // Helper function to get notification color based on state
  const getNotificationColor = (state: CreatorSetEntryState): MantineColor => {
    switch (state) {
      case CreatorSetEntryState.ACCEPTED:
        return "green";
      case CreatorSetEntryState.REJECTED:
        return "red";
      case CreatorSetEntryState.SAVED:
        return "blue";
      case CreatorSetEntryState.WORKED_WITH:
        return "gray";
      default:
        return "blue";
    }
  };

  const submitCreatorSetState = async (entryState: CreatorSetEntryState) => {
    // Set loading state for the clicked button
    setLoadingState(entryState);

    // Trigger animation in parent component
    if (onActionStarted) {
      onActionStarted();
    }

    try {
      // Check if we have a mapping for this state
      if (!actionMap[entryState]) {
        console.warn(`No action mapping for creator set entry state: ${entryState}`);
        notifications.show({
          color: "red",
          title: "Action Not Supported",
          message: `This action is not available for ${creatorName} in the current view`,
          icon: <IconExclamationCircle />,
          withBorder: true,
          autoClose: 5000,
        });
        setLoadingState(null);
        return;
      }

      if (!sessionId) {
        console.error("Session ID is required but was not provided");
        notifications.show({
          color: "red",
          title: "Session Error",
          message: `Cannot update ${creatorName}. Please refresh and try again.`,
          icon: <IconExclamationCircle />,
          withBorder: true,
          autoClose: 5000,
        });
        setLoadingState(null);
        return;
      }

      const { action, friendlyName } = actionMap[entryState];
      const response = await recordCreatorAction(
        sessionId,
        String(creatorSetId),
        String(creatorId),
        action as "activate" | "reject" | "save" | "existing_partner",
        position,
        surface,
      );

      if (response && response.success) {
        // Slight delay to allow animation to complete
        setTimeout(() => {
          onCreatorStateChanged(creatorId, entryState, null);
        }, 300);
        // Determine message based on entry state
        let message = `${creatorName} has been ${friendlyName.toLowerCase()} successfully`;
        if (entryState !== CreatorSetEntryState.WORKED_WITH) {
          message = `${creatorName} has been ${friendlyName.toLowerCase()} successfully in ${creatorSetName}`;
        }

        // Determine icon based on entry state
        let icon = <IconCircleCheck size={20} />;
        if (entryState === CreatorSetEntryState.ACCEPTED) {
          icon = <IconCircleCheck size={20} />;
        } else if (entryState === CreatorSetEntryState.REJECTED) {
          icon = <IconX size={20} />;
        } else if (entryState === CreatorSetEntryState.SAVED) {
          icon = <IconBookmark size={20} />;
        } else if (entryState === CreatorSetEntryState.WORKED_WITH) {
          icon = <IconPlus size={20} />;
        }

        notifications.show({
          color: getNotificationColor(entryState),
          title: `${friendlyName}`,
          message,
          icon,
          withBorder: true,
          autoClose: 4000,
        });
      } else {
        notifications.show({
          color: "red",
          title: `Failed to ${action.replace('_', ' ')}`,
          message: response?.message || `Could not ${action.replace('_', ' ')} ${creatorName}. Please try again.`,
          icon: <IconExclamationCircle size={20} />,
          withBorder: true,
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error updating creator state:", error);
      notifications.show({
        color: "red",
        title: "System Error",
        message: `Could not update ${creatorName}. Please try again or contact support.`,
        icon: <IconExclamationCircle size={20} />,
        withBorder: true,
        autoClose: 5000,
      });
    } finally {
      // Clear loading state when operation completes
      setLoadingState(null);
    }
  };

  return (
    <Flex
      align="center"
      h="100%"
      pr="var(--mantine-spacing-lg)"
      pl="var(--mantine-spacing-lg)"
      justify="flex-end"
      style={{
        backgroundColor: "white",
        borderRadius: "0 0 8px 8px",
        padding: "12px 16px",
        borderTop: "1px solid var(--mantine-color-gray-2)",
      }}>
      <Group gap="sm">
        <SimpleActionButton
          onClick={() => submitCreatorSetState(CreatorSetEntryState.WORKED_WITH)}
          color="gray"
          text="Existing Partner"
          icon={<IconPlus size={18} />}
          loading={loadingState === CreatorSetEntryState.WORKED_WITH}
        />
        <SimpleActionButton
          onClick={() => submitCreatorSetState(CreatorSetEntryState.REJECTED)}
          color="red"
          text="Reject"
          icon={<IconX size={18} />}
          loading={loadingState === CreatorSetEntryState.REJECTED}
        />
        <SimpleActionButton
          onClick={() => submitCreatorSetState(CreatorSetEntryState.SAVED)}
          color="blue"
          text="Save"
          icon={<IconBookmark size={18} />}
          loading={loadingState === CreatorSetEntryState.SAVED}
        />
        <SimpleActionButton
          onClick={() => submitCreatorSetState(CreatorSetEntryState.ACCEPTED)}
          color="green"
          text="Activate"
          icon={<IconCheck size={18} />}
          loading={loadingState === CreatorSetEntryState.ACCEPTED}
        />
      </Group>
    </Flex>
  );
};

export default SimpleActionRows;
