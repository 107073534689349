import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Chip,
  Flex,
  Group,
  Image,
  Modal,
  Space,
  Text,
  Textarea,
  Title,
  Tooltip
} from "@mantine/core";

import {
  IconInfoCircle
} from "@tabler/icons-react";

import successImage from "components/ratings/assets/success.svg"
import Stars from "components/ratings/Stars";
import { Rating, POSITIVE_TAGS, NEGATIVE_TAGS } from "models/Rating";

type RatingsModalProps = {
  opened: boolean,
  close: () => void,
  handleSubmit: (rating: Rating) => void | Promise<void>
}

const RatingsModal = ({ opened, close, handleSubmit }: RatingsModalProps) => {
  const [rating, setRating] = useState<Rating>({
    "starValue": null,
    "tags": [],
    "description": ""
  });

  const isFiveStars = rating.starValue === 5;
  const isOther = rating.tags.includes("Other");
  const tags = isFiveStars ? POSITIVE_TAGS : NEGATIVE_TAGS;

  const handleStarClick = (starValue: number) => {
    if (starValue === rating.starValue) {
      return setRating({
        ...rating,
        "starValue": 0,
        "tags": [],
        "description": ""
      });
    }
    return setRating({
      ...rating,
      "starValue": starValue,
      "tags": [],
      "description": ""
    })
  }

  const handleTagClick = (newTags: string[]) => {
    setRating({...rating, tags: newTags})
  }

  return (
    <Modal 
      size="30rem"
      opened={opened}
      onClose={close}
      removeScrollProps={{
        allowPinchZoom: true,
        removeScrollBar: false
      }}
    >
      <Center>
        <Flex 
            direction="column"
            gap="lg"
            align="center"
        >
          <Flex 
            direction="column"
            align="center"
            justify="center"
          >
            <Image src={successImage} />
            <Title size="h3">
              Partnership completed!
            </Title>
          </Flex>
          <Flex
            direction="column"
            justify="space-between"
          >
            <Flex>
              <Text>
                How likely are you to work with this creator again?
              </Text>
              <Center pl="xs">
                <Tooltip 
                  multiline
                  arrowSize={4} 
                  w={220}
                  label={
                    <Text ta="center" size="xs">
                      Your ratings will be aggregated and anonymized. Creators cannot see how you rate them.
                    </Text>
                  }
                  color="#868E96" 
                  withArrow>
                  <IconInfoCircle size={18} color="#868E96"/>
                </Tooltip>
              </Center>
            </Flex>
            <Space h="sm"/>
            <Center>
              <Stars 
                rating={rating}
                handleStarClick={handleStarClick}
              />
            </Center>
          </Flex>         
          {rating.starValue > 0 && <Flex 
            direction="column"
            align="center"
            justify="center"
          >
            <Flex>
              <Text>
                {isFiveStars
                  ? "What did you like about this partnership?"
                  : "What issues did you encounter in this partnership?"}
              </Text>
            </Flex>
            <Chip.Group multiple value={rating.tags} onChange={(newTags) => handleTagClick(newTags)}>
              <Group justify="center" mt="md">
                {tags.map((tag) => 
                  <Chip key={tag} variant="outline" value={tag}>{tag}</Chip>
                )}
              </Group>
            </Chip.Group>
          </Flex>}
          {isOther && <Textarea
            w="100%"
            label="Add reasons for 'Other':"
            value={rating.description}
            onChange={(event) => setRating({
              ...rating,
              description: event.currentTarget.value
            })}
          />}                
          <Button 
            onClick={() => handleSubmit(rating)}
            fullWidth
            color={rating.starValue 
              ? "#228BE6"
              : "#E9ECEF" 
            }>
            <Text c={rating.starValue ? "#FFFFFF" : "#ADB5BD"}>Submit</Text>
          </Button>
        </Flex>
      </Center>
    </Modal>
  );
}

export default RatingsModal;