// contains all routes and resources used for the admin app.

import React from "react";
import { Route } from "react-router-dom";

import Admin from "admin/Admin";
import AdminLayout from "admin/AdminLayout";
import InstagramProfiles from "admin/InstagramProfiles";
import AdminInstagramProfile from "admin/AdminInstagramProfile";
import AdminBrands from "admin/AdminBrands";
import AdminCampaigns from "admin/AdminCampaigns";
import AdminCRM from "admin/AdminCRM";
import AdminLiveContent from "admin/AdminLiveContent";
import CreatorContract from "components/contracts/deliverables/CreatorContract";
import CreateContract from "components/contracts/contract/CreateContract";
import UserTable from "admin/UserTable";
import RelatedCreatorsBackend from "admin/RelatedCreatorsBackend";
import AdminRelatedCreators from "admin/AdminRelatedCreators";
import RelatedCreatorsFrontend from "admin/RelatedCreatorsFrontend";
import AdminUserEdit from "admin/AdminUserEdit";
import AdminDashboard from "components/contracts/dashboard/AdminDashboard";
import ContentReviewFeed from "components/contracts/review/ContentReviewFeed";
import Invoice from "components/contracts/billing/Invoice";
import ViewPrintContract from "components/contracts/contract/ViewPrintContract";

function adminRoutes() {
  return (
    <Route path="/admin" element={<AdminLayout />}>
      <Route index element={<Admin />} />
      <Route path="user_edit" element={<AdminUserEdit />} />
      <Route path="user_table" element={<UserTable />} />
      <Route path="related_creators" element={<AdminRelatedCreators />}>
        <Route path="backend_logs" element={<RelatedCreatorsBackend />} />
        <Route path="frontend_logs" element={<RelatedCreatorsFrontend />} />
      </Route>
      <Route path="create_contract" element={<CreateContract showAdminOptions />} />
      <Route path="dashboard" element={<AdminDashboard />} />
      <Route path="contracts/:contractId" element={<CreatorContract showAdminOptions />} />
      <Route path="export_contract/:contractId" element={<ViewPrintContract />} />
      <Route path="invoice/:invoiceId" element={<Invoice />} />
      <Route path="review/:campaignHashId" element={<ContentReviewFeed showAdminOptions />} />
      <Route path="instagram_profiles" element={<InstagramProfiles />} />
      <Route path="instagram_profile/:username" element={<AdminInstagramProfile />} />
      {/* <Route path="campaigns/settings" element={<AdminCampaignSettings />} /> */}
      <Route path="campaigns" element={<AdminCampaigns />} />
      <Route path="brands" element={<AdminBrands />} />
      <Route path="global_crm" element={<AdminCRM userOnly={false} />} />
      <Route path="crm" element={<AdminCRM userOnly />} />
      <Route path="live" element={<AdminLiveContent />} />
    </Route>
  );
}

export default adminRoutes;
