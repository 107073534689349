import React, { useState } from "react";

import {
  Badge,
  Button,
  Card,
  Flex,
  Grid,
  Group,
  NumberInput,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { IconCheck, IconLink, IconPlus } from "@tabler/icons-react";

import InfoCircleWithTooltipNode from "campaigns/create/common/InfoCircleWithTooltipNode";
import {
  ContractBrandReview,
  ContractReviewDeliverable,
  ContractReviewStatus,
} from "components/contracts/brand_review/types";
import { ContractBrandReviewDeliverable } from "components/contracts/brand_review/ContractBrandReviewDeliverable";
import Spacer from "components/Spacer";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { CreatorDetails } from "components/discovery/Datamodels";
import {
  archiveContractReview,
  getContractUrlFromContractReview,
} from "components/contracts/common/Api";
import { notifications } from "@mantine/notifications";
import { ActionButton } from "components/creator_lists/unified_creator_rep/ActionRows";

import { timeAgo } from "utils/TimeUtils";
import { getUTCDate } from "utils/DateUtils";
import { UsageRightsDuration } from "models/Common";

export function getStatusBadge(status: number) {
  if (status === ContractReviewStatus.APPROVED) {
    return (
      <Badge variant="light" color="green">
        Contract Approved
      </Badge>
    );
  }
  if (status === ContractReviewStatus.REJECTED) {
    return (
      <Badge variant="light" color="red">
        Contract Rejected
      </Badge>
    );
  }
  return (
    <Badge variant="light" color="yellow">
      Review Contract
    </Badge>
  );
}

export const AdminActionRow = ({
  contractReview,
  setRefreshKey,
}: {
  contractReview: ContractBrandReview;
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [contractAmount, setContractAmount] = useState(0);
  const markComplete = (contractReviewId: number) => {
    archiveContractReview(contractReviewId).then(() => {
      notifications.show({
        color: "blue",
        title: "Contract Review marked as Complete",
        message: `Contract Review with id ${contractReviewId} has been marked as complete and will no longer be shown!`,
        autoClose: true,
      });
      setRefreshKey((prevKey) => prevKey + 1);
    });
  };

  const createContract = () => {
    getContractUrlFromContractReview(contractReview.id, contractAmount).then(({ contract_url }) => {
      window.open(contract_url, "_blank");
    });
  };

  const viewContract = () => {
    const url = `/admin/contracts/${contractReview.contract_hash_id}`;
    window.open(url, "_blank");
  };
  const hasContractHashId = contractReview.contract_hash_id;
  return (
    <Flex align="center" h="100%" justify="space-between" gap="8px">
      {!hasContractHashId && contractReview.brand_review_status === ContractReviewStatus.APPROVED && (
        <>
          <NumberInput
            radius="xl"
            size="sm"
            hideControls
            leftSection="$"
            style={{ flexGrow: 1 }}
            placeholder="Contract Amount"
            onChange={(value: number) => {
              setContractAmount(value);
            }}
          />
          <ActionButton
            onClick={() => createContract()}
            color="green"
            text="Create Contract"
            IconName={IconPlus}
            disabled={contractAmount <= 0}
          />
        </>
      )}
      {hasContractHashId &&
        contractReview.brand_review_status === ContractReviewStatus.APPROVED && (
          <ActionButton
            onClick={() => viewContract()}
            color="blue"
            text="View Contract"
            IconName={IconLink}
          />
        )}
      <Tooltip label="Marking this as complete will remove this from this the view.">
        <ActionButton
          onClick={() => markComplete(contractReview.id)}
          color="green"
          text="Mark Complete"
          IconName={IconCheck}
        />
      </Tooltip>
      {/* <ActionButton
                    onClick={() => onDeleteContractReview(contractReview.id)}
                    color="red"
                    text="Delete Contract Review"
                    IconName={IconTrash}
                /> */}
    </Flex>
  );
};

export const ContractApprovalCard = ({
  contractReview,
  deliverables,
  deliverablePackageInfo,
  creatorDetail,
  isAccepted,
  isRejected,
  isInReview,
  showAdminOptions,
  index,
  setContractReviews,
  approveOffer,
  declineCreator,
  setRefreshKey,
}: {
  contractReview: ContractBrandReview;
  deliverables: ContractReviewDeliverable[];
  deliverablePackageInfo: {
    usageRightsDuration: number;
    usagePrice: number;
    creatorAskingUsageRightsPrice: number;
  };
  creatorDetail: CreatorDetails;
  isAccepted: boolean;
  isRejected: boolean;
  isInReview: boolean;
  showAdminOptions: boolean;
  index: number;
  setContractReviews?: React.Dispatch<React.SetStateAction<ContractBrandReview[]>>;
  approveOffer?: (contractReviewId: number, index: number, isBrand: boolean) => void;
  declineCreator?: (contractReviewId: number, index: number, isBrand: boolean) => void;
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const dateToShow = showAdminOptions ? contractReview.last_updated : contractReview.date_created;
  const dateText = showAdminOptions ? "Updated" : "Created";
  const { usageRightsDuration, usagePrice, creatorAskingUsageRightsPrice } = deliverablePackageInfo;
  const usageDuration = usageRightsDuration as UsageRightsDuration;
  const creatorAskingPrice = contractReview.creator_asking_price;
  const combinedRecommendedPrice = contractReview.recommended_price;
  const priceDebugArray = [
    contractReview.deliverable_package_instagram_price_debug,
    contractReview.deliverable_package_tiktok_dedicated_video_price_debug,
    contractReview.deliverable_package_youtube_short_price_debug,
    contractReview.deliverable_package_youtube_dedicated_video_price_debug,
    contractReview.deliverable_package_youtube_60s_integrated_video_price_debug,
    contractReview.deliverable_package_youtube_90s_integrated_video_price_debug,
  ];

  const actionRow = (
    <Card px="md" shadow="none" py="sm" radius="md" m="xs" withBorder>
      {/* <Card.Section>
                                                <Alert
                                                        p="lg"
                                                        m="md"
                                                        variant="light"
                                                        color="red"
                                                        radius="md"
                                                        title="Creator Bid above Max Offer Cap"
                                                        icon={<IconInfoCircle />}>
                                                        This creator submitted a price above your max offer cap. If you would like to
                                                        include this creator in your campaign, please confirm and then submit a new Max
                                                        Offer.
                                                </Alert>
                                        </Card.Section> */}
      <Flex justify="space-between" align="center">
        <Text fw="600">Deliverables</Text>
        <Tooltip label={`${getUTCDate(dateToShow)}`}>
          <Badge variant="light" color="gray">
            {`${dateText} ${timeAgo(dateToShow)}`}
          </Badge>
        </Tooltip>
      </Flex>
      <Flex gap="sm" my="xs">
        {deliverables.map((deliverable) => (
          <ContractBrandReviewDeliverable
            key={`deliverable-card-${deliverable.format}`}
            format={deliverable.format}
            platform={deliverable.platform}
            usageRights={deliverable.usageRightsDays}
            usageRightsInPerpetuity={false}
          />
        ))}
      </Flex>
      <Spacer height={8} />
      <Flex align="center" h="100%" justify="space-between" gap="8px">
        <Textarea
          label="Reason / Feedback"
          readOnly={isAccepted || isRejected}
          size="sm"
          style={{ flexGrow: 1 }}
          placeholder="Optional feedback"
          value={contractReview.brand_feedback || ""}
          onChange={(event) => {
            const { value } = event.target;
            setContractReviews((prevReviews) => {
              const updatedReviews = prevReviews.map((review) => {
                if (review.id === contractReview.id) {
                  return {
                    ...review,
                    brand_feedback: value,
                  };
                }
                return review;
              });
              return updatedReviews;
            });
          }}
          rightSection={
            <Text
              size="xs"
              ta="end"
              pr="4px"
              c={contractReview.brand_feedback.length > 5000 ? "red" : undefined}>
              {contractReview.brand_feedback.length}/5000
            </Text>
          }
          error={contractReview.brand_feedback.length > 5000}
          rightSectionWidth="67px"
          miw="200px"
          styles={{
            section: {
              justifyContent: "flex-end",
            },
          }}
        />
      </Flex>
      <Text />
      <Group mt="md" mb="xs">
        <NumberInput
          id="brandMaxOffer"
          value={contractReview.brand_max_price}
          thousandSeparator=","
          onChange={(value: number) => {
            setContractReviews((prevReviews: ContractBrandReview[]) => {
              const updatedReviews = prevReviews.map((review) => {
                if (review.id === contractReview.id) {
                  return {
                    ...review,
                    brand_max_price: value,
                  };
                }
                return review;
              });
              return updatedReviews;
            });
          }}
          label="Max Offer"
          min={1}
          allowDecimal={false}
          prefix="$"
          description={
            <>
              <>
                {`Creator's Asking Price: $${creatorAskingPrice.toLocaleString("en-US", {})}`}
                <br />
              </>
              {`Recommended Price: $${combinedRecommendedPrice.toLocaleString("en-US", {})}`}{" "}
              {priceDebugArray.filter((elt) => elt).length > 0 && (
                <InfoCircleWithTooltipNode
                  tooltipNode={
                    <Stack gap={20}>
                      {priceDebugArray
                        .filter((elt) => elt)
                        .map((elt) => (
                          <Text key={elt}>{elt}</Text>
                        ))}
                    </Stack>
                  }
                />
              )}
            </>
          }
          hideControls
          disabled={isAccepted || isRejected}
        />
      </Group>

      {isAccepted && (
        <Grid>
          <Grid.Col span={12}>
            <Button color="green" disabled fullWidth mt="md" radius="md">
              Max Offer Approved
            </Button>
          </Grid.Col>
        </Grid>
      )}
      {isRejected && (
        <Grid>
          <Grid.Col span={12}>
            <Button color="red" disabled fullWidth mt="md" radius="md">
              Creator Rejected
            </Button>
          </Grid.Col>
        </Grid>
      )}
      {isInReview && (
        <Grid>
          <Grid.Col span={6}>
            <Button
              color="red"
              fullWidth
              mt="md"
              radius="md"
              onClick={() => declineCreator(contractReview.id, index, true)}>
              Decline Creator
            </Button>
          </Grid.Col>
          <Grid.Col span={6}>
            <Button
              color="green"
              fullWidth
              mt="md"
              radius="md"
              onClick={() => approveOffer(contractReview.id, index, true)}>
              Approve Max Offer
            </Button>
          </Grid.Col>
        </Grid>
      )}

      {showAdminOptions && (
        <Group justify="right" p="md">
          <AdminActionRow contractReview={contractReview} setRefreshKey={setRefreshKey} />
        </Group>
      )}
    </Card>
  );
  return (
    <React.Fragment key={`entry-${contractReview.id}`}>
      <UnifiedCreatorRep
        creator={creatorDetail}
        key={`live-result-${contractReview.id}`}
        defaultExpandedPlatforms={[]}
        archetype={getStatusBadge(contractReview.brand_review_status)}
        useScrollOverflow={false}
        actionRow={actionRow}
      />
    </React.Fragment>
  );
};
