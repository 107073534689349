import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Badge,
  Center,
  Flex,
  Loader,
  LoadingOverlay,
  Select,
  Stack,
  Switch,
  Tabs,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";

import { Notifications } from "@mantine/notifications";

import { IconListCheck } from "@tabler/icons-react";

import { useAppSelector, useAppDispatch } from "reduxStore/hooks";
import {
  adjustGlobalTaskCount,
  resetGlobalTaskCount,
  updateGlobalTaskCount,
} from "reduxStore/tasksSlice";

import { getNumTasks, getCampaignsWithTasks, submitCreatorRatings } from "components/contracts/tasks/api/Api";
import { getTaskTypeLabel } from "components/contracts/tasks/common/Utils";

import { TaskCountType, TaskStage } from "components/contracts/tasks/models/Common";

import BaseTaskFeed from "components/contracts/tasks/BaseTaskFeed";
import RatingsModal from "components/ratings/RatingsModal";
import { useDisclosure } from "@mantine/hooks";
import { Rating } from "models/Rating";
import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

const SUPPORTED_TASK_TYPES = [
  TaskStage.ALL,
  // Contains TaskStage.CONTRACT_REVIEW and TaskStage.CONTRACT_OFFER_REVIEW
  TaskStage.UNIFIED_CONTRACT_REVIEW,
  TaskStage.PRODUCT_ACCESS,
  TaskStage.CODES_LINKS,
  TaskStage.CONTENT_PRODUCTION,
  TaskStage.MESSAGES,
  TaskStage.LIVE_VERIFICATION,
  TaskStage.CREATOR_RATINGS,
];

const DEFAULT_CAMPAIGN_ID = "all";
const DEFAULT_POLLING_FREQUENCY = 10_000;

function NumTasksBadge({
  numPendingTasks,
  numCompletedTasks,
}: {
  numPendingTasks: number;
  numCompletedTasks: number;
}) {
  if (!numPendingTasks && !numCompletedTasks) {
    return null;
  }
  return (
    <Badge color={numPendingTasks > 0 ? "red" : "gray"} size="sm">
      {numPendingTasks > 0 ? numPendingTasks : numCompletedTasks}
    </Badge>
  );
}

function TabLabel({
  numPendingTasks,
  numCompletedTasks,
  label,
  tabType,
  activeTab,
}: {
  numPendingTasks: number;
  numCompletedTasks: number;
  label: string;
  tabType: TaskStage;
  activeTab: TaskStage;
}) {
  const isSelected = tabType === activeTab;
  return (
    <Flex align="center" gap="xs" mx="-6px">
      <NumTasksBadge numPendingTasks={numPendingTasks} numCompletedTasks={numCompletedTasks} />
      <Text size="sm" fw="500" c={isSelected ? "blue" : "dimmed"}>
        {label}
      </Text>
    </Flex>
  );
}

function EmptyState() {
  return (
    <Center style={{ height: "80vh", width: "100%" }}>
      <Stack gap="sm">
        <Center>
          <ThemeIcon size={108} variant="transparent" color="var(--mantine-color-gray-5)">
            <IconListCheck size={108} />
          </ThemeIcon>
        </Center>
        <Stack gap={4}>
          <Flex justify="center">
            <Text size="xl" fw="600" c="dimmed">
              All tasks have been completed!
            </Text>
          </Flex>
          <Flex justify="center">
            <Text size="lg" c="dimmed">
              You will be notified when you have new tasks.
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Center>
  );
}

export default function TasksView() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useAppSelector((state) => state.me.user);

  // Get active tab from URL params or default to ALL
  const tabFromParams = searchParams.get("tab") as TaskStage;
  const initialTab = SUPPORTED_TASK_TYPES.includes(tabFromParams) ? tabFromParams : TaskStage.ALL;

  // Get campaign hash id from URL params or use default
  const campaignHashIdFromParams = searchParams.get("campaign");
  const initialCampaignHashId = campaignHashIdFromParams ?? DEFAULT_CAMPAIGN_ID;

  // Get test mode from URL params or use default
  const testModeFromParams = searchParams.get("test");
  const initialTestMode = testModeFromParams === "true";

  const [activeTab, setActiveTab] = useState<string>(initialTab);
  const [testMode, setTestMode] = useState<boolean>(initialTestMode);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>(initialCampaignHashId);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [campaignsInitialized, setCampaignsInitialized] = useState<boolean>(false);
  const [taskCountsInitialized, setTaskCountsInitialized] = useState<boolean>(false);

  const [campaignsWithTasks, setCampaignsWithTasks] = useState([{ value: "all", label: "All" }]);
  const [taskCountMap, setTaskCountMap] = useState<Record<string, Record<TaskCountType, number>>>(
    {},
  );

  const [ratingsModalOpened, { open: openRatingsModal, close: closeRatingsModal }] = useDisclosure(false);
  const [currentContractId, setCurrentContractId] = useState<string | null>(null);

  const handleOpenRatingsModal = (contractId: string) => {
    openRatingsModal();
    setCurrentContractId(contractId);
  };

  const handleSubmitRating = async (rating: Rating) => {
    if (!currentContractId) {
      showFailureNotification({
        title: "Error",
        message: "No contract ID found for rating submission.",
      });
      return;
    }

    try {
      const response = await submitCreatorRatings({
        contractId: currentContractId,
        starValue: rating.starValue,
        tags: rating.tags,
        other_notes: rating.description,
      });

      if (response.success) {
        showSuccessNotification({
          title: "Success",
          message: "Rating submitted successfully.",
        });
        closeRatingsModal();
      } else {
        showFailureNotification({
          title: "Error",
          message: response.error || "Failed to submit rating.",
        });
      }
    } catch (error) {
      showFailureNotification({
        title: "Error",
        message: "An error occurred while submitting the rating.",
      });
    }
    setCurrentContractId(null);
  };

  // Update URL params when active tab changes
  useEffect(() => {
    if (activeTab === TaskStage.ALL) {
      searchParams.delete("tab");
    } else {
      searchParams.set("tab", activeTab);
    }

    if (selectedCampaignId === DEFAULT_CAMPAIGN_ID) {
      searchParams.delete("campaign"); 
    } else {
      searchParams.set("campaign", selectedCampaignId);
    }

    if (testMode) {
      searchParams.set("test", "true");
    } else {
      searchParams.delete("test");
    }

    setSearchParams(searchParams, { replace: true });
  }, [activeTab, selectedCampaignId, testMode, searchParams, setSearchParams]);

  // Initialize the Campaign Selector and re-initailize when testMode changes.
  useEffect(() => {
    const abortController = new AbortController();

    getCampaignsWithTasks({ testMode, abortController })
      .then((response) => {
        const { success, data } = response;
        if (success) {
          const campaigns = [
            { value: DEFAULT_CAMPAIGN_ID, label: "All" },
            ...data.map((campaign: { id: string; title: string }) => ({
              value: campaign.id.toString(), 
              label: campaign.title,
            })),
          ];
          
          setCampaignsWithTasks(campaigns);

          // Reset selectedCampaignId if it's not in the list of valid campaigns
          const validCampaignIds = campaigns.map(c => c.value);
          if (!validCampaignIds.includes(selectedCampaignId)) {
            setSelectedCampaignId(DEFAULT_CAMPAIGN_ID);
          }
        }
      })
      .finally(() => setCampaignsInitialized(true));

    return () => abortController.abort();
  }, [testMode]);

  const fetchTaskCounts = (abortController?: AbortController) => {
    setIsFetching(true);
    getNumTasks({
      campaignHashId: selectedCampaignId === DEFAULT_CAMPAIGN_ID ? null : selectedCampaignId,
      testMode,
      abortController,
    })
      .then((response) => {
        if (!response) {
          return;
        }
        const { success, data } = response;

        if (success) {
          setTaskCountMap(data);
          // In the case that we are fetching the global task count, update the global state.
          if (selectedCampaignId === DEFAULT_CAMPAIGN_ID) {
            dispatch(updateGlobalTaskCount(data[TaskStage.ALL]));
          }
        }
      })
      .finally(() => {
        setIsFetching(false);
        setTaskCountsInitialized(true);
      });
  };

  // Fetch initial task counts.
  useEffect(() => {
    if (campaignsInitialized && !taskCountsInitialized) {
      const abortController = new AbortController();
      fetchTaskCounts(abortController);
      return () => abortController.abort();
    }
    return () => {};
  }, [selectedCampaignId, testMode, campaignsInitialized]);

  // Set up a polling interval for task counts.
  useEffect(() => {
    if (taskCountsInitialized) {
      const abortController = new AbortController();
      const intervalId = setInterval(() => {
        if (!isFetching) {
          fetchTaskCounts(abortController);
        }
      }, DEFAULT_POLLING_FREQUENCY);

      return () => {
        clearInterval(intervalId);
        abortController.abort();
      };
    }
    return () => {};
  }, [taskCountsInitialized]);

  if (!campaignsInitialized) {
    return <LoadingOverlay visible />;
  }

  return (
    <>
      <Notifications />
      <Stack>
        <Flex justify="space-between" align="center">
          <Title order={2}>Tasks</Title>
          {currentUser.is_staff && (
            <Switch
              label="Test Mode"
              checked={testMode}
              onChange={(event) => {
                setTestMode(event.currentTarget.checked);
                dispatch(resetGlobalTaskCount());
                setSelectedCampaignId(DEFAULT_CAMPAIGN_ID);
                setCampaignsInitialized(false);
                setTaskCountsInitialized(false);
                setTaskCountMap({});
              }}
            />
          )}
        </Flex>
        <Select
          label="Campaign"
          searchable
          data={campaignsWithTasks}
          value={selectedCampaignId}
          onChange={(value) => {
            setSelectedCampaignId(value);
            setTaskCountsInitialized(false);
            setTaskCountMap({});
          }}
          defaultValue={DEFAULT_CAMPAIGN_ID}
          allowDeselect={false}
          w="300px"
        />
        {!taskCountsInitialized && (
          <Center>
            <Loader />
          </Center>
        )}
        {taskCountsInitialized && (
          <Tabs value={activeTab} onChange={setActiveTab}>
            <Tabs.List>
              {SUPPORTED_TASK_TYPES.map((taskType) => (
                <Tabs.Tab key={`${taskType}-tab`} value={taskType}>
                  <TabLabel
                    numPendingTasks={taskCountMap[taskType]?.[TaskCountType.PENDING] ?? 0}
                    numCompletedTasks={taskCountMap[taskType]?.[TaskCountType.COMPLETED] ?? 0}
                    label={getTaskTypeLabel(taskType)}
                    tabType={taskType}
                    activeTab={activeTab as TaskStage}
                  />
                </Tabs.Tab>
              ))}
            </Tabs.List>

            {/* TODO(albert): For the Messages tab, we might still want to show completed messaging tasks. */}
            {SUPPORTED_TASK_TYPES.map((taskType) => (
              <Tabs.Panel key={`${taskType}-panel`} value={taskType}>
                {taskCountMap[taskType]?.[TaskCountType.ALL] > 0 ? (
                  <BaseTaskFeed
                    user={currentUser}
                    testMode={testMode}
                    isActive={activeTab === taskType}
                    selectedCampaignId={
                      selectedCampaignId === DEFAULT_CAMPAIGN_ID ? null : selectedCampaignId
                    }
                    taskType={taskType}
                    numTasks={taskCountMap[taskType]}
                    numMessagingTasks={taskCountMap[TaskStage.MESSAGES][TaskCountType.PENDING]}
                    setNumTasks={(
                      taskTypeToUse: TaskStage,
                      pendingCountChange: number,
                      completedCountChange: number,
                    ) => {
                      setTaskCountMap((prevMap) => {
                        if (taskTypeToUse === TaskStage.ALL) {
                          throw new Error("taskTypeToUse cannot be TaskStage.ALL");
                        }

                        const newMap = {
                          ...prevMap,
                          [taskTypeToUse]: {
                            [TaskCountType.ALL]:
                              prevMap[taskTypeToUse][TaskCountType.ALL] +
                              pendingCountChange +
                              completedCountChange,
                            [TaskCountType.PENDING]:
                              prevMap[taskTypeToUse][TaskCountType.PENDING] + pendingCountChange,
                            [TaskCountType.COMPLETED]:
                              prevMap[taskTypeToUse][TaskCountType.COMPLETED] +
                              completedCountChange,
                          },
                        };

                        // Update ALL task type totals
                        const allPending = Object.entries(newMap)
                          .filter(([type]) => type !== TaskStage.ALL)
                          .reduce((sum, [_, counts]) => sum + counts[TaskCountType.PENDING], 0);
                        const allCompleted = Object.entries(newMap)
                          .filter(([type]) => type !== TaskStage.ALL)
                          .reduce((sum, [_, counts]) => sum + counts[TaskCountType.COMPLETED], 0);

                        newMap[TaskStage.ALL] = {
                          [TaskCountType.ALL]: allPending + allCompleted,
                          [TaskCountType.PENDING]: allPending,
                          [TaskCountType.COMPLETED]: allCompleted,
                        };

                        dispatch(
                          adjustGlobalTaskCount({
                            pending: pendingCountChange,
                            completed: completedCountChange,
                          }),
                        );

                        return newMap;
                      });
                    }}
                    handleOpenRatingsModal={handleOpenRatingsModal}
                  />
                ) : (
                  <EmptyState />
                )}
              </Tabs.Panel>
            ))}
          </Tabs>
        )}
        <RatingsModal
          opened={ratingsModalOpened}
          close={closeRatingsModal}
          handleSubmit={handleSubmitRating}
        />
      </Stack>
    </>
  );
}
