import React, { useEffect, useState } from "react";

import {
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useDisclosure, useInputState } from "@mantine/hooks";
import { notifications, Notifications } from "@mantine/notifications";

import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { fetchCreatorInfoFromLink } from "campaigns/api/fetchCreatorInfoFromLink";
import { CreatorDetails } from "components/discovery/Datamodels";
import { BaseAddCreatorDetails } from "components/campaign/dashboard/CreatorDetailsForms";
import SummaryCreatorProfileView from "components/creator/SummaryCreatorProfileView";
import { markIsCreator } from "admin/api/labeling/sourcingApi";

const addCreatorFromLink = async ({
  youtubeHandle,
  instagramHandle,
  tiktokHandle,
  firstName,
  lastName,
  email,
}: {
  youtubeHandle?: string;
  instagramHandle?: string;
  tiktokHandle?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}) => {
  const reqBody = JSON.stringify({
    youtube_handle: youtubeHandle,
    instagram_handle: instagramHandle,
    tiktok_handle: tiktokHandle,
    first_name: firstName,
    last_name: lastName,
    email,
  });
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/creator/import_creator`,
    method: "POST",
    body: reqBody,
  });
  const response = await handleResult(request);
  return response;
};

const CreatorSearchResultItem = ({ creatorDetail }: { creatorDetail: CreatorDetails }) => {
  if (!creatorDetail) {
    return null;
  }

  const [isCreator, setIsCreator] = useState<boolean>(creatorDetail.is_creator || false);
  const [isCheckboxLoading, setIsCheckboxLoading] = useState<boolean>(false);

  return (
    <Group grow preventGrowOverflow={false} wrap="nowrap">
      <Checkbox
        label="Is Creator"
        disabled={isCheckboxLoading}
        checked={isCreator}
        onChange={(event) => {
          // Send the update to the backend
          setIsCheckboxLoading(true);
          const eventTargetChecked = event.currentTarget.checked as boolean;
          markIsCreator(creatorDetail.creator_id, null, eventTargetChecked)
            .then((response) => {
              if (response.success) {
                setIsCreator(eventTargetChecked);
              } else {
                notifications.show({
                  color: "red",
                  title: "Error with marking the creator as a creator!",
                  message: `${response.message}`,
                });
              }
            })
            .catch((error) => {
              notifications.show({
                color: "red",
                title: "Error with marking the creator as a creator!",
                message: `${error}`,
              });
            })
            .finally(() => {
              setIsCheckboxLoading(false);
            });
        }}
      />
      <SummaryCreatorProfileView
        key={creatorDetail.creator_id}
        creatorId={creatorDetail.creator_id}
        baseCreator={creatorDetail}
        tikTokStats={null}
        youtubeLongStats={null}
        youtubeShortsStats={null}
        instagramStats={null}
        defaultExpandedPlatforms={[]}
        includeLinkToProfile
        showExtendedStats={false}
        disableCreatorEditAction
      />
    </Group>
  );
};

const AddCreatorDetails = ({
  youtubeHandle,
  setYoutubeHandle,
  tiktokHandle,
  setTiktokHandle,
  instagramHandle,
  setInstagramHandle,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  hidden = true,
  close,
  results,
  setResults,
}: {
  youtubeHandle?: string;
  setYoutubeHandle?: (youtubeHandle: string) => void;
  tiktokHandle?: string;
  setTiktokHandle?: (tiktokHandle: string) => void;
  instagramHandle?: string;
  setInstagramHandle?: (instagramHandle: string) => void;
  firstName?: string;
  setFirstName?: (firstName: string) => void;
  lastName?: string;
  setLastName?: (lastName: string) => void;
  email?: string;
  setEmail?: (email: string) => void;
  hidden?: boolean;
  close?: () => void;
  results?: Record<number, CreatorDetails>;
  setResults?: (results: Record<number, CreatorDetails>) => void;
}) => {
  if (hidden) {
    return null;
  }

  const [addCreatorLoading, setAddCreatorLoading] = useState(false);

  const [addedResult, setAddedResult] = useState<Record<number, CreatorDetails>>({});

  useEffect(() => {
    setResults({ ...results, ...addedResult });
  }, [addedResult]);

  return (
    <Stack>
      <BaseAddCreatorDetails
        youtubeHandle={youtubeHandle}
        setYoutubeHandle={setYoutubeHandle}
        tiktokHandle={tiktokHandle}
        setTiktokHandle={setTiktokHandle}
        instagramHandle={instagramHandle}
        setInstagramHandle={setInstagramHandle}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
      />
      <Flex direction="column" gap="sm" justify="center" align="center">
        <Button
          loading={addCreatorLoading}
          onClick={() => {
            setAddCreatorLoading(true);
            addCreatorFromLink({
              youtubeHandle,
              instagramHandle,
              tiktokHandle,
              firstName,
              lastName,
              email,
            })
              .then((response) => {
                if (response == null || !response.success) {
                  notifications.show({
                    color: "red",
                    title: "Error with adding the creator!",
                    message: `${response.message}`,
                    autoClose: false,
                  });
                } else {
                  notifications.show({
                    title: "Creator added successfully!",
                    message: `Creator added successfully!`,
                    autoClose: false,
                  });
                  if (response?.creator_details !== undefined) {
                    // combine the response with the existing creatorSetDetails
                    setAddedResult(response.creator_details);
                  }
                  close();
                }
              })
              .finally(() => {
                setAddCreatorLoading(false);
              });
          }}>
          Import Creator
        </Button>
      </Flex>
    </Stack>
  );
};

const ManualImportCreator = ({
  results,
  setResults,
}: {
  results: Record<number, CreatorDetails>;
  setResults?: (results: Record<number, CreatorDetails>) => void;
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [hidden, setHidden] = useState(true);
  const [linkUrl, setLinkUrl] = useInputState("");
  const [youtubeHandle, setYoutubeHandle] = useInputState("");
  const [tiktokHandle, setTiktokHandle] = useInputState("");
  const [instagramHandle, setInstagramHandle] = useInputState("");
  const [firstName, setFirstName] = useInputState("");
  const [lastName, setLastName] = useInputState("");
  const [email, setEmail] = useInputState("");

  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (!opened) {
      setLinkUrl("");
      setYoutubeHandle("");
      setTiktokHandle("");
      setInstagramHandle("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setHidden(true);
    }
  }, [opened]);

  return (
    <>
      <Notifications />
      <Stack>
        <Modal
          size="lg"
          opened={opened}
          onClose={() => {
            close();
            setHidden(true);
          }}
          title={<Text fw="600">Add Creator</Text>}>
          <Flex direction="column" gap="sm" justify="center" align="center">
            <TextInput
              placeholder="https://www.instagram.com/eleanor_the_pug"
              label="Profile URL"
              description="Enter the profile URL of the creator to automatically import the creator details."
              value={linkUrl}
              onChange={(event) => setLinkUrl(event.currentTarget.value)}
              size="sm"
              styles={{
                root: {
                  minWidth: "80%",
                },
              }}
            />
            <Flex direction="row" align="center" gap="sm">
              <Button
                loading={buttonLoading}
                onClick={() => {
                  setButtonLoading(true);
                  setHidden(true);
                  fetchCreatorInfoFromLink(linkUrl)
                    .then((data) => {
                      const { creator, ad_groups: adGroups, archetypes } = data;
                      if (creator != null) {
                        if (creator.youtube_handle != null) {
                          setYoutubeHandle(creator.youtube_handle);
                        }
                        if (creator.tiktok_handle != null) {
                          setTiktokHandle(creator.tiktok_handle);
                        }
                        if (creator.instagram_handle != null) {
                          setInstagramHandle(creator.instagram_handle);
                        }
                        if (creator.first_name != null) {
                          setFirstName(creator.first_name);
                        }
                        if (creator.last_name != null) {
                          setLastName(creator.last_name);
                        }
                        if (creator.email != null) {
                          setEmail(creator.email);
                        }
                        setHidden(false);
                      }
                    })
                    .finally(() => {
                      setButtonLoading(false);
                    });
                }}>
                Import
              </Button>
            </Flex>
          </Flex>
          <AddCreatorDetails
            hidden={hidden}
            youtubeHandle={youtubeHandle}
            setYoutubeHandle={setYoutubeHandle}
            tiktokHandle={tiktokHandle}
            setTiktokHandle={setTiktokHandle}
            instagramHandle={instagramHandle}
            setInstagramHandle={setInstagramHandle}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            close={close}
            results={results}
            setResults={setResults}
          />
        </Modal>
        <Divider />
        <Stack align="center">
          <Text>Can&apos;t find the creator you&apos;re looking for?</Text>
          <Button size="sm" onClick={open}>
            Manually Add Creator
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export const CreatorSearchResults = ({
  results,
  setResults,
  loading = false,
  allowManualImport = false,
}: {
  results: Record<number, CreatorDetails>;
  setResults?: (results: Record<number, CreatorDetails>) => void;
  loading?: boolean;
  allowManualImport?: boolean;
}) => {
  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <Stack>
      {Object.entries(results).map(([creatorId, creatorDetail]: [string, CreatorDetails]) => {
        return (
          <CreatorSearchResultItem key={creatorDetail?.creator_id} creatorDetail={creatorDetail} />
        );
      })}
      {allowManualImport && <ManualImportCreator results={results} setResults={setResults} />}
    </Stack>
  );
};

export default CreatorSearchResults;
