import React from "react";

type StarProps = {
  active: boolean,
  clickable?: boolean,
  isHovered?: boolean,
  onClick?: () => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
  size?: number  // New size prop with default of 40
}

const Star = ({ active, clickable = true, isHovered, onClick, onMouseEnter, onMouseLeave, size = 40 }: StarProps) => {
  const activeColor = "#228BE6";
  const inactiveColor = "#CED4DA";
  const hoverActiveColor = "#62aced";
  const hoverInactiveColor = "#D3D3D3";

  const getFillColor = () => {
    if (isHovered) {
      return active ? hoverActiveColor : hoverInactiveColor;
    }
    return active ? activeColor : inactiveColor;
  };


  if (clickable) {
    return (
      <button
      type="button"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        background: "none",
        border: "none",
        padding: 0,
        cursor: "pointer",
      }}
      aria-label={active ? "Active star" : "Inactive star"}
      >
        <svg
          width={size}
          height={size + 1}
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_121_6174)">
          <path
            d="M13.7384 12.6545L3.1051 14.1961L2.91676 14.2345C2.63166 14.3102 2.37176 14.4602 2.16359 14.6691C1.95541 14.8781 1.80644 15.1386 1.73187 15.424C1.6573 15.7094 1.65981 16.0095 1.73914 16.2936C1.81848 16.5777 1.97179 16.8357 2.18343 17.0411L9.88677 24.5395L8.0701 35.1311L8.04843 35.3145C8.03098 35.6093 8.09221 35.9035 8.22585 36.167C8.35949 36.4304 8.56074 36.6536 8.80899 36.8137C9.05724 36.9737 9.34358 37.065 9.63868 37.078C9.93378 37.091 10.227 37.0254 10.4884 36.8878L19.9984 31.8878L29.4868 36.8878L29.6534 36.9645C29.9285 37.0728 30.2275 37.106 30.5197 37.0607C30.8119 37.0154 31.0867 36.8932 31.3161 36.7066C31.5454 36.52 31.721 36.2757 31.8248 35.9989C31.9286 35.722 31.9569 35.4225 31.9068 35.1311L30.0884 24.5395L37.7951 17.0395L37.9251 16.8978C38.1108 16.6691 38.2326 16.3952 38.278 16.1041C38.3234 15.813 38.2908 15.5151 38.1836 15.2407C38.0764 14.9663 37.8983 14.7252 37.6676 14.542C37.4368 14.3588 37.1617 14.24 36.8701 14.1978L26.2368 12.6545L21.4834 3.02114C21.3459 2.74203 21.133 2.50699 20.8687 2.34264C20.6045 2.17829 20.2996 2.09119 19.9884 2.09119C19.6773 2.09119 19.3723 2.17829 19.1081 2.34264C18.8439 2.50699 18.631 2.74203 18.4934 3.02114L13.7384 12.6545Z"
            fill={getFillColor()}
          />
          </g>
          <defs>
          <clipPath id="clip0_121_6174">
            <rect
            width="40"
            height="40"
            fill="black"
            transform="translate(0 0.421143)"
            />
          </clipPath>
          </defs>
        </svg>
      </button>
    );
  }
  // If not clickable, render a non-interactive <span>
  return (
  <span>
    <svg
    width={size}
    height={size + 1}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <g clipPath="url(#clip0_121_6174)">
      <path
      d="M13.7384 12.6545L3.1051 14.1961L2.91676 14.2345C2.63166 14.3102 2.37176 14.4602 2.16359 14.6691C1.95541 14.8781 1.80644 15.1386 1.73187 15.424C1.6573 15.7094 1.65981 16.0095 1.73914 16.2936C1.81848 16.5777 1.97179 16.8357 2.18343 17.0411L9.88677 24.5395L8.0701 35.1311L8.04843 35.3145C8.03098 35.6093 8.09221 35.9035 8.22585 36.167C8.35949 36.4304 8.56074 36.6536 8.80899 36.8137C9.05724 36.9737 9.34358 37.065 9.63868 37.078C9.93378 37.091 10.227 37.0254 10.4884 36.8878L19.9984 31.8878L29.4868 36.8878L29.6534 36.9645C29.9285 37.0728 30.2275 37.106 30.5197 37.0607C30.8119 37.0154 31.0867 36.8932 31.3161 36.7066C31.5454 36.52 31.721 36.2757 31.8248 35.9989C31.9286 35.722 31.9569 35.4225 31.9068 35.1311L30.0884 24.5395L37.7951 17.0395L37.9251 16.8978C38.1108 16.6691 38.2326 16.3952 38.278 16.1041C38.3234 15.813 38.2908 15.5151 38.1836 15.2407C38.0764 14.9663 37.8983 14.7252 37.6676 14.542C37.4368 14.3588 37.1617 14.24 36.8701 14.1978L26.2368 12.6545L21.4834 3.02114C21.3459 2.74203 21.133 2.50699 20.8687 2.34264C20.6045 2.17829 20.2996 2.09119 19.9884 2.09119C19.6773 2.09119 19.3723 2.17829 19.1081 2.34264C18.8439 2.50699 18.631 2.74203 18.4934 3.02114L13.7384 12.6545Z"
      fill={getFillColor()}
      />
    </g>
    <defs>
      <clipPath id="clip0_121_6174">
      <rect
        width="40"
        height="40"
        fill="black"
        transform="translate(0 0.421143)"
      />
      </clipPath>
    </defs>
    </svg>
  </span>
  );
};

export default Star;