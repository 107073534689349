import React, { useEffect, useState } from "react";
import CardBase from "campaigns/create/common/CardBase";
import {
  ActionIcon,
  Button,
  Group,
  MultiSelect,
  Popover,
  Select,
  Stack,
  Table,
  TextInput,
} from "@mantine/core";
import { IconDots, IconPlus } from "@tabler/icons-react";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { CreatorSetResponse, fetchCreatorSetsByBrandId } from "campaigns/api/fetchCreatorSets";
import { CreatorSet } from "components/discovery/Datamodels";
import CreativeBrief from "models/CreativeBrief";
import {
  CreativeBriefSettingsResponse,
  fetchCreativeBriefsByBrandId,
} from "campaigns/api/fetchCreativeBriefs";
import { useForm } from "@mantine/form";
import { randomId, useDisclosure } from "@mantine/hooks";
import { Campaign, CampaignStatus } from "models/Campaign";
import {
  createAdGroupByHashId,
  getAdGroupCreatorSets,
  deleteAdGroup,
  updateAdGroup,
} from "campaigns/api/fetchCampaignSettings";
import isEqual from "react-fast-compare";
import { fetchMyBrand } from "reduxStore/meSlice";
import EditAdGroupEmailModal from "campaigns/create/adgroups/EditAdGroupEmailModal";
import { FormAdGroup } from "campaigns/create/adgroups/types";
import EditEmailSettingsButton from "campaigns/create/adgroups/EditEmailSettingsButton";

export interface AdGroupsFormType {
  adGroups: FormAdGroup[];
}

const splitExistingAndNewAdGroups = (formAdGroups: FormAdGroup[]): FormAdGroup[][] =>
  formAdGroups.reduce(
    (acc, val, i) => {
      acc[val.isNew ? 0 : 1].push(val);
      return acc;
    },
    [[], []], // [new ad groups, existing ad groups]
  );

const getValidCreatorSetsData = (
  possibleCreatorSets: CreatorSet[],
  alreadyLiveCreatorSets: string[],
): { label: string; value: string; disabled: boolean }[] => {
  return possibleCreatorSets.map((creatorSet) => {
    return {
      label: creatorSet.name,
      value: creatorSet.id.toString(),
      disabled: alreadyLiveCreatorSets.includes(creatorSet.id.toString()),
    };
  });
};

const AdGroupsCampaignCard = ({
  campaign,
  isActive,
  onSaveSuccess,
  onEmailSettingsUpdated,
}: {
  campaign: Campaign | null;
  isActive: boolean;
  onSaveSuccess: (input: AdGroupsFormType) => void;
  onEmailSettingsUpdated: () => void;
}) => {
  const dispatch = useAppDispatch();
  const brand = useAppSelector((state) => state.me.brand);
  const [adGroupEmailModalOpened, { open: openAdGroupEmailModal, close: closeAdGroupEmailModal }] =
    useDisclosure(false);
  const [adGroupForEmailModal, setAdGroupForEmailModal] = useState<FormAdGroup | null>(null);

  const [possibleCreatorSets, setPossibleCreatorSets] = useState<CreatorSet[]>([]);
  const [possibleCreativeBriefs, setPossibleCreativeBriefs] = useState<CreativeBrief[]>([]);

  const [adGroupCreatorSets, setAdGroupCreatorSets] = useState<Record<number, CreatorSet[]>>(null);

  const [existingFormAdGroupIdToFormAdGroupMap, setExistingFormAdGroupIdToFormAdGroupMap] =
    useState<{ [id: string]: FormAdGroup }>({});

  const [deletedAdGroupIds, setDeletedAdGroupIds] = useState<string[]>([]);
  const [isCampaignAlreadyLive, setIsCampaignAlreadyLive] = useState<boolean>(false);
  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);

  const creativeBriefsData: { label: string; value: string }[] = possibleCreativeBriefs.map(
    (brief) => {
      return {
        label: brief.name,
        value: brief.id.toString(),
      };
    },
  );

  const form = useForm<AdGroupsFormType>({
    mode: "uncontrolled",
    initialValues: {
      adGroups: [],
    },

    validate: {
      adGroups: {
        name: (value) => (value ? null : "Please give your ad group a name"),
        creativeBrief: (value) => (value ? null : "Please select a creative brief"),
      },
    },

    onValuesChange: (values) => {
      setHasFormChanged(form.isDirty());
      // TODO (victoria 5.2024): we can be smarter about ordering of creator sets so it doesn't mark it as dirty
    },
  });

  useEffect(() => {
    const fetchMyBrandAbortController = new AbortController();

    dispatch(fetchMyBrand({ abortController: fetchMyBrandAbortController }));

    return () => {
      fetchMyBrandAbortController.abort();
    };
  }, []);

  useEffect(() => {
    const creatorSetsAbortController = new AbortController();
    const creativeBriefsAbortController = new AbortController();
    const adGroupCreatorSetsAbortController = new AbortController();

    setIsCampaignAlreadyLive(campaign?.status === CampaignStatus.Active);

    if (campaign) {
      if (campaign.brand?.id) {
        fetchCreatorSetsByBrandId(campaign.brand.id, creatorSetsAbortController).then(
          (creatorSetResponse: CreatorSetResponse) => {
            if (creatorSetResponse) {
              setPossibleCreatorSets(creatorSetResponse.results ?? []);
            }
          },
        );
        fetchCreativeBriefsByBrandId(campaign.brand.id, creativeBriefsAbortController).then(
          (creativeBriefResponse: CreativeBriefSettingsResponse) => {
            if (creativeBriefResponse) {
              setPossibleCreativeBriefs(creativeBriefResponse.results ?? []);
            }
          },
        );
        getAdGroupCreatorSets(
          campaign.ad_groups.map((adGroup) => adGroup.id),
          campaign.hash_id,
          adGroupCreatorSetsAbortController,
        ).then((creatorSetsResponse) => {
          if (creatorSetsResponse) {
            setAdGroupCreatorSets(creatorSetsResponse || {});
          }
        });
      }
    }

    return () => {
      creatorSetsAbortController.abort();
      creativeBriefsAbortController.abort();
    };
  }, [campaign]);

  useEffect(() => {
    if (!campaign || !adGroupCreatorSets) {
      return;
    }
    const formAdGroups = campaign.ad_groups.map((adGroup) => {
      const formAdGroup: FormAdGroup = {
        isNew: false,
        key: adGroup.id.toString(),
        name: adGroup.name,
        blurb: adGroup.brand_blurb,
        creativeBrief: adGroup?.brief_id?.toString(),
        creatorSets: adGroupCreatorSets[adGroup.id]?.map((creatorSet) => creatorSet.id.toString()),
        creatorFitBlurb: adGroup.creator_fit_blurb,
        brandLink: adGroup.brand_link,
        brandDisplayName: adGroup.brand_display_name,
      };
      return formAdGroup;
    });

    const formAdGroupMap: { [id: string]: FormAdGroup } = {};
    formAdGroups.forEach((formAdGroup) => {
      formAdGroupMap[formAdGroup.key] = formAdGroup;
    });
    setExistingFormAdGroupIdToFormAdGroupMap(formAdGroupMap);
    form.setValues({ adGroups: formAdGroups });
    form.resetDirty();
    setHasFormChanged(form.isDirty());
  }, [campaign, adGroupCreatorSets]);

  return (
    <>
      <CardBase
        title="Ad Groups"
        subtitle="Create targeted ad groups to get your brand in front of specific audiences."
        isActive={isActive}
        nodeShownWhenActive={
          <form
            onSubmit={form.onSubmit((values) => {
              const [newAdGroups, existingAdGroups] = splitExistingAndNewAdGroups(values.adGroups);

              const creationRequests = newAdGroups.map((newAdGroup) =>
                createAdGroupByHashId(
                  campaign.hash_id,
                  newAdGroup.name,
                  null,
                  Number(newAdGroup.creativeBrief),
                  null,
                  newAdGroup.blurb,
                  newAdGroup.creatorSets.map((creatorSetId) => Number(creatorSetId)),
                ),
              );

              const deletionRequests = isCampaignAlreadyLive
                ? []
                : deletedAdGroupIds.map((deletedAdGroupId) =>
                    deleteAdGroup(deletedAdGroupId, null),
                  );

              const changedExistingAdGroups = existingAdGroups.filter((existingFormAdGroup) => {
                const apiFormAdGroup =
                  existingFormAdGroupIdToFormAdGroupMap[existingFormAdGroup.key];
                if (apiFormAdGroup) {
                  return !isEqual(existingFormAdGroup, apiFormAdGroup);
                }
                return false;
              });

              const updateRequests = changedExistingAdGroups.map((updatedAdGroup) =>
                updateAdGroup(campaign.hash_id, updatedAdGroup.key, {
                  brief_id: updatedAdGroup.creativeBrief,
                  name: updatedAdGroup.name,
                  brand_blurb: updatedAdGroup.blurb,
                  creator_sets: updatedAdGroup.creatorSets,
                }),
              );

              const allRequests = deletionRequests.concat(creationRequests).concat(updateRequests);
              Promise.all(allRequests).then((response) => {
                onSaveSuccess(values);
              });
            })}>
            <Stack style={{ "--stack-gap": "24px" }}>
              <Stack style={{ "--stack-gap": "16px" }}>
                <Table stickyHeader style={{ tableLayout: "fixed" }}>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Name</Table.Th>
                      <Table.Th>Creative Brief</Table.Th>
                      <Table.Th colSpan={2}>Sets</Table.Th>
                      <Table.Th>Email Settings</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {form.getValues().adGroups.map((formAdGroup, index) => {
                      const isFieldDisabled = isCampaignAlreadyLive && !formAdGroup.isNew;
                      const apiFormAdGroup = isFieldDisabled
                        ? existingFormAdGroupIdToFormAdGroupMap[formAdGroup.key]
                        : null;
                      return (
                        <Table.Tr key={formAdGroup.key}>
                          <Table.Td style={{ alignContent: "flex-start" }}>
                            <TextInput
                              placeholder={`Ad Group ${index + 1}`}
                              key={form.key(`adGroups.${index}.name`)}
                              {...form.getInputProps(`adGroups.${index}.name`)}
                              disabled={isFieldDisabled}
                              styles={{
                                input: {
                                  paddingInlineStart: isFieldDisabled ? 12 : undefined,
                                  paddingInlineEnd: isFieldDisabled ? 12 : undefined,
                                },
                              }}
                            />
                          </Table.Td>
                          <Table.Td style={{ alignContent: "flex-start" }}>
                            <Select
                              placeholder="Select creative brief"
                              data={creativeBriefsData}
                              key={form.key(`adGroups.${index}.creativeBrief`)}
                              {...form.getInputProps(`adGroups.${index}.creativeBrief`)}
                              disabled={isFieldDisabled}
                              styles={{
                                input: {
                                  paddingInlineStart: isFieldDisabled ? 12 : undefined,
                                  paddingInlineEnd: isFieldDisabled ? 12 : undefined,
                                },
                              }}
                            />
                          </Table.Td>
                          <Table.Td style={{ alignContent: "flex-start" }} colSpan={2}>
                            <MultiSelect
                              placeholder="Select creator sets"
                              data={getValidCreatorSetsData(
                                possibleCreatorSets,
                                formAdGroup.isNew ? [] : apiFormAdGroup?.creatorSets ?? [],
                              )}
                              key={form.key(`adGroups.${index}.creatorSets`)}
                              {...form.getInputProps(`adGroups.${index}.creatorSets`)}
                              style={{ flexGrow: 1 }}
                            />
                          </Table.Td>
                          <Table.Td style={{ alignContent: "flex-start" }}>
                            <Group
                              style={{ "--group-gap": 12 }}
                              wrap="nowrap"
                              align="flex-start"
                              justify="space-between">
                              <EditEmailSettingsButton
                                isDisabled={formAdGroup.isNew}
                                onClick={() => {
                                  setAdGroupForEmailModal(formAdGroup);
                                  openAdGroupEmailModal();
                                }}
                              />
                              <Popover withArrow shadow="md">
                                <Popover.Target>
                                  <ActionIcon variant="transparent" style={{ flexShrink: 0 }}>
                                    <IconDots size={16} color="var(--mantine-color-dark-8)" />
                                  </ActionIcon>
                                </Popover.Target>
                                <Popover.Dropdown>
                                  <Stack style={{ "--stack-gap": "4px" }}>
                                    {isFieldDisabled ? null : (
                                      <Button
                                        size="xs"
                                        variant="transparent"
                                        fullWidth
                                        color="var(--mantine-color-red-6)"
                                        onClick={() => {
                                          if (!formAdGroup.isNew) {
                                            setDeletedAdGroupIds(
                                              deletedAdGroupIds.concat(formAdGroup.key),
                                            );
                                          }
                                          form.removeListItem("adGroups", index);
                                        }}>
                                        Delete
                                      </Button>
                                    )}
                                    <Button
                                      size="xs"
                                      variant="transparent"
                                      fullWidth
                                      color="var(--mantine-color-dark-6)"
                                      onClick={() => {
                                        const dupeFormAdGroup: FormAdGroup = {
                                          ...formAdGroup,
                                          isNew: true,
                                          name: formAdGroup.name.concat(" (Copy)"),
                                          key: randomId(),
                                        };
                                        form.insertListItem("adGroups", dupeFormAdGroup, index + 1);
                                      }}>
                                      Duplicate
                                    </Button>
                                  </Stack>
                                </Popover.Dropdown>
                              </Popover>
                            </Group>
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                </Table>
                <Group style={{ justifyContent: "flex-end" }}>
                  <Button
                    size="xs"
                    variant="light"
                    leftSection={<IconPlus size={12} />}
                    c="var(--mantine-color-blue-6)"
                    style={{ width: "min-content" }}
                    onClick={() => {
                      const newAdGroup: FormAdGroup = {
                        key: randomId(), // only used for form maintenance
                        isNew: true,
                        name: null,
                        blurb: null,
                        creativeBrief: null,
                        creatorSets: [],
                        creatorFitBlurb: null,
                        brandLink: null,
                        brandDisplayName: null,
                      };
                      form.insertListItem("adGroups", newAdGroup);
                    }}>
                    New Ad Group
                  </Button>
                </Group>
              </Stack>
              <Button type="submit" variant="filled" disabled={!campaign || !hasFormChanged}>
                Save
              </Button>
            </Stack>
          </form>
        }
      />
      {adGroupEmailModalOpened && adGroupForEmailModal ? (
        <EditAdGroupEmailModal
          adGroup={adGroupForEmailModal}
          campaignHashId={campaign.hash_id}
          closeModal={(hasUpdatedAdGroup) => {
            if (hasUpdatedAdGroup) {
              onEmailSettingsUpdated();
            }
            closeAdGroupEmailModal();
            setAdGroupForEmailModal(null);
          }}
          brand={brand}
        />
      ) : null}
    </>
  );
};

export default AdGroupsCampaignCard;
