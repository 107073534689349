import React from "react";

import { Anchor, HoverCard, Text } from "@mantine/core";

import { SupportedFormat } from "models/Common";

import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";

import {
  PromoCode,
  ReferralLinkURL,
} from "components/contracts/deliverables/ReferralLinkAndPromoCode";

import PreSubmissionChecklist from "components/contracts/common/PreSubmissionChecklist";

function PromoCodeHoverCard({
  missingPromoCode,
  promoCode,
}: {
  missingPromoCode: boolean;
  promoCode: string;
}) {
  return (
    <HoverCard shadow="sm">
      <HoverCard.Target>
        <Anchor
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            /* intercept click events */
            event.preventDefault();
            event.stopPropagation();
          }}>
          promo code
        </Anchor>
      </HoverCard.Target>
      <HoverCard.Dropdown p={0}>
        <PromoCode
          requiresPromoCode
          missingPromoCode={missingPromoCode}
          promoCode={promoCode}
          disableBorder
        />
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

function ReferralLinkHoverCard({
  missingReferralLink,
  url,
}: {
  missingReferralLink: boolean;
  url: string;
}) {
  return (
    <HoverCard shadow="sm">
      <HoverCard.Target>
        <Anchor
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            /* intercept click events */
            event.preventDefault();
            event.stopPropagation();
          }}>
          referral link
        </Anchor>
      </HoverCard.Target>
      <HoverCard.Dropdown p={0}>
        <ReferralLinkURL
          requiresReferralLink
          missingReferralLink={missingReferralLink}
          url={url}
          disableBorder
        />
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

export default function LiveContentSubmissionChecklist({
  deliverableStatus,
  requiredVideoReview,
  brandName,
  format,
  requiresReferralLink,
  requiresPromoCode,
  missingReferralLink,
  missingPromoCode,
  referralLinkUrl,
  promoCode,
  creativeBriefUrl,
  disabled,
  isPostOrCarousel,
  children,
}: {
  deliverableStatus: ContractDeliverableStatus;
  requiredVideoReview: boolean;
  brandName: string;
  format: SupportedFormat;
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  referralLinkUrl: string;
  promoCode: string;
  creativeBriefUrl: string;
  disabled: boolean;
  isPostOrCarousel?: boolean;
  children: React.ReactElement;
}) {
  if (disabled) {
    return children;
  }

  const requiresInput =
    deliverableStatus === ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT ||
    deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED;

  const checklistItems: React.ReactElement[] = [];

  if (requiresReferralLink) {
    if (
      [
        SupportedFormat.INSTAGRAM_DEDICATED_REEL,
        SupportedFormat.INSTAGRAM_DEDICATED_POST,
        SupportedFormat.INSTAGRAM_CAROUSEL,
        SupportedFormat.TIKTOK_DEDICATED_VIDEO,
      ].includes(format)
    ) {
      checklistItems.push(
        <Text>
          I have added my{" "}
          <ReferralLinkHoverCard missingReferralLink={missingReferralLink} url={referralLinkUrl} />{" "}
          to the top of my bio, and I plan to keep it there for at least 7 days.
        </Text>,
      );
    } else if ([SupportedFormat.YOUTUBE_SHORT].includes(format)) {
      checklistItems.push(
        <Text>
          I will post my{" "}
          <ReferralLinkHoverCard missingReferralLink={missingReferralLink} url={referralLinkUrl} />{" "}
          in the comments section of my YouTube Short.
        </Text>,
      );
    } else if (
      [
        SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO,
        SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO,
        SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO,
        SupportedFormat.YOUTUBE_DEDICATED_VIDEO,
      ].includes(format)
    ) {
      checklistItems.push(
        <Text>
          I have posted my{" "}
          <ReferralLinkHoverCard missingReferralLink={missingReferralLink} url={referralLinkUrl} />{" "}
          at the top of the video description of my YouTube video, above the fold.
        </Text>,
      );
    }
  }

  const creativeBriefLink = (
    <Anchor
      href={creativeBriefUrl}
      target="_blank"
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
      }}>
      creative brief
    </Anchor>
  );

  if (requiresPromoCode) {
    checklistItems.push(
      <Text>
        I have displayed my{" "}
        <PromoCodeHoverCard missingPromoCode={missingPromoCode} promoCode={promoCode} />{" "}
        prominently, following the guidelines provided to me in the {creativeBriefLink}.
      </Text>,
    );
  }

  if (requiredVideoReview) {
    checklistItems.push(
      <Text>
        I have incorporated all of the latest feedback provided to me by {brandName} in my{" "}
        {isPostOrCarousel ? "post" : "video"}.
      </Text>,
    );
  }
  checklistItems.push(
    <Text>
      I have made sure to follow all the Do&apos;s and Do Not&apos;s in the {creativeBriefLink} (if
      applicable).
    </Text>,
  );
  checklistItems.push(
    <Text>
      My live {isPostOrCarousel ? "post" : "video"} is publically viewable and accessible to{" "}
      {brandName} for review.
    </Text>,
  );

  return (
    <PreSubmissionChecklist
      header={
        <Text size="md" fw="500">
          Before going live for your {brandName} partnership, please confirm the following:
        </Text>
      }
      checklistItems={checklistItems}
      disabled={!requiresInput}>
      {children}
    </PreSubmissionChecklist>
  );
}
