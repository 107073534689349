import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Divider, Group, Text } from "@mantine/core";

import {
  filterCreatorSetCandidates,
  getCreatorSetCandidates,
} from "admin/api/labeling/sourcingApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { AdminCreatorLabelingMain } from "admin/app/labeling/AdminCreatorLabelingMain";
import { AdminLabelingSidebar } from "admin/app/labeling/AdminLabelingSidebar";
import { CreatorDetails, CreatorSet } from "components/discovery/Datamodels";
import {
  FilteredReasonStat,
  LabelingDatasetItemsResponse,
  RecommendationAttribution,
} from "components/creator_lists/LabelingUtils";

export const AdminCreatorLabeling = ({
  syncProgress,
  setSyncProgress,
  searchParams,
  setSearchParams,
}: {
  syncProgress: boolean;
  setSyncProgress: (syncProgress: boolean) => void;
  searchParams: URLSearchParams;
  setSearchParams: (searchParams: URLSearchParams) => void;
}) => {
  const { creatorSets } = useAdminAppContext();
  const [creatorSetId, setCreatorSetId] = useState<number | null>(null);
  const [creatorSet, setCreatorSet] = useState<CreatorSet | null>(null);
  const [creatorId, setCreatorId] = useState<number | null>(null);
  // TODO(andrew): do we need this?
  const [candidates, setCandidates] = useState<LabelingDatasetItemsResponse>(null);
  const [error, setError] = useState<string | null>(null);
  const [emptyCandidates, setEmptyCandidates] = useState<boolean>(false);
  const [validCandidateIds, setValidCandidateIds] = useState<number[]>([]);
  const [cachedCreatorDetails, setCachedCreatorDetails] = useState<Record<number, CreatorDetails>>(
    {},
  );
  const [isLoadingCreatorDetails, setIsLoadingCreatorDetails] = useState<boolean>(true);
  const [attributionsMap, setAttributionsMap] = useState<
    Record<number, RecommendationAttribution[]>
  >({});
  const [filteredStats, setFilteredStats] = useState<FilteredReasonStat[]>([]);

  useEffect(() => {
    const paramCreatorSetId = searchParams.get("creatorSetId");
    if (paramCreatorSetId) {
      const parsedCreatorSetId = parseInt(paramCreatorSetId, 10);
      if (!Number.isNaN(parsedCreatorSetId) && parsedCreatorSetId !== creatorSetId) {
        setCreatorSetId(parsedCreatorSetId);
      }
    } else {
      setCreatorSetId(null);
    }
    // parse creator id
    const paramCreatorId = searchParams.get("creatorId");
    if (paramCreatorId) {
      const parsedCreatorId = parseInt(paramCreatorId, 10);
      if (!Number.isNaN(parsedCreatorId) && parsedCreatorId !== creatorId) {
        setCreatorId(parsedCreatorId);
        // also set it as valid candidate
        setValidCandidateIds((prev) => [...prev, parsedCreatorId]);
      }
    } else {
      setCreatorId(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (creatorSetId) {
      // if search params is not equal
      // update search params
      if (searchParams.get("creatorSetId") !== creatorSetId.toString()) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("creatorSetId", creatorSetId.toString());
        setSearchParams(newSearchParams);
      }
      // set creator set
      const newCreatorSet = creatorSets.find((cs) => cs.id === creatorSetId);
      if (newCreatorSet) {
        setCreatorSet(newCreatorSet);
      }
    }
  }, [creatorSetId, creatorSets]);

  useEffect(() => {
    if (creatorId) {
      if (searchParams.get("creatorId") !== creatorId.toString()) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("creatorId", creatorId.toString());
        setSearchParams(newSearchParams);
      }
    }
  }, [creatorId]);

  // Get candidates if creator set id is set and there are no valid candidate ids
  useEffect(() => {
    const abortController = new AbortController();
    if (creatorSetId && !emptyCandidates && validCandidateIds?.length === 0) {
      setError(null);
      getCreatorSetCandidates(creatorSetId, abortController).then((response) => {
        if (!response?.creatorIds) {
          setError(`Error in fetching candidates: ${(response as any)?.text || "Unknown error"}`);
        } else if (response?.creatorIds?.length === 0) {
          setEmptyCandidates(true);
        } else {
          setCandidates(response);
          setEmptyCandidates(false);
          // Update cached creator details
          // TODO(andrew): fix this
          // we currently don't store this cache because the returned data here is partial
          // ex: if these are tiktok candidates, we only have details for their tiktok profile
          // and frequently miss their instagram profile
          // const newCreatorDetails = response.creatorDetails.reduce((acc, creator) => {
          //   acc[creator.creator_id] = creator;
          //   return acc;
          // }, {} as Record<number, CreatorDetails>);
          // setCachedCreatorDetails((prev) => ({ ...prev, ...newCreatorDetails }));
          // Update valid candidate ids
          setValidCandidateIds((prev) => [...prev, ...response.creatorIds]);
          // Set creator id to the first valid candidate id
          if (response?.creatorIds?.length > 0) {
            setCreatorId(response.creatorIds[0]);
          }

          // set attributions map
          setAttributionsMap(response?.attribution || {});

          // set filtered stats
          setFilteredStats(response?.filteredStats || []);
        }
      });
    }
    return () => abortController.abort();
  }, [creatorSetId, validCandidateIds]);

  // Function to get next candidate
  // This will iterate through the existing candidate list
  // Make a quick call to the backend to filter out the candidates that have already been labeled
  // And then return the next valid candidate that needs labeling
  const getNextCandidate = () => {
    setIsLoadingCreatorDetails(true);
    // remove the current candidate
    const abortController = new AbortController();
    // remove the current candidate
    const currValidCandidateIds = validCandidateIds.filter((id) => id !== creatorId);
    filterCreatorSetCandidates(creatorSetId, currValidCandidateIds, abortController).then(
      (response) => {
        if (response?.creatorIds !== null && response?.creatorIds !== undefined) {
          setValidCandidateIds(response.creatorIds);
          if (response.creatorIds?.length > 0) {
            setCreatorId(response.creatorIds[0]);
          }
        }
      },
    );
    setSyncProgress(true);
  };

  const onChangeCreatorSetId = (csId: number) => {
    // reset creator id and valid candidate ids
    setCreatorId(null);
    setValidCandidateIds([]);
    // reset search params
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("creatorId");
    newSearchParams.set("creatorSetId", csId.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <Group gap={0} wrap="nowrap" align="flex-start" w="100vw">
      <AdminLabelingSidebar
        creatorSet={creatorSet}
        creatorId={creatorId}
        getNextCandidate={getNextCandidate}
        isLoadingCreatorDetails={isLoadingCreatorDetails}
        candidates={candidates}
        creatorSetId={creatorSetId}
        setCreatorSetId={setCreatorSetId}
        onChangeCreatorSetId={onChangeCreatorSetId}
        filteredStats={filteredStats}
      />
      <Divider orientation="vertical" />
      {error ? (
        <Group justify="center" align="center" h="100%" w="100%">
          <Text>{error || "Error in fetching candidates"}</Text>
        </Group>
      ) : null}
      {emptyCandidates ? (
        <Group justify="center" align="center" h="100%" w="100%">
          <Text>No candidates remaining for this creator set. Please flag with the team!</Text>
        </Group>
      ) : null}
      {!error && !emptyCandidates ? (
        <AdminCreatorLabelingMain
          creatorId={creatorId}
          creatorSetId={creatorSetId}
          setCreatorSetId={setCreatorSetId}
          cachedCreatorDetails={cachedCreatorDetails}
          setCachedCreatorDetails={setCachedCreatorDetails}
          isLoadingCreatorDetails={isLoadingCreatorDetails}
          setIsLoadingCreatorDetails={setIsLoadingCreatorDetails}
          attributionsMap={attributionsMap}
        />
      ) : null}
    </Group>
  );
};

export default AdminCreatorLabeling;
