import { Box } from "gestalt";
import React from "react";
import {
  BrowserRouter as Router,
  Routes, // replaces "Switch" used till v5
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import "gestalt/dist/gestalt.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Helmet, HelmetProvider } from "react-helmet-async";

import NavBar from "components/NavBar";
import Auth from "auth/Auth";
import PrivacyPolicy from "components/Privacy";
import TermsOfService from "components/TermsOfService";
import Profile from "pages/profile/Profile";
import { UserProvider } from "utils/UserContext";
import AuthLayout from "auth/AuthLayout";
import AuthenticationLanding from "auth/AuthenticationLanding";
import EmailVerificationConfirmation from "auth/EmailVerificationConfirmation";
import FacebookProvider from "social/fb/FacebookContext";
import Settings from "pages/settings/Settings";
import SettingsAccount from "pages/settings/SettingsAccount";
import SettingsAbout from "pages/settings/SettingsAbout";
import SettingsSocial from "pages/settings/SettingsSocial";
import SettingsVerification from "pages/settings/SettingsVerification";
import InstagramView from "pages/profile/InstagramView";
import Home from "pages/home/Home";
import ApplyCampaign from "components/campaign/ApplyCampaign";
import CreatorContract from "components/contracts/deliverables/CreatorContract";
import StripeRedirect from "components/contracts/deliverables/StripeRedirect";
import ContentReviewFeed from "components/contracts/review/ContentReviewFeed";
import { SessionProvider } from "utils/SessionContext";
import OnboardingThanks from "onboarding/OnboardingThanks";
import MediaKits from "pages/home/MediaKits";
import TiktokRedirect from "social/tiktok/TiktokRedirect";
import DiscoverLandingPage from "pages/home/DiscoverLandingPages";
import opsRoutes from "ops_team/OpsRoutes";
import ListView from "components/creator_lists/ListView";
import LabelingDatasetsListView from "components/creator_lists/LabelingDatasetsListView";
import RecommendedSearchView from "components/search/RecommendedSearch";
import SearchSummaryView from "components/search/SearchSummary";
import UserListView from "components/creator_lists/UserListView";
import Footer from "pages/home/Footer";
import FAQs from "components/FAQs";
import AdminApp from "admin/app/AdminApp";
import CampaignApp from "campaigns/CampaignApp";
import CreateContract from "components/contracts/contract/CreateContract";
import { CreatorSetInternalCreatorReviewView } from "components/creator_sets/CreatorSetInternalCreatorView";
import OnboardingV2 from "onboarding/v2/OnboardingV2";
import CampaignWaitlistScheduleTime from "components/campaign/CampaignWaitlistScheduleTime";
import InstagramCallback from "social/instagram/InstagramCallback";
import { InstagramDeleteData, InstagramLogout } from "social/instagram/InstagramConnect";
import OfferSelection from "components/offers/OfferSelection";
import adminRoutes from 'admin/AdminRoutes';
import EmbeddingDemo from "components/discovery/embedding_search/EmbeddingDemo";

const CLIENT_ID = "658226151527-ifg8d1jo4c79h9jqd8qag6rv64c6q0bh";

const MainApp = () => (
  <>
    <Box padding={4}>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Auth isLogin />} />
        <Route path="/signup" element={<Auth isLogin={false} />} />
        <Route path="/auth" element={<AuthenticationLanding />} />
        <Route path="/media-kits" element={<MediaKits />} />
        <Route path="/discover" element={<DiscoverLandingPage />} />
        <Route path="/apply_campaign/:campaignId" element={<ApplyCampaign />} />
        <Route path="/campaign-waitlist" element={<CampaignWaitlistScheduleTime />} />
        <Route path="/contracts/:contractId" element={<CreatorContract />} />
        <Route path="/contract_deliverables/:contractId" element={<CreatorContract />} />
        <Route path="/offers/:offerId" element={<OfferSelection />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/instagram/:username" element={<InstagramView />} />
        <Route path="/authentication/instagram/callback" element={<InstagramCallback />} />
        <Route path="/authentication/instagram/logout" element={<InstagramLogout />} />
        <Route path="/authentication/instagram/delete" element={<InstagramDeleteData />} />
        <Route path="/authentication/tiktok" element={<TiktokRedirect />} />
        <Route path="/authentication/stripe/:contractId" element={<StripeRedirect />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route element={<AuthLayout canContinue />}>
          <Route path="/:username" element={<Profile />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/onboarding" element={<OnboardingV2 />} />
          <Route path="/onboarding_thanks" element={<OnboardingThanks />} />
          <Route path="/settings" element={<Settings />}>
            <Route index element={<SettingsAccount />} />
            <Route path="account" element={<SettingsAccount />} />
            <Route path="about" element={<SettingsAbout />} />
            <Route path="social" element={<SettingsSocial />} />
            <Route path="verification" element={<SettingsVerification />} />
          </Route>
          <Route path="/:username/saved/" element={<UserListView />} />
          <Route path="/:username/saved/:listname" element={<ListView />} />
          <Route
            path="/campaign-creator-candidates/:campaignId/:platform"
            element={<Navigate to="/campaigns" replace />}
          />
          <Route path="/create_contract" element={<CreateContract />} />
          <Route path="/labeling-search" element={<LabelingDatasetsListView />} />
          <Route path="/labeling-search-summary" element={<SearchSummaryView />} />
          <Route path="/labeling-search-recs" element={<RecommendedSearchView />} />
          <Route path="/internal-review" element={<CreatorSetInternalCreatorReviewView />} />
          <Route
            path="/campaign-creator-candidates/:campaignId"
            element={<Navigate to="/campaigns" replace />}
          />
          <Route path="/review/:campaignHashId" element={<ContentReviewFeed />} />
        </Route>
        {opsRoutes()}
        {adminRoutes()}
        <Route path="/admin/embedding_demo" element={<EmbeddingDemo />} />
      </Routes>
    </Box>
    <Footer />
  </>
);

const AppSwitcher = () => {
  const location = useLocation();
  const isCampaignPortal = location.pathname.startsWith("/campaigns");
  if (isCampaignPortal) {
    return <CampaignApp />;
  }
  const isAdminPortal = location.pathname.startsWith("/admin");
  if (isAdminPortal) {
    return <AdminApp />;
  }
  return <MainApp />;
};

const App = () => (
  <HelmetProvider>
    <Helmet>
      <title>1stCollab - Launch your influencer campaign in minutes</title>
      <meta name="description" content="Launch your influencer campaign in minutes" />
      <meta
        name="title"
        property="og:title"
        content="1stCollab - Launch your influencer campaign in minutes"
      />
      <meta name="type" property="og:type" content="website" />
      <meta name="image" property="og:image" content="favicon.svg" />
      <meta name="url" property="og:url" content="https://www.1stcollab.com" />
      <meta
        name="description"
        property="og:description"
        content="Connecting the best creators with world class brands."
      />
    </Helmet>
    <Router>
      <SessionProvider>
        <UserProvider>
          <GoogleOAuthProvider clientId={CLIENT_ID}>
            <FacebookProvider>
              <AppSwitcher />
            </FacebookProvider>
          </GoogleOAuthProvider>
        </UserProvider>
      </SessionProvider>
      {/* <Routes>
          <Route path="offline_sandbox" element={<OfflineSandbox />} />
        </Routes> */}
    </Router>
  </HelmetProvider>
);

export default App;
