import React, { useState, useEffect } from "react";
import { Card, Group, Stack, Text, Button, Textarea, Flex, Center, Tooltip, Chip, ThemeIcon, Anchor, SimpleGrid } from "@mantine/core";
import { IconAlertCircle, IconBrandTiktok, IconBrandInstagram, IconBrandYoutube, IconInfoCircle } from "@tabler/icons-react";

import {  TaskStage, DeliverableContent } from "components/contracts/tasks/models/Common";
import { submitCreatorRatings } from "components/contracts/tasks/api/Api";
import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";
import { POSITIVE_TAGS, NEGATIVE_TAGS, Rating } from "models/Rating";
import { getDeliverablesViewData } from "components/contracts/common/Api";

import { toDateTimeStringNoYear } from "utils/DateUtils";
import { User } from "firebase/auth";
import AuthContext from "auth/AuthContext";
import { useOutletContext } from "react-router-dom";
import Stars from "components/ratings/Stars";

type Deliverable = DeliverableContent & {
  liveContentUrl: string;
}

type SupportedPlatform = "Instagram" | "TikTok" | "YouTube";

function AutoExpirationNotification({ dateCreated }: { dateCreated: Date }) {
  const expirationDate = new Date(dateCreated);
  expirationDate.setDate(expirationDate.getDate() + 7);
  return (
    <Card bg="var(--mantine-color-yellow-light)" p={4}>
      <Flex gap={4} align="center" justify="center">
        <ThemeIcon color="#F08C00" radius="xl" variant="transparent">
          <IconAlertCircle size="1.2rem" />
        </ThemeIcon>
        <Text fw="700" size="sm" c="#F08C00">
          We will hide this task on {toDateTimeStringNoYear(expirationDate)} PDT. You can rate the creator directly on their partnership page after then.
        </Text>
      </Flex>
    </Card>
  );
} 

function DeliverablesSection({ deliverables }: { deliverables: Deliverable[] }) {

  const sourceToIcon: Record<SupportedPlatform, JSX.Element> = {
    "Instagram": <IconBrandInstagram size={24} />,
    "TikTok": <IconBrandTiktok size={24} />,
    "YouTube": <IconBrandYoutube size={24} />,
  };

  const formatToLabel: Record<string, { source: SupportedPlatform, label: string }> = {
    "instagram_dedicated_post": {
      source: "Instagram",
      label: "Dedicated Post"
    },
    "instagram_dedicated_reel": {
      source: "Instagram",
      label: "Dedicated Reel"
    },
    "instagram_carousel": {
      source: "Instagram",
      label: "Carousel"
    },
    "tiktok_dedicated_video": {
      source: "TikTok",
      label: "Dedicated Video"
    },
    "youtube_dedicated_video": {
      source: "YouTube",
      label: "Dedicated Video"
    },
    "youtube_90s_integrated_video": {
      source: "YouTube",
      label: "90s Integrated Video"
    },
    "youtube_60s_integrated_video": {
      source: "YouTube",
      label: "60s Integrated Video"
    },
    "youtube_30s_integrated_video": {
      source: "YouTube",
      label: "30s Integrated Video"
    },
    "youtube_short": {
      source: "YouTube",
      label: "Short"
    }
  }

  function getPlatformIcon(platform: string): JSX.Element | null {
    return sourceToIcon[platform as SupportedPlatform] ?? null;
  }

  return (
    <Card radius="md" withBorder bg="#F1F3F5">
      <Stack gap="xs">
        <Text fw={500}>Live Deliverables</Text>
        <Stack gap="md">
          <SimpleGrid
            spacing="md"
            cols={deliverables.length}
            style={{ width: "100%" }}
          >
            {deliverables.map((deliverable, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Card key={index} radius="sm" withBorder>
                <Group>
                  {getPlatformIcon(formatToLabel[deliverable.format].source)}
                  <Stack gap="0">
                    <Text fw={500}>
                      {formatToLabel[deliverable.format].source}: {formatToLabel[deliverable.format].label}
                    </Text>
                    <Text size="sm">
                      {deliverable.liveContentUrl ? (
                        <Anchor href={deliverable.liveContentUrl} target="_blank">
                          View Live Content
                        </Anchor>
                      ) : (
                        "No live content available"
                      )}
                    </Text>
                  </Stack>
                </Group>
              </Card>
            ))}
          </SimpleGrid>
        </Stack>
      </Stack>
    </Card>
  );
}

function RatingContent({
  rating,
  setRating,
  onSubmit,
}: {
  rating: Rating;
  setRating: React.Dispatch<React.SetStateAction<Rating>>;
  onSubmit: () => void;
}) {
  const isFiveStars = rating.starValue === 5;
  const isOther = rating.tags.includes("Other");
  const tags = isFiveStars ? POSITIVE_TAGS : NEGATIVE_TAGS;

  const handleStarClick = (starValue: number) => {
    if (starValue === rating.starValue) {
      return setRating({
        ...rating,
        starValue: 0,
        tags: [],
        description: ""
      });
    }
    return setRating({
      ...rating,
      starValue,
      tags: [],
      description: ""
    });
  };

  const handleTagClick = (newTags: string[]) => {
    setRating({ ...rating, tags: newTags });
  };

  return (
    <Stack gap="sm">
      <Card radius="md" withBorder bg="white">
        <Stack gap="xs">
          <Flex>
            <Text size="sm">
              How likely are you to work with this creator again?
            </Text>
            <Center pl="xs">
              <Tooltip 
                multiline
                arrowSize={4} 
                w={220}
                label={
                  <Text ta="center" size="xs">
                    Your ratings will be aggregated and anonymized. Creators cannot see how you rate them.
                  </Text>
                }
                color="#868E96" 
                withArrow>
                <IconInfoCircle size={18} color="#868E96"/>
              </Tooltip>
            </Center>
          </Flex>

          <Stars 
            rating={rating}
            handleStarClick={handleStarClick}
          />
          
          {rating.starValue > 0 && (
            <Stack gap="xs">
              <Text size="sm">
                {isFiveStars
                  ? "What did you like about this partnership?"
                  : "What issues did you encounter in this partnership?"}
              </Text>
              <Chip.Group multiple value={rating.tags} onChange={(newTags) => handleTagClick(newTags)}>
                <Group wrap="wrap" gap="xs" w="70%">
                  {tags.map((tag) => 
                    <Chip key={tag} variant="outline" value={tag}>{tag}</Chip>
                  )}
                </Group>
              </Chip.Group>
            </Stack>
          )}

          {isOther && (
            <Textarea
              label="Add reasons for 'Other':"
              value={rating.description}
              onChange={(event) => setRating({
                ...rating,
                description: event.currentTarget.value
              })}
            />
          )}
        </Stack>
      </Card>

      <Button 
        onClick={onSubmit} 
        disabled={rating.starValue === 0}
        color={rating.starValue > 0 ? "#228BE6" : "#E9ECEF"}
      >
        <Text c={rating.starValue > 0 ? "#FFFFFF" : "#ADB5BD"}>Submit Rating</Text>
      </Button>
    </Stack>
  );
}

export default function CreatorRatingsContent({
  taskId,
  taskType,
  dateCreated,
  avatarUrl,
  displayName,
  creatorId,
  contractId,
  handleCompleteTask,
}: {
  taskId: string;
  taskType: TaskStage;
  dateCreated: Date;
  avatarUrl: string;
  displayName: string;
  creatorId: string;
  contractId: string;
  handleCompleteTask: (waitingForCreator?: boolean) => void;
}) {
  const [rating, setRating] = useState<Rating>({
    starValue: 0,
    tags: [],
    description: "",
  });
  const [deliverables, setDeliverables] = useState<Deliverable[]>([]);
  const [isLoadingDeliverables, setIsLoadingDeliverables] = useState(true);
  const user: User = useOutletContext<AuthContext>()?.user;

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDeliverables = async () => {
      try {
        getDeliverablesViewData(user, contractId, abortController).then((response) => {
          if (response.success && response.contract?.deliverables) {
            setDeliverables(response.contract.deliverables);
          }
        });
      } catch (error) {
        console.error("Failed to fetch deliverables:", error);
      } finally {
        setIsLoadingDeliverables(false);
      }
    };

    fetchDeliverables();
    return () => {
      abortController.abort();
    };
  }, [contractId]);

  const handleSubmitRating = async () => {
    try {
      if (rating.starValue === 0) {
        showFailureNotification({
          title: "Error",
          message: "Please select a rating before submitting.",
        });
        return;
      }
      const response = await submitCreatorRatings({
        contractId,
        starValue: rating.starValue,
        tags: rating.tags,
        other_notes: rating.description,
      });

      if (response.success) {
        showSuccessNotification({
            title: "Success",
            message: "Rating submitted successfully.",
        });
        handleCompleteTask();
      } else {
        showFailureNotification({
          title: "Error",
          message: response.error || "Failed to submit rating.",
        });
      }
    } catch (error) {
      showFailureNotification({
        title: "Error",
        message: "An error occurred while submitting the rating.",
      });
    }
  };

  return (
    <Stack gap="xs">
        <Stack py="sm">
          <AutoExpirationNotification dateCreated={dateCreated}/>
        </Stack>
        {!isLoadingDeliverables && deliverables.length > 0 && (
          <DeliverablesSection deliverables={deliverables} />
        )}
        <RatingContent
            rating={rating}
            setRating={setRating}
            onSubmit={handleSubmitRating}
        />
    </Stack>
  );
} 